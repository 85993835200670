import styled from "styled-components/macro"; // Импортим именно /macro (для читаемых имен классов)
import DonorLogo from "../../../../img/DonorLogo.png";
import Checkmark from "../../../../img/checkmark.svg";


// Комопонент - функция
export default function Thanks () {
  let value = 1;
  return (
      <Content>
        <Container>
          <Logo src={DonorLogo} />
          <CheckmarkImg src={Checkmark} />
          <Title>Thank you for your support!</Title>
          <Text>We have sent you an email with a confirmation and link to login page. </Text>
        </Container>
      </Content>
  );
}

const Content = styled.main`
  width: 100%;
  border-top: 1px solid #E0E0E0;
  padding-top: 123px;
  padding-bottom: 200px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1242px;
  margin: 0px auto;
`;

const Logo = styled.img`
  display: block;
  margin: 0px auto;
`;

const CheckmarkImg = styled.img`
  display: block;
  margin: 80px auto 38px auto;

`;

const Title = styled.h1`
  display: block;
  text-align: center;
  font-weight: 450;
  font-size: 26px;
  margin-bottom: 28px;
  line-height: 26px;
`;


const Text = styled.p`
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #1F1F1F;
  max-width: 380px;
  margin: 0px auto;
`;

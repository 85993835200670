import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import axios from 'axios';
import CloseIcon from '../../../../../img/close.svg';

export default function TermsAndConditionsModal({
  onClose,
  type,
}: {
  onClose: () => void;
  type: string;
}) {
  const [content, setContent] = useState('');

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND}/api/open/tc-find/${type}`,
      )
      .then((res) => setContent(res.data.tc_html));
  }, [type]);

  return (
    <Container>
      <CloseButton onClick={onClose} alt="" src={CloseIcon} />
      <ModalContent>
        {content && content !== '' ? (
          <HtmlRenderIframe src={content} />
        ) : (
          'loading..'
        )}
      </ModalContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100%;
  max-height: 80vh;
  box-sizing: border-box;
  max-width: 700px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const ModalContent = styled.div`
  height: 630px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: scroll;
`;

const HtmlRenderIframe = styled.iframe`
  width: 640px;
  height: 550px;
`;

import styled from 'styled-components/macro';
import { Select } from 'antd';

const { Option } = Select;

interface SelectInputProps {
  setFieldValue: (field: string, value: string) => void;
  initialData: Array<string>;
  name: string;
  value: string;
  className?: string;
  placeholder?: string;
}

export default function SelectInput({
  setFieldValue, name, value, className, initialData, placeholder,
}: SelectInputProps) {
  return (
    <StyledSelect placeholder={placeholder} className={className} value={value} onChange={(v) => setFieldValue(name, v as string)}>
      {initialData.map((d) => <Option key={d} value={d}>{d}</Option>)}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  /* border-radius: 4px !important; */

  .ant-select-selector {
    box-sizing: border-box;
    padding: 0;
  }
`;

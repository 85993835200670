import * as yup from 'yup';
import { SchoolRegisterType, SchoolUpdateType } from './types';
import { billingSchemaFieldsRequiered } from '../billing/schemas';

export const schoolCreateSchema: yup.SchemaOf<SchoolRegisterType> = yup.object({
  schoolName: yup.string().required(),
  URN: yup.string().required(),
  charityName: yup.string().optional(),
  charityNumber: yup.number().optional(),
  logo: yup.string().optional(),
}).concat(billingSchemaFieldsRequiered);


export const schoolChangeSchema: yup.SchemaOf<SchoolUpdateType> = yup.object({
  email: yup.string().email('email must be a valid email').optional(),
  password: yup.string().optional(),
  schoolName: yup.string().optional(),
  URN: yup.string().optional(),
  postcode: yup.string().optional(),
  address1: yup.string().optional(),
  address2: yup.string().optional(),
  address3: yup.string().optional(),
  town: yup.string().optional(),
  country: yup.string().optional(),
  firstName: yup.string().optional(),
  lastName: yup.string().optional(),
  phoneNumber: yup.string().optional(),
  bankAccountName: yup.string().optional(),
  branchSortCode1: yup.string().optional(),
  branchSortCode2: yup.string().optional(),
  branchSortCode3: yup.string().optional(),
  logo: yup.string().optional(),
  accountNumber: yup.string().optional(),
});

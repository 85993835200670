import * as yup from 'yup';
import { ApiCreateUser } from './api-types';

export const signUpSchema: yup.SchemaOf<ApiCreateUser['data']> = yup.object({
  email: yup
    .string()
    .email('email must be a valid email')
    .defined('email must be a valid email'),
  password: yup.string().max(256).min(5).defined(),
});

import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import { SchoolCollectionTableRow } from '../../../types';
import { formatDateDDMMYY } from "../../../../../ui/DateFormatMethods";

interface CollectionsTableProps {
  tableData: Array<SchoolCollectionTableRow>;
}

export default function CollectionsTable({ tableData }: CollectionsTableProps) {
  const tableConfig: ColumnsType<SchoolCollectionTableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => formatDateDDMMYY(new Date(text))
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Amount',
      dataIndex: 'collectionAmount',
      key: 'collectionAmount',
    },
  ];

  return (
    <Table<SchoolCollectionTableRow>
      dataSource={tableData}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE' }}
    />
  );
}

import styled from 'styled-components/macro';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import { DonationsTableRow } from '../../../types';
import { CampaignCollectionType } from "../../../../../project-types/campaign/types";
import { formatDateDDMMYY } from "../../../../../ui/DateFormatMethods";

interface CollectionsTableProps {
  tableData: Array<DonationsTableRow>;
  onShowContributorInfoHandler: (contributorId: string) => void;
}

export default function CollectionTable({
  tableData,
  onShowContributorInfoHandler,
}: CollectionsTableProps) {
  const tableConfig: ColumnsType<DonationsTableRow> = [
    {
      title: 'Collection ID',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Contributor Name',
      dataIndex: 'contributorName',
      key: 'contributorName',
      render: (value, record) => (
        <ContributorNameLink
          onClick={() => onShowContributorInfoHandler(record.contributorID)}
        >
          {value}
        </ContributorNameLink>
      ),
    },
    {
      title: 'Collection Date',
      dataIndex: 'collectionDate',
      key: 'collectionDate',
      render: (text) => formatDateDDMMYY(new Date(text)),
    },
    {
      title: 'Amount',
      dataIndex: 'collectionAmount',
      key: 'collectionAmount',
    },
    {
      title: 'Gift Aid Amount',
      dataIndex: 'giftAidAmount',
      key: 'giftAidAmount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => text[0] + text.slice(1).toLowerCase(),
    },
  ];

  return (
    <Table<DonationsTableRow>
      dataSource={tableData}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE' }}
    />
  );
}

const ContributorNameLink = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

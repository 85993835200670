import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { adminCreateCampaign } from '../../../feature/adminState';
import { CampaignAdminCreateType } from '../../../project-types/admin-campaigns/types';
import ROUTER_CONFIG from '../../../router/router-config';
import { CampaignFormFields } from '../../school/types';
import AdminLayout from '../layout/AdminLayout';
import AdminCreateCampaign from './AdminCreateCampaign';

export default function CreateCampaignPage() {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { schoolId } = useParams<{ schoolId: string }>();

  const onSuccessCreateCampaign = () => push(ROUTER_CONFIG.ADMIN_SCHOOL_PAGE.getPath({ schoolId }));

  const onFormSubmitHandler = (formFields: CampaignFormFields) => {
    const adminCreateCampaignFields: CampaignAdminCreateType = { ...formFields, school: schoolId };
    dispatch(adminCreateCampaign(adminCreateCampaignFields, onSuccessCreateCampaign));
  };

  return (
    <AdminLayout>
      <AdminCreateCampaign onSubmit={onFormSubmitHandler} />
    </AdminLayout>
  );
}

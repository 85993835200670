import { useState } from 'react';
import styled from 'styled-components/macro';
import Logo from './Logo';
import TwitterIcon from '../../img/icons/social-icons/twitter.svg';
import FacebookIcon from '../../img/icons/social-icons/facebook.svg';
import LinkedinIcon from '../../img/icons/social-icons/linkedin.svg';
import ModalInPortal from '../../ui/Modal/ModalPortal';
import TermsAndConditionsModal from '../school/campaign/campaign-folder/Modals/TermsAndConditionsModal';

export default function Footer() {
  const [termsType, setTermsType] = useState('');

  const helpLinks = [
    {
      title: 'Support centre',
      link: 'https://www.edgecomputers.co.uk/about',
    },
    {
      title: 'Contact Us',
      link: 'https://www.edgecomputers.co.uk/about',
    },
  ];

  const legalLinks = [
    {
      title: 'Website Terms',
      type: 'donorterms',
    },
    {
      title: 'Privacy Policy',
      type: 'donorprivacy',
    },
    {
      title: 'Service Agreement',
      type: 'donoragreement',
    },
  ];

  const TermsAndCondition = ModalInPortal();

  return (
    <Container>
      {TermsAndCondition.ModalRender && (
        <TermsAndCondition.ModalRender>
          <TermsAndConditionsModal
            type={termsType}
            onClose={TermsAndCondition.onClose}
          />
        </TermsAndCondition.ModalRender>
      )}
      <LinksContainer>
        <LinksBlock>
          <LinksTitle>Help</LinksTitle>
          {helpLinks.map((link) => (
            <Link
              key={link.title}
              href={link.link}
              target="_blank"
              rel="noreferrer"
            >
              {link.title}
            </Link>
          ))}
        </LinksBlock>
        <RightLinksBlock>
          <LinksBlock>
            <LinksTitle>Legal</LinksTitle>
            {legalLinks.map(({ title, type }) => (
              <Link
                onClick={() => {
                  setTermsType(type);
                  TermsAndCondition.onShow();
                }}
              >
                {title}
              </Link>
            ))}
          </LinksBlock>
        </RightLinksBlock>
      </LinksContainer>
      <BottomContainer>
        <Logo />
        <StyledText>
          © 2023 Silverwing. All Rights Reserved.{' '}
          <StyledLink
            onClick={() => {
              setTermsType('donorterms');
              TermsAndCondition.onShow();
            }}
          >
            Terms
          </StyledLink>{' '}
          and{' '}
          <StyledLink
            onClick={() => {
              setTermsType('donorprivacy');
              TermsAndCondition.onShow();
            }}
          >
            Privacy
          </StyledLink>{' '}
        </StyledText>
        <SocialLinksBlock>
          <SocialLink>
            <img alt="" src={FacebookIcon} />
          </SocialLink>
          <SocialLink>
            <img alt="" src={LinkedinIcon} />
          </SocialLink>
          <SocialLink>
            <img alt="" src={TwitterIcon} />
          </SocialLink>
        </SocialLinksBlock>
      </BottomContainer>
    </Container>
  );
}

const Container = styled.footer`
  padding: 30px 150px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  margin-top: auto;
`;

const LinksContainer = styled.div`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
`;

const RightLinksBlock = styled.div`
  display: flex;
  gap: 100px;
`;

const LinksBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LinksTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  color: #6b7a87;
  margin-bottom: 5px;
`;

const Link = styled.a`
  text-decoration: none;
  color: #909da8;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  margin-top: 49px;
  padding: 20px 10px 30px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dee7f0;
`;

const StyledText = styled.span`
  color: #909da8;
  font-size: 14px;
`;

const SocialLinksBlock = styled.div`
  display: flex;
  gap: 12.5px;
`;

const SocialLink = styled.a`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fafafa;
  width: 28px;
  height: 28px;
`;

const StyledLink = styled.span`
  cursor: pointer;
  color: #1890ff;
`;

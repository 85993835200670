import {
  FieldWrap, FieldName, FieldCodeWrap, FieldCode,
} from '../LayoutComponents';

interface BranchSortCodeProps {
  branchSortCode1: string;
  branchSortCode2: string;
  branchSortCode3: string;
}

export function BranchSortCode({ branchSortCode1, branchSortCode2, branchSortCode3 }: BranchSortCodeProps) {
  const resultArr = branchSortCode1.concat(branchSortCode2).concat(branchSortCode3).split('');

  return (
    <FieldWrap>
      <FieldName>Branch Sort Code</FieldName>
      <FieldCodeWrap>
        {resultArr.map((num: string) => <FieldCode key={num}>{num}</FieldCode>)}
      </FieldCodeWrap>
    </FieldWrap>
  );
}

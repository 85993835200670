import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { DatePicker, Input } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonDark } from '../../../../../ui/Button';
import CloseIcon from '../../../../../img/close.svg';
import {
  CALLIBRATION_OPTIONS,
  ChangePaymentPlanForm,
  COLLECTION_FREQUENCY,
  COLLECTION_PERIOD,
  INITIAL_COLLECTION_AMOUNT,
  PAYMENT_PLAN,
  PAYMENT_PLAN_OPTIONS,
} from '../../../types';
import { ChangePaymentPlanSchema } from '../../../school-schemas';
import { RadioGroup, RadioItem } from '../../../../../ui/forms/Radio';
import {
  loadContributorScheme,
  postLoadCancelCollection,
  postLoadPayment,
} from '../../../../../feature/campaignState';

interface IChangePaymentPlanModalProps {
  onClose: () => void;
  onChangeSubmit: (values: ChangePaymentPlanForm) => void;
  campaignId: string;
  contributorId: string;
}

export default function ChangeCancelFurtherCollection({
  onClose,
  onChangeSubmit,
  campaignId,
  contributorId,
}: IChangePaymentPlanModalProps) {
  const dispatch = useDispatch();
  const [calibrationChoice, setCalibrationChoice] = useState('no');
  const [
    selectedCollectionFrequency,
    setSelectedCollectionFrequency,
  ] = useState('Monthly');
  const [
    selectedInitialCollectionAmount,
    setSelectedInitialCollectionAmount,
  ] = useState('same');
  const [selectedCollectionPeriod, setSelectedCollectionPeriod] = useState('continue');

  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [customRecurringAmount, setCustomRecurringAmount] = useState('');
  const [
    customInitialCollectionAmount,
    setCustomInitialCollectionAmount,
  ] = useState('');
  const [customNumberCollections, setCustomNumberCollections] = useState('');

  const cancelPlanOptions = [
    { text: 'Take no further payments', status: 'no' },
    {
      text: 'Take a final payment that covers all remaining sum due',
      status: 'final',
    },
    // { text: 'Custom action', status: 'custom' },
  ];

  const customCollectionFrequencyList = [
    'Monthly',
    'Quarterly',
    'Annually',
    'Termly',
    'Yearly',
  ];

  const customInitialCollectionAmountList = [
    { text: 'The same as monthly collections', value: 'same' },
    { text: 'Initial collection amount is different', value: 'different' },
  ];

  const customCollectionPeriodList = [
    { text: 'Continuous collections', value: 'continue' },
    { text: 'Fixed number of collections', value: 'fixed' },
  ];

  const getFormkiInitiaValues = (): ChangePaymentPlanForm => {
    const defaultValues = {
      options: PAYMENT_PLAN_OPTIONS.WITHIN,
      campaignName: '',
      paymentPlan: PAYMENT_PLAN.MONTHLY,
      callibration: CALLIBRATION_OPTIONS.NO_CALLIBRATION,
      startDate: '',
      endDate: '',
      collectionFrequency: COLLECTION_FREQUENCY.MONTHLY,
      recurringAmount: '',
      initialAmount: INITIAL_COLLECTION_AMOUNT.SAME,
      collectionPeriod: COLLECTION_PERIOD.CONTINIOUS,
      numOfCollections: '',
    };

    return defaultValues;
  };

  const formik = useFormik({
    initialValues: getFormkiInitiaValues(),
    validationSchema: ChangePaymentPlanSchema,
    onSubmit: (values) => onChangeSubmit(values),
  });

  const onSubmitPayment = () => {
    const customData = {
      currency: 'GBP',
      initialAmount:
        customInitialCollectionAmount !== ''
          ? customInitialCollectionAmount
          : '0',
      recurringAmount:
        customRecurringAmount !== '' ? customRecurringAmount : '0',
      collectionFrequency: selectedCollectionFrequency,
      numberOfCollections:
        customNumberCollections !== '' ? customNumberCollections : '0',
      startDate: customStartDate,
      endDate: customEndDate,
    };
    if (calibrationChoice === 'custom' && contributorId && campaignId) {
      postLoadPayment(contributorId, campaignId, customData).then((res) =>
        res.executed ? onClose() : alert('This is not allowed')
      );
      setTimeout(() => {
        dispatch(loadContributorScheme(contributorId));
      }, 1000);
    }
    if (calibrationChoice !== 'custom' && contributorId && campaignId) {
      postLoadCancelCollection(contributorId, campaignId, calibrationChoice).then(
        (res) => res.success ? onClose() : alert('This is not allowed')
      );
      setTimeout(() => {
        dispatch(loadContributorScheme(contributorId));
      }, 1000);
    }
  };

  return (
    <Container>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>Cancel Plan</Title>
      <form onSubmit={formik.handleSubmit}>
        <RadioGroup value={calibrationChoice}>
          {cancelPlanOptions.map((el) => (
            <RadioItem
              value={el.status}
              onChange={() => setCalibrationChoice(el.status)}
            >
              {el.text}
            </RadioItem>
          ))}
        </RadioGroup>
        {calibrationChoice === 'custom' && (
          <CustomWrapper>
            <SubTitle>Payments Start Date</SubTitle>
            <StyledDatePicker
              placeholder="Select a date"
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              onChange={(value, dateString) => setCustomStartDate(dateString)}
            />
            <SubTitle>Payments End Date</SubTitle>
            <StyledDatePicker
              placeholder="Select a date"
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              onChange={(value, dateString) => setCustomEndDate(dateString)}
            />
            <SubTitle>Collection Frequency*</SubTitle>
            <RadioGroup value={selectedCollectionFrequency}>
              {customCollectionFrequencyList.map((el) => (
                <RadioItem
                  value={el}
                  onChange={() => setSelectedCollectionFrequency(el)}
                >
                  {el}
                </RadioItem>
              ))}
            </RadioGroup>
            <SubTitle style={{ marginTop: 20 }}>
              Recurring collection amounts
            </SubTitle>
            <StyledInput
              placeholder="£"
              value={customRecurringAmount}
              onChange={(e) => setCustomRecurringAmount(e.target.value)}
            />
            <SubTitle style={{ marginTop: 20 }}>
              Initial collection amount
            </SubTitle>
            <RadioGroup value={selectedInitialCollectionAmount}>
              {customInitialCollectionAmountList.map((el) => (
                <RadioItem
                  value={el.value}
                  onChange={() => setSelectedInitialCollectionAmount(el.value)}
                >
                  {el.text}
                </RadioItem>
              ))}
            </RadioGroup>
            {selectedInitialCollectionAmount === 'different' && (
              <>
                <SubTitle style={{ marginTop: 10 }}>
                  Initial collection amount
                </SubTitle>
                <StyledInput
                  placeholder="£"
                  value={customInitialCollectionAmount}
                  onChange={(e) =>
                    setCustomInitialCollectionAmount(e.target.value)
                  }
                />
              </>
            )}
            <SubTitle style={{ marginTop: 20 }}>Collection Period</SubTitle>
            <RadioGroup value={selectedCollectionPeriod}>
              {customCollectionPeriodList.map((el) => (
                <RadioItem
                  value={el.value}
                  onChange={() => setSelectedCollectionPeriod(el.value)}
                >
                  {el.text}
                </RadioItem>
              ))}
            </RadioGroup>
            {selectedCollectionPeriod === 'fixed' && (
              <>
                <SubTitle style={{ marginTop: 20 }}>
                  Number of collections
                </SubTitle>
                <StyledInput
                  value={customNumberCollections}
                  onChange={(e) => setCustomNumberCollections(e.target.value)}
                />
              </>
            )}
          </CustomWrapper>
        )}
        <SubmitButton type="submit" onClick={onSubmitPayment}>
          Cancel
        </SubmitButton>
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  box-sizing: border-box;
  max-width: 572px;
  max-height: 90vh;
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 14px 57px;
  align-self: flex-start;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-top: 74px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const SubTitle = styled.h4`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
`;

const CustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
  min-height: 200px;
  margin-top: 20px;
  padding: 26px 36px;
  background-color: rgba(245, 248, 251, 0.7);
`;

const StyledDatePicker = styled(DatePicker)`
  width: 192px;
  height: 40px;
  margin-bottom: 20px;
`;

const StyledInput = styled(Input)`
  max-width: 313px;
  width: 100%;
  height: 41px;
`;

import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
// import { CloseIcon } from '../../images/iconSvg';

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  unclosed?: boolean;
  className?: string;
}

const Modal = ({
  children, onClose, unclosed, className,
}: ModalProps) => {
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape' && !unclosed) onClose();
  });

  return (
    <Page>
      <Background onClick={() => !unclosed && onClose()} />
      <Container className={className}>
        {/* {!unclosed && (
          <CloseIconStyled onClick={() => onClose()} />
        )} */}
        {children}
      </Container>
    </Page>
  );
};

export default Modal;

const Page = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 99999;
`;

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Container = styled.div`
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  align-self: baseline;
  position: relative;
  margin-top: calc(5vh);
  z-index: 1000;
`;

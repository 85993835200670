import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import ArrowDownImg from '../img/arrowDown.svg';

interface DropDownItemProps {
  title: string,
  onClick: () => void
}

export function DropDownItem({ title, onClick }: DropDownItemProps) {
  return (
    <DropDownItemWrap href="#" onClick={onClick}>
      {/* <img src={icon} alt={title} /> */}
      <ItemText>{title}</ItemText>
    </DropDownItemWrap>
  );
}

const DropDownItemWrap = styled.a`
  display: flex;
  padding: 10px;
  text-decoration: none;
  :hover {
    background-color: #F5F8FB
  }
`;

const ItemText = styled.div`
  font-size: 14px;
  color: #000000;
  /* margin-left: 14px; */
  font-weight: 450;
`;

interface DropDownProps {
  children: ReactNode;
  title?: string;
}

export default function DropDown({ children, title = 'Login' }: DropDownProps) {
  return (
    <DropDownWrap>
      <LoginWrap>
        <Title>
          {title}
        </Title>
        <IconArrow src={ArrowDownImg} alt="drop-down" />
      </LoginWrap>
      <Menu>
        {children}
      </Menu>
    </DropDownWrap>
  );
}

const Menu = styled.div`
  position: absolute;
  background: #FFFFFF;
  box-shadow: 3px 3px 14px 3px rgba(0, 0, 0, 0.08);
  width: 179px;
  right: 0;
  display: none;
  flex-direction: column;
  padding: 10px;
  z-index:10;
`;

const DropDownWrap = styled.nav`
  position: relative;
  :hover ${Menu} {
    display: flex;
  }
`;

const Title = styled.span`
  font-size: 18px;
  line-height: 22px;
`;

const LoginWrap = styled.div`
  display: flex;
  justify-content: flex-end;

`;

const IconArrow = styled.img`
  padding-left: 5px;
`;

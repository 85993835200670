import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';

import SchoolLayout from '../../layout/SchoolLayoutWithTabs';
import CampaignsActions from './data/CampaignsActions';
import InfoTable from '../../../../ui/elements/InfoTable';
import BackButton from '../../../../ui/elements/BackButton';
import CollectionsTable from './data/CollectionsTable';
import CampaignsTable from './data/CampaignsTable';
import CollectionsActions from './data/CollectionsActions';

import {
  CAMPAIGN_STATUSES,
  COLLECTION_STATUSES,
  PaymnetInformationCorrectionForm,
  SchoolCampaignTableRow,
  SchoolCollectionTableRow,
} from '../../types';
import { serializeStatisticsData } from '../../../../utils/formats';
import ROUTER_CONFIG from '../../../../router/router-config';
import { formatDateDDMMYY } from '../../../../ui/DateFormatMethods';
import {
  loadCampaignScheme,
  loadContributorScheme,
  selectContributorScheme,
  updateInformationCorrection,
} from '../../../../feature/campaignState';
import { useDispatch, useSelector } from 'react-redux';
import ModalInPortal from '../../../../ui/Modal/ModalPortal';
import ChangePaymentPlanContent from '../campaign-folder/Modals/ChangePaymentPlanModal';
import ChangeCancelFurtherCollectionContent from '../campaign-folder/Modals/ChangeCancelFurtherCollection';
import EditCollectioPaymentInfoContent from '../campaign-folder/Modals/EditCollectioPaymentInfoModal';

export default function ContributorInfo() {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const contributorScheme = useSelector(selectContributorScheme);
  const ChangePaymentPlanModal = ModalInPortal();
  const ChangeCancelFurtherCollectionModal = ModalInPortal();
  const EditCollectionPaymentInfoModal = ModalInPortal();

  const { campaignId } = useParams<{ campaignId: string }>();
  const { contributorId } = useParams<{ contributorId: string }>();

  const [selectedCampaign, setSelectedCampaign] = useState('');

  const [selectedCampaingsStatus, setSelectedCampaignsStatus] =
    useState<keyof typeof CAMPAIGN_STATUSES | null>(null);
  const [selectedCampaignPeriod, setSelectedCampaignPeriod] = useState<
    [string, string]
  >(['', '']);
  const [selectedCollectionsStatus, setSelectedCollectionsStatus] =
    useState<keyof typeof COLLECTION_STATUSES | null>(null);
  const [selectedCollectionPeriod, setSelectedCollectionPeriod] = useState<
    [string, string]
  >(['', '']);

  const [sortedCollections, setSortedCollections] = useState(
    contributorScheme.collections
  );
  const [sortedSchemes, setSortedSchemes] = useState(contributorScheme.schemes);

  const onChangeSubmitPlan = () => {
    if (selectedCampaign !== '') {
      ChangePaymentPlanModal.onShow();
    }
  };

  const onSubmitPaymentCorrection = (
    values: PaymnetInformationCorrectionForm
  ) => {
    EditCollectionPaymentInfoModal.onClose();
    dispatch(
      updateInformationCorrection(contributorId, selectedCampaign, values)
    );
    setTimeout(() => {
      dispatch(loadCampaignScheme(campaignId as string));
    }, 500);
  };

  const onChangeCancelFurtherCollection = () => {
    if (selectedCampaign !== '') {
      ChangeCancelFurtherCollectionModal.onShow();
    }
  };

  useEffect(() => {
    dispatch(loadContributorScheme(contributorId));
  }, [contributorId]);

  useEffect(() => {
    setSortedCollections(contributorScheme.collections);
    setSortedSchemes(contributorScheme.schemes);
  }, [contributorScheme]);

  const onBackClickHandler = () => {
    push(
      ROUTER_CONFIG.SCHOOL_CAMPAIGN_INFO.getPath({ campaignId } as {
        campaignId: string;
      })
    );
  };

  const ContributorStatData = [
    {
      title: 'Active collection campaigns',
      value: `${serializeStatisticsData(
        contributorScheme.parent.activeSchemes
      )}`,
    },
    {
      title: 'Collections made',
      value: serializeStatisticsData(
        contributorScheme.parent.totalCollectionsNumber
      ),
    },
    {
      title: 'Total collections sum',
      value: contributorScheme.parent.totalCollectionsSum,
    },
  ];

  const schoolCampaignsTableSource: Array<SchoolCampaignTableRow> =
    sortedSchemes.map((collection) => ({
      id: collection.subscriptionID,
      key: collection.id,
      school: collection.schoolName,
      campaignName: collection.name,
      studentReferences: collection.studentReferenceArray.join(', '),
      status: collection.status,
      activePeriod: `${formatDateDDMMYY(
        new Date(collection.startDate)
      )} - ${formatDateDDMMYY(new Date(collection.endDate))}`,
      paymentPlan: collection.subscriptionName,
      totalCollected: collection.totalCollected,
      totalCollections: collection.totalCollections,
      totalFailedCollections: collection.totalFailedCollections,
      subscriptionID: collection.subscriptionID,
    }));

  const schoolCollectionsTableSource: Array<SchoolCollectionTableRow> =
    sortedCollections.map((collection) => ({
      id: collection.id,
      key: collection.id,
      date: collection.collectionDate,
      status: collection.status,
      giftAidAmount: collection.giftAidAmount,
      collectionAmount: collection.collectionAmount,
    }));

  const filteredCampaigns = schoolCampaignsTableSource.filter((source) => {
    if (source.status === 'All') {
      return true;
    }

    return (
      source.status.toLowerCase() === selectedCampaingsStatus?.toLowerCase()
    );
  });

  const filteredCollections = schoolCollectionsTableSource.filter((source) => {
    if (source.status === 'All') {
      return true;
    }

    return source.status === selectedCollectionsStatus;
  });

  useEffect(() => {
    const tmpArray = [...contributorScheme.collections];
    if (selectedCollectionPeriod[0] && selectedCollectionPeriod[1]) {
      const date1 = new Date(selectedCollectionPeriod[0]).getTime();
      const date2 = new Date(selectedCollectionPeriod[1]).getTime();

      const filteredByDateCollections = sortedCollections.filter(
        (collection) => {
          const collectionDate = new Date(collection.collectionDate).getTime();

          if (collectionDate >= date1 && collectionDate <= date2) {
            return true;
          }

          return false;
        }
      );

      setSortedCollections(filteredByDateCollections);
    } else {
      setSortedCollections(tmpArray);
    }
  }, [selectedCollectionsStatus, selectedCollectionPeriod]);

  useEffect(() => {
    const tmpArray = [...contributorScheme.schemes];
    if (selectedCampaignPeriod[0] && selectedCampaignPeriod[1]) {
      const date1 = new Date(selectedCampaignPeriod[0]).getTime();
      const date2 = new Date(selectedCampaignPeriod[1]).getTime();

      const filteredByDateCampaigns = sortedSchemes.filter((campaign) => {
        const campaignStartDate = new Date(campaign.startDate).getTime();
        const campaignEndDate = new Date(campaign.endDate).getTime();

        if (campaignStartDate >= date1 && campaignEndDate <= date2) {
          return true;
        }

        return false;
      });

      setSortedSchemes(filteredByDateCampaigns);
    } else {
      setSortedSchemes(tmpArray);
    }
  }, [selectedCampaingsStatus, selectedCampaignPeriod]);

  const onInformationCorrectionHandler = () => {
    EditCollectionPaymentInfoModal.onShow();
  };

  return (
    <SchoolLayout>
      {EditCollectionPaymentInfoModal.ModalRender && (
        <EditCollectionPaymentInfoModal.ModalRender>
          <EditCollectioPaymentInfoContent
            onChangeSubmit={onSubmitPaymentCorrection}
            onClose={EditCollectionPaymentInfoModal.onClose}
            status="Information Correction"
          />
        </EditCollectionPaymentInfoModal.ModalRender>
      )}
      {ChangeCancelFurtherCollectionModal.ModalRender &&
        selectedCampaign !== '' && (
          <ChangeCancelFurtherCollectionModal.ModalRender>
            <ChangeCancelFurtherCollectionContent
              contributorId={contributorId}
              campaignId={selectedCampaign}
              onClose={ChangeCancelFurtherCollectionModal.onClose}
              onChangeSubmit={onChangeCancelFurtherCollection}
            />
          </ChangeCancelFurtherCollectionModal.ModalRender>
        )}
      {ChangePaymentPlanModal.ModalRender && selectedCampaign !== '' && (
        <ChangePaymentPlanModal.ModalRender>
          <ChangePaymentPlanContent
            contributorId={contributorId}
            campaignId={selectedCampaign}
            onClose={ChangePaymentPlanModal.onClose}
            onChangeSubmit={onChangeSubmitPlan}
          />
        </ChangePaymentPlanModal.ModalRender>
      )}
      <CreateCampaignWrap>
        <StyledBackButton onClickHandler={onBackClickHandler} />
        <Content>
          <MainHeader>
            Contributor:&nbsp;
            {contributorScheme.parent.name}
          </MainHeader>
          <StyledInfoTable data={ContributorStatData} />
          <CollectionsActions
            onChangeTimePeriod={setSelectedCollectionPeriod}
            onChangeFilter={setSelectedCollectionsStatus}
          />
          <CollectionsTable
            tableData={
              selectedCollectionsStatus
                ? filteredCollections
                : schoolCollectionsTableSource
            }
          />
          <CampaignsActions
            onChangeSubmitPlan={onChangeSubmitPlan}
            onChangeCancelFurtherCollection={onChangeCancelFurtherCollection}
            onChangeTimePeriod={setSelectedCampaignPeriod}
            onChangeFilter={setSelectedCampaignsStatus}
            onInformationCorrectionHandler={onInformationCorrectionHandler}
          />
          <CampaignsTable
            setSelectedCampaign={setSelectedCampaign}
            tableData={
              selectedCampaingsStatus
                ? filteredCampaigns
                : schoolCampaignsTableSource
            }
          />
        </Content>
      </CreateCampaignWrap>
    </SchoolLayout>
  );
}

const CreateCampaignWrap = styled.main`
  gap: 34px;
  display: flex;
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledBackButton = styled(BackButton)`
  padding-top: 3px;
`;

const MainHeader = styled.h1`
  font-weight: 750;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledInfoTable = styled(InfoTable)`
  margin-top: 30px;
`;

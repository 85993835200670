import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../../router/router-config';
import AdminLayout from '../../layout/AdminLayout';
import { NewCharityAccountType } from '../../types';
import CreateCharityView from './CreateCharityView';

export default function CreateCharityPage() {
  const { push } = useHistory();

  const onFormSubmit = (values: NewCharityAccountType) => console.log(values);
  const onCancelClickHandler = () => push(ROUTER_CONFIG.ADMIN_CHARITIES_OVERVIEW.getPath());

  return (
    <AdminLayout>
      <CreateCharityView onFormSubmit={onFormSubmit} onCancelClickHandler={onCancelClickHandler} />
    </AdminLayout>
  );
}

import { SchoolRegisterType } from '../../project-types/schools/types';
import { UserRegisterType } from '../../project-types/users/types';

export interface NewSchoolAccountFormType extends UserRegisterType, SchoolRegisterType {
}

export interface DonorsTableRow {
  key: string;
  name: string;
  campaignId?: string;
  school?: string;
  activeCollections: string;
  activeTradeIns: string;
}
export interface TableRow {
  schoolName: string;
  schoolId: string;
  dateOfRegistration: string;
  delete: string;
}

export interface VerificationTableRow extends TableRow {
  verify: string;
}

export interface VerifiedSchoolTableRow extends TableRow {
  accountStatus: AccountStatuses,
}

export enum AccountStatuses {
  VERIFIED = 'Verified',
  NEEDS_VERIFICATION = 'Needs verification',
}
export interface SchoolCollectionsTableRow {
  key: string;
  status: string;
  date: string;
  donor: string;
  campaignId: string;
  giftAid: 'yes' | 'no';
  giftAidAmount: string;
}

export interface AllCollectionsTableRow {
  status: string;
  date: string;
  donor: string;
  school: string;
  campaignId: string;
  ammount: string;
  giftAid: 'yes' | 'no';
  giftAidAmount: string;
}

export interface DonorTableRow {
  key: string;
  collectionDate: string;
  school: string;
  campaignId: string;
  collectionSum: string;
  giftAid: 'yes' | 'no';
}

export interface SchoolCampaignsTableRow {
  key: string;
  campaignId: string;
  activePeriod: string;
  collectionFee: string;
  giftAidFee: string;
  isFreeze: boolean;
}

export type CampaignStatuses = 'Active' | 'Frozen';

export interface PayoutsTableRow {
  key: string;
  date: string;
  totalAmount: string;
  school: string;
  campaignId: string;
  balanceTransferStatement: string;
  giftAidAmount: string;
  giftAidStatement: string;
}

export interface CharitiesTableRow {
  key: string;
  charityName: string;
  id: string;
  regNumber: number;
  nameOfRegulator: string;
  authorisedOfficial: string;
}

export interface NewCharityAccountType {
  charityName: string;
  id: string;
  regNumber: string;
  nameOfRegulator: string;
  title?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  postcode: string;
}

export interface CharityTableRow {
  key: string;
  donationDate: string;
  donorName: string;
  schoolName: string;
  amount: string;
  giftAid: string;
  approvalRequestSent: string;
  approvalDefaultDate: string;
}

export enum Statuses {
  SUCCESSFUL = 'Successful',
  UNSUCCESSFUL = 'Unsuccessful',
  PENDING = 'Pending',
}

export enum APPROVAL_STATUSES {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

import { useState } from 'react';
import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import InputWrap from '../../../../ui/forms/InputWrap';
import { renderFormConfig } from '../../../../utils/form';

interface PersonalDetailsFormProps {
  formik: any;
}

export default function PersonalDetailsForm({ formik }: PersonalDetailsFormProps) {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div>
      <EditButton onClick={(e) => {
        e.preventDefault();
        setIsEdit(!isEdit);
      }}
      >
        Edit Details
      </EditButton>
      <Title>Bank Details</Title>
      <div id="ref-bank-details" />
      {renderFormConfig([
        {
          title: 'Name(s) of account holder(s)',
          name: 'bankAccountName',
          component: InputStyled,
          props: {
            disabled: !isEdit,
          },
        },
      ], formik)}

      <InputWrap
        title="Branch sort code"
        hasError={
      !!formik.errors.branchSortCode1
      || !!formik.errors.branchSortCode2
      || !!formik.errors.branchSortCode3
    }
      >
        <BranchInputWrap>
          <BranchInput disabled={!isEdit} name="branchSortCode1" value={formik.values.branchSortCode1} onChange={formik.handleChange} />
          <Dot />
          <BranchInput disabled={!isEdit} name="branchSortCode2" value={formik.values.branchSortCode2} onChange={formik.handleChange} />
          <Dot />
          <BranchInput disabled={!isEdit} name="branchSortCode3" value={formik.values.branchSortCode3} onChange={formik.handleChange} />
        </BranchInputWrap>
      </InputWrap>

      {renderFormConfig([
        {
          title: 'Bank/building society account number',
          name: 'accountNumber',
          component: InputStyled,
          props: {
            disabled: !isEdit,
          },
        },
      ], formik)}
      <SubmitStyled type="submit">Confirm</SubmitStyled>
    </div>
  );
}

const Title = styled.h2`
  font-size: 20px;
  margin: 0;
  margin-bottom: 24px;
  font-weight: bold;
`;

const BranchInputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BranchInput = styled(Input).attrs({ maxLength: 2 })`
  width: 60px;
  font-size: 14px;
  padding: 21px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #102F54;
`;

const SubmitStyled = styled(ButtonDark)`
  padding: 14px 67px;
`;

const EditButton = styled(SubmitStyled)`
  margin-top: 30px;
  margin-bottom: 38px;
`;

import { DatePicker } from 'antd';
import styled from 'styled-components/macro';

const { RangePicker } = DatePicker;

interface FundraisingReportsProps {
  onChangeTimePeriod: (period: [string, string]) => void;
}

export default function FundraisingReports({ onChangeTimePeriod }: FundraisingReportsProps) {
  return (
    <Container>
      <RangePicker onChange={(_e, period) => onChangeTimePeriod(period)} picker="month" />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 40px;
`;

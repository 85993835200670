import styled from "styled-components/macro";

export default function DocumentsList() {
  return (
    <Wrap>
      <Title>Documentation</Title>
      <DocList>
        <DocItem>
          <DocLink target="_blank" href="/privacy.html">Terms &amp;&nbsp;Conditions and Privacy Policy</DocLink>
        </DocItem>
        <DocItem>
          <DocLink target="_blank" href="/agrement.html">Donor Agreement</DocLink>
        </DocItem>
      </DocList>
    </Wrap>
  );
}

const Wrap = styled.main`
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 58px;
`;

const DocList = styled.ul`
  list-style: none;
  padding: 0;
`;

const DocItem = styled.li`
  margin-bottom: 40px;
`;

const DocLink = styled.a`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat';

  &:hover {
    text-decoration: underline;
  }
`;
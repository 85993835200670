import {
  FieldWrap, FieldName, FieldCodeWrap, FieldCode,
} from '../LayoutComponents';

interface BankAccountNumberProps {
  accountNumber: string;
}

export function BankAccountNumber({ accountNumber }: BankAccountNumberProps) {
  return (
    <FieldWrap>
      <FieldName>Bank/ Building Sociery Account Number</FieldName>
      <FieldCodeWrap>
        {accountNumber.split('').map((num: string) => <FieldCode key={num}>{num}</FieldCode>)}
      </FieldCodeWrap>
    </FieldWrap>
  );
}

import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { changeDonorProfile } from '../../../feature/userState';
import ChangeLayout from '../layout/ChangeLayout';
import ROUTER_CONFIG from '../../../router/router-config';
import ChangeEmailForm from './ChangeEmailForm';
import { UpdateDonorFields } from '../../../project-types/donor/types';

export default function ChangeEmailPage() {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const onSuccess = () => push(ROUTER_CONFIG.DONOR_DASHBOARD.getPath());

  const onFormSubmit = (formData: UpdateDonorFields) => dispatch(changeDonorProfile(formData, onSuccess));
  const onCancelClickHandler = () => push(ROUTER_CONFIG.DONOR_SETTINGS.getPath());
  const onForgotPasswordClickHandler = () => push(ROUTER_CONFIG.FORGOT_PASSWORD_PAGE.getPath());

  return (
    <ChangeLayout title="Change email">
      <ChangeEmailForm onFormSubmit={onFormSubmit} onCancelClickHandler={onCancelClickHandler} onForgotPasswordClickHandler={onForgotPasswordClickHandler} />
    </ChangeLayout>
  );
}

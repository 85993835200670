import styled from "styled-components/macro"; // Импортим именно /macro (для читаемых имен классов)
import DonorLogo from "../../../../img/DonorLogo.png"

// Комопонент - функция
export default function DonorPaymentPreview () {
  return (<Wrap>
    <img src={DonorLogo}/>
  </Wrap>);
}

const Wrap = styled.main` // Общая обертка контента страницы
  padding: 102px 0 0 78px;
`;

import styled from 'styled-components/macro';

export default function CharityInfo() {
  return (
    <TableWrapper>
      <TableTitle>
        <Text>Authorised Official</Text>
        <LinkText>Edit</LinkText>
      </TableTitle>
      <Table>
        <TableRow>
          <BoldTableText>Mr John Smith</BoldTableText>
        </TableRow>
        <TableRow>
          <BoldTableText>Email</BoldTableText>
          <TableText>johnsmith@gmail.com</TableText>
        </TableRow>
        <TableRow>
          <BoldTableText>Phone</BoldTableText>
          <TableText>01937849747</TableText>
        </TableRow>
        <TableRow>
          <BoldTableText>Postcode</BoldTableText>
          <TableText>SE1 2DZ</TableText>
        </TableRow>
      </Table>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.span`
  font-size: 14px;
  color: #102f54;
`;

const LinkText = styled(Text)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Table = styled.div`
  box-sizing: border-box;
  width: 230px;
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #E0E0E0;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableText = styled(Text)`
  font-size: 12px;
  line-height: 19px;
`;

const BoldTableText = styled(TableText)`
  font-weight: bold;
`;

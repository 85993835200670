import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { createCampaign, loadCampaignList } from '../../../../feature/campaignState';
import { CampaignCreateType } from '../../../../project-types/campaign/types';
import ROUTER_CONFIG from '../../../../router/router-config';
import SchoolLayout from '../../layout/SchoolLayoutWithTabs';
import CreateCampaign from './CreateCampaign';

export default function CreateCampaignPage() {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const onCreateCampaign = (campaign: CampaignCreateType) => {
    // console.log('rerender')
    // console.log('API CAPMAIN', campaign)
    createCampaign(campaign).then(({ campaign }) => {
      dispatch(loadCampaignList());
      push(ROUTER_CONFIG.SCHOOL_INVITE.getPath({ campaignId: campaign._id }));
    });
  };

  const onBackClickHandler = () => push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());

  return (
    <SchoolLayout>
      <CreateCampaign onSubmit={onCreateCampaign} onBackClickHandler={onBackClickHandler} />
    </SchoolLayout>
  );
}

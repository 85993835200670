import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

interface LogoProps {
  className?: string,
  onClick?: () => void
}

export default function Logo({ className, onClick }: LogoProps) {
  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    // Determine the logo file name
    const logoFile = process.env.REACT_APP_LOGO_OVERRIDE || 'logoColored.svg';

    // Dynamically import the logo image
    import(`../../img/${logoFile}`)
      .then(module => setLogoSrc(module.default))
      .catch(error => console.error('Error loading logo:', error));
  }, []);

  return (
    <LogoStyled src={logoSrc} onClick={onClick} alt="logo" className={className} />
  );
}

const LogoStyled = styled.img`
  width: 100px;
`;

import styled from 'styled-components/macro';

export default function GiftAidCharityTab() {
  return (
    <Container>
      <Text>The charity below acts to receive donations and claims any relevant gift aid.</Text>
      <Table>
        <SmallText>Associated Charity</SmallText>
        <Divider />
        <Row>
          <RowTitle>Name</RowTitle>
          <RowValue>Riverfields Academy Charity</RowValue>
        </Row>
        <Divider />
        <Row>
          <RowTitle>ID</RowTitle>
          <RowValue>16353</RowValue>
        </Row>
        <Divider />
        <Row>
          <RowTitle>Reg No.</RowTitle>
          <RowValue>1089464</RowValue>
        </Row>
        <Divider />
      </Table>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 70px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  gap: 15px;
  margin-top: 47px;
  padding: 18px 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #D5DCE5;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.span`
  font-size: 14px;
  color: #909DA8;
`;

const RowTitle = styled(Text)`
  color: #102F54;
`;

const SmallText = styled(RowTitle)`
  font-size: 12px;
`;

const RowValue = styled(Text)`
  color: #619DD8;
`;

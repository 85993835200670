import styled from 'styled-components/macro';
import { useState } from 'react';
import { Tabs } from 'antd';
import { MainHeader } from '../../../ui/Headers';
import InfoTilel from '../../../ui/elements/InfoTiles';
import CollectionsView from './CollectionsTab/CollectionsView';
import CampaignsView from './CampaignsTab/CampaignsView';
import { SchoolCollectionsTableRow, SchoolCampaignsTableRow } from '../types';
import { SchoolPublicType } from '../../../project-types/schools/types';
import { CampaignPublicType } from '../../../project-types/campaign/types';
import { formatDateDDMMMYYYY, getEndDate } from '../../../ui/DateFormatMethods';
import { AdminPaymentSchoolItem, AdminPaymentSchoolStatsType } from '../../../project-types/admin-payment/types';
import { serializeStatisticsData, serializeStatisticsPrice, showPriceDigits } from '../../../utils/formats';

const { TabPane } = Tabs;

interface SchoolViewProps {
  currentSchool: SchoolPublicType;
  campaignsList: Array<CampaignPublicType>;
  onFreeze: (selectedCampaigns: Array<string>) => void;
  onUnFreeze: (selectedCampaigns: Array<string>) => void;
  onDelete: (selectedCampaigns: Array<string>) => void;
  onAddNewCampaignClickHandler: () => void;
  schoolCollections: Array<AdminPaymentSchoolItem>;
  schoolCollectionsStatistics: AdminPaymentSchoolStatsType | null;
  currentMonth: number;
  onMonthChange: (month: number) => void;
  donorSearch: string;
  onChangeDonorSearch: (donor: string) => void;
  campaignSearch: string;
  onChangeCampaignSearch: (campaign: string) => void;
  dateSearch: string;
  onChangeDateSearch: (date: string) => void;
  onChangeStatusSearch: (status: Array<string> | null) => void;
}

export default function SchoolView({
  onFreeze, onUnFreeze, onDelete, onAddNewCampaignClickHandler, currentSchool, campaignsList, schoolCollections, schoolCollectionsStatistics, currentMonth, onMonthChange, donorSearch, onChangeDonorSearch, campaignSearch, onChangeCampaignSearch, dateSearch, onChangeDateSearch, onChangeStatusSearch,
}: SchoolViewProps) {
  const [selectedCampaigns, setSelectedCampaigns] = useState<Array<string>>([]);

  const onFreezeClickHandler = () => {
    onFreeze(selectedCampaigns);
    setSelectedCampaigns([]);
  };
  const onUnFreezeClickHandler = () => {
    onUnFreeze(selectedCampaigns);
    setSelectedCampaigns([]);
  };
  const onDeleteClickHandler = () => {
    onDelete(selectedCampaigns);
    setSelectedCampaigns([]);
  };

  const SchoolStatData = [
    {
      title: 'Active campaigns',
      value: serializeStatisticsData(schoolCollectionsStatistics?.campaignsCount),
    },
    {
      title: 'Collected in the current period',
      value: `£ ${serializeStatisticsPrice(schoolCollectionsStatistics?.collectedInPeriod)}`,
    },
    {
      title: 'Collected total for all time',
      value: `£ ${serializeStatisticsPrice(schoolCollectionsStatistics?.colectedTotal)}`,
    },
    {
      title: 'Trade-in credit',
      value: '00',
    },
  ];

  const schoolCollectionsTableData: Array<SchoolCollectionsTableRow> = schoolCollections.map((collection: any) => ({
    key: collection._id,
    status: collection.status,
    date: formatDateDDMMMYYYY(new Date(collection.postedDate)),
    donor: collection.donorName,
    campaignId: collection.campaign,
    amount: `£ ${showPriceDigits(collection.amount)}`,
    giftAid: 'yes',
    giftAidAmount: `£ ${showPriceDigits(collection.amount * 0.25)}`,
  }));

  const campaignsTableData: Array<SchoolCampaignsTableRow> = campaignsList.map((campaign) => ({
    key: campaign._id,
    campaignId: campaign._id,
    activePeriod: `${formatDateDDMMMYYYY(new Date(campaign.startDate))} - ${getEndDate(campaign)}`,
    collectionFee: 'XXXX',
    giftAidFee: 'XXXX',
    isFreeze: campaign.isFreezed,
  }));

  return (
    <Wrap>
      <StyledMainHeader>{currentSchool.schoolName}</StyledMainHeader>
      <InfoTilel data={SchoolStatData} />
      <StyledTabs>
        <TabsPaneStyled tab="Collections" key="1">
          <CollectionsView tableData={schoolCollectionsTableData} currentMonth={currentMonth} onMonthChange={onMonthChange} donorSearch={donorSearch} onChangeDonorSearch={onChangeDonorSearch} campaignSearch={campaignSearch} onChangeCampaignSearch={onChangeCampaignSearch} dateSearch={dateSearch} onChangeDateSearch={onChangeDateSearch} onChangeStatusSearch={onChangeStatusSearch} />
        </TabsPaneStyled>
        <TabsPaneStyled tab="Campaigns" key="2">
          <CampaignsView tableData={campaignsTableData} selectedCampaigns={selectedCampaigns} onSelectCampaignsHandler={setSelectedCampaigns} onAddNewCampaignClickHandler={onAddNewCampaignClickHandler} onFreezeClickHandler={onFreezeClickHandler} onUnFreezeClickHandler={onUnFreezeClickHandler} onDeleteClickHandler={onDeleteClickHandler} />
        </TabsPaneStyled>
      </StyledTabs>
    </Wrap>
  );
}

const Wrap = styled.main`
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
  box-sizing: border-box;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 64px;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 96px;
`;

const TabsPaneStyled = styled(TabPane)`
  & .ant-tabs-tabpane {
  }
  padding-top: 41px;
`;

import styled from 'styled-components/macro';
import {ButtonDark, ButtonDarkBlack} from '../../../../ui/Button';
import FindYourAddress from '../../../../ui/forms/FindYourAddress';
import Input from '../../../../ui/forms/Input';
import { renderFormConfig } from '../../../../utils/form';
import PostcodeInput from '../../create-school/PostcodeInput';
import UploadPhotoBtn from "../../../../ui/elements/UploadPhotoBtn";
import React, {useState} from "react";

interface SchoolDetailsFormProps {
  formik: any;
  avatar: string;
  setAvatar: (avatar: any) => void
}

export default function SchoolDetailsForm({ formik, avatar, setAvatar }: SchoolDetailsFormProps) {
    const [imageUrl, setImageUrl] = useState<string>();

    const handleRemove = () => {
        setImageUrl('')
        formik.setFieldValue('logo', '');
    }
  return (
    <Container>
      {/*<Title>Logo</Title>*/}
      {/*  <UploadPhotoBtn/>*/}
      <Title>School Details</Title>
        <UploadPhotoBtn formik={formik} imageUrl={imageUrl} setImageUrl={setImageUrl} />
        {imageUrl ? <ButtonStyledRem onClick={handleRemove}>Remove avatar</ButtonStyledRem> : null}
      {renderFormConfig([
        // {
        //   title: 'Logo',
        //   name: 'logo',
        //   component: InputStyled,
        // },
        {
          title: 'School Name',
          name: 'schoolName',
          component: ShortInputStyled,
        },
        {
          title: 'Postcode',
          name: 'postcode',
          component: PostcodeInputStyled,
        },
      ], formik)}
      <FindYourAddress postCode={formik.values.postcode} formikInstance={formik} />
      {
        renderFormConfig([
          {
            title: 'Address line 1',
            name: 'address1',
            component: InputStyled,
          },
          {
            title: 'Address line 2',
            name: 'address2',
            component: InputStyled,
          },
          {
            title: 'Address line 3',
            name: 'address3',
            component: InputStyled,
          },
          {
            title: 'Town',
            name: 'town',
            component: InputStyled,
          },
          {
            title: 'Country:',
            name: 'country',
            component: InputStyled,
          },
          {
            title: 'Phone number',
            name: 'phoneNumber',
            component: InputStyled,
          },
        ], formik)
      }
      <SubmitStyled type="submit">Save</SubmitStyled>

    </Container>
  );
}

const Container = styled.div`
  margin-top: 40px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin: 0;
  margin-bottom: 24px;
  font-weight: bold;
`;

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const PostcodeInputStyled = styled(PostcodeInput)`
  max-width: 421px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const SubmitStyled = styled(ButtonDark)`
  padding: 14px 67px;
  margin-top: 80px;
`;

const ShortInputStyled = styled(InputStyled)`
  max-width: 313px;
`;

const ButtonStyledRem = styled(ButtonDarkBlack)`
  //margin-top: 21px;
  color: #FFFFFF;
  //margin-bottom: 53px; 
  margin: 15px 0;
  
`;

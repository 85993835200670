import styled from 'styled-components/macro';
import {
  FieldWrap, FieldName, FieldTextarea, FieldTextareaText,
} from '../LayoutComponents';

interface ContactDetailsProps {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  address3?: string;
  town?: string;
  country?: string;
  postCode?: string;
  phoneNumber?: string;
}

export function ContactDetails({
  firstName, lastName, address1, address2, address3, town, country, postCode, phoneNumber,
}: ContactDetailsProps) {
  return (
    <FieldWrap>
      <FieldName>Contact Details</FieldName>
      <FieldTextarea>
        <FieldTextareaText>
          <BlockSpan>
            {firstName}
            {' '}
            {lastName}
          </BlockSpan>
          <BlockSpan>
            {town ?? ''}
            {' '}
            {country ?? ''}
          </BlockSpan>
          <BlockSpan>{address1}</BlockSpan>
          <BlockSpan>{address2 ?? ''}</BlockSpan>
          <BlockSpan>{address3 ?? ''}</BlockSpan>
          <BlockSpan>{`postcode: ${postCode}` ?? ''}</BlockSpan>
          <BlockSpan>{`tel: ${phoneNumber}` ?? ''}</BlockSpan>
        </FieldTextareaText>
      </FieldTextarea>
    </FieldWrap>
  );
}

const BlockSpan = styled.span`
  display: block;
`;

import styled from 'styled-components/macro';
import { FieldWrap, FieldName } from '../LayoutComponents';

export function BankInstruction() {
  return (
    <FieldWrap>
      <FieldName>Instruction to your Bank or Building Society</FieldName>
      <Text>
        Please pay L&Z RE SILVERWING Direct Debits from the account detailed in
        this Instruction subject to the safeguards assured by the Direct Debit Guarantee. I
        understand that this Instruction may remain with L&Z RE SILVERWING and, if so, details will
        be passed electronically to my bank/building society.
      </Text>
    </FieldWrap>
  );
}

const Text = styled.p`
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 12px;
`;

import { CloudDownloadOutlined } from '@ant-design/icons';

import DataTable from '../../../../ui/elements/Table';
import { loadPayment } from '../../../../feature/campaignState';

const tableConfig = [
  {
    title: 'ID',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'School',
    dataIndex: 'school',
    key: 'school',
  },
  {
    title: 'Scheme Name',
    dataIndex: 'schemeName',
    key: 'schemeName',
  },
  {
    title: 'Scheme Choice',
    dataIndex: 'schemeChoice',
    key: 'schemeChoice',
  },
  {
    title: 'Student References',
    dataIndex: 'studentReferences',
    key: 'studentReferences',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Download',
    dataIndex: 'download',
    key: 'download',
    render: (url: string, record: any) => {
      const handleClick = () => {
        loadPayment(record.key);
      };

      if (record.status === 'Successful') {
        return <CloudDownloadOutlined onClick={handleClick} />;
      }

      return null;
    },
  },
];

export default function CollectionTable({ tableData }: any) {
  return <DataTable dataSource={tableData} columns={tableConfig} />;
}

import React from 'react';
import styled from 'styled-components/macro';

interface InfoTableProps {
  data: Array<{
    title: string;
    value?: string | number;
  }>;
  className?: string;
}

const InfoTable: React.FC<InfoTableProps> = ({ data, className }) => (
  <Container className={className}>
    {data.map((d) => (
      <TableCell key={d.title}>
        <GreenTitle>{d.title}</GreenTitle>
        <ValueCell>
          <ValueContent>
            <ValueText>{d.value}</ValueText>
          </ValueContent>
        </ValueCell>
      </TableCell>
    ))}
  </Container>
);

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  border: 1px solid #e3e8ee;
  border-radius: 4px;
`;

const ValueCell = styled.div`
  background-color: #f9f9f9;
  padding: 8px 0 8px 20px;
`;

const ValueContent = styled.div`
  border-right: 1px solid #d5dce5;
`;

const TableCell = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr;

  &:last-child {
    ${ValueContent} {
      border-right: none;
    }
  }
`;

const GreenTitle = styled.h3`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #1b9d75;
  padding: 15px 20px;
`;

const ValueText = styled.p`
  font-size: 21px;
  color: #102f54;
  padding: 15px 0;
  margin: 0;
`;

export default InfoTable;

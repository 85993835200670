import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components/macro';
import { ManageTableModel } from '../../../school/types';

interface OverviewTableProps {
  tableData: Array<ManageTableModel>;
  selectedRows: Array<string>;
  setSelectedRows: (deviceIds: Array<string>) => void;
  onShowContributorInfoHandler: (contributorId: string) => void;
  setPreAuthSelectedContributorId: (contributorId: string) => void;
}

export default function OverviewManageTable({
  onShowContributorInfoHandler,
  tableData,
  selectedRows,
  setSelectedRows,
  setPreAuthSelectedContributorId,
}: OverviewTableProps) {
  const tableConfig: ColumnsType<ManageTableModel> = [
    {
      title: 'Subscription ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'First Collection Date',
      dataIndex: 'firstCollectionDate',
      key: 'firstCollectionDate',
    },
    {
      title: 'Contributor Name',
      dataIndex: 'contributorName',
      key: 'contributorName',
      // render: (value, record) => (
      //   <ContributorNameLink
      //     onClick={() => onShowContributorInfoHandler(record.key)}
      //   >
      //     {value}
      //   </ContributorNameLink>
      // ),
    },
    {
      title: 'Student ID',
      dataIndex: 'studentReferenceArray',
      key: 'studentReferenceArray',
      render: (val) => <span>{`${val} `}</span>,
    },
    {
      title: 'Gift Aid Registered',
      dataIndex: 'giftAidRegistered',
      key: 'giftAidRegistered',
    },
    {
      title: 'Scheme Choice',
      dataIndex: 'subscriptionName',
      key: 'subscriptionName',
    },
    {
      title: 'Payment Information Errors',
      dataIndex: 'informationError',
      key: 'informationError',
      render: (val) => val && <RedStatus />,
    },
  ];

  return (
    <Table
      columns={tableConfig}
      dataSource={tableData}
      rowSelection={{
        type: 'radio',
        onChange: (ids, arr) => {
          const filteredContributorID = arr.filter((contributor) => contributor.id === ids[0])[0].contributorID;
          setSelectedRows(ids as Array<string>);
          setPreAuthSelectedContributorId(filteredContributorID);
        },
        selectedRowKeys: selectedRows,
      }}
    />
  );
}

const RedStatus = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  margin: 0 auto;
`;

// const Span = styled.span`
//   font-weight: bold;
// `;
//
// const ContributorNameLink = styled.span`
//   cursor: pointer;
//
//   &:hover {
//     text-decoration: underline;
//   }
// `;

import styled from 'styled-components/macro';
import { ApiCreateDonor } from '../../../../project-types/donor/api-types';
import DonorBlankHeader from '../../../layout/DonorBlankHeader';
import CreateDonorForms from './CreateDonorForms';

interface CreateDonorProps {
  campaignName: string;
  schoolName: string;
  onDonorDetailsBackClick: () => void;
  onFormConfirm: (formData: ApiCreateDonor['data']) => Promise<void>;
  inviteCode: string;
  onLoginClickHandler: () => void;
}

export default function CreateDonor({
  campaignName, schoolName, onDonorDetailsBackClick, inviteCode, onFormConfirm, onLoginClickHandler,
}: CreateDonorProps) {
  return (
    <Content>
      <Container>
        <CreateDonorForms onLoginClickHandler={onLoginClickHandler} inviteCode={inviteCode} onDonorDetailsBackClick={onDonorDetailsBackClick} onFormConfirm={onFormConfirm} />
      </Container>
    </Content>
  );
}

const Content = styled.main`
  width: 100%;
  border-top: 1px solid #E0E0E0;
  padding-top: 60px;
  padding-bottom: 60px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 870px;
  margin: 0px auto;
`;

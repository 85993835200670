import styled from 'styled-components/macro'; // Импортим именно /macro (для читаемых имен классов)
import { useState } from 'react';
import Header from '../../../layout/Header';
import GlobalWrap from '../../../layout/GlobalWrap';
import { ButtonDark, ButtonLightGray } from '../../../../ui/Button';
import { SchoolPublicTypeLite } from '../../../../project-types/schools/types';
import { CampaignFormFields } from '../../../school/types';

interface AgreementProps {
  currentSchool: SchoolPublicTypeLite | null,
  campaign: CampaignFormFields | null,
  onNext: () => void
  onBack: () => void
}

export default function Agreement({
  currentSchool, campaign, onNext, onBack,
}: AgreementProps) {
  const [confirm, setConfirm] = useState(false);
  return (
    <GlobalWrap>
      <TitleBlock>
        <TitleContent>
          <TopTitle>{campaign?.name}</TopTitle>
          <Text>
            {`You have been invited to join a ${currentSchool?.schoolName} donation schema`}
          </Text>
        </TitleContent>
      </TitleBlock>
      <Container>
        <Title>
          Donor Agreement
        </Title>
        <SubTitleSmall>
          Please read and accept below
        </SubTitleSmall>
        <TextField />
        <CheckBox>
          <input type="checkbox" onChange={(e) => setConfirm(e.target.checked)} />
          <CheckBoxText>I have read and agree to the Donor Agreement</CheckBoxText>
        </CheckBox>
        <Buttons>
          <ButtonStyledOutlined onClick={onBack}>
            Back
          </ButtonStyledOutlined>
          {confirm && (
          <ButtonStyled onClick={onNext}>
            Next
          </ButtonStyled>
          )}
        </Buttons>
      </Container>
    </GlobalWrap>
  );
}

const Content = styled.main`
  width: 100%;
  border-top: 1px solid #E0E0E0;
  padding-top: 123px;
  padding-bottom: 200px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 870px;
  margin: 0px auto;
  font-family: Avenir Next Cyr, sans-serif;
`;

const TitleBlock = styled.div`
  display: flex;
  background-color: #F5F8FB;
  justify-content: center;
  padding: 40px 0;
  margin-bottom: 80px;
`;

const TitleContent = styled.div`
  width: 870px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TopTitle = styled.h1`
  font-size: 30px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
`;

const SubTitleSmall = styled.span`
  font-size: 14px;
  line-height: 17px;
  display: block;
  text-align: left;
  margin-bottom: 20px;
  color: #909DA8;
`;

const TextField = styled.iframe.attrs({ src: '/agrement.html' })`
  margin: 0 auto;
  max-width: 810px;
  width: 810px;
  height: 488px;
  margin-bottom: 45px;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 34px 25px;
  overflow: auto;
  position: relative;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

const CheckBox = styled.label`
  display: flex;
  align-items: center;
`;

const CheckBoxText = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin-left: 18px;

  &::after {
    content: '*';
    color: red;
    padding-left: 2px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 70px;
  padding-bottom: 100px;
`;

const ButtonStyled = styled(ButtonDark)`
  padding: 11px 0;
  width: 235px;
`;

const ButtonStyledOutlined = styled(ButtonLightGray)`
  padding: 11px 0;
  width: 174px;
`;

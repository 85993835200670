import { Select } from 'antd';
import styled from 'styled-components/macro';
import DownloadIcon from '../../../../img/icons/download.svg';
import { useState } from "react";

const { Option } = Select;

const UserStatuses = ['October'];

type DataType = {
  period: string,
  name: string,
  link: string,
  sum: string,
}


export default function Documentation({ data }: {data: Array<DataType>}) {
  const [selectedPeriod, setSelectedPeriod] = useState('');

  return (
    <Wrap>
      <MainHeader>
        <MainTitle>Documentation</MainTitle>
        <MainText>
          Download payout summary and invoices for platform fees
        </MainText>
      </MainHeader>
      <SelectStyled onChange={(e) => setSelectedPeriod(e as string)} placeholder="Time Period">
        {data.map(({ period }) => (
          <Option value={period}>{period}</Option>
        ))}
      </SelectStyled>
      <DownloadWrap>
        {data.filter((el) => el.period === selectedPeriod).map(({ name, link }) => (
          <DownloadLink target="_blank" href={`${process.env.REACT_APP_BACKEND}${link}`}>
            <DownloadLinkText>{name}</DownloadLinkText>
            <DownloadLinkImg src={DownloadIcon} />
          </DownloadLink>
        ))}
      </DownloadWrap>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: block;
`;

const MainHeader = styled.div``;

const MainTitle = styled.h2`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
`;

const MainText = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 38px;
`;

const SelectStyled = styled(Select)`
  width: 231px;
  margin-bottom: 16px;
`;

const DownloadWrap = styled.div`
  width: 100%;
  border-top: 1px solid #e0e0e0;
  padding: 16px 0 0;
`;

const DownloadLink = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
`;

const DownloadLinkText = styled.a`
  margin-right: 5px;
`;

const DownloadLinkImg = styled.img`
  width: 15px;
  height: 15px;
`;

import { message } from 'antd';
import { request } from '../api';
import { ApiAdminChangeSettings, ApiAdminGetSettings } from '../project-types/admin-settings/api-types';
import { createAxiosConfig } from '../project-types/common/axios';
import { HTTP_METHODS } from '../project-types/common/http-types';
import { SettingsCreateOrUpdateType } from '../project-types/settings/types';

export const getAdminSettings = () => request(createAxiosConfig<ApiAdminGetSettings>({
  url: ['/admin/settings'],
  method: HTTP_METHODS.GET,
})).then((response: { data: ApiAdminGetSettings['successResponse'] }) => response.data.settings).catch(() => message.error('Error loading settings'));

export const setAdminSettings = (settings: SettingsCreateOrUpdateType) => request(createAxiosConfig<ApiAdminChangeSettings>({
  url: ['/admin/settings'],
  method: HTTP_METHODS.PATCH,
  data: settings,
})).then((response: { data: ApiAdminChangeSettings['successResponse'] }) => response.data.settings).catch(() => message.error('Error saving settings'));

import { ChangeEvent, useState } from 'react';
import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../../ui/Button';
import CloseIcon from '../../../../../img/close.svg';
import Input from '../../../../../ui/forms/Input';
import { CampaignEditType, SchemeEditType } from '../../../../../project-types/campaign/types';

interface EditCollectionNotificationsModalContentProps {
  onClose: () => void;
  initialData: CampaignEditType['collectionNotificationEmailArray'];
  onChangeSubmit: (newArr: SchemeEditType['notifications_emails_attributes']) => void;
}

export default function EditCollectionNotificationsModalContent({ onClose, initialData, onChangeSubmit }: EditCollectionNotificationsModalContentProps) {
  const [recipients, setRecipients] = useState(initialData && initialData.length ? initialData : ['']);

  const onChangeHandler = (key: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValues = [
      ...recipients.slice(0, key),
      e.target.value,
      ...recipients.slice(key + 1),
    ];

    setRecipients(newValues);
  };

  const onAddNew = () => setRecipients((prev) => [...prev, '']);

  const onRemove = (i: number) => setRecipients((prev) => prev.filter((_, index) => index !== i));

  const onSubmit = () => {
    const newRecipients = recipients.map((el) => ({ email: el || '' }));
    onChangeSubmit(newRecipients);
    onClose();
  };

  return (
    <Container>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>Collection Notifications</Title>
      <Text>
        After each collection date for a campaign, a email report will be sent to your accounts email address. You can enter additional email addresses below to be additional recipients of the report.
      </Text>
      <SubTitle>Additional Report Recipients</SubTitle>
      <Inputs>
        {recipients.map((d, i) => (
          <InputBlock key={i}>
            <StyledInput placeholder="Email" value={d} onChange={onChangeHandler(i)} />
            <RemoveRecipient onClick={() => onRemove(i)}>Remove recipient</RemoveRecipient>
          </InputBlock>
        ))}
        <AddNewRecipient onClick={onAddNew}>+ Add new recipient</AddNewRecipient>
      </Inputs>
      <SubmitButton onClick={onSubmit}>Save Edits</SubmitButton>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  max-width: 570px;
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const SubTitle = styled.h4`
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Text = styled.p`
  color: #6B7A87;
  font-size: 14px;
  margin-bottom: 32px;
`;

const SubmitButton = styled(ButtonDark)`
  margin-top: 100px;
  padding: 14px 70px;
  align-self: flex-start;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputBlock = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 310px;
`;

const RemoveRecipient = styled.span`
  color: #DE2644;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const AddNewRecipient = styled(RemoveRecipient)`
  color: #2682DE;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

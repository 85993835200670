import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signInSchool } from '../../../feature/schoolsState';
import { getUserProfile } from '../../../feature/userState';
import ROUTER_CONFIG from '../../../router/router-config';
import MenuPublick from '../../layout/Menu/MenuPublick';
import SchoolLayout from '../layout/SchoolLayout';
import { SchoolLoginFields } from '../types';
import SchoolLogin from './SchoolLogin';

export default function LoginSchoolPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const profile = useSelector(getUserProfile);

  const onSignIn = (schoolLoginFields: SchoolLoginFields) => {
    dispatch(signInSchool(schoolLoginFields, setLoading));
    setLoading(true)
  };

  const onForgotPasswordClickHandler = () => history.push(ROUTER_CONFIG.FORGOT_PASSWORD_PAGE.getPath());

  const onRegisterClickHandler = () => history.push(ROUTER_CONFIG.CREATE_SCHOOL_PAGE.getPath());

  useEffect(() => {
    if (profile?.schoolProfile) {
      history.push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());
    }
  }, [profile, history]);

  return (
    <SchoolLayout headerLoginLinkTo={ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.path} menu={<MenuPublick />}>
      <SchoolLogin onForgotPasswordClickHandler={onForgotPasswordClickHandler} title="School login" onSignIn={onSignIn} loading={loading} onRegisterClickHandler={onRegisterClickHandler} />
    </SchoolLayout>
  );
}

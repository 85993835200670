import styled from 'styled-components/macro';
import { useState } from 'react';
import { MainHeader } from '../../../ui/Headers';
import CollectionPeriodComponent from '../all-collections/CollectionPeriodComponent';
import { ButtonLight } from '../../../ui/Button';
import PayoutsTable from './PayoutsTable';
import { PayoutsTableRow } from '../types';

interface PayoutsViewProps {
  onMakePayoutClickHandler: () => void;
}

export default function PayoutsView({ onMakePayoutClickHandler }: PayoutsViewProps) {
  const [monthNum, setMonthNum] = useState(new Date().getMonth() + 1);

  const onIncDateRange = () => {
    if (monthNum < 12) {
      setMonthNum(monthNum + 1);
    } else {
      setMonthNum(1);
    }
  };
  const onDecrDateRange = () => {
    if (monthNum > 1) {
      setMonthNum(monthNum - 1);
    } else {
      setMonthNum(12);
    }
  };

  const mockTableData: Array<PayoutsTableRow> = [];

  return (
    <Wrapper>
      <StyledMainHeader>Payouts</StyledMainHeader>
      <MakePayoutButton onClick={onMakePayoutClickHandler}>Make payout</MakePayoutButton>
      <CollectionPeriodComponent styles={{ margin: '0 0 40px' }} monthNum={monthNum} onIncDateRange={onIncDateRange} onDecrDateRange={onDecrDateRange} />
      <PayoutsTable tableData={mockTableData} />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  box-sizing: border-box;
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  margin-bottom: 36px;
`;

const MakePayoutButton = styled(ButtonLight)`
  box-sizing: border-box;
  width: 100%;
  max-width: 420px;
  padding: 15px 0;
  margin-bottom: 70px;
`;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getSchoolsList, selectSchoolsList } from '../../../../feature/schoolsState';
import SideBarWrap from '../../../layout/SideBar/SiedeBarWrap';
import AdminSidebar from './AdminSidebarView';
import ROUTER_CONFIG from '../../../../router/router-config';
import { removeToken } from '../../../../api';

export default function AdminSidebarPage() {
  const { push } = useHistory();
  const schoolsList = useSelector(selectSchoolsList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!schoolsList) {
      dispatch(getSchoolsList());
    }
  }, [dispatch]);

  const onClickLogo = () => push(ROUTER_CONFIG.ADMIN_ALL_COLLECTIONS.getPath());
  const onSchoolClick = (schoolId: string) => push(ROUTER_CONFIG.ADMIN_SCHOOL_PAGE.getPath({ schoolId }));
  const onLogout = () => {
    // debugger;
    push(ROUTER_CONFIG.DONOR_LOGIN.getPath());
    removeToken();
  };

  return (
    <SideBarWrap
      onClickLogo={onClickLogo}
      onLogout={onLogout}
    >
      <AdminSidebar schoolsList={schoolsList} onSchoolClick={onSchoolClick} />
    </SideBarWrap>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components/macro';
import Input, { InputProps } from 'antd/lib/input';
import { FC } from 'react';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

interface IRemoveInputProps extends InputProps {
  onRemove: () => void;
  onAdd?: () => void;
}

const RemoveInput: FC<IRemoveInputProps> = ({ onRemove, onAdd, ...props }) => (
  <StyledInputContainer>
    <StyledInput {...props} />
    <StyledPlusOutlined onClick={onAdd} />
    {Number(props.id) !== 1 && <StyledDeleteOutlined onClick={onRemove} />}
  </StyledInputContainer>
);

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  border-radius: 4px;
  height: 40px;
  margin-bottom: 2px;
  width: 422px;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  font-size: 14px;
  color: #de2644;
  margin-left: 14px;
`;

const StyledPlusOutlined = styled(PlusCircleOutlined)`
  font-size: 14px;
  margin-left: 14px;
`;

export default RemoveInput;

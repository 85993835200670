import { FC, useState } from 'react';
import { Radio, Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { showPriceDigits } from '../../../../../utils/formats';
import { selectCurrentCampaign } from '../../../../../feature/campaignState';

const SelectPaymentOption: FC<{
  collections: Array<any>;
  onChange: (id: any) => void;
  activeId: string;
}> = ({ collections = [], onChange, activeId }) => {
  // const [value, setValue] = useState(1);

  // const onChange = (e: RadioChangeEvent) => {
  //   // console.log('radio checked', e.target.value);
  //   setValue(e.target.value);
  // };

  const campaign = useSelector(selectCurrentCampaign);

  const payments = collections.map((collection) => ({
    value: collection._id,
    string: `${
      collection.numberOfCollections === 0
        ? 'Continuous'
        : collection.collectionFrequency === 'oneoff'
        ? 'One-off'
        : collection.numberOfCollections
    } ${
      collection.collectionFrequency === 'oneoff' ? 'collection' : 'collections'
    }   of \u00A3${showPriceDigits(collection.recurringDonationAmounts)}${
      collection.collectionFrequency === 'oneoff'
        ? ''
        : '/' + collection.collectionFrequency
    } via Direct Debit`,
    initialCollectionAmount: collection.initialDonationAmount,
    recurringCollectionAmount: collection.recurringDonationAmounts,
    collectionFrequency: collection.collectionFrequency,
    numberOfCollections: collection.numberOfCollections,
    collectionSchemeName: collection.collectionSchemeName,
  }));
  // £ ${showPriceDigits(donor.donationAmount)}
  // console.log('values', payments);

  return (
    <StyledRadioGroup onChange={onChange} value={activeId}>
      {payments.map(
        ({
          value,
          collectionFrequency,
          initialCollectionAmount,
          recurringCollectionAmount,
          numberOfCollections,
          collectionSchemeName,
        }) => {
          const firstPayment =
            initialCollectionAmount && initialCollectionAmount !== 0
              ? initialCollectionAmount
              : recurringCollectionAmount;
          const totalAmount = ((numberOfCollections - 1) * recurringCollectionAmount) + firstPayment;

          return (
            <RadioContainer key={value} active={activeId === value}>
              <StyledRadio value={value}>
                <Details>
                  <DetailsMainTitle>{collectionSchemeName}</DetailsMainTitle>
                  {initialCollectionAmount && initialCollectionAmount !== 0 ? (
                    <DetailsContainer>
                      <DetailsKeyTitle>
                        Initial Collection Amount
                      </DetailsKeyTitle>

                      <DetailsValueTitle>
                        {'£ ' + initialCollectionAmount}
                      </DetailsValueTitle>
                    </DetailsContainer>
                  ) : null}
                  <DetailsContainer>
                    <DetailsKeyTitle>
                      Recurring Collection Amount
                    </DetailsKeyTitle>
                    <DetailsValueTitle>
                      &#163;{showPriceDigits(recurringCollectionAmount)}
                    </DetailsValueTitle>
                  </DetailsContainer>

                  <DetailsContainer>
                    <DetailsKeyTitle>Collection Frequency</DetailsKeyTitle>
                    <DetailsValueTitle>{collectionFrequency}</DetailsValueTitle>
                  </DetailsContainer>

                  <DetailsContainer>
                    <DetailsKeyTitle>Number Of Collections</DetailsKeyTitle>
                    <DetailsValueTitle>
                      {numberOfCollections === 0
                        ? '\u221E'
                        : numberOfCollections}
                    </DetailsValueTitle>
                  </DetailsContainer>

                  <DetailsContainer>
                    <DetailsKeyTitle>Payment Method</DetailsKeyTitle>
                    <DetailsValueTitle>Direct Debit</DetailsValueTitle>
                  </DetailsContainer>
                  {!campaign?.theme?.hideTotals && (
                    <DetailsContainer>
                      <DetailsKeyTitle>Total Payment Amount</DetailsKeyTitle>
                      <DetailsValueTitle>
                        &#163;{showPriceDigits(totalAmount)}
                      </DetailsValueTitle>
                    </DetailsContainer>
                  )}
                </Details>
              </StyledRadio>
            </RadioContainer>
          );
        }
      )}
    </StyledRadioGroup>
  );
};

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

const RadioContainer = styled.div<{ active: boolean }>`
  border: 1.3px solid ${(props) => (props.active ? '#2682DE' : '#e0e0e0')};
  border-radius: 5px;

  background: #ffffff;

  padding: 7px;

  width: 350px;
`;

const StyledRadio = styled(Radio)`
  width: 350px;
  height: 100%;
`;

const Details = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  gap: 8px;
  background-color: #ffffff;
  //background-color: rgba(245, 248, 251, 0.7);
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DetailsKeyTitle = styled.span`
  font-weight: 400;
  font-size: 14px;

  color: #102f54;
`;

const DetailsValueTitle = styled.span`
  font-weight: 600;
  font-size: 14px;

  color: #102f54;

  ::first-letter {
    text-transform: capitalize;
  }
`;

const DetailsMainTitle = styled.span`
  font-weight: 600;
  font-size: 16px;

  color: #6b7a87;
`;

export default SelectPaymentOption;

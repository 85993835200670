import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button, Tabs } from 'antd';
import styled from 'styled-components/macro';
import { DownloadOutlined } from '@ant-design/icons';

import SchoolLayout from '../../layout/SchoolLayoutWithTabs';
import CollectionReport from './Collections/CollectionReports';
import CollectionTable from './Collections/CollectionTable';
import DonorsReports from './Donors/DonorsReports';
import DonorsTable from './Donors/DonorsTable';
import BackButton from '../../../../ui/elements/BackButton';
import InfoTable from '../../../../ui/elements/InfoTable';
import SummaryTable from './SummaryTable';
import ManageDonorsTable from '../../../donor/dashboard/CollectionsManagement/ManageDonorsTable';
import ActionRequiredActions from './ActionRequired/ActionRequiredActions';
import ActionRequiredTable from './ActionRequired/ActionRequiredTable';
import ModalInPortal from '../../../../ui/Modal/ModalPortal';
import EditDescriptionModalContent from './Modals/EditDescriptionModal';
import EditCollectionRulesModalContent from './Modals/EditCollectionRulesModal';
import EditCollectionNotificationsModalContent from './Modals/EditCollectionNotificationsModal';
import ViewCollectioPaymentStatusContent from './Modals/ViewCollectioPaymentStatusModal';
import EditCollectioPaymentInfoContent from './Modals/EditCollectioPaymentInfoModal';
import ChangePaymentPlanContent from './Modals/ChangePaymentPlanModal';

import ROUTER_CONFIG from '../../../../router/router-config';
import {
  deleteDonors,
  freezeDonors,
  loadCampaignScheme,
  loadExport,
  loadExportProblematic,
  loadExportSummary,
  selectCampaignScheme,
  selectCurrentCampaign,
  selectCurrentCampaignCollections,
  updateInformationCorrection,
  updateScheme,
} from '../../../../feature/campaignState';
import {
  formatDateString,
  serializeStatisticsData,
  showPriceDigits,
} from '../../../../utils/formats';
import { SchemeEditType } from '../../../../project-types/campaign/types';
import {
  ChangePaymentPlanForm,
  CollectionActionTableRow,
  COLLECTION_STATUSES,
  DonationsTableRow,
  DonorStatuses,
  DonorTableRow,
  PaymnetInformationCorrectionForm,
} from '../../types';
import { DonorPublicFields } from '../../../../project-types/donor/types';
import ActionRequiredEditPaymentInfoContent from './Modals/ActionRequiredEditPaymentInfoModal';

const { TabPane } = Tabs;

export default function CampaignInfo() {
  const { campaignId } = useParams<{ campaignId: string }>();

  const { push } = useHistory();
  const dispatch = useDispatch();
  const campaign = useSelector(selectCurrentCampaign);
  const campaignCollections = useSelector(selectCurrentCampaignCollections);

  const campaignScheme = useSelector(selectCampaignScheme);

  const EditDescriptionModal = ModalInPortal();
  const EditCollectionRulesModal = ModalInPortal();
  const EditCollectionNotificationsModal = ModalInPortal();
  const ViewCollectioPaymentStatusModal = ModalInPortal();
  const EditCollectioPaymentInfoModal = ModalInPortal();
  const ChangePaymentPlanModal = ModalInPortal();
  const ActionRequiredEditPaymentInfoModal = ModalInPortal();

  const [selectedDonors, setSelectedDonors] = useState<
    Array<DonorPublicFields['_id']>
  >([]);
  const [selectedCollectionStatus, setSelectedCollectionStatus] =
    useState<keyof typeof COLLECTION_STATUSES | null>(null);
  const [selectedActionRequiredStatus, setSelectedActionRequiredStatus] =
    useState<string | null>(null);
  const [selectedCollectionPeriod, setSelectedCollectionPeriod] = useState<
    [string, string]
  >(['', '']);
  const [selectedActionRequiredPeriod, setSelectedActionRequiredPeriod] =
    useState<[string, string]>(['', '']);
  const [selectedFilter, setSelectedFilter] = useState<DonorStatuses>('Active');
  const [nameFilter, setNameFilter] = useState('');
  const [idFilter, setIdFilter] = useState('');
  const [nameContr, setNameContr] = useState('');
  const [paymentData, setPaymentData] =
    useState<null | CollectionActionTableRow>(null);
  const [paymentStatus, setPeymentStatus] = useState<string | null>(null);
  const [preAuthSelectedRows, setPreAuthSelectedRows] = useState<Array<string>>(
    []
  );
  const [preAuthSelectedContributorId, setPreAuthSelectedContributorId] =
    useState('');

  const donorStatuses: Array<DonorStatuses> = ['Active', 'Frozen'];
  const CampaignStatData = [
    {
      title: 'Total amount collected',
      value: campaignScheme.scheme.totalCollections,
    },
    {
      title: 'Registered contributors',
      value: serializeStatisticsData(
        campaignScheme.scheme.registeredContributors
      ),
    },
    {
      title: 'Total number of collections',
      value: serializeStatisticsData(campaignScheme.scheme.numberCollections),
    },
  ];

  const onFreeze = () => {
    dispatch(freezeDonors(campaignId as string, selectedDonors, true));
  };

  const onUnFreeze = () => {
    dispatch(freezeDonors(campaignId as string, selectedDonors, false));
  };

  const onDelete = () => {
    dispatch(deleteDonors(campaignId as string, selectedDonors));
  };

  const onSubmitDescriptionChange = (
    newDescription: SchemeEditType['description']
  ) => {
    dispatch(
      updateScheme(campaignId as string, { description: newDescription })
    );
    setTimeout(() => {
      dispatch(loadCampaignScheme(campaignId as string));
    }, 500);
  };

  const onSubmitCollectionRulesChange = (newRules: {
    collectionAttempts: SchemeEditType['auto_retry_number'];
  }) => {
    dispatch(
      updateScheme(campaignId as string, {
        auto_retry_number: newRules.collectionAttempts,
      })
    );
    setTimeout(() => {
      dispatch(loadCampaignScheme(campaignId as string));
    }, 500);
  };
  const onSubmitCollectionNotifications = (
    newArr: SchemeEditType['notifications_emails_attributes']
  ) =>
    dispatch(
      updateScheme(campaignId as string, {
        notifications_emails_attributes: newArr,
      })
    );
  const onSubmitPaymentStatus = () => {
    EditCollectioPaymentInfoModal.onShow();
  };
  const onSubmitPaymentCorrection = (
    values: PaymnetInformationCorrectionForm
  ) => {
    EditCollectioPaymentInfoModal.onClose();
    console.log('[PAYMENT INFORMATION CORRECTION]:', values);
    dispatch(
      updateInformationCorrection(
        preAuthSelectedContributorId,
        preAuthSelectedRows[0],
        values
      )
    );
    setTimeout(() => {
      dispatch(loadCampaignScheme(campaignId as string));
    }, 500);
  };
  const onSubmitChangePaymentPlan = (values: ChangePaymentPlanForm) => {
    ChangePaymentPlanModal.onClose();
    console.log('[CHANGE PAYMENT PLAN]:', values);
  };

  const onBackClickHandler = () =>
    push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());

  const onViewCollectionHandler = (paymentRecord: CollectionActionTableRow) => {
    if (paymentRecord.exceptionType === 'info') {
      setPaymentData(paymentRecord);
      setPeymentStatus('Information Error');
      ActionRequiredEditPaymentInfoModal.onShow();
    } else if (paymentRecord.exceptionType === 'mandate') {
      setPaymentData(paymentRecord);
      setPeymentStatus('DDI Mandate Cancelled');
      ActionRequiredEditPaymentInfoModal.onShow();
    } else {
      setPaymentData(paymentRecord);
      ViewCollectioPaymentStatusModal.onShow();
    }
  };
  const onEditDescriptionClickHandler = () => EditDescriptionModal.onShow();
  const onEditCollectionRulesClickHandler = () =>
    EditCollectionRulesModal.onShow();
  const onEditCollectionNotificationsClickHandler = () =>
    EditCollectionNotificationsModal.onShow();
  const onShowContributorInfoHandler = (contributorId: string) =>
    push(
      ROUTER_CONFIG.SCHOOL_CAMPAIGN_CONTRIBUTOR_INFO.getPath({
        campaignId,
        contributorId,
      } as { campaignId: string; contributorId: string })
    );
  const onInformatioCorrectionHandler = () => {
    setPeymentStatus('Information Correction');
    EditCollectioPaymentInfoModal.onShow();
  };

  const onChangePaymentPlan = () => {
    ChangePaymentPlanModal.onShow();
  };

  const actionRequiredTableSource: Array<CollectionActionTableRow> =
    campaignScheme.exceptions.map((collection) => ({
      key: collection.id,
      id: collection.id,
      contributorName: collection.contributorName,
      status: collection.exceptionStatus,
      studentId: collection.studentReferenceArray.join(', '),
      exceptionAmount: `£${showPriceDigits(
        Number(collection.exceptionAmount)
      )}`,
      giftAidAmount: `£${showPriceDigits(
        Number(collection.exceptionGiftAidAmount)
      )}`,
      exceptionType: collection.exceptionType,
      exceptionDate: collection.exceptionDate,
    }));

  const donorTableSource: Array<DonorTableRow> =
    campaignScheme.contributors.map((collection) => ({
      id: collection.id,
      key: collection.id,
      fullName: collection.name,
      email: collection.email,
      giftAidBool: collection.giftAidBool ? 'yes' : 'no',
      successfulCollections: collection.successfulCollections,
      failedCollections: collection.failedCollections,
      plannedCollections: collection.plannedCollections,
      totalCollected: collection.totalCollected,
      studentReferences:
        collection.studentReferenceArray &&
        collection.studentReferenceArray.length
          ? collection.studentReferenceArray.join(', ')
          : '-',
      notes: collection.notes,
    }));
  // .reduce((acc: Array<DonorTableRow>, donor) => {
  //   switch (selectedFilter) {
  //     case 'Active':
  //       !campaign?.freezed.includes(donor.key) && acc.push(donor);
  //       break;
  //     case 'Frozen':
  //       campaign?.freezed.includes(donor.key) && acc.push(donor);
  //       break;
  //     default:
  //       acc.push(donor);
  //   }
  //
  //   return acc;
  // }, []);

  const donationsTableSource: Array<DonationsTableRow> =
    campaignScheme.collections.map((collection) => ({
      key: collection.id,
      contributorName: collection.contributorName,
      contributorID: collection.contributorID,
      collectionDate: collection.collectionDate,
      collectionAmount: `£${showPriceDigits(
        Number(collection.collectionAmount)
      )}`,
      giftAidAmount: collection.giftAidAmount,
      status: collection.status,
    }));

  const [collectionsFiltered, setCollectionsFiltered] =
    useState(donationsTableSource);

  const [exceptionsFiltered, setExceptionsFiltered] = useState(
    actionRequiredTableSource
  );

  useEffect(() => {
    setCollectionsFiltered(donationsTableSource);
    setExceptionsFiltered(actionRequiredTableSource);
  }, [campaignScheme]);

  const onAddNewDonor = () => {
    push(
      ROUTER_CONFIG.SCHOOL_INVITE.getPath({ campaignId } as {
        campaignId: string;
      })
    );
  };

  useEffect(() => {
    const startDate = selectedActionRequiredPeriod[0]
      ? formatDateString(selectedActionRequiredPeriod[0])
      : '';
    const endDate = selectedActionRequiredPeriod[1]
      ? formatDateString(selectedActionRequiredPeriod[1], true)
      : '';

    const updatedList = [...actionRequiredTableSource];

    if (selectedActionRequiredStatus !== 'ALL') {
      setExceptionsFiltered(
        updatedList.filter(
          (el) => el.exceptionType === selectedActionRequiredStatus
        )
      );
    }

    if (startDate !== '' && endDate !== '') {
      const start = new Date(startDate);
      const end = new Date(endDate);
      setExceptionsFiltered(
        updatedList.filter((el) => {
          const collDate = new Date(el.exceptionDate ? el.exceptionDate : '');

          return start < collDate && collDate < end;
        })
      );
    }

    if (
      selectedActionRequiredStatus === 'ALL' &&
      startDate === '' &&
      endDate === ''
    ) {
      setExceptionsFiltered(updatedList);
    }
  }, [
    campaignId,
    selectedActionRequiredStatus,
    selectedActionRequiredPeriod,
    dispatch,
  ]);

  useEffect(() => {
    const startDate = selectedCollectionPeriod[0]
      ? formatDateString(selectedCollectionPeriod[0])
      : '';
    const endDate = selectedCollectionPeriod[1]
      ? formatDateString(selectedCollectionPeriod[1], true)
      : '';
    const collectionStatus = selectedCollectionStatus
      ? COLLECTION_STATUSES[selectedCollectionStatus]
      : '';

    const updatedList = [...donationsTableSource];

    if (startDate !== '' && endDate !== '') {
      const start = new Date(startDate);
      const end = new Date(endDate);
      setCollectionsFiltered(
        updatedList.filter((el) => {
          const collDate = new Date(el.collectionDate);

          return start < collDate && collDate < end;
        })
      );
    }
    if (nameFilter !== '') {
      setCollectionsFiltered(
        updatedList.filter((el) =>
          el.contributorName.toLowerCase().includes(nameFilter.toLowerCase())
        )
      );
    }
    if (selectedCollectionStatus !== null) {
      setCollectionsFiltered(
        updatedList.filter((el) => el.status === collectionStatus)
      );
    }
    if (idFilter !== '') {
      setCollectionsFiltered(
        updatedList.filter((el) => el.contributorID === idFilter)
      );
    }
    if (
      selectedCollectionStatus === null &&
      nameFilter === '' &&
      startDate === '' &&
      endDate === '' &&
      idFilter === ''
    ) {
      setCollectionsFiltered(updatedList);
    }
  }, [
    campaignId,
    selectedCollectionStatus,
    selectedCollectionPeriod,
    nameFilter,
    idFilter,
    dispatch,
  ]);

  const filteredContr = donorTableSource?.filter((d) =>
    d.fullName.toLowerCase().includes(nameContr.toLowerCase())
  );
  const filteredActionRequiredCollections = actionRequiredTableSource.filter(
    (source) => {
      if (source.status === 'All') {
        return true;
      }

      return source.status === selectedActionRequiredStatus;
    }
  );

  const onCloseActionRequiredModal = () => {
    ActionRequiredEditPaymentInfoModal.onClose();
    ViewCollectioPaymentStatusModal.onClose();
    dispatch(loadCampaignScheme(campaignId as string));
  };

  const handeExport = () => {
    loadExport(campaignId as string);
  };
  const handeExportProblematic = () => {
    loadExportProblematic(campaignId as string);
  };

  const handeExportSummary = () => {
    loadExportSummary(campaignId as string);
  };

  useEffect(() => {
    // dispatch(loadCampaign('22'));
    dispatch(loadCampaignScheme(campaignId as string));
  }, [campaignId, dispatch]);

  // useEffect(() => {
  //   if (selectedActionRequiredPeriod[0] && selectedActionRequiredPeriod[1]) {
  //     const date1 = new Date(selectedActionRequiredPeriod[0]).getTime();
  //     const date2 = new Date(selectedActionRequiredPeriod[1]).getTime();
  //
  //     const filteredByDateCollections = actionRequiredDataMock.filter(
  //       (collection) => {
  //         const collectionDate = new Date(collection.postedDate).getTime();
  //
  //         if (collectionDate >= date1 && collectionDate <= date2) {
  //           return true;
  //         }
  //
  //         return false;
  //       }
  //     );
  //
  //     setActionRequiredCollections(filteredByDateCollections);
  //   } else {
  //     setActionRequiredCollections([...actionRequiredDataMock]);
  //   }
  // }, [selectedActionRequiredStatus, selectedActionRequiredPeriod]);

  return (
    <SchoolLayout>
      {ActionRequiredEditPaymentInfoModal.ModalRender && (
        <ActionRequiredEditPaymentInfoModal.ModalRender>
          <ActionRequiredEditPaymentInfoContent
            onChangeSubmit={onSubmitPaymentCorrection}
            onClose={onCloseActionRequiredModal}
            status={paymentStatus}
            initialData={paymentData}
          />
        </ActionRequiredEditPaymentInfoModal.ModalRender>
      )}
      {EditDescriptionModal.ModalRender && (
        <EditDescriptionModal.ModalRender>
          <EditDescriptionModalContent
            campaignDescription={
              campaignScheme.scheme.campaignDescription || ''
            }
            onClose={EditDescriptionModal.onClose}
            onSubmit={onSubmitDescriptionChange}
          />
        </EditDescriptionModal.ModalRender>
      )}
      {EditCollectionRulesModal.ModalRender && (
        <EditCollectionRulesModal.ModalRender>
          <EditCollectionRulesModalContent
            campaignCollectionAttempts={campaignScheme.scheme.collectionRules}
            onClose={EditCollectionRulesModal.onClose}
            onFormSubmit={onSubmitCollectionRulesChange}
          />
        </EditCollectionRulesModal.ModalRender>
      )}
      {EditCollectionNotificationsModal.ModalRender && (
        <EditCollectionNotificationsModal.ModalRender>
          <EditCollectionNotificationsModalContent
            onChangeSubmit={onSubmitCollectionNotifications}
            onClose={EditCollectionNotificationsModal.onClose}
            initialData={
              campaignScheme.scheme?.collectionNotificationEmailArray
            }
          />
        </EditCollectionNotificationsModal.ModalRender>
      )}
      {ViewCollectioPaymentStatusModal.ModalRender && paymentData && (
        <ViewCollectioPaymentStatusModal.ModalRender>
          <ViewCollectioPaymentStatusContent
            onChangeSubmit={onSubmitPaymentStatus}
            onClose={onCloseActionRequiredModal}
            initialData={paymentData}
          />
        </ViewCollectioPaymentStatusModal.ModalRender>
      )}
      {EditCollectioPaymentInfoModal.ModalRender && paymentStatus && (
        <EditCollectioPaymentInfoModal.ModalRender>
          <EditCollectioPaymentInfoContent
            onChangeSubmit={onSubmitPaymentCorrection}
            onClose={EditCollectioPaymentInfoModal.onClose}
            status={paymentStatus}
          />
        </EditCollectioPaymentInfoModal.ModalRender>
      )}
      {ChangePaymentPlanModal.ModalRender && preAuthSelectedRows.length > 0 && (
        <ChangePaymentPlanModal.ModalRender>
          <ChangePaymentPlanContent
            preAuth
            onClose={ChangePaymentPlanModal.onClose}
            onChangeSubmit={onSubmitChangePaymentPlan}
            campaignId={campaignId}
            contributorId={preAuthSelectedRows[0]}
          />
        </ChangePaymentPlanModal.ModalRender>
      )}
      {campaignScheme && (
        <CreateCampaignWrap>
          <StyledBackButton onClickHandler={onBackClickHandler} />
          <Content>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <MainHeader>{campaignScheme.scheme.schemeName}</MainHeader>
              <TopButtonsWrapper>
                <Download
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handeExport}
                >
                  Export All Sign-ups
                </Download>
                <Download
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handeExportProblematic}
                >
                  Export Problem Sign-ups
                </Download>
                <Download
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handeExportSummary}
                >
                  Export Summary
                </Download>
                <InviteParentsButton type="primary" onClick={onAddNewDonor}>
                  + Invite New Parents
                </InviteParentsButton>
              </TopButtonsWrapper>
            </div>

            <StyledInfoTable data={CampaignStatData} />
            <StyledSummaryTable
              campaign={campaignScheme}
              campaignStats={campaignCollections.stats}
              onEditDescriptionClickHandler={onEditDescriptionClickHandler}
              onEditCollectionRulesClickHandler={
                onEditCollectionRulesClickHandler
              }
              onEditCollectionNotificationsClickHandler={
                onEditCollectionNotificationsClickHandler
              }
            />

            <ManageDonorsTable
              campaign={campaignScheme}
              campaignId={campaignId as string}
              onShowContributorInfoHandler={onShowContributorInfoHandler}
              onInformatioCorrectionHandler={onInformatioCorrectionHandler}
              onChangePaymentPlan={onChangePaymentPlan}
              setPreAuthSelectedRows={setPreAuthSelectedRows}
              preAuthSelectedRows={preAuthSelectedRows}
              setPreAuthSelectedContributorId={setPreAuthSelectedContributorId}
            />
            <ActionRequiredActions
              onChangeTimePeriod={setSelectedActionRequiredPeriod}
              onChangeFilter={setSelectedActionRequiredStatus}
            />
            <ActionRequiredTable
              tableData={exceptionsFiltered}
              onViewCollectionHandler={onViewCollectionHandler}
            />
            <TabsStyled defaultActiveKey="1">
              <TabsPaneStyled tab="Collections" key="1">
                <CollectionReport
                  onChangeTimePeriod={setSelectedCollectionPeriod}
                  onChangeFilter={setSelectedCollectionStatus}
                  onChangeID={setIdFilter}
                  nameFilter={nameFilter}
                  idFilter={idFilter}
                  setNameFilter={setNameFilter}
                />
                <CollectionTable
                  tableData={collectionsFiltered}
                  onShowContributorInfoHandler={onShowContributorInfoHandler}
                />
              </TabsPaneStyled>
              <TabsPaneStyled tab="Contributors" key="2">
                <DonorsReports
                  onAddNewDonor={onAddNewDonor}
                  numberOfDonors={campaignScheme.contributors.length}
                  onFreeze={onFreeze}
                  onUnFreeze={onUnFreeze}
                  onDelete={onDelete}
                  nameContr={nameContr}
                  setNameContr={setNameContr}
                  donorStatuses={donorStatuses}
                  onFilterChange={setSelectedFilter}
                  selectedFilter={selectedFilter}
                />
                <DonorsTable
                  selectedDonorsKeys={selectedDonors}
                  tableSource={nameContr ? filteredContr : donorTableSource}
                  onSelectDonor={setSelectedDonors}
                  onShowContributorInfoHandler={onShowContributorInfoHandler}
                />
              </TabsPaneStyled>
            </TabsStyled>
          </Content>
        </CreateCampaignWrap>
      )}
    </SchoolLayout>
  );
}

const CreateCampaignWrap = styled.main`
  gap: 34px;
  display: flex;
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledBackButton = styled(BackButton)`
  padding-top: 10px;
`;

const MainHeader = styled.h1`
  font-weight: 750;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
`;

const TabsStyled = styled(Tabs)`
  margin-top: 57px;
`;

const TabsPaneStyled = styled(TabPane)`
  & .ant-tabs-tabpane {
  }
  padding-top: 25px;
`;

const StyledInfoTable = styled(InfoTable)`
  margin-top: 30px;
`;

const StyledSummaryTable = styled(SummaryTable)`
  margin-top: 42px;
`;

const Download = styled(Button)``;

const TopButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InviteParentsButton = styled(Button)`
  margin-left: 20px;
  background-color: #66bf3c;
  border-color: #66bf3c;
  &:hover {
    background-color: #66bf3c;
    border-color: #66bf3c;
    opacity: 0.9;
  }
`;

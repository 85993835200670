import { useState } from 'react';
import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../../router/router-config';
import AdminLayout from '../../layout/AdminLayout';
import { APPROVAL_STATUSES } from '../../types';
import CharityView from './CharityView';

export default function CharityPage() {
  const { push } = useHistory();

  const [selectedPeriod, setSelectedPeriod] = useState<[string, string]>(['', '']);
  const [selectedFilter, setSelectedFilter] = useState<keyof typeof APPROVAL_STATUSES>('Pending');

  const onBackClickHandler = () => push(ROUTER_CONFIG.ADMIN_CHARITIES_OVERVIEW.getPath());

  return (
    <AdminLayout>
      <CharityView onBackClickHandler={onBackClickHandler} onChangeFilter={setSelectedFilter} onChangeTimePeriod={setSelectedPeriod} currentFilter={selectedFilter} />
    </AdminLayout>
  );
}

import { Tabs } from 'antd';
import styled from 'styled-components/macro';
import { SchoolPublicType } from '../../../project-types/schools/types';
import { UpdateSchoolBillingDetails } from '../types';
import BankAccountForm from './BankAccountForm';
import GiftAidCharityTab from './GiftAidCharityTab';

const { TabPane } = Tabs;

interface SchemeSupportSettingsProps {
  onBillingDetailsFormSubmit: (values: UpdateSchoolBillingDetails) => void;
  schoolProfile: SchoolPublicType;
}

export default function SchemeSupportSettings({ onBillingDetailsFormSubmit, schoolProfile }: SchemeSupportSettingsProps) {
  const formInitialValues: UpdateSchoolBillingDetails = {
    accountNumber: schoolProfile.accountNumber,
    bankAccountName: schoolProfile.bankAccountName,
    branchSortCode1: schoolProfile.branchSortCode1,
    branchSortCode2: schoolProfile.branchSortCode2,
    branchSortCode3: schoolProfile.branchSortCode3,
  };

  return (
    <Container>
      <Title>Scheme Support Settings</Title>
      <Tabs>
        <TabPane tab="Bank Account" key="bankAccount">
          <BankAccountForm onFormSubmit={onBillingDetailsFormSubmit} formInitialValues={formInitialValues} />
        </TabPane>
        <TabPane tab="Gift Aid Charity" key="giftAidCharity">
          <GiftAidCharityTab />
        </TabPane>
      </Tabs>
    </Container>
  );
}

const Container = styled.div`

`;

const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 31px;
`;

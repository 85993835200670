import { SchoolPublicTypeLite } from '../schools/types';

export enum COLLECTION_PERRIODS {
  'monthly' = 'monthly',
  'quarterly' = 'quarterly',
  'annualy' = 'annualy',
  'oneoff' = 'oneoff',
}

interface Theme {
  hideTotals: boolean;
}

export interface CampaignPublicType {
  _id: string;
  name: string;
  description: string;
  collectionPeriod: COLLECTION_PERRIODS;
  inviteCode: string;
  initialDonation?: number;
  numberOfCollections?: number;
  differentInitalDonation?: boolean;
  donationAmounts?: Array<number | undefined>;
  allowCustomAmounts?: boolean;
  startDate: string;
  freezed: Array<string>;
  owner: string;
  isFreezed: boolean;
  school: string | SchoolPublicTypeLite;
  isStudentsRefRequired?: boolean;
  isAuthorizationRequired?: boolean;
  collectionAttempts?: number;
  collectionNotificationEmailArray?: Array<string | undefined>;
  collectionType?: string;
  giftAid?: boolean;
  collections?: Array<any>;
  activePaymentOption?: Array<any>;
  question?: string;
  contributionHTML?: string;
  contributionURL?: string;
  choices?: Array<string | undefined>;
  logo?: string;
  deadlineDate?: string;
  theme?: Theme;
}

type NotificationEmailsType = {
  email: string;
};

export interface SchemePublicType {
  description: string;
  notifications_emails_attributes?: Array<NotificationEmailsType>;
  auto_retry_number?: number;
}

export interface CollectionForm {
  collectionSchemeName: string;
  collectionFrequency: string;
  initialDonationAmount: number;
  recurringDonationAmounts: number;
  collectionPeriod: string;
  differentInitalDonation: boolean;
  numberOfCollections: number;
  initialDonation: string;
}

export interface PayOptType {
  collectionFrequency: string;
  collectionPeriod: string;
  collectionSchemeName: string;
  donationAmount: string;
  initialDonationAmount: number;
  numberOfCollections: number;
  recurringDonationAmounts: number;
  _id: string;
}

export type CampaignCreateType = Omit<
  CampaignPublicType,
  '_id' | 'inviteCode' | 'freezed' | 'owner' | 'isFreezed' | 'school'
>;

export type CreateCampignSchemaType = Omit<CampaignCreateType, 'startDate'> & {
  startDate: Date;
};

export type CreateCampignFields = Omit<
  CampaignPublicType,
  '_id' | 'freezed' | 'owner' | 'isFreezed' | 'startDate' | 'school'
> & { startDate: Date };

export interface InviteType {
  email: string;
  fullName?: string;
}

export interface CreateInvitesListType {
  members: Array<InviteType>;
  message?: string;
}

export interface FreezeDonorsType {
  donors: string[];
  toFreeze: boolean;
}

export type CampaignEditType = Partial<
  Omit<
    CampaignPublicType,
    | '_id'
    | 'inviteCode'
    | 'school'
    | 'startDate'
    | 'freezed'
    | 'owner'
    | 'isFreezed'
  > & {
    school: string;
    startDate: Date;
  }
>;

export type SchemeEditType = Partial<Omit<SchemePublicType, '_id'>>;

export type FullCampaignType = {
  scheme: CampaignType;
  contributors: Array<CampaignContributorsType>;
  collections: Array<CampaignCollectionType>;
  exceptions: Array<CampaignExceptionType>;
  collectionPreAuthorisations: Array<CampaignPreAuthType>;
};

export type CampaignType = {
  id: string;
  inviteCode: string;
  schemeName: string;
  startDate: string;
  endDate: string;
  registeredContributors: number;
  totalCollections: string;
  numberCollections: number;
  collectionType?: string;
  campaignDescription: string;
  collectionRules: number;
  collectionNotificationEmailArray: Array<string>;
  schoolContributionAgreementID: string;
};

export type CampaignContributorsType = {
  id: string;
  name: string;
  email: string;
  giftAidBool: boolean;
  studentReferenceArray: Array<string>;
  successfulCollections: number;
  plannedCollections: number;
  failedCollections: number;
  totalCollected: string;
  notes: string;
};

export type CampaignCollectionType = {
  id: string;
  contributorName: string;
  contributorID: string;
  collectionDate: string;
  collectionAmount: string;
  giftAidAmount: string;
  status: string;
};

export type CampaignExceptionType = {
  id: string;
  contributorName: string;
  contributorID: string;
  exceptionDate: string;
  exceptionAmount: string;
  exceptionGiftAidAmount: string;
  exceptionStatus: string;
  exceptionType: string;
  studentReferenceArray: Array<string>;
};

export type CampaignPreAuthType = {
  id: string;
  contributorName: string;
  contributorID: string;
  firstCollectionDate: string;
  studentReferenceArray: Array<string>;
  giftAidRegistered: boolean;
  subscriptionName: string;
  informationError: boolean;
};

export type FullContributorType = {
  parent: ContributorParentType;
  collections: Array<ContributorCollectionsType>;
  schemes: Array<ContributorSchemesType>;
};

export type ContributorParentType = {
  id: string;
  name: string;
  email: string;
  activeSchemes: number;
  totalCollectionsSum: string;
  totalCollectionsNumber: number;
};

export type ContributorCollectionsType = {
  id: string;
  collectionDate: string;
  collectionAmount: string;
  giftAidAmount: string;
  status: string;
};

export type ContributorSchemesType = {
  id: string;
  name: string;
  schoolName: string;
  studentReferenceArray: Array<string>;
  status: string;
  subscriptionName: string;
  subscriptionID: number;
  totalCollected: string;
  totalCollections: number;
  totalFailedCollections: number;
  startDate: string;
  endDate: string;
};

export type FullCurrentPlanType = {
  currentSchemeChoicesArray: Array<CurrentPlanSchemeChoiceType>;
  otherSchemeChoicesArray: Array<OtherPlanSchemeChoiceType>;
};

export type CurrentPlanSchemeChoiceType = {
  id: string;
  text: string;
  isActive?: boolean;
};

export type OtherPlanSchemeChoiceType = {
  schemeID: string;
  schemeName: string;
  schemeChoicesArray: Array<CurrentPlanSchemeChoiceType>;
};

export type CalibrateSummaryType = {
  initialAmount: string;
  recurringAmount: string;
  collectionFrequency: string;
  numberOfCollections: number;
  activePeriod: string;
  executed: boolean;
};

export type BalanceTransferType = {
  totalCollected: string;
  totalRemittance: string;
  totalGiftAid: string;
  BTS: Array<BalanceBTSType>;
};

export type BalanceBTSType = {
  period: string;
  name: string;
  link: string;
  sum: string;
};

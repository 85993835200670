import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import Header from '../layout/Header';
import { MainHeader } from '../../ui/Headers';
import CloseIcon from '../../img/icons/close_icon.svg';
import ROUTER_CONFIG from '../../router/router-config';

export default function ForgotPasswordCompletePage() {
  const { push } = useHistory();
  const onCloseClickHandler = () => push(ROUTER_CONFIG.DONOR_LOGIN.getPath());

  return (
    <>
      <Header />
      <Wrap>
        <Content>
          <Title>Check you mailbox</Title>
          <Text>We’ve send you an email with instructions on how to reset your password</Text>
          <CloseButton onClick={onCloseClickHandler} src={CloseIcon} alt="" />
        </Content>
      </Wrap>
    </>
  );
}

const Wrap = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  box-sizing: border-box;
  min-height: calc(100vh - 165px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
`;

const Content = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 640px;
  box-shadow: 3px 3px 14px 14px #00000014;
  background-color: #fff;
  padding: 60px;
`;

const Title = styled(MainHeader)`
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 40px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 28px;
`;

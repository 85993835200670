import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { changeDonorProfile, getUserProfile } from '../../../feature/userState';
import DonorLayout from '../layout/DonorLayoutWithTabs';
import DonorSettings from './DonorSettings';
import { UpdateDonorFields } from '../../../project-types/donor/types';
import ROUTER_CONFIG from '../../../router/router-config';

export default function DonorSettingsPage() {
  const { push } = useHistory();
  const profile = useSelector(getUserProfile);
  const dispatch = useDispatch();

  const onEmailEditClickHandler = () => profile?.donorProfile?._id && push(ROUTER_CONFIG.DONOR_CHANGE_EMAIL.getPath({ donorId: profile?.donorProfile?._id }));
  const onChangePasswordClickHandler = () => profile?.donorProfile?._id && push(ROUTER_CONFIG.DONOR_CHANGE_PASSWORD.getPath({ donorId: profile?.donorProfile?._id }));
  const onFormSubmitHandler = (formData: UpdateDonorFields) => dispatch(changeDonorProfile(formData));

  return (
    <DonorLayout>
      {profile && profile?.donorProfile && (
        <DonorSettings profileData={profile?.donorProfile} userData={profile.user} onEmailEditClickHandler={onEmailEditClickHandler} onChangePasswordClickHandler={onChangePasswordClickHandler} onFormSubmitHandler={onFormSubmitHandler} />
      )}
    </DonorLayout>
  );
}

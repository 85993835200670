import styled from 'styled-components/macro';

export default function StatusBlock() {
  return (
    <Container>
      <StatusItem>
        <GreenText>expected value</GreenText>
        <ValueText>£800.00</ValueText>
      </StatusItem>
      <StatusItem>
        <GreenText>status</GreenText>
        <ValueText>Pending</ValueText>
      </StatusItem>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 350px;
  background-color: #F9F9F9;
  padding: 15px 20px;
  justify-content: space-between;
  border: 1px solid #D5DCE5;
`;

const StatusItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const GreenText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #1B9D75;
  text-transform: uppercase;
`;

const ValueText = styled.span`
  font-size: 21px;
  color: #102f54;
`;

import styled from 'styled-components/macro';
import CollectionPeriodComponent from '../../all-collections/CollectionPeriodComponent';
import { SchoolCollectionsTableRow } from '../../types';
import CollectionsTable from './CollectionsTable';

interface CollectionsViewProps {
  tableData: Array<SchoolCollectionsTableRow>;
  currentMonth: number;
  onMonthChange: (month: number) => void;
  donorSearch: string;
  onChangeDonorSearch: (donor: string) => void;
  campaignSearch: string;
  onChangeCampaignSearch: (campaign: string) => void;
  dateSearch: string;
  onChangeDateSearch: (date: string) => void;
  onChangeStatusSearch: (status: Array<string> | null) => void;
}

export default function CollectionsView({
  tableData, currentMonth, onMonthChange, donorSearch, onChangeDonorSearch, campaignSearch, onChangeCampaignSearch, dateSearch, onChangeDateSearch, onChangeStatusSearch,
}: CollectionsViewProps) {
  const onIncDateRange = () => {
    if (currentMonth < 12) {
      onMonthChange(currentMonth + 1);
    } else {
      onMonthChange(1);
    }
  };
  const onDecrDateRange = () => {
    if (currentMonth > 1) {
      onMonthChange(currentMonth - 1);
    } else {
      onMonthChange(12);
    }
  };

  return (
    <Container>
      <CollectionPeriodComponent styles={{ margin: '40px 0 0' }} monthNum={currentMonth} onIncDateRange={onIncDateRange} onDecrDateRange={onDecrDateRange} />
      <CollectionsTable tableData={tableData} donorSearch={donorSearch} onChangeDonorSearch={onChangeDonorSearch} campaignSearch={campaignSearch} onChangeCampaignSearch={onChangeCampaignSearch} dateSearch={dateSearch} onChangeDateSearch={onChangeDateSearch} onChangeStatusSearch={onChangeStatusSearch} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  margin-top: -70px;
`;

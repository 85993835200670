import styled from 'styled-components/macro';

export const FieldWrap = styled.div`
  margin-bottom: 12px;
  width: 100%;
`;

export const FieldName = styled.span`
  font-weight: bold;
  display: block;
  font-size: 14px;
  margin-bottom: 6px;
  line-height: 14px;
`;

export const FieldTextarea = styled.div`
  background: #EEEEEE;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 11px 25px 11px;
  min-height: 85px;
`;

export const FieldTextareaText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #A7A7A7;
  margin: 0;
  max-width: 141px;
`;

export const FieldInput = styled.div`
  background: #EEEEEE;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 9px 11px 5px 11px;
`;

export const FieldInputText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #A7A7A7;
  margin: 0;
`;

export const FieldCodeWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FieldCode = styled.div`
  background: #EEEEEE;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 4px;
  width: 34px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #A7A7A7;
`;

import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { Input, Switch } from 'antd';
import PinInput from 'react-pin-input';

import { ButtonDark } from '../../../../../ui/Button';

import CloseIcon from '../../../../../img/close.svg';
import {
  CollectionActionTableRow,
  PaymnetInformationCorrectionForm,
} from '../../../types';
import { PaymentInformatioCorrectionScheme, RetryPaymentInformatioCorrectionScheme } from '../../../school-schemas';
import { FormConfig, renderFormConfig } from '../../../../../utils/form';
import { ExceptionsPostDataApi } from '../../../../../project-types/campaign/api-types';
import { postExceptions } from '../../../../../feature/campaignState';

interface ActionRequiredEditPaymentInfoModalProps {
  onClose: () => void;
  onChangeSubmit: (values: PaymnetInformationCorrectionForm) => void;
  status: string | null;
  initialData: CollectionActionTableRow | null;
}

const pinInputStyles = {
  borderColor: '#E0E0E0',
  borderRadius: '4px',
  width: '34px',
  height: '32px',
  margin: '0 1px 0 0',
};

export default function ActionRequiredEditPaymentInfoModal({
  onClose,
  onChangeSubmit,
  status,
  initialData,
}: ActionRequiredEditPaymentInfoModalProps) {
  const formConfig1: FormConfig = [
    {
      title: 'Name of Account Holder',
      name: 'accountHolderName',
      props: {
        type: 'text',
        maxLength: 18,
      },
      component: StyledInput,
      containerStyle: {
        marginBottom: '12px',
      },
      labelStyle: {
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
  ];
  const getFormkiInitiaValues = () => {
    const defaultValues = {
      mandateRetryMode: 'no update',
      accountHolderName: '',
      bankBuildingSocietyAccountNumber: '',
      branchSortCode: '',
    };

    return defaultValues;
  };

  const formik = useFormik({
    initialValues: getFormkiInitiaValues(),
    validationSchema: RetryPaymentInformatioCorrectionScheme,
    onSubmit: (values) => {
      const data: ExceptionsPostDataApi = {
        exceptionType: initialData?.exceptionType
          ? initialData?.exceptionType
          : '',
        mandateRetryMode: values.mandateRetryMode,
        customRetry: '',
        info: {
          bankAccount: values.bankBuildingSocietyAccountNumber,
          sortCode: values.branchSortCode,
          debitName: values.accountHolderName,
        },
      };
      if (initialData?.id) {
        postExceptions(initialData?.id, data)
          .then(() => {
            onClose();
            // onChangeSubmit();
          })
          .catch(() => alert('Something went wrong!'));
      }
    },
  });

  return (
    <Container>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>{status}</Title>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <Switch
            style={{ marginRight: 10 }}
            size="small"
            checked={!(formik.values.mandateRetryMode === 'no update')}
            onChange={(value) =>
              formik.setFieldValue(
                'mandateRetryMode',
                value ? 'update' : 'no update'
              )
            }
          />
          <SubTitle>Update Parent Bank Details</SubTitle>
        </div>
        {formik.values.mandateRetryMode === 'update' && (
          <>
            {status === 'DDI Mandate Cancelled' && (
              <WarningMessage>
                You must have contacted the parent and received authorisation to
                restart the Direct Debit!
              </WarningMessage>
            )}
            {renderFormConfig(formConfig1, formik)}
            <PinCodeWrapper>
              <SubTitle>Bank/ Building Sociery Account Number</SubTitle>
              <PinInput
                length={8}
                initialValue=""
                type="numeric"
                inputMode="number"
                inputStyle={pinInputStyles}
                onComplete={(value) =>
                  formik.setFieldValue(
                    'bankBuildingSocietyAccountNumber',
                    value
                  )
                }
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </PinCodeWrapper>
            <PinCodeWrapper>
              <SubTitle>Branch Sort Code</SubTitle>
              <PinInput
                length={6}
                initialValue=""
                type="numeric"
                inputMode="number"
                inputStyle={pinInputStyles}
                onComplete={(value) =>
                  formik.setFieldValue('branchSortCode', value)
                }
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </PinCodeWrapper>
          </>
        )}
        <SubmitButton type="submit">
          {status === 'DDI Mandate Cancelled' ? 'Save & Retry' : 'Save'}
        </SubmitButton>
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100%;
  max-height: 80vh;
  box-sizing: border-box;
  max-width: 572px;
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

const PinCodeWrapper = styled.div`
  margin-bottom: 12px;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const WarningMessage = styled.h4`
  font-size: 14px;
  font-weight: bold;
  color: red;
`;

const SecondTitle = styled.h4<{ isStudentsExist: boolean }>`
  font-size: 15px;
  font-weight: bold;
  margin: ${({ isStudentsExist }) =>
    isStudentsExist ? '57px 0 27px 0' : '0 0 27px 0'}
}

;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 14px 57px;
  align-self: flex-start;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const SubTitle = styled.h4`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
`;

const StyledInput = styled(Input)`
  width: 395px;
  border-radius: 4px;
`;

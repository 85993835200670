import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { isNumber } from 'util';
import Input from '../../../../ui/forms/Input';
import InputWrap from '../../../../ui/forms/InputWrap';
import crossImg from '../../../../img/icons/cross.svg';

type DonationAmoutTypes = {
  title: string,
  hasError?: boolean,
  name: string,
  onChange: any
}

interface DonationAmountWithSetField extends DonationAmoutTypes {
  setFieldValue: any;
}

export function DonationAmout({
  title,
  hasError,
  name,
  onChange,
}: DonationAmoutTypes) {
  return (
    <InputWrap
      title={title}
      hasError={hasError}
    >
      <DonationAmoutWrap>
        <DonationAmount name={name} onChange={onChange} type="number" min="0" />
      </DonationAmoutWrap>
    </InputWrap>
  );
}

export function SingleDonationAmountHack({
  title,
  hasError,
  name,
  setFieldValue,
}: DonationAmountWithSetField) {
  return (
    <InputWrap
      title={title}
      hasError={hasError}
    >
      <DonationAmoutWrap>
        <DonationAmount name={name} onChange={(e) => setFieldValue(name, [e.target.value])} type="number" step="0.01" min="0" />
      </DonationAmoutWrap>
    </InputWrap>
  );
}

interface DonationAmoutsTypes {
  name: string,
  onChange: (values: string[]) => void;
  setFieldValue: any
}

export default function DonationAmouts({ name, onChange, setFieldValue }: DonationAmoutsTypes) {
  const [values, setValues] = useState<string[]>(['']);

  useEffect(() => {
    // debugger;
    setFieldValue(name, values.filter((val) => val));
  }, [values, setFieldValue]);

  const updateValue = (key: number) => (e: any) => {
    const { value } = e.target;
    const newValues = [...values];
    newValues[key] = value;

    if (value && key === values.length - 1) {
      newValues.push('');
    }
    setValues(newValues);
  };

  const removeElement = (key: number) => () => {
    const newValues = ([
      ...values.slice(0, key),
      ...values.slice(key + 1),
    ]);

    // debugger;

    setValues(newValues);
  };

  return (
    <>
      {values.map((value, key) => (
        <DonationAmoutListWrap>
          <DonationAmount onChange={updateValue(key)} value={value} type="number" min="0" step="0.01" />
          {key < values.length - 1 && <CrossStyled src={crossImg} alt="remove" onClick={removeElement(key)} />}
        </DonationAmoutListWrap>
      ))}
    </>
  );
}

const DonationWrap = styled.div`
`;

const InputStyled = styled(Input)`
  width: 278px;
  height: 47px;
`;

const DonationAmount = styled(InputStyled)`
  padding-left: 25px;

`;

const DonationAmoutWrap = styled.div`
  position: relative;
  display: flex;
  &:before {
    content: "£";
    font-size: 14px;
    line-height: 22px;
    color: #BDBDBD;
    position: absolute;
    top: 0.9em;
    left: 14px;
  }
`;

const DonationAmoutListWrap = styled(DonationAmoutWrap)`
  margin-bottom: 10px;
`;

const CrossStyled = styled.img`
  cursor: pointer;
  margin-left: 18px;
`;

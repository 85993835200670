import styled from 'styled-components/macro'; // Импортим именно /macro (для читаемых имен классов)
import { Tabs, DatePicker } from 'antd';

import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';
import {
  DonorSchemesType,
  DashboardDataType,
  DonorCollectionsState,
} from '../../../project-types/donor-payment/types';
import InfoTable from '../../../ui/elements/InfoTable';

import CollectionTable from './Collections/CollectionTable';
import DonorsTable from './Donors/DonorsTable';
import DonorsReports from './Donors/DonorsReports';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

interface DonorDashboardProps {
  onCancelDonationsClickHandler: () => void;
  donorCollections: Array<DonorCollectionsState>;
  dashboardData: DashboardDataType | null;
  currentPeriodMonth: number;
  onIncDateRange: () => void;
  onDecrDateRange: () => void;
  selectedCampaigns: Array<string>;
  onCampaignSelectHandler: (campaignIds: Array<string>) => void;
  donorSchemesList: Array<DonorSchemesType>;
  onChangeTimePeriod: (period: [string, string]) => void;
}

export default function DonorDashboard({
  onCancelDonationsClickHandler,
  donorCollections,
  dashboardData,
  currentPeriodMonth,
  onIncDateRange,
  onDecrDateRange,
  selectedCampaigns,
  onCampaignSelectHandler,
  donorSchemesList,
  onChangeTimePeriod,
}: DonorDashboardProps) {
  const DonorStatData = [
    {
      title: 'Active or Pending Subscriptions',
      value: dashboardData?.activeOrPendingSubscriptions,
    },
    {
      title: 'Registered Students (Active/Pending Campaigns)',
      value: dashboardData?.totalStudentsNumber,
    },
    {
      title: 'Collections made',
      value: dashboardData?.totalCollectionsNumber,
    },
    {
      title: 'Collections sum',
      value: dashboardData?.totalCollectionsSum,
    },
  ];

  const schemesTableRowsData = donorSchemesList.map((scheme) => ({
    key: scheme.subscriptionID,
    school: scheme.schoolName,
    schemeName: scheme.name,
    schemeChoice: scheme.subscriptionChoice,
    numberOfStudents: scheme.studentCount,
    studentReferences: scheme.studentReferenceArray.length
      ? scheme.studentReferenceArray.join(', ')
      : '-',
    collectionSum: scheme.totalCollected,
    status: scheme.status,
  }));

  const activeSchemesTable = schemesTableRowsData.filter((scheme) => scheme.status === 'active');
  const pendingSchemesTable = schemesTableRowsData.filter((scheme) => scheme.status === 'pending');
  const archivedSchemesTable = schemesTableRowsData.filter(
    (scheme) => scheme.status === 'archived',
  );

  const collectionTableData = donorCollections.map((collection) => ({
    key: collection.id,
    date: formatDateDDMMMYYYY(new Date(collection.collectionDate)),
    amount: collection.collectionAmount,
    school: collection.assocSchool,
    schemeName: collection.assocSchemeName,
    schemeChoice: collection.assocSchemeChoice,
    giftAidAmount: collection.giftAidAmount,
    status: collection.status,
    studentReferences: collection.assocStudentReferences.length
      ? collection.assocStudentReferences.join(', ')
      : '-',
    download: collection.download,
  }));

  return (
    <Wrap>
      <Head>
        <Title>Collection Schemes</Title>
        <InfoTable data={DonorStatData} />
      </Head>
      <Main>
        <TabsStyled defaultActiveKey="1">
          <TabsPaneStyled tab="Collections" key="1">
            <RangePicker onChange={(_e, period) => onChangeTimePeriod(period)} />
            <CollectionTable tableData={collectionTableData} />
          </TabsPaneStyled>
          <TabsPaneStyled tab="Schemes" key="2">
            <DonorsReports
              onCancelClickHandler={onCancelDonationsClickHandler}
              headerTitle="Pending Schemes"
            />
            <DonorsTable
              tableRows={pendingSchemesTable}
              selectedCampaigns={selectedCampaigns}
              onCampaignSelectHandler={onCampaignSelectHandler}
            />
            <DonorsReports
              onCancelClickHandler={onCancelDonationsClickHandler}
              headerTitle="Active Schemes"
            />
            <DonorsTable
              tableRows={activeSchemesTable}
              selectedCampaigns={selectedCampaigns}
              onCampaignSelectHandler={onCampaignSelectHandler}
            />
            <DonorsReports
              onCancelClickHandler={onCancelDonationsClickHandler}
              headerTitle="Archived Schemes"
            />
            <DonorsTable
              tableRows={archivedSchemesTable}
              selectedCampaigns={selectedCampaigns}
              onCampaignSelectHandler={onCampaignSelectHandler}
            />
          </TabsPaneStyled>
        </TabsStyled>
      </Main>
    </Wrap>
  );
}

const Wrap = styled.main``;

const Head = styled.div``;

const Main = styled.div``;

const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  color: #102f54;
`;

const TabsStyled = styled(Tabs)`
  margin-top: 57px;
`;

const TabsPaneStyled = styled(TabPane)`
  & .ant-tabs-tabpane {
  }
  padding-top: 41px;
`;

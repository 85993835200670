import { message } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { signUpSchool } from '../../../feature/schoolsState';
import ROUTER_CONFIG from '../../../router/router-config';
import LoginLink from '../../layout/Menu/LoginLink';
import SchoolLayout from '../layout/SchoolLayout';
import { SignUpSchoolFields } from '../types';
import CreateSchool from './CreateSchool';

export default function CreateSchoolPage() {
  const history = useHistory();
  const [externalErrors, setExternalErrors] = useState<Record<string, string[]>>({});

  const onSignUp = (schoolFields: SignUpSchoolFields) => {
    const {
      email,
      password,
      ...fields
    } = schoolFields;

    signUpSchool({
      email,
      password,
      schoolProfile: fields,
      captcha: '123',
    })
      .then(() => {
        history.push(ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.getPath());
      })
      .catch((e) => {
        if (e?.response?.data) {
          // debugger;
          Object.keys(e.response.data)
            .forEach((row) => e.response.data[row]
              .forEach((err: string) => message.error(err)));
          // Object.keys(e?.response?.data)
          setExternalErrors(e.response.data);
        }
      });
  };

  const onLoginClickHandler = () => history.push(ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.getPath());

  return (
    <SchoolLayout headerLoginLinkTo={ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.path} menu={<LoginLink onLoginClick={onLoginClickHandler} />}>
      <CreateSchool onSignUp={onSignUp} externalErrors={externalErrors} />
    </SchoolLayout>
  );
}

// const Accept

import Footer from '../layout/Footer';
import GlobalWrap from '../layout/GlobalWrap';
import Header from '../layout/Header';
import MenuPublick from '../layout/Menu/MenuPublick';
import WelcomeContent from './WelcomeContent';

export default function WelcopmePage() {
  return (
    <div>
      <GlobalWrap>
        <Header>
          <MenuPublick />
        </Header>
        <WelcomeContent />
      </GlobalWrap>
      <Footer />
    </div>
  );
}

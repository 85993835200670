import styled from 'styled-components/macro';
import { useState } from 'react';
import { MainHeader } from '../../../ui/Headers';
import Input from '../../../ui/forms/Input';
import SearchIcon from '../../../img/icons/icon_search.svg';
import { ButtonDark, ButtonLight } from '../../../ui/Button';

interface MakePayoutProps {
  onSelectClickHandler: (searchValue: string) => void;
  onPaidClickHandler: () => void;
  onGoBackClickHandler: () => void;
  onSubmitClickHandler: () => void;
}

export default function MakePayout({
  onSelectClickHandler, onPaidClickHandler, onGoBackClickHandler, onSubmitClickHandler,
}: MakePayoutProps) {
  const [searchValue, setSearchValue] = useState('');

  return (
    <Wrap>
      <StyledMainHeader>Make payout</StyledMainHeader>
      <InputContainer>
        <Label>Choose school</Label>
        <InputContent>
          <StyledInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} iconSource={SearchIcon} />
          <Search onClick={() => onSelectClickHandler(searchValue)}>Select</Search>
        </InputContent>
      </InputContainer>
      <PaidBlock>
        <PaidTextContent>
          <GrayTitle>
            Amount outstanding for a past collection period
          </GrayTitle>
          <AmountsNumber>
            00
          </AmountsNumber>
        </PaidTextContent>
        <Paid onClick={onPaidClickHandler}>Paid</Paid>
      </PaidBlock>
      <ButtonBlock>
        <Cancel onClick={onGoBackClickHandler}>Go Back</Cancel>
        <Done onClick={onSubmitClickHandler}>Done</Done>
      </ButtonBlock>
    </Wrap>
  );
}

const Wrap = styled.main`
  box-sizing: border-box;
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 58px;
  margin-bottom: 65px;
`;

const InputContent = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const Label = styled.span`
  font-size: 18px;
`;

const Search = styled.span`
  color: #333;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledInput = styled(Input)<{ iconSource: string }>`
  width: 100%;
  max-width: 420px;
  padding: 15px 15px 15px 48px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 17px center;
`;

const PaidBlock = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  width: 100%;
  max-width: 500px;
`;

const PaidTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 185px;
`;

const GrayTitle = styled.span`
  font-size: 14px;
  color: #b2b2b2;
  text-align: center;
`;

const AmountsNumber = styled.span`
  font-size: 34px;
  font-weight: 750;
`;

const Paid = styled(ButtonDark)`
  padding: 12px 50px;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 180px;
`;

const Done = styled(ButtonDark)`
  box-sizing: border-box;
  width: 300px;
  padding: 15px;
`;

const Cancel = styled(ButtonLight)`
  box-sizing: border-box;
  width: 300px;
  padding: 15px;
`;

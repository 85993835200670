import styled from 'styled-components/macro';
import BackIcon from '../../img/icons/backIcon.svg';

interface BackButtonProps {
  onClickHandler: () => void;
  className?: string;
}

export default function BackButton({ onClickHandler, className }: BackButtonProps) {
  return <Button className={className} alt="" src={BackIcon} onClick={onClickHandler} />;
}

const Button = styled.img`
  width: 34px;
  height: 34px;
  cursor: pointer;
`;

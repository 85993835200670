import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateSchool } from '../../../feature/schoolsState';
import { getUserProfile, loadProfile } from '../../../feature/userState';
import SchoolLayoutWithTabs from '../layout/SchoolLayoutWithTabs';
import { UpdateSchoolBillingDetails } from '../types';
import SchemeSupportSettings from './SchemeSupportSettings';

export default function SchemeSupportSettingsPage() {
  const dispatch = useDispatch();
  const profile = useSelector(getUserProfile);

  const onBillingDetailsFormSubmit = (values: UpdateSchoolBillingDetails) => {
    updateSchool(values)
      .then(() => {
        message.info('School is updated');
        dispatch(loadProfile());
      })
      .catch((e) => {
        if (e?.response?.data) {
          Object.keys(e.response.data)
            .forEach((row) => e.response.data[row]
              .forEach((err: string) => message.error(err)));
        }
      });
  };

  return (
    <SchoolLayoutWithTabs>
      {profile && profile.schoolProfile && (
        <SchemeSupportSettings onBillingDetailsFormSubmit={onBillingDetailsFormSubmit} schoolProfile={profile.schoolProfile} />
      )}
    </SchoolLayoutWithTabs>
  );
}

import styled from "styled-components/macro";

interface InfoTilelProps {
  data: Array<{
    title: string,
    value: string
  }>
}


export default function InfoTilel({ data }: InfoTilelProps) {
  return (
    <CampaignStat>
      {data.map(item => (
        <CampaignStatSection>
          <CampaignStatSectionTitle>
            {item.title}
          </CampaignStatSectionTitle>
          <CampaignStatSectionAmount>
            {item.value}
          </CampaignStatSectionAmount>
        </CampaignStatSection>
      ))}
    </CampaignStat>
  );
}


const CampaignStat = styled.div`
  height: 116px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 13px;
  margin-top: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CampaignStatSection = styled.div`
  height: 88px;
  border-right: 1px solid #E0E0E0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:nth-last-of-type(1) {
    border: none;
  }
`;

const CampaignStatSectionTitle = styled.div`
  font-weight: 750;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
  margin-bottom: 6px;
`;


const CampaignStatSectionAmount = styled.div`
  font-weight: 450;
  font-size: 34px;
  line-height: 42px;
  color: #000000;
`;

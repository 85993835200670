import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface WrapComponentProps {
  children: ReactNode
}

export default function GlobalWrap({ children }: WrapComponentProps) {
  return (
    <Wrap>
      {children}
    </Wrap>
  );
}

const Wrap = styled.div`
  /* max-width: 1440px; */
  /* margin: 0 auto; */
  /* min-height: 100%; */
`;

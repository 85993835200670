import styled from 'styled-components/macro';
import VerificationIcon from '../../../../img/icons/VerificationIcon.svg';

export const PendingMessage = () => (
  <Container>
    <img alt="" src={VerificationIcon} />
    <TextContainer>
      <Title>Your school is pending verification</Title>
      <Text>
        Our admins will be approving
        your request shortly
      </Text>
    </TextContainer>
  </Container>
);

export const VerifiedMessage = () => (
  <Container>
    <img alt="" src={VerificationIcon} />
    <TextContainer>
      <Title>Your school&rsquo;s account has been successfully verified</Title>
    </TextContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin: 100px 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: #102F54;
  margin: 0;
  padding: 0;
`;

const Text = styled.span`
  font-size: 14px;
  color: #909DA8;
  margin: 0;
  padding: 0;
`;

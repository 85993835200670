import {
  FieldWrap, FieldName, FieldInput, FieldInputText,
} from '../LayoutComponents';

export function StatementName() {
  return (
    <FieldWrap>
      <FieldName>The name that will appear on your statement </FieldName>
      <FieldInput>
        <FieldInputText>
          L&Z RE SILVERWING
        </FieldInputText>
      </FieldInput>
    </FieldWrap>
  );
}

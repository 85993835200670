import styled from 'styled-components/macro';
import { ButtonDark, ButtonLight } from '../../../../ui/Button';
import { DeviceTradeinTableRow } from '../../types';
import OverviewTable from './OverviewTable';

interface DeviceTradeinProps {
  tableData: Array<DeviceTradeinTableRow>;
  onRowClickHandler: (campaignId: string) => void;
  onGenerateNewValuationClickHandler: () => void;
  onNewSchemeClickHandler: () => void;
}

export default function DeviceTradein({
  tableData, onRowClickHandler, onNewSchemeClickHandler, onGenerateNewValuationClickHandler,
}: DeviceTradeinProps) {
  return (
    <Container>
      <TitleBlock>
        <MainHeader>Device Trade-in</MainHeader>
        <ButtonBlock>
          <StyledButtonLight onClick={onNewSchemeClickHandler}>New Community Trade-in Scheme</StyledButtonLight>
          <StyledButtonDark onClick={onGenerateNewValuationClickHandler}>Generate New Valuation</StyledButtonDark>
        </ButtonBlock>
      </TitleBlock>
      <StyledOverviewTable tableData={tableData} onRowClickHandler={onRowClickHandler} />
    </Container>
  );
}

const Container = styled.main`
  padding: 20px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 34px;
`;

const MainHeader = styled.h1`
  font-weight: 750;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
`;

const TitleBlock = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const ButtonBlock = styled.div`
  display: flex;
  gap: 30px;
`;

const StyledButtonDark = styled(ButtonDark)`
  padding: 15px 40px;
`;

const StyledButtonLight = styled(ButtonLight)`
  padding: 15px 40px;
`;

const StyledOverviewTable = styled(OverviewTable)`
  margin-top: 100px;
  border-top: 2px solid #2682DE;
`;

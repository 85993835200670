import styled from 'styled-components/macro';

interface GuaranteeBlockProps {
  imageSrc: string;
}

export function GuaranteeBlock({ imageSrc }: GuaranteeBlockProps) {
  return (
    <Card>
      <CardInner>
        <CardTitleWrap>
          <CardTitle>
            The Direct Debit Guarantee
          </CardTitle>
          <img src={imageSrc} width="100" height="57" alt="" />
        </CardTitleWrap>
        <CardList>
          <CardListItem>
            This Guarantee is offered by all banks and building societies that accept instructions to
            pay Direct Debits
          </CardListItem>
          <CardListItem>
            If there are any changes to the amount, date or frequency of your Direct Debit L&Z RE
            SILVERWING will notify you 3 working days in advance of your account being debited or as
            otherwise agreed. If you request L&Z RE SILVERWING to collect a payment, confirmation of the
            amount and date will be given to you at the time of the request
          </CardListItem>
          <CardListItem>
            If an error is made in the payment of your Direct Debit, by L&Z RE SILVERWING or your bank
            or building society, you are entitled to a full and immediate refund of the amount paid from
            your bank or building society
          </CardListItem>
          <CardListItem>
            If you receive a refund you are not entitled to, you must pay it back when L&Z RE SILVERWING
            asks you to
          </CardListItem>
          <CardListItem>
            You can cancel a Direct Debit at any time by simply contacting your bank or building
            society. Written confirmation may be required. Please also notify us.
          </CardListItem>
        </CardList>
      </CardInner>
    </Card>
  );
}

const Card = styled.div`
  width: 100%;
  background: #EEEEEE;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 26px;
`;

const CardInner = styled.div`
  width: 100%;
  padding: 29px 15px 19px 15px;
  background: #FFFFFF;
  box-sizing: border-box;
`;

const CardTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const CardTitle = styled.h3`
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
`;

const CardList = styled.ul`
  width: 100%;
  padding-left: 19px;
  box-sizing: border-box;
`;

const CardListItem = styled.li`
  font-size: 14px;
  line-height: 18px;
`;

const CardBottomText = styled.p`
  float: right;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
`;

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  loadCampaignByInvite,
  createDonor,
  selectCampaignInvite,
} from '../../../../feature/campaignState';
import {
  getUserProfile,
  setCurrentProfile,
} from '../../../../feature/userState';
import CreateDonor from './CreateDonor';
import ROUTER_CONFIG from '../../../../router/router-config';
import { ApiCreateDonor } from '../../../../project-types/donor/api-types';
import { useCampaignFromInvite } from '../../hooks';
import DonorLayout from '../../layout/DonorSignupLayout';

export default function CreateDonorPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const profile = useSelector(getUserProfile);
  const { donationAmount } = useSelector(selectCampaignInvite);
  const { push } = useHistory();

  const { campaign, inviteCode, currentSchool, campaignId } =
    useCampaignFromInvite();

  useEffect(() => {
    if (profile && profile.donorProfile) {
      push(
        ROUTER_CONFIG.DONOR_SIGNUP_DIRECTPAY.getPath({
          campaignId,
          inviteCode,
        }),
        { donorProfile: profile.donorProfile }
      );
    }
  }, [profile]);

  useEffect(() => {
    if (!campaign) {
      dispatch(loadCampaignByInvite(campaignId, inviteCode));
    }
  }, [campaignId, inviteCode]);

  const redirectToAcceptDebitPage = (data: ApiCreateDonor['successResponse']) =>
    push(
      ROUTER_CONFIG.DONOR_SIGNUP_DIRECTPAY.getPath({ campaignId, inviteCode }),
      data
    );
  const redirectToDonorAcceptInvitePage = () =>
    push(ROUTER_CONFIG.DONOR_INVITE_ACCEPT.getPath({ campaignId, inviteCode }));

  const onFormSubmit = async (formData: ApiCreateDonor['data']) => {
    try {
      const data: ApiCreateDonor['successResponse'] | void = await createDonor({
        ...formData,
        donationAmount,
      });
      if (data) {
        // debugger;
        dispatch(setCurrentProfile(data));
        redirectToAcceptDebitPage(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onLoginClickHandler = () => {
    push(ROUTER_CONFIG.DONOR_LOGIN.getPath(), { from: location });
  };

  return (
    <DonorLayout>
      {campaign && (
        <CreateDonor
          inviteCode={inviteCode}
          onDonorDetailsBackClick={redirectToDonorAcceptInvitePage}
          onFormConfirm={onFormSubmit}
          campaignName={campaign.name}
          schoolName={currentSchool?.schoolName || ''}
          onLoginClickHandler={onLoginClickHandler}
        />
      )}
    </DonorLayout>
  );
}

import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { MainHeader } from '../../ui/Headers';
import Input from '../../ui/forms/Input';
import { ButtonDark } from '../../ui/Button';
import { renderFormConfig } from '../../utils/form';
import { ForgotPasswordSchema } from './schemas';

import CloseIcon from '../../img/icons/close_icon.svg';

interface ForgotPasswordFormProps {
  onFormSubmitHandler: (email: string) => void;
  onGoBackClickHandler: () => void;
}

export default function ForgotPasswordForm({
  onFormSubmitHandler,
  onGoBackClickHandler,
}: ForgotPasswordFormProps) {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: ({ email }) => onFormSubmitHandler(email),
  });

  return (
    <Wrap>
      <Content onSubmit={formik.handleSubmit}>
        <CloseButton onClick={onGoBackClickHandler} src={CloseIcon} alt="" />

        <Title>Forgotten Password?</Title>
        <Text>
          We will send you an email with instructions on how to reset your
          password
        </Text>
        <InputBlock>
          {renderFormConfig(
            [
              {
                title: 'Email',
                name: 'email',
                component: StyledInput,
              },
            ],
            formik
          )}
        </InputBlock>
        <ConfirmButton type="submit">Reset Password</ConfirmButton>
      </Content>
    </Wrap>
  );
}

const Wrap = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  box-sizing: border-box;
  min-height: calc(100vh - 165px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
`;

const Content = styled.form`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 640px;
  box-shadow: 3px 3px 14px 14px #00000014;
  background-color: #fff;
  padding: 60px 100px 60px 75px;
`;

const Title = styled(MainHeader)`
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 40px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 45px;
  max-width: 410px;
`;

const InputBlock = styled.div``;

const StyledInput = styled(Input)`
  width: 100%;
`;

const ConfirmButton = styled(ButtonDark)`
  max-width: 299px;
  padding: 16px 80px;
  align-self: center;
  margin-top: 40px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 28px;
`;

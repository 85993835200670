import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { FormConfig, renderFormConfig } from '../../../../utils/form';
import { ButtonDark } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import SelectInput from '../../../../ui/forms/SelectInput';

import InfoIcon from '../../../../img/icons/info_icon.svg';

interface RegisterDeviceTradeinFormProps {
  onFormSubmit: (values: any) => void;
}

export default function RegisterDeviceTradeinForm({ onFormSubmit }: RegisterDeviceTradeinFormProps) {
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => onFormSubmit(values),
  });

  const formDeviceLeft: FormConfig = [
    {
      title: 'Device Type',
      name: 'deviceType',
      component: StyledSelect,
      isRequired: true,
      props: {
        initialData: ['123'],
        placeholder: 'Select...',
      },

    },
    {
      title: 'Device Model',
      name: 'deviceModel',
      component: StyledSelect,
      isRequired: true,
      props: {
        initialData: ['123'],
        placeholder: 'Select...',
      },
    },
    {
      title: 'CPU Model',
      name: 'deviceCPUModel',
      component: StyledSelect,
      isRequired: true,
      props: {
        initialData: ['123'],
        placeholder: 'Select...',
      },
    },
    {
      title: 'Quantity',
      name: 'quantity',
      component: StyledInput,
      props: {
        type: 'number',
        min: '0',
        step: '1',
      },
      isRequired: true,
    },
  ];

  const formDeviceRight: FormConfig = [
    {
      title: 'Device Brand',
      name: 'deviceBrand',
      component: StyledSelect,
      isRequired: true,
      props: {
        initialData: ['123'],
        placeholder: 'Select...',
      },
    },
    {
      title: 'Device CPU',
      name: 'deviceCPU',
      component: StyledSelect,
      isRequired: true,
      props: {
        initialData: ['123'],
        placeholder: 'Select...',
      },
    },
    {
      title: 'Model Number',
      name: 'modelNumber',
      component: StyledInput,
      props: {
        type: 'number',
        min: '0',
        step: '1',
      },
      isRequired: true,
    },
  ];

  const formDetailsLeft: FormConfig = [
    {
      title: 'First Name',
      name: 'firstName',
      isRequired: true,
      component: StyledInput,
    },
    {
      title: 'Email',
      name: 'email',
      isRequired: true,
      component: StyledInput,
    },
    {
      title: 'Telephone',
      name: 'telephone',
      isRequired: true,
      component: StyledInput,
    },
  ];

  const formDetailsRight: FormConfig = [
    {
      title: 'Second Name',
      name: 'secondName',
      isRequired: true,
      component: StyledInput,
    },
  ];

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Title>Register A Device</Title>
      <FormBlock>
        <NumberInSquare>1</NumberInSquare>
        <FormItem>
          <FormTitleBlock>
            <FormTitle>Device Details</FormTitle>
            <Info alt="" src={InfoIcon} />
          </FormTitleBlock>
          <FormColumns>
            <FormColumn>
              {renderFormConfig(formDeviceLeft, formik)}
            </FormColumn>
            <FormColumn>
              {renderFormConfig(formDeviceRight, formik)}
            </FormColumn>
          </FormColumns>
        </FormItem>
      </FormBlock>
      <FormBlock>
        <NumberInSquare>2</NumberInSquare>
        <FormItem>
          <FormTitleBlock>
            <FormTitle>Your Details</FormTitle>
            <Info alt="" src={InfoIcon} />
          </FormTitleBlock>
          <FormColumns>
            <FormColumn>
              {renderFormConfig(formDetailsLeft, formik)}
            </FormColumn>
            <FormColumn>
              {renderFormConfig(formDetailsRight, formik)}
            </FormColumn>
          </FormColumns>
          <SubmitButton type="submit">Continue to Valuation</SubmitButton>
        </FormItem>
      </FormBlock>
    </Container>
  );
}

const Container = styled.form`
  padding: 90px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
`;

const Title = styled.h2`
  font-size: 30px;
  color: #102F54;
  margin: 0;
  align-self: center;
  font-weight: bold;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #E8F4FF;
  color: #2682DE;
`;

const FormBlock = styled.div`
  display: flex;
  gap: 15px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const FormTitleBlock = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;

const FormTitle = styled.h3`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
  margin: 0;
`;

const FormColumns = styled.div`
  display: flex;
  gap: 75px;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.img`
  cursor: pointer;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 13px 50px;
  align-self: flex-end;
`;

const StyledSelect = styled(SelectInput)`
  width: 310px;
`;

const StyledInput = styled(Input)`
  width: 310px;
`;

import styled from 'styled-components/macro';
import { useState, useEffect } from 'react';
import { Table, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import Input from '../../../ui/forms/Input';
import { MainHeader } from '../../../ui/Headers';
import { DonorsTableRow } from '../types';
import SearchIcon from '../../../img/icons/icon_search.svg';

interface DonorsTableProps {
  tableData: Array<DonorsTableRow>;
  onSelectDonor: (donorIds: Array<string>) => void;
  selectedDonors: Array<string>;
  onFreezeClickHandler: () => void;
  onResumeClickHandler: () => void;
  onDeleteClickHandler: () => void;
  onDonorClickHandler: (campaignId: string) => void;
}

export default function DonorsTable({
  tableData, onSelectDonor, selectedDonors, onFreezeClickHandler, onResumeClickHandler, onDeleteClickHandler, onDonorClickHandler,
}: DonorsTableProps) {
  const [filteredTableSource, setFilteredTableSource] = useState(tableData);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setFilteredTableSource(tableData.filter((row) => row.name.toLowerCase().includes(searchValue.toLowerCase())));
  }, [searchValue, tableData]);

  const dropDownContent = (
    <Menu>
      <Menu.Item onClick={onFreezeClickHandler}>
        Freeze collections
      </Menu.Item>
      <Menu.Item onClick={onResumeClickHandler}>
        Resume collections
      </Menu.Item>
      <Menu.Item onClick={onDeleteClickHandler}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const tableConfig: ColumnsType<DonorsTableRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => <NameDiv onClick={() => onDonorClickHandler(record.key)}>{value}</NameDiv>,
      width: '150px',
    },
    {
      title: 'Campaign ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
      render: (text) => <CampaignsSpan>{text}</CampaignsSpan>,
      width: '250px',
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
    },
    {
      title: 'Active collections',
      dataIndex: 'activeCollections',
      key: 'activeCollections',
      width: '100px',
    },
    {
      title: 'Active Trade-Ins',
      dataIndex: 'activeTradeIns',
      key: 'activeTradeIns',
      width: '100px',
    },
  ];

  return (
    <Wrap>
      <StyledMainHeader>Donors</StyledMainHeader>
      <Controls>
        <StyledInput iconSrc={SearchIcon} placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
        <Dropdown overlay={dropDownContent}>
          <StyledLink className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            Actions
            {' '}
            <DownOutlined />
          </StyledLink>
        </Dropdown>
      </Controls>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (ids) => {
            onSelectDonor(ids as Array<string>);
          },
          selectedRowKeys: selectedDonors,
        }}
        columns={tableConfig}
        dataSource={filteredTableSource}
      />
    </Wrap>
  );
}

const Wrap = styled.main`
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
  box-sizing: border-box;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  line-height: 26px;
`;

const StyledLink = styled.a`
  font-size: 16px;
  font-weight: 750;
  /* padding-top: 45px; */
  align-self: end;
  margin: 0;
`;

const NameDiv = styled.div`
  cursor: pointer;
  white-space: wrap;
  word-break: 'break-word';
  max-width: 150px;
  &:hover {
    text-decoration: underline;
    font-weight: 750;
  }
`;

const StyledInput = styled(Input)<{ iconSrc: string }>`
  width: 100%;
  max-width: 420px;
  padding: 15px 0 15px 48px;
  margin-top: 50px;
  background-image: ${(props) => `url(${props.iconSrc})`};
  background-repeat: no-repeat;
  background-position: 17px center;
`;

const CampaignsSpan = styled.span`
  display: block;
`;

import styled from 'styled-components/macro';
import { ButtonDark, ButtonDarkBlack, ButtonLightGray } from '../../../ui/Button';
import CloseImg from '../../../img/close.svg';
// import useKeyboardAction from '../../hooks/keyboardAction';

interface ComponentProps {
  onNext: () => void
  onClose: () => void
  number: number
}

export default function InviteSentModal({
  onNext,
  onClose,
  number,
}: ComponentProps) {
  // useKeyboardAction({
  //   enterKeyPressed: onAddGroupName,
  //   escKeyPressed: onClose,
  // });

  return (
    <Wrapper>
      <ModalTop>
        <Close>
          <img src={CloseImg} onClick={onClose} />
        </Close>
        <Title>Invitation sent</Title>
      </ModalTop>
      <ModalMiddle>
        <p>
          You successfully invited
          {' '}
          <b>
            {number}
            {' '}
            contacts
          </b>
          {' '}
          to donate to your Donation Scheme
        </p>
      </ModalMiddle>
      <ModalBottom>
        <ButtonStyledOutlined onClick={onClose}>
          Done
        </ButtonStyledOutlined>
      </ModalBottom>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 647px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
  padding: 70px 27px 24px;
`;

const ModalTop = styled.div`
  /* padding-top: 88px; */
  width: 100%;
  /* padding-bottom: 21px; */
  /* border-bottom: 1px solid #E0E0E0; */
  position relative;
`;

const Close = styled.div`
  position: absolute;
  right: 0px;
  top: -50px;
  cursor: pointer;
`;

const ModalMiddle = styled.div`
  /* padding: 57px 27px; */
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 45px;
`;

const ModalBottom = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-bottom: 45px;
`;

const ButtonStyledOutlined = styled(ButtonLightGray)`
  padding: 11px 0;
  width: 286px;
`;

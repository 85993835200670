import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../../ui/Button';
import CloseIcon from '../../../../../img/close.svg';
import { CollectionActionTableRow } from '../../../types';
import { formatDateDDMMYY } from "../../../../../ui/DateFormatMethods";
import { RadioGroup, RadioItem } from "../../../../../ui/forms/Radio";
import { useState } from "react";
import { DatePicker } from "antd";
import { ExceptionsPostDataApi } from "../../../../../project-types/campaign/api-types";
import { postExceptions } from "../../../../../feature/campaignState";

interface IViewCollectioPaymentStatusModalProps {
  onClose: () => void;
  initialData: CollectionActionTableRow;
  onChangeSubmit: () => void;
}

export default function ViewCollectioPaymentStatusModal({
  onClose,
  initialData,
  onChangeSubmit,
}: IViewCollectioPaymentStatusModalProps) {
  const [paymentOption, setPaymentOption] = useState('soon');
  const [customDate, setCustomDate] = useState('');

  const paymentOptionsRadioList = [
    {text: 'Retry as soon as possible', value: 'soon'},
    {text: 'Add payment to end of schedule', value: 'end'},
    {text: 'Retry on custom date', value: 'custom'},
  ];

  const onSubmit = () => {
    const tmpData: ExceptionsPostDataApi = {
      exceptionType: initialData.exceptionType,
      retryRule: paymentOption,
      customRetry: customDate !== '' && paymentOption === 'custom' ? customDate : '',
      info: {
        sortCode: '',
        debitName: '',
      },
    };
    postExceptions(initialData.id, tmpData).then(() => {
      onClose();
      // onChangeSubmit();
    }).catch(() => alert('Something went wrong!'));
  };

  return (
    <Container>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>{initialData.status}</Title>
      <MainPaymentBlock>
        <DataRow>
          <FieldName>Posted Date</FieldName>
          <FieldValue>{initialData.exceptionDate && formatDateDDMMYY(new Date(initialData.exceptionDate))}</FieldValue>
        </DataRow>
        <DataRow>
          <FieldName>Contributor Name</FieldName>
          <FieldValue>{initialData.contributorName}</FieldValue>
        </DataRow>
        <DataRow>
          <FieldName>Student ID</FieldName>
          <FieldValue>{initialData.studentId}</FieldValue>
        </DataRow>
      </MainPaymentBlock>
      <AmountBlock>
        <DataRow>
          <FieldName>Amount</FieldName>
          <FieldValue>{initialData.exceptionAmount}</FieldValue>
        </DataRow>
        <DataRow>
          <FieldName>Gift Aid Amount</FieldName>
          <FieldValue>{initialData.giftAidAmount}</FieldValue>
        </DataRow>
      </AmountBlock>
      <RadioBlock>
        <SubTitle>Retry Payment Options*</SubTitle>
        <StyledRadioGroup value={paymentOption}>
          {paymentOptionsRadioList.map((option) => <RadioItem value={option.value} onChange={() => setPaymentOption(option.value)}>{option.text}</RadioItem>)}
        </StyledRadioGroup>
        <StyledDatePicker placeholder='' onChange={((_, dateString) => setCustomDate(dateString))} disabled={paymentOption !== 'custom'} getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}/>
      </RadioBlock>
      {initialData.status !== 'Successful' && (
        <SubmitButton onClick={onSubmit}>Retry Payment</SubmitButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  box-sizing: border-box;
  max-width: 572px;
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FieldName = styled.h4`
  color: #6b7a87;
  font-weight: 600;
  width: 30%;
  margin-bottom: 8px;
`;
const FieldValue = styled.p`
  margin-bottom: 8px;
`;

const MainPaymentBlock = styled.div`
  margin-bottom: 55px;
`;

const AmountBlock = styled.div`
  margin-bottom: 30px;
`;

const RadioBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 64px;
`;

const DataRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 14px 57px;
  align-self: flex-start;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const SubTitle = styled.h4`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  max-width: 200px;
  height: 36px;
  margin-top: 10px;
`;

import { useState } from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { Checkbox, Input, Switch } from 'antd';
import PinInput from 'react-pin-input';

import { ButtonDark } from '../../../../../ui/Button';

import CloseIcon from '../../../../../img/close.svg';
import { PaymnetInformationCorrectionForm } from '../../../types';
import {
  PaymentInformatioCorrectionScheme,
  PaymentInformationCorrectionSchemeNotRequired,
} from '../../../school-schemas';
import { FormConfig, renderFormConfig } from '../../../../../utils/form';
import RemoveInput from '../../../../../ui/forms/RemoveInput';

interface EditCollectioPaymentInfoModalProps {
  onClose: () => void;
  onChangeSubmit: (values: PaymnetInformationCorrectionForm) => void;
  status: string;
}

interface IStudent {
  name: string;
  title: string;
  index?: number;
}

const pinInputStyles = {
  borderColor: '#E0E0E0',
  borderRadius: '4px',
  width: '34px',
  height: '32px',
  margin: '0 1px 0 0',
};

const CORRECTION_STATUS = 'Information Correction';

export default function EditCollectioPaymentInfoModal({
  onClose,
  onChangeSubmit,
  status,
}: EditCollectioPaymentInfoModalProps) {
  const [students, setStudents] = useState<IStudent[]>([
    { name: 'student1', title: 'Student #1 ID', index: 1 },
    // { name: 'student2', title: 'Student #2 ID' },
    // { name: 'student3', title: 'Student #3 ID' },
  ]);

  const [studentInfoOverwrite, setStudentInfoOverwrite] = useState(false);
  const [paymentInfoOverwrite, setPaymentInfoOverwrite] = useState(false);
  const [disconnectParent, setDisconnectParent] = useState(false);

  const removeStudentHandler = (studentIndex: number) => {
    const copyStudents = [...students];

    formik.setFieldValue(copyStudents[studentIndex].name, '');
    copyStudents.splice(studentIndex, 1);
    setStudents(copyStudents);
  };

  const addStudentHandler = () => {
    if (students.length < 3) {
      setStudents([
        ...students,
        {
          name: `student${students.length + 1}`,
          title: `Student #${students.length + 1} ID`,
        },
      ]);
    }
  };

  const formConfig1: FormConfig = [
    {
      title: 'Name of Account Holder',
      name: 'accountHolderName',
      props: {
        type: 'text',
        maxLength: 18,
      },
      component: StyledInput,
      containerStyle: {
        marginBottom: '12px',
      },
      labelStyle: {
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
  ];

  const formConfig2: FormConfig = students.map((student) => ({
    id: student.index ? student.index : 2,
    title: student.title,
    name: student.name,
    props: {
      type: 'text',
    },
    component: RemoveInput,
    containerStyle: {
      marginBottom: '12px',
    },
    labelStyle: {
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: '12px',
      fontWeight: 'bold',
    },
  }));

  const getFormkiInitiaValues = () => {
    const defaultValues = {
      student1: '',
      student2: '',
      student3: '',
      accountHolderName: '',
      bankBuildingSocietyAccountNumber: '',
      branchSortCode: '',
    };

    // if (status === CORRECTION_STATUS) {
    //   return {
    //     student1: '',
    //     student2: '',
    //     student3: '',
    //     ...defaultValues,
    //   };
    // }

    return defaultValues;
  };

  const overwriteMode =
    paymentInfoOverwrite && studentInfoOverwrite
      ? 'full'
      : paymentInfoOverwrite && !studentInfoOverwrite
      ? 'payment'
      : studentInfoOverwrite && !paymentInfoOverwrite
      ? 'students'
      : 'none';

  const formik = useFormik({
    initialValues: getFormkiInitiaValues(),
    validationSchema:
      (paymentInfoOverwrite && !disconnectParent)
        ? PaymentInformatioCorrectionScheme
        : PaymentInformationCorrectionSchemeNotRequired,
    onSubmit: (values) => {
      const studentsList = [
        values?.student1,
        values?.student2,
        values?.student3,
      ];
      const tempData = {
        bankDebitName: values.accountHolderName,
        bankAccount: values.bankBuildingSocietyAccountNumber,
        sortCode: values.branchSortCode,
        studentReferences: studentsList.filter((student) => student !== ''),
        overwriteMode,
        bankUnhookException: disconnectParent,
      };
      onChangeSubmit(tempData);
      // onChangeSubmit(values)
    },
  });

  return (
    <Container>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>{status}</Title>
      {status === 'DDI Mandate Cancelled' && (
        <WarningMessage>
          You must have contacted the parent and received authorisation to
          restart the Direct Debit!
        </WarningMessage>
      )}
      <form onSubmit={formik.handleSubmit}>
        {status === CORRECTION_STATUS && (
          <>
            <StudentTitle>Students information</StudentTitle>
            <ToggleWrapper>
              <ToggleTitle>Student Info Overwrite</ToggleTitle>
              <Switch
                size="small"
                checked={studentInfoOverwrite}
                onChange={(checked) => setStudentInfoOverwrite(checked)}
              />
            </ToggleWrapper>
            {studentInfoOverwrite &&
              renderFormConfig(
                formConfig2,
                formik,
                removeStudentHandler,
                addStudentHandler
              )}
            <SecondTitle isStudentsExist={students.length !== 0}>
              Payment information
            </SecondTitle>
            <ToggleWrapper>
              <ToggleTitle>Payment Information Overwrite</ToggleTitle>
              <Switch
                size="small"
                checked={paymentInfoOverwrite}
                onChange={(checked) => {
                  setDisconnectParent(false);
                  setPaymentInfoOverwrite(checked);
                }}
              />
            </ToggleWrapper>
          </>
        )}
        {paymentInfoOverwrite && (
          <>
            {renderFormConfig(formConfig1, formik)}
            <PinCodeWrapper>
              <SubTitle>Bank/ Building Sociery Account Number</SubTitle>
              <PinInput
                length={8}
                initialValue=""
                type="numeric"
                inputMode="number"
                inputStyle={pinInputStyles}
                onComplete={(value) =>
                  formik.setFieldValue(
                    'bankBuildingSocietyAccountNumber',
                    value
                  )
                }
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </PinCodeWrapper>
            <PinCodeWrapper>
              <SubTitle>Branch Sort Code</SubTitle>
              <PinInput
                length={6}
                initialValue=""
                type="numeric"
                inputMode="number"
                inputStyle={pinInputStyles}
                onComplete={(value) =>
                  formik.setFieldValue('branchSortCode', value)
                }
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </PinCodeWrapper>
          </>
        )}
        {!paymentInfoOverwrite && (
          <ToggleWrapper>
            <ToggleTitle>Disconnect Parent from Direct Debits</ToggleTitle>
            <Checkbox
              checked={disconnectParent}
              onChange={(e) => setDisconnectParent(e.target.checked)}
            />
          </ToggleWrapper>
        )}
        <SubmitButton type="submit">
          {status === 'DDI Mandate Cancelled' ? 'Save & Retry' : 'Save'}
        </SubmitButton>
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100%;
  max-height: 80vh;
  box-sizing: border-box;
  max-width: 572px;
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

const PinCodeWrapper = styled.div`
  margin-bottom: 12px;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const WarningMessage = styled.h4`
  font-size: 14px;
  font-weight: bold;
  color: red;
`;

const StudentTitle = styled.h4`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ToggleTitle = styled.p`
  font-size: 14px;
  margin: 0 14px 0 0;
`;

const SecondTitle = styled.h4<{ isStudentsExist: boolean }>`
  font-size: 15px;
  font-weight: bold;
  margin: ${({ isStudentsExist }) =>
    isStudentsExist ? '57px 0 27px 0' : '0 0 27px 0'}};
`;

const SubmitButton = styled(ButtonDark)`
  padding: 14px 57px;
  align-self: flex-start;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-top: 74px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const SubTitle = styled.h4`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
`;

const StyledInput = styled(Input)`
  width: 395px;
  border-radius: 4px;
`;

import styled from 'styled-components';
import CreateCampaignForm from './CreateCampaignForm';
import { CampaignFormFields } from '../../types';
import BackButton from '../../../../ui/elements/BackButton';

interface CreateCampaignProps {
  onSubmit: (schoolFields: CampaignFormFields) => void;
  onBackClickHandler: () => void;
}

export default function CreateCampaign({ onSubmit, onBackClickHandler }: CreateCampaignProps) {
  return (
    <CreateCampaignWrap>
      <StyledBackButton onClickHandler={onBackClickHandler} />
      <FormContent>
        <MainHeader>New Scheme</MainHeader>
        Launch a new Collection Scheme
        <CreateCampaignForm onSubmit={onSubmit} />
      </FormContent>
    </CreateCampaignWrap>
  );
}

const CreateCampaignWrap = styled.main`
  display: flex;
  align-items: flex-start;
  gap: 34px;
`;

const StyledBackButton = styled(BackButton)`
  padding-top: 10px;
`;

const FormContent = styled.div``;

const MainHeader = styled.h1`
  font-weight: 750;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
`;

import { useState } from 'react';
import CampaignsTableControls from './CampaignsTableControls';
import CampaignsTable from './CampaignsTable';
import { SchoolCampaignsTableRow, CampaignStatuses } from '../../types';

const Statuses = ['Frozen', 'Active'];

interface CampaignsViewProps {
  onDeleteClickHandler: () => void;
  onFreezeClickHandler: () => void;
  onUnFreezeClickHandler: () => void;
  onAddNewCampaignClickHandler: () => void;
  onSelectCampaignsHandler: (campaignIds: Array<string>) => void;
  selectedCampaigns: Array<string>;
  tableData: Array<SchoolCampaignsTableRow>;
}

export default function CampaignsView({
  onDeleteClickHandler, onFreezeClickHandler, onUnFreezeClickHandler, onAddNewCampaignClickHandler, onSelectCampaignsHandler, selectedCampaigns, tableData,
}: CampaignsViewProps) {
  const [filter, setFilter] = useState<CampaignStatuses>('Active');

  const filteredTableData = tableData.filter((row) => {
    if (filter === 'Active') {
      return !row.isFreeze;
    }
    return row.isFreeze;
  });

  return (
    <>
      <CampaignsTableControls onAddNewCampaignClickHandler={onAddNewCampaignClickHandler} onDeleteClickHandler={onDeleteClickHandler} onFreezeClickHandler={onFreezeClickHandler} onUnFreezeClickHandler={onUnFreezeClickHandler} onChangeFilter={setFilter} statuses={Statuses} />
      <CampaignsTable onSelectCampaigns={onSelectCampaignsHandler} selectedCampaigns={selectedCampaigns} tableData={filteredTableData} />
    </>
  );
}

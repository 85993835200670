import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';

import { SchoolCampaignTableRow } from '../../../types';

interface CampaignsTableProps {
  tableData: Array<SchoolCampaignTableRow>;
  setSelectedCampaign: (id: string) => void
}

export default function CampaignsTable({ tableData, setSelectedCampaign }: CampaignsTableProps) {
  const tableConfig: ColumnsType<SchoolCampaignTableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
    },
    {
      title: 'Scheme Name',
      dataIndex: 'campaignName',
      key: 'campaignName',
    },
    {
      title: 'Student Reference(s)',
      dataIndex: 'studentReferences',
      key: 'studentReferences',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Active Period',
      dataIndex: 'activePeriod',
      key: 'activePeriod',
    },
    {
      title: 'Payment Plan',
      dataIndex: 'paymentPlan',
      key: 'paymentPlan',
    },
    {
      title: 'Total Collections',
      dataIndex: 'totalCollections',
      key: 'totalCollections',
    },
    {
      title: 'Total Collected',
      dataIndex: 'totalCollected',
      key: 'totalCollected',
    },
  ];

  return (
    <Table<SchoolCampaignTableRow>
      rowSelection={{type: 'radio', onSelect: (e) => setSelectedCampaign(e.subscriptionID.toString())}}
      dataSource={tableData}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE' }}
    />
  );
}

import * as yup from 'yup';
import { signUpSchema } from '../../project-types/users/schemas';
import {
  SchoolFormUpdateType,
  SignUpSchoolForm,
  UpdateCampaignCollectionRulesType,
  UpdateSchoolBillingDetails,
  PaymnetInformationCorrectionForm,
  ChangePaymentPlanForm,
  PAYMENT_PLAN_OPTIONS,
  PAYMENT_PLAN,
  CALLIBRATION_OPTIONS,
  COLLECTION_FREQUENCY,
  INITIAL_COLLECTION_AMOUNT,
  COLLECTION_PERIOD,
} from './types';

type SchoolCreateFieldsForSchema = Omit<
  SignUpSchoolForm,
  'address2' | 'address3'
> & { address2?: string; address3?: string };

export const signUpSchoolSchema: yup.SchemaOf<SchoolCreateFieldsForSchema> = yup
  .object({
    agree: yup.boolean().required().oneOf([true], 'You cannot proceed unless this box is checked'),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    schoolName: yup.string().required(),
    URN: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phoneNumber: yup.string().required(),
    postcode: yup.string().required(),
    address1: yup.string().required(),
    address2: yup.string().optional(),
    address3: yup.string().optional(),
    town: yup.string().required(),
    logo: yup.string().optional(),
    country: yup.string().required(),
  })
  .concat(signUpSchema);

// export const signUpSchoolSchema: yup.SchemaOf<SchoolCreateFieldsForSchema> = yup.object({
//   agree: yup.boolean().required().oneOf([true]),
//   confirmPassword: yup.string().required()
//     .oneOf([yup.ref('password'), null], 'Passwords must match'),
// })
//   .concat(schoolCreateSchema)
//   .concat(signUpSchema);

type SchoolUpdateFieldsForSchema = Omit<
  SchoolFormUpdateType,
  'address2' | 'address3' | 'logo'
> & { address2?: string; address3?: string; logo?: string };

// TODO Dirty hack
export const updateSchoolSchema: yup.SchemaOf<SchoolUpdateFieldsForSchema> =
  yup.object({
    schoolName: yup.string().required(),
    // URN: yup.string().required(),
    postcode: yup.string().required().required(),
    address1: yup.string().required(),
    address2: yup.string().optional(),
    address3: yup.string().optional(),
    logo: yup.string().optional(),
    town: yup.string().required(),
    country: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phoneNumber: yup.string().required(),
    bankAccountName: yup.string().required(),
    branchSortCode1: yup.string().required(),
    branchSortCode2: yup.string().required(),
    branchSortCode3: yup.string().required(),
    accountNumber: yup.string().required(),
  });

export const updateSchoolPersonalDetailsSchema: yup.SchemaOf<
  Pick<SchoolUpdateFieldsForSchema, 'firstName' | 'lastName'>
> = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

export const updateSchoolDetailsSchema: yup.SchemaOf<
  Pick<
    SchoolUpdateFieldsForSchema,
    | 'schoolName'
    | 'postcode'
    | 'address1'
    | 'address2'
    | 'address3'
    | 'town'
    | 'country'
    | 'phoneNumber'
  >
> = yup.object({
  schoolName: yup.string().required(),
  postcode: yup.string().required().required(),
  address1: yup.string().required(),
  address2: yup.string().optional(),
  address3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
  phoneNumber: yup.string().required(),
});

export const updateSchoolBillingDetails: yup.SchemaOf<UpdateSchoolBillingDetails> =
  yup.object({
    bankAccountName: yup.string().required(),
    branchSortCode1: yup.string().required(),
    branchSortCode2: yup.string().required(),
    branchSortCode3: yup.string().required(),
    accountNumber: yup.string().required(),
  });

export const UpdateSchoolStepsSchema = {
  billingDetails: updateSchoolBillingDetails,
  personalDetails: updateSchoolPersonalDetailsSchema,
  schoolDetails: updateSchoolDetailsSchema,
};

export const CampaignCollectionRulesSchema: yup.SchemaOf<UpdateCampaignCollectionRulesType> =
  yup.object({
    collectionAttempts: yup.number().optional(),
  });

export const ChangePaymentPlanSchema: yup.SchemaOf<ChangePaymentPlanForm> =
  yup.object({
    options: yup
      .mixed<PAYMENT_PLAN_OPTIONS>()
      .oneOf(Object.values(PAYMENT_PLAN_OPTIONS))
      .required(),
    campaignName: yup.string().required(),
    paymentPlan: yup
      .mixed<PAYMENT_PLAN>()
      .oneOf(Object.values(PAYMENT_PLAN))
      .required(),
    callibration: yup
      .mixed<CALLIBRATION_OPTIONS>()
      .oneOf(Object.values(CALLIBRATION_OPTIONS))
      .required(),
    startDate: yup.string(),
    endDate: yup.string(),
    collectionFrequency: yup
      .mixed<COLLECTION_FREQUENCY>()
      .oneOf(Object.values(COLLECTION_FREQUENCY))
      .required(),
    recurringAmount: yup.string().required(),
    initialAmount: yup
      .mixed<INITIAL_COLLECTION_AMOUNT>()
      .oneOf(Object.values(INITIAL_COLLECTION_AMOUNT))
      .required(),
    collectionPeriod: yup
      .mixed<COLLECTION_PERIOD>()
      .oneOf(Object.values(COLLECTION_PERIOD))
      .required(),
    numOfCollections: yup.string().required(),
  });

export const RetryPaymentInformatioCorrectionScheme = yup.object().shape({
  mandateRetryMode: yup.string().optional(),
  accountHolderName: yup.string().when('mandateRetryMode', {
    is: 'update',
    then: yup.string().required(),
  }),
  bankBuildingSocietyAccountNumber: yup.string().when('mandateRetryMode', {
    is: 'update',
    then: yup.string().required(),
  }),
  branchSortCode: yup.string().when('mandateRetryMode', {
    is: 'update',
    then: yup.string().required(),
  }),
});

export const PaymentInformatioCorrectionScheme = yup.object({
  accountHolderName: yup.string().required(),
  bankBuildingSocietyAccountNumber: yup.string().required(),
  branchSortCode: yup.string().required(),
});

export const PaymentInformationCorrectionSchemeNotRequired = yup.object({
  accountHolderName: yup.string().notRequired(),
  bankBuildingSocietyAccountNumber: yup.string().notRequired(),
  branchSortCode: yup.string().notRequired(),
});

import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, changeDonorProfile } from '../../../feature/userState';
import { UpdateDonorFields } from '../../../project-types/donor/types';
import DonorLayout from '../layout/DonorLayout';
import BillingInformation from './BillingInformation';

export default function BillingInformationPage() {
  const profile = useSelector(getUserProfile);
  const dispatch = useDispatch();

  const onFormSubmit = (formData: UpdateDonorFields) => dispatch(changeDonorProfile(formData));

  return (
    <DonorLayout>
      {profile?.donorProfile && (
        <BillingInformation email={profile.user.email} profile={profile?.donorProfile} onFormSubmit={onFormSubmit} />
      )}
    </DonorLayout>
  );
}

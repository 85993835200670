import styled from "styled-components/macro";

export const MainHeader = styled.h1`
  font-weight: 750;
  font-size: 34px;
  margin-top: 0;
  margin-bottom: 0; 
`;


export const SecondHeader = styled.h2`
  font-weight: 750;
  font-size: 18px;
  line-height: 26px;
`;
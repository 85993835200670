import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import Footer from '../../layout/Footer';
import GlobalWrap from '../../layout/GlobalWrap';
import Header from '../../layout/Header';

interface DonorLayoutTypes {
  children: ReactNode;
  headerLoginLinkTo?: string;
  menu?: ReactNode;
}

export default function DonorLayout({ children, menu, headerLoginLinkTo }: DonorLayoutTypes) {
  return (
    <Container>
      <GlobalWrap>
        <Header loginLinkTo={headerLoginLinkTo}>
          {menu}
        </Header>
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </GlobalWrap>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

/* eslint-disable camelcase */
import {Button, message, Modal, Upload, UploadProps} from 'antd';
import React, {useState, MouseEvent, useEffect} from 'react';
import styled from 'styled-components/macro';
import {ButtonDark, ButtonDarkBlack} from '../Button';
import {RcFile, UploadChangeParam} from "antd/es/upload";
import {UploadFile} from "antd/es/upload/interface";

interface PhotoProps {
    formik: any;
    imageUrl: string | undefined;
    setImageUrl: (imageUrl: any) => void
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};


export default function UploadPhotoBtn({formik, imageUrl, setImageUrl}: PhotoProps) {

    useEffect(() => {
        if (formik.values?.logo) {
            setImageUrl(formik.values?.logo)
        }

    }, [])


    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        getBase64(info.file.originFileObj as RcFile, url => {
            // setLoading(false);
            setImageUrl(url);
            formik.setFieldValue('logo', imageUrl);
        });
    };

    const uploadButton = (
        <div>
            <div style={{marginTop: 8, color: '#2682DE', fontSize: 14, fontWeight: 500, background: '#fff'}}>Upload
            </div>
        </div>
    );
    return (
        <div style={{maxHeight: '112px'}}>
            <Test
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"

                onChange={handleChange}
                style={{backgroundColor: '#fff'}}
            >
                {/*{fileList.length >= 2 ? null : uploadButton}*/}
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '102px', maxHeight: '102px'}}/> : uploadButton}
            </Test>

        </div>
    );
};

const Test = styled(Upload)`
  background-color: #fff;
  border: #fff;
  max-height: 102px;
  margin-bottom: 10px;
`;



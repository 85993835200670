import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SchoolCampaignsTableRow } from '../../types';

interface CampaignsTableProps {
  onSelectCampaigns: (arr: Array<string>) => void;
  selectedCampaigns: Array<string>;
  tableData: Array<SchoolCampaignsTableRow>;
}

export default function CampaignsTable({ onSelectCampaigns, selectedCampaigns, tableData }: CampaignsTableProps) {
  const tableConfig: ColumnsType<SchoolCampaignsTableRow> = [
    {
      title: 'Campaign ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
    },
    {
      title: 'Active period',
      dataIndex: 'activePeriod',
      key: 'activePeriod',
    },
    {
      title: 'Collection fee',
      dataIndex: 'collectionFee',
      key: 'collectionFee',
    },
    {
      title: 'Gift Aid fee',
      dataIndex: 'giftAidFee',
      key: 'giftAidFee',
    },
  ];

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        onChange: (ids) => {
          onSelectCampaigns(ids as Array<string>);
        },
        selectedRowKeys: selectedCampaigns,
      }}
      columns={tableConfig}
      dataSource={tableData}
    />
  );
}

import { useState, useEffect } from 'react';
import AdminLayout from '../layout/AdminLayout';
import { getAdminSettings, setAdminSettings } from '../../../feature/adminSettings';
import Settings from './Settings';
import { ApiAdminGetSettings } from '../../../project-types/admin-settings/api-types';

export default function SettingsPage() {
  const [collectionFeeValue, setCollectionFeeValue] = useState('');
  const [giftAidFeeValue, setGiftAidFee] = useState('');

  useEffect(() => {
    const getSettings = async () => {
      const response: ApiAdminGetSettings['successResponse']['settings'] = await getAdminSettings();
      setCollectionFeeValue(String(response.defaultCollectionFee));
      setGiftAidFee(String(response.defaultGiftAidFee));
    };
    getSettings();
  }, []);

  const onSaveCollectionFeeHandler = () => setAdminSettings({ defaultCollectionFee: +collectionFeeValue });
  const onSaveGiftAidFeeHandler = () => setAdminSettings({ defaultGiftAidFee: +giftAidFeeValue });

  return (
    <AdminLayout>
      <Settings collectionFeeValue={collectionFeeValue} setCollectionFeeValue={setCollectionFeeValue} giftAidFeeValue={giftAidFeeValue} setGiftAidFee={setGiftAidFee} onSaveCollectionFeeHandler={onSaveCollectionFeeHandler} onSaveGiftAidFeeHandler={onSaveGiftAidFeeHandler} />
    </AdminLayout>
  );
}

import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import { useState } from 'react';
import { useFormik } from 'formik';
import {
  DonorPublicFields,
  UpdateDonorFields,
} from '../../../project-types/donor/types';
import { UserPublicType } from '../../../project-types/users/types';
import { SecondHeader } from '../../../ui/Headers';
import UpdateDonorSettingsForm from './UpdateDonorSettingsForm';
import UpdateEmailComponent from './UpdateEmailComponent';
import ChangePasswordComponent from './ChangePasswordComponent';
import BillingDetailsForm from './BillingDetailsForm';
import { ChangeDebitDetailsSchema } from '../donor-schemas';

const { TabPane } = Tabs;

interface DonorSettingsProps {
  onEmailEditClickHandler: () => void;
  onChangePasswordClickHandler: () => void;
  onFormSubmitHandler: (formData: UpdateDonorFields) => void;
  profileData: DonorPublicFields;
  userData: UserPublicType;
}

export default function DonorSettings({
  profileData,
  userData,
  onEmailEditClickHandler,
  onChangePasswordClickHandler,
  onFormSubmitHandler,
}: DonorSettingsProps) {
  const [currentTab, setCurrentTab] = useState('1');
  const formik = useFormik({
    initialValues: {
      ...profileData,
      email: userData.email,
    },
    validationSchema: ChangeDebitDetailsSchema,
    onSubmit: ({
      email,
      firstName,
      lastName,
      branchSortCode1,
      branchSortCode2,
      branchSortCode3,
      ...values
    }) =>
      onFormSubmitHandler({
        parent: {
          ...values,
          sortCode: `${branchSortCode1}${branchSortCode2}${branchSortCode3}`,
        },
      }),
  });

  return (
    <Wrap>
      <StyledSecondHeader>Account settings</StyledSecondHeader>
      <GrayText>For any queries contact our helpdesk at support@silverwing.co</GrayText>
      <StyledTabs activeKey={currentTab} onChange={(key) => setCurrentTab(key)}>
        <TabPane tab="Your User Account" key="1">
          <UpdateDonorSettingsForm
            firstName={profileData.firstName as string}
            lastName={profileData.lastName as string}
            onFormSubmitHandler={onFormSubmitHandler}
          />
          <Divider />
          <UpdateEmailComponent
            email={userData.email}
            onEmailEditClickHandler={onEmailEditClickHandler}
          />
          <ChangePasswordComponent
            onChangePasswordClickHandler={onChangePasswordClickHandler}
          />
          <Divider />
          <GrayText>
            To modify or delete your user account email or name please contact our helpdesk at support@silverwing.co. Please note that billing details, including name, may be edited in the Billing tab.
          </GrayText>
        </TabPane>
        <TabPane tab="Billing Name and Details" key="2">
          <BillingDetailsForm formikInstance={formik} />
        </TabPane>
      </StyledTabs>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 100px;
`;

const StyledSecondHeader = styled(SecondHeader)`
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 13px;
`;

const GrayText = styled.span`
  font-size: 14px;
  color: #909da8;
`;

const Divider = styled.div`
  width: 100%;
  max-width: 520px;
  height: 0px;
  border-top: 1px solid #e0e0e0;
  margin: 0 auto;
  margin: 20px 0;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 30px;
`;

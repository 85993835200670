export function showPriceDigits(d: number) {
  return d.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
}

export const serializeStatisticsPrice = (value: number | undefined) => {
  if (!value) return '00.00';

  if (value < 10) {
    return `0${showPriceDigits(value)}`;
  }

  return `${showPriceDigits(value)}`;
};

export const serializeStatisticsData = (value: number | undefined) => {
  if (!value) return '00';

  if (value < 10) {
    return `0${value}`;
  }

  return `${value}`;
};

// period format YYYY-MM
export const formatDateString = (period: string, isLast: boolean = false) => {
  const timeArr = period.split('-');
  const dateSelected = new Date(+timeArr[0], +timeArr[1], 0, 0, 0, 0);

  const lastDayOfMonth = new Date(dateSelected.getFullYear(), dateSelected.getMonth() + 1, 0);

  const date = isLast ? lastDayOfMonth.getDate() : 1;

  return new Date(+timeArr[0], parseInt(timeArr[1], 10) - 1, date, 0, 0, 0).toISOString();
};

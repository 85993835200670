import { MouseEvent } from 'react';
import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../ui/Button';
import Input from '../../../../ui/forms/Input';
import { renderFormConfig } from '../../../../utils/form';

interface PersonalDetailsFormProps {
  formik: any;
  onChangePasswordClickHandler: () => void;
}

export default function PersonalDetailsForm({ formik, onChangePasswordClickHandler }: PersonalDetailsFormProps) {
  const onChangePassword = (e: MouseEvent) => {
    e.preventDefault();
    onChangePasswordClickHandler();
  };

  return (
    <Container>
      <Title>Contact Person Details</Title>
      {
        renderFormConfig([
          {
            title: 'First Name',
            name: 'firstName',
            component: InputStyled,
          },
          {
            title: 'Last Name',
            name: 'lastName',
            component: InputStyled,
          },

        ], formik)
      }
      <Divider />
      <SubTitle>Password</SubTitle>
      <StyledButton onClick={onChangePassword}>Change Password</StyledButton>
      <Divider />
      <SubmitStyled type="submit">Save</SubmitStyled>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 520px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin: 0;
  margin-bottom: 24px;
  font-weight: bold;
`;

const SubTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
`;

const StyledButton = styled(ButtonDark)`
  padding: 14px 67px;
`;

const SubmitStyled = styled(StyledButton)`
  /* margin-top: 60px; */
`;

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #D5DCE5;
  margin: 40px 0;
`;

import { Select, Menu, Dropdown } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { DownOutlined } from '@ant-design/icons';
import { DonorTableActions, DonorStatuses } from '../../../types';
import { SecondHeader } from '../../../../../ui/Headers';
import Input from "../../../../../ui/forms/Input";

const { Option } = Select;

interface DonorsReportsProps {
  numberOfDonors: number
  onAddNewDonor: () => void
  // onSelectAction: Dispatch<SetStateAction<DonorTableActions | null>>;
  onFreeze: () => void
  onUnFreeze: () => void
  onDelete: () => void
  donorStatuses: Array<DonorStatuses>;
  onFilterChange: Dispatch<SetStateAction<DonorStatuses>>;
  selectedFilter: DonorStatuses;
  nameContr: string;
  setNameContr: (nameContr: string) => void;
}

export default function DonorsReports({
  numberOfDonors, onAddNewDonor, onFreeze, onUnFreeze, onDelete, donorStatuses, onFilterChange, selectedFilter, nameContr, setNameContr
}: DonorsReportsProps) {
  const dropDownContent = (
    <Menu>
      <Menu.Item onClick={onFreeze}>
        Freeze collections
      </Menu.Item>
      <Menu.Item onClick={onUnFreeze}>
        Resume collections
      </Menu.Item>
      <Menu.Item onClick={onDelete}>
        Remove donor
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <SecondHeader>
        Scheme Contributors (
        { numberOfDonors }
        )
      </SecondHeader>

        <Filter>
        <InputStyled placeholder="Find by name:" value={nameContr} onChange={(e) => setNameContr(e.target.value)}/>
        {/*<SelectStyled defaultValue="Active" onChange={(value) => onFilterChange(value as DonorStatuses)}>*/}
        {/*  {donorStatuses.map((perriod) => (*/}
        {/*    <Option value={perriod}>{perriod}</Option>*/}
        {/*  ))}*/}
        {/*</SelectStyled>*/}
        {/*<Controls>*/}
        {/*  <AttributeItem onClick={onAddNewDonor}>+ Invite New Parents</AttributeItem>*/}
        {/*  <AttributeItem>*/}
        {/*    <Dropdown overlay={dropDownContent}>*/}
        {/*      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>*/}
        {/*        Actions*/}
        {/*        {' '}*/}
        {/*        <DownOutlined />*/}
        {/*      </a>*/}
        {/*    </Dropdown>*/}
        {/*  </AttributeItem>*/}
        {/*</Controls>*/}
      </Filter>
    </>
  );
}

const Filter = styled.div`
  margin-top: 41px;
  margin-top: 31px;
  display: flex;
  align-items: center;
`;

const SelectStyled = styled(Select)`
  width: 231px;
`;

const Controls = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const AttributeItem = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 47px;
  cursor: pointer;
  color: #2682DE;

  .ant-dropdown-link {
    color: #2682DE;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const InputStyled = styled.input`
  width: 255px;
  height: 34px;
  margin-right: 20px;
  background: #FFFFFF;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  padding: 0 25px 0 25px;
`;


import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { renderFormConfig } from '../../../utils/form';
import Input from '../../../ui/forms/Input';
import { ButtonDark } from '../../../ui/Button';
import InputWrap from '../../../ui/forms/InputWrap';
import { updateSchoolBillingDetails } from '../school-schemas';
import { UpdateSchoolBillingDetails } from '../types';

interface BankAccountFormProps {
  onFormSubmit: (values: UpdateSchoolBillingDetails) => void;
  formInitialValues: UpdateSchoolBillingDetails;
}

export default function BankAccountForm({ onFormSubmit, formInitialValues }: BankAccountFormProps) {
  const formik = useFormik({
    initialValues: {
      ...formInitialValues,
    },
    validationSchema: updateSchoolBillingDetails,
    onSubmit: (values) => onFormSubmit(values),
  });

  console.log(formik.values);

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig([
        {
          title: 'Name(s) of account holder(s)',
          name: 'bankAccountName',
          component: InputStyled,
        },
      ], formik)}

      <InputWrap
        title="Branch sort code"
        hasError={
      !!formik.errors.branchSortCode1
      || !!formik.errors.branchSortCode2
      || !!formik.errors.branchSortCode3
    }
      >
        <BranchInputWrap>
          <BranchInput name="branchSortCode1" value={formik.values.branchSortCode1} onChange={formik.handleChange} />
          <Dot />
          <BranchInput name="branchSortCode2" value={formik.values.branchSortCode2} onChange={formik.handleChange} />
          <Dot />
          <BranchInput name="branchSortCode3" value={formik.values.branchSortCode3} onChange={formik.handleChange} />
        </BranchInputWrap>
      </InputWrap>

      {renderFormConfig([
        {
          title: 'Bank/building society account number',
          name: 'accountNumber',
          component: InputStyled,
        },
      ], formik)}
      <SubmitStyled type="submit">Save</SubmitStyled>
    </Container>
  );
}

const Container = styled.form`
  margin-top: 30px;
`;

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const BranchInputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BranchInput = styled(Input).attrs({ maxLength: 2 })`
  width: 60px;
  font-size: 14px;
  padding: 21px;
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #102F54;
`;

const SubmitStyled = styled(ButtonDark)`
  margin-top: 40px;
  margin-bottom: 70px;
  padding: 14px 67px;
`;

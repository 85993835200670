import * as yup from 'yup';
import { DonorIdsArrayType } from '../donor/types';
import {
  CreateInvitesListType,
  COLLECTION_PERRIODS,
  CreateCampignSchemaType,
  CampaignEditType,
} from './types';
import { validateDateString } from '../utils/dateTransform';

const campaignBaseSchema = {
  name: yup.string(),
  description: yup.string(),
  collectionPeriod: yup.mixed().oneOf(Object.values(COLLECTION_PERRIODS)),
  startDate: yup.date().transform(validateDateString),
  collectionType: yup.string(),
  collections: yup.array(),
  activePaymentOption: yup.array(),
  giftAid: yup.boolean(),
  question: yup.string(),
  contributionURL: yup.string(),
  contributionHTML: yup.string(),
  logo: yup.string(),
  deadlineDate: yup.string(),
};

const collectionBaseSchema = {
  collectionFrequency: yup.mixed().oneOf(Object.values(COLLECTION_PERRIODS)),
  initialDonationAmount: yup.string(),
  recurringDonationAmounts: yup.string(),
  collectionPeriod: yup.string(),
  numberOfCollections: yup.number(),
  initialDonation: yup.string(),
};

const campaignOptionalFieldsSchema = yup.object({
  numberOfCollections: yup.number().optional(),
  differentInitalDonation: yup.bool().optional(),
  initialDonation: yup.number().optional(),
  donationAmounts: yup.array(yup.number().optional()).optional(),
  allowCustomAmounts: yup.bool().optional(),
  isStudentsRefRequired: yup.bool().optional(),
  isAuthorizationRequired: yup.bool().optional(),
  collectionAttempts: yup.number().min(0).max(3).optional(),
  collectionNotificationEmailArray: yup
    .array(yup.string().optional())
    .optional(),
  choices: yup.array(yup.string().optional()).optional(),
});
export const campaignSchema: yup.SchemaOf<CreateCampignSchemaType> = yup
  .object({
    name: campaignBaseSchema.name.required(),
    description: campaignBaseSchema.description.required(),
    collectionPeriod: campaignBaseSchema.collectionPeriod.required(),
    startDate: campaignBaseSchema.startDate.required(),
    collectionType: campaignBaseSchema.collectionType,
    collections: campaignBaseSchema.collections.optional(),
    giftAid: campaignBaseSchema.giftAid.optional(),
    question: campaignBaseSchema.question.optional(),
    contributionURL: campaignBaseSchema.contributionURL.optional(),
    contributionHTML: campaignBaseSchema.contributionHTML.optional(),
    activePaymentOption: campaignBaseSchema.activePaymentOption.optional(),
    logo: campaignBaseSchema.logo.optional(),
    deadlineDate: campaignBaseSchema.deadlineDate.optional(),
    theme: yup.object().shape({ hideTotals: yup.boolean().optional() }).optional(),
  })
  .concat(campaignOptionalFieldsSchema);

export const invitesSchema: yup.SchemaOf<CreateInvitesListType> = yup.object({
  members: yup.array(),
  message: yup.string().optional(),
});

export const donorsListSchema: yup.SchemaOf<DonorIdsArrayType> = yup.object({
  donors: yup.array().of(yup.mixed()).required().min(1),
});

// EDIT SCHECMA
export const campaignEditSchema: yup.SchemaOf<CampaignEditType> = yup
  .object({
    name: campaignBaseSchema.name.optional(),
    description: campaignBaseSchema.description.optional(),
    collectionPeriod: campaignBaseSchema.collectionPeriod.optional(),
    startDate: campaignBaseSchema.startDate.optional(),
    school: yup.string().optional(),
    collectionType: campaignBaseSchema.collectionType,
    collections: campaignBaseSchema.collections.optional(),
    giftAid: campaignBaseSchema.giftAid.optional(),
    question: campaignBaseSchema.question.optional(),
    contributionURL: campaignBaseSchema.contributionURL.optional(),
    contributionHTML: campaignBaseSchema.contributionHTML.optional(),
    activePaymentOption: campaignBaseSchema.activePaymentOption.optional(),
    logo: campaignBaseSchema.logo.optional(),
    deadlineDate: campaignBaseSchema.deadlineDate.optional(),
    theme: yup.object().shape({ hideTotals: yup.boolean().optional() }).optional(),
  })
  .concat(campaignOptionalFieldsSchema);

export const collectionCreateSchema = yup.object({
  collectionFrequency: collectionBaseSchema.collectionFrequency.required(),
  initialDonationAmount: collectionBaseSchema.initialDonationAmount.required(),
  recurringDonationAmounts: collectionBaseSchema.recurringDonationAmounts.required(),
  collectionPeriod: collectionBaseSchema.collectionPeriod.required(),
  numberOfCollections: collectionBaseSchema.numberOfCollections.required(),
});

import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ButtonLight } from '../../../ui/Button';
import Logo from '../Logo';

interface SideBarWrapProps {
  name?: string
  children: ReactNode
  onClickLogo: () => void
  onLogout: () => void
}

export default function SideBarWrap({
  name, children, onClickLogo, onLogout,
}: SideBarWrapProps) {
  return (
    <SideBarSchoolWrap>
      <LogoStyled onClick={onClickLogo} />
      <SchoolName>
        {name}
      </SchoolName>
      <Menu>
        {children}
      </Menu>
      <LogoutButton onClick={onLogout}>
        Logout
      </LogoutButton>
    </SideBarSchoolWrap>
  );
}

const LogoStyled = styled(Logo)`
  margin: 0 auto;
  display: block;
  cursor: pointer;
`;

const SideBarSchoolWrap = styled.aside`
  width: 337px;
  padding-top: 43px;
  border-right: 1px solid #E0E0E0;
  min-height: 99vh;
`;

const SchoolName = styled.div`
  margin-top: 55px;
  height: 50px;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  align-items: center;
  font-weight: 750;
  font-size: 18px;
  padding: 10px 0 10px 55px;
`;

const Menu = styled.nav`
  border-bottom: 1px solid #E0E0E0;
  padding-top: 47px;
  padding-bottom: 49px;
  padding-left: 55px;
`;

const LogoutButton = styled(ButtonLight)`
  width: 248px;
  height: 43px;
  display: block;
  margin: 30px auto auto;
  margin-bottom: 10px;
`;

import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { DatePicker, Divider, Input, Select, Space } from 'antd';

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonDark } from '../../../../../ui/Button';

import CloseIcon from '../../../../../img/close.svg';
import {
  CALLIBRATION_OPTIONS,
  ChangePaymentPlanForm,
  COLLECTION_FREQUENCY,
  COLLECTION_PERIOD,
  INITIAL_COLLECTION_AMOUNT,
  PAYMENT_PLAN,
  PAYMENT_PLAN_OPTIONS,
} from '../../../types';
import { ChangePaymentPlanSchema } from '../../../school-schemas';
import { RadioGroup, RadioItem } from '../../../../../ui/forms/Radio';
import {
  loadCampaignScheme,
  loadContributorScheme,
  loadCurrentPlan,
  loadCurrentPlanPreAuth,
  postLoadCalibrate,
  postLoadCalibratePreAuth,
  postLoadPayment,
  postLoadPaymentPreAuth,
  selectCurrentPlan,
} from '../../../../../feature/campaignState';

interface IChangePaymentPlanModalProps {
  onClose: () => void;
  onChangeSubmit: (values: ChangePaymentPlanForm) => void;
  campaignId: string;
  contributorId: string;
  preAuth?: boolean;
}

const NEW_PAYMENT_PLAN_OPTIONS = {
  [PAYMENT_PLAN_OPTIONS.WITHIN]: 'Change payment option within the campaign',
  [PAYMENT_PLAN_OPTIONS.ANOTHER]: 'Move to another campaign',
};

const calibrationSummaryInitialState = {
  initialAmount: '',
  recurringAmount: '',
  collectionFrequency: '',
  numberOfCollections: 1,
  activePeriod: '',
  executed: false,
};

const anotherCampaignsInitialList = [
  {
    value: '',
    label: '',
  },
];

export default function ChangePaymentPlanModal({
  onClose,
  onChangeSubmit,
  campaignId,
  contributorId,
  preAuth = false,
}: IChangePaymentPlanModalProps) {
  const dispatch = useDispatch();
  const currentPlan = useSelector(selectCurrentPlan);

  const [currentPlanFiltered, setCurrentPlanFiltered] = useState(
    currentPlan.currentSchemeChoicesArray
  );

  const [paymentPlanOption, setPaymentPlanOption] = useState('within');
  const [currentPlanChoice, setCurrentPlanChoice] = useState('');
  const [calibrationChoice, setCalibrationChoice] = useState('no');
  const [calibrationSummary, setCalibrationSummary] = useState(
    calibrationSummaryInitialState
  );
  const [anotherCampaignsList, setAnotherCampaignsList] = useState(
    anotherCampaignsInitialList
  );

  const [anotherCampaign, setAnotherCampaign] = useState('');
  const [
    selectedCollectionFrequency,
    setSelectedCollectionFrequency,
  ] = useState('Monthly');
  const [
    selectedInitialCollectionAmount,
    setSelectedInitialCollectionAmount,
  ] = useState('same');
  const [selectedCollectionPeriod, setSelectedCollectionPeriod] = useState(
    'continue'
  );

  const [customStartDate, setCustomStartDate] = useState('');
  // const [customEndDate, setCustomEndDate] = useState('');
  const [customRecurringAmount, setCustomRecurringAmount] = useState('');
  const [
    customInitialCollectionAmount,
    setCustomInitialCollectionAmount,
  ] = useState('');
  const [customNumberCollections, setCustomNumberCollections] = useState('');

  const getFormkiInitiaValues = (): ChangePaymentPlanForm => {
    const defaultValues = {
      options: PAYMENT_PLAN_OPTIONS.WITHIN,
      campaignName: '',
      paymentPlan: PAYMENT_PLAN.MONTHLY,
      callibration: CALLIBRATION_OPTIONS.NO_CALLIBRATION,
      startDate: '',
      endDate: '',
      collectionFrequency: COLLECTION_FREQUENCY.MONTHLY,
      recurringAmount: '',
      initialAmount: INITIAL_COLLECTION_AMOUNT.SAME,
      collectionPeriod: COLLECTION_PERIOD.CONTINIOUS,
      numOfCollections: '',
    };

    return defaultValues;
  };

  // useEffect(() => {
  //   setCurrentPlanChoice(currentPlan.currentSchemeChoicesArray[0].id);
  // }, [currentPlan]);

  useEffect(() => {
    const data = {
      execute: false,
      method: calibrationChoice,
    };
    if (currentPlanChoice !== '' && !preAuth) {
      postLoadCalibrate(
        contributorId,
        campaignId,
        currentPlanChoice,
        data
      ).then((res) => setCalibrationSummary(res));
    }
    if (currentPlanChoice !== '' && preAuth) {
      postLoadCalibratePreAuth(
        campaignId,
        contributorId,
        currentPlanChoice,
        data
      ).then((res) => setCalibrationSummary(res));
    }
  }, [calibrationChoice, campaignId, contributorId, currentPlanChoice]);

  useEffect(() => {
    if (calibrationChoice !== 'custom' && !preAuth) {
      dispatch(loadCurrentPlan(contributorId, campaignId));
    }
    if (calibrationChoice !== 'custom' && preAuth) {
      dispatch(loadCurrentPlanPreAuth(campaignId, contributorId));
    }
  }, [campaignId, contributorId, calibrationChoice, preAuth]);

  useEffect(() => {
    setAnotherCampaignsList(
      currentPlan.otherSchemeChoicesArray.map((el) => ({
        label: el.schemeName,
        value: el.schemeID,
      }))
    );
  }, [currentPlan]);

  useEffect(() => {
    if (paymentPlanOption === 'within' && currentPlan) {
      // setCurrentPlanChoice(currentPlan.currentSchemeChoicesArray[0].id);
      setCurrentPlanFiltered(currentPlan.currentSchemeChoicesArray);
    }
    if (
      paymentPlanOption === 'another' &&
      anotherCampaign !== '' &&
      currentPlan
    ) {
      const tmpArray = currentPlan.otherSchemeChoicesArray.filter(
        (el) => el.schemeID === anotherCampaign
      );
      setCurrentPlanFiltered(tmpArray[0].schemeChoicesArray);
    }
  }, [currentPlan, paymentPlanOption, anotherCampaign]);

  const formik = useFormik({
    initialValues: getFormkiInitiaValues(),
    validationSchema: ChangePaymentPlanSchema,
    onSubmit: (values) => onChangeSubmit(values),
  });

  const showSummary = useMemo(
    () =>
      currentPlanChoice !== '' ||
      (calibrationChoice !== 'no' && calibrationChoice !== 'custom') ||
      anotherCampaign !== '',

    [currentPlanChoice, calibrationChoice, calibrationSummary]
  );

  const calibrationOptions = [
    { text: 'No calibration of payments', status: 'no' },
    ...(!preAuth && showSummary
      ? [
          { text: 'Calibrate according to new payment sum', status: 'sum' },
          {
            text: 'Calibrate according to new payment schedule end date',
            status: 'end_date',
          },
        ]
      : []),
    { text: 'Custom action', status: 'custom' },
  ];

  const customCollectionFrequencyList = [
    'Monthly',
    'Quarterly',
    'Annually',
    'Termly',
    'Yearly',
  ];

  const customInitialCollectionAmountList = [
    { text: 'The same as monthly collections', value: 'same' },
    { text: 'Initial collection amount is different', value: 'different' },
  ];

  const customCollectionPeriodList = [
    { text: 'Continuous collections', value: 'continue' },
    { text: 'Fixed number of collections', value: 'fixed' },
  ];

  const onSubmitPayment = () => {
    const customData = {
      currency: 'GBP',
      initialAmount:
        customInitialCollectionAmount !== ''
          ? customInitialCollectionAmount
          : '0',
      recurringAmount:
        customRecurringAmount !== '' ? customRecurringAmount : '0',
      collectionFrequency: selectedCollectionFrequency,
      numberOfCollections:
        customNumberCollections !== '' ? customNumberCollections : '0',
      startDate: customStartDate,
    };
    if (
      calibrationChoice === 'custom' &&
      contributorId &&
      campaignId &&
      !preAuth
    ) {
      postLoadPayment(contributorId, campaignId, customData).then((res) =>
        res.executed ? onClose() : alert('This is not allowed')
      );
    }
    if (
      calibrationChoice === 'custom' &&
      contributorId &&
      campaignId &&
      preAuth
    ) {
      postLoadPaymentPreAuth(
        campaignId,
        contributorId,
        customData
      ).then((res) =>
        res.executed ? onClose() : alert('This is not allowed')
      );
    }
    if (
      calibrationChoice !== 'custom' &&
      currentPlanChoice !== '' &&
      contributorId &&
      campaignId
    ) {
      const data = {
        execute: true,
        method: calibrationChoice,
      };

      if (!preAuth) {
        postLoadCalibrate(
          contributorId,
          campaignId,
          currentPlanChoice,
          data
        ).then((res) => setCalibrationSummary(res));
      }
      if (preAuth) {
        postLoadCalibratePreAuth(
          campaignId,
          contributorId,
          currentPlanChoice,
          data
        ).then((res) => setCalibrationSummary(res));
      }
    }
    if (preAuth && campaignId) {
      setTimeout(() => {
        dispatch(loadCampaignScheme(campaignId));
      }, 500);
      onClose();
    }
    if (!preAuth && contributorId) {
      setTimeout(() => {
        dispatch(loadContributorScheme(contributorId));
      }, 500);
      onClose();
    }
  };

  return (
    <Container>
      <CloseButton alt="" src={CloseIcon} onClick={onClose} />
      <Title>Change Payment Plan</Title>
      <form onSubmit={formik.handleSubmit}>
        <SubTitle>New Payment Plan Options</SubTitle>
        <RadioGroup value={paymentPlanOption}>
          {Object.values(PAYMENT_PLAN_OPTIONS).map((key) => (
            <RadioItem
              onChange={() => setPaymentPlanOption(key)}
              key={key}
              value={key}
            >
              {NEW_PAYMENT_PLAN_OPTIONS[key as PAYMENT_PLAN_OPTIONS]}
            </RadioItem>
          ))}
        </RadioGroup>

        <WithinWrapper>
          {paymentPlanOption === 'another' && (
            <>
              <SubTitle>Select New Campaign</SubTitle>
              <SelectStyled
                placeholder="Campaign Name"
                onChange={(value) =>
                  value && setAnotherCampaign(value as string)
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                options={anotherCampaignsList}
                disabled={calibrationChoice === 'custom'}
              />
            </>
          )}
          <SubTitle>Select New Payment Plan </SubTitle>
          <RadioGroup value={currentPlanChoice}>
            {currentPlanFiltered.map((plan) => (
              <RadioButton key={plan.id}>
                <StyledRadioItem
                  value={plan.id}
                  onChange={() => setCurrentPlanChoice(plan.id)}
                  disabled={calibrationChoice === 'custom' || plan.isActive}
                >
                  {plan.isActive && <StyledBadge>Current</StyledBadge>}
                  {plan.text}
                </StyledRadioItem>
              </RadioButton>
            ))}
          </RadioGroup>
        </WithinWrapper>
        <Divider />
        <SubTitle>
          Select from the following payments calibration options:
        </SubTitle>
        <RadioGroup value={calibrationChoice}>
          {calibrationOptions.map((el) => (
            <div>
              <RadioItem
                value={el.status}
                onChange={() => setCalibrationChoice(el.status)}
              >
                {el.text}
              </RadioItem>
            </div>
          ))}
        </RadioGroup>
        {calibrationChoice === 'custom' && (
          <CustomWrapper>
            <SubTitle>Payments Start Date</SubTitle>
            <StyledDatePicker
              placeholder="Select a date"
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              onChange={(value, dateString) => setCustomStartDate(dateString)}
            />
            {/*<SubTitle>Payments End Date</SubTitle>*/}
            {/*<StyledDatePicker*/}
            {/*  placeholder="Select a date"*/}
            {/*  getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}*/}
            {/*  onChange={(value, dateString) => setCustomEndDate(dateString)}*/}
            {/*/>*/}
            <SubTitle>Collection Frequency*</SubTitle>
            <RadioGroup value={selectedCollectionFrequency}>
              {customCollectionFrequencyList.map((el) => (
                <RadioItem
                  value={el}
                  onChange={() => setSelectedCollectionFrequency(el)}
                >
                  {el}
                </RadioItem>
              ))}
            </RadioGroup>
            <SubTitle style={{ marginTop: 20 }}>
              Recurring collection amounts
            </SubTitle>
            <StyledInput
              placeholder="£"
              value={customRecurringAmount}
              onChange={(e) => setCustomRecurringAmount(e.target.value)}
            />
            <SubTitle style={{ marginTop: 20 }}>
              Initial collection amount
            </SubTitle>
            <RadioGroup value={selectedInitialCollectionAmount}>
              {customInitialCollectionAmountList.map((el) => (
                <RadioItem
                  value={el.value}
                  onChange={() => setSelectedInitialCollectionAmount(el.value)}
                >
                  {el.text}
                </RadioItem>
              ))}
            </RadioGroup>
            {selectedInitialCollectionAmount === 'different' && (
              <>
                <SubTitle style={{ marginTop: 10 }}>
                  Initial collection amount
                </SubTitle>
                <StyledInput
                  placeholder="£"
                  value={customInitialCollectionAmount}
                  onChange={(e) =>
                    setCustomInitialCollectionAmount(e.target.value)
                  }
                />
              </>
            )}
            <SubTitle style={{ marginTop: 20 }}>Collection Period</SubTitle>
            <RadioGroup value={selectedCollectionPeriod}>
              {customCollectionPeriodList.map((el) => (
                <RadioItem
                  value={el.value}
                  onChange={() => setSelectedCollectionPeriod(el.value)}
                >
                  {el.text}
                </RadioItem>
              ))}
            </RadioGroup>
            {selectedCollectionPeriod === 'fixed' && (
              <>
                <SubTitle style={{ marginTop: 20 }}>
                  Number of collections
                </SubTitle>
                <StyledInput
                  value={customNumberCollections}
                  onChange={(e) => setCustomNumberCollections(e.target.value)}
                />
              </>
            )}
          </CustomWrapper>
        )}
        <Divider />
        <SubTitle>New Payment Plan Summary</SubTitle>
        {showSummary ? (
          <SummaryWrapper>
            <SummaryItem>
              <SummaryTitle>Initial Collection Amount</SummaryTitle>
              <SummaryValue>{calibrationSummary.initialAmount}</SummaryValue>
            </SummaryItem>
            <SummaryItem>
              <SummaryTitle>
                Recurring Collection Amount <span>(Per Student)</span>
              </SummaryTitle>
              <SummaryValue>{calibrationSummary.recurringAmount}</SummaryValue>
            </SummaryItem>
            <SummaryItem>
              <SummaryTitle>Collection Frequency</SummaryTitle>
              <SummaryValue>
                {calibrationSummary.collectionFrequency}
              </SummaryValue>
            </SummaryItem>
            <SummaryItem>
              <SummaryTitle>Number Of Collections</SummaryTitle>
              <SummaryValue>
                {calibrationSummary.numberOfCollections}
              </SummaryValue>
            </SummaryItem>
            <SummaryItem>
              <SummaryTitle>Active Period</SummaryTitle>
              <SummaryValue>{calibrationSummary.activePeriod}</SummaryValue>
            </SummaryItem>
          </SummaryWrapper>
        ) : (
          <SummaryWrapper>No Change</SummaryWrapper>
        )}
        <SubmitButton
          type="submit"
          onClick={onSubmitPayment}
          isDisabled={!(showSummary || calibrationChoice === 'custom')}
          disabled={!(showSummary || calibrationChoice === 'custom')}
        >
          Apply
        </SubmitButton>
      </form>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  box-sizing: border-box;
  max-width: 572px;
  max-height: 90vh;
  padding: 24px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const SubmitButton = styled(ButtonDark)<{ isDisabled: boolean }>`
  padding: 14px 57px;
  align-self: flex-start;
  color: ${({ isDisabled }) => isDisabled && 'white'};
  background-color: ${({ isDisabled }) => isDisabled && '#BDBDBD'};
  border: ${({ isDisabled }) => isDisabled && 'none'};
  font-size: 14px;
  font-weight: 700;
  margin-top: 74px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const SubTitle = styled.h4`
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
`;

const WithinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

const RadioButton = styled.div`
  position: relative;
  padding: 10px 12px;
  width: 342px;
  margin: 5px 0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 8px;
  width: 385px;
  background-color: rgba(245, 248, 251, 0.7);
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SummaryTitle = styled.p`
  margin: 0;
  font-size: 14px;
  max-width: 176px;
  color: #102f54;
  font-weight: 400;
  span {
    color: #6b7a87;
  }
`;

const SummaryValue = styled.p`
  margin: 0;
  font-size: 14px;
  color: #102f54;
  font-weight: 500;
`;

const SelectStyled = styled(Select)`
  display: flex;
  width: 422px;
  margin-bottom: 10px;
  &:before {
    content: '';
    /* width: 100%; */
  }
`;

const CustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
  min-height: 200px;
  margin-top: 20px;
  padding: 26px 36px;
  background-color: rgba(245, 248, 251, 0.7);
`;

const StyledDatePicker = styled(DatePicker)`
  width: 192px;
  height: 40px;
  margin-bottom: 20px;
`;

const StyledInput = styled(Input)`
  max-width: 313px;
  width: 100%;
  height: 41px;
`;

const StyledBadge = styled.span`
  position: absolute;
  right: -12px;
  top: -10px;
  padding: 2px;
  color: white;
  font-size: 10px;
  background-color: #1677ff;
  border-radius: 4px;
`;

const StyledRadioItem = styled(RadioItem)`
  width: 100%;
`;

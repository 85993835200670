import { useState } from 'react';
import styled from 'styled-components/macro'; // Импортим именно /macro (для читаемых имен классов)
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { ButtonDark } from '../../../../ui/Button';

import { CampaignPublicType } from '../../../../project-types/campaign/types';
import { CampaignInviteAcceptType } from '../../../../project-types/campaign-join/types';
import { getCurrentSchool } from '../../../../feature/schoolsState';
import { SchoolPublicTypeLite } from '../../../../project-types/schools/types';
import { showPriceDigits } from '../../../../utils/formats';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import CampaignDetails from './Components/CampaignDetails';
import StudentsForm from './Components/StudentsForm';
import DeviceTradeinBlock from './Components/DeviceTradeinBlock';
import BoostYourDonationBlock from './Components/BoostYourDonationBlock';

import { ReactComponent as InviteLogo } from '../../../../img/invite-logo.svg';
import newLogo from '../../../../img/newPhoto.jpg';
import JoinCampaignPrivacyPolicy from './Components/PrivacyPolicy';
import SchoolContribution from './Components/SchoolContribution';
import PaymentType from './Components/PaymentType';
import QuestionForm from './Components/QuestionForm';

interface DonorInviteAcceptProps {
  campaign: CampaignPublicType;
  campaignJoin: Partial<CampaignInviteAcceptType>;
  setDonationAmout: (amount: number) => void;
  setCustomAmmount: (amount: number) => void;
  customAmmount: number | null;
  donationAmount?: number;
  onNext: (
    students: CampaignInviteAcceptType['students'],
    choice: CampaignInviteAcceptType['choice'],
    studentNumber: CampaignInviteAcceptType['studentNumber']
  ) => void;
  currentSchool: SchoolPublicTypeLite;
  onTradeinClickHandler: () => void;
  setIsCheckedPrivacyPolicy: (status: boolean) => void;
  setIsCheckedContribution: (status: boolean) => void;
  isCheckedPrivacyPolicy: boolean;
  isCheckedContribution: boolean;
}

export default function DonorInviteAccept({
  campaign,
  currentSchool,
  donationAmount,
  setDonationAmout,
  setCustomAmmount,
  customAmmount,
  onNext,
  onTradeinClickHandler,
  setIsCheckedPrivacyPolicy,
  setIsCheckedContribution,
  isCheckedContribution,
  isCheckedPrivacyPolicy,
}: DonorInviteAcceptProps) {
  const [students, setStudents] = useState(['']);
  const [choice, setChoice] = useState('');

  const onClickChangeDonationAmount = (amount: number) => () => {
    setDonationAmout(amount);
  };

  // console.log(campaign);

  const onChangeCustomDonationAmount = (e: any) => {
    console.log(e.target.value);
    setCustomAmmount(e.target.value);
    // setDonationAmout(e.target.value);
  };

  const onDecButtonClickHandler = () =>
    setStudents((prev) => prev.filter((_, i) => i < prev.length - 1));
  const onIncButtonClickHandler = () => setStudents((prev) => [...prev, '']);

  const initialDonationAmount = campaign.initialDonation
    ? campaign.initialDonation
    : campaign.donationAmounts && campaign.donationAmounts[0]
    ? campaign.donationAmounts[0]
    : 0;
  const recurringDonationAmount =
    campaign.donationAmounts && campaign.donationAmounts[0]
      ? campaign.donationAmounts[0]
      : 0;

  const onNextClickHandler = () => {
    const studentsArr =
      campaign.isStudentsRefRequired && students.length
        ? students.map((s) => (s ? s : '0'))
        : undefined;

    const studentNumber = students.length;
    if (!studentsArr || !studentsArr.find((student) => student === '0')) {
      onNext(studentsArr, choice, studentNumber);
    }
  };

  return (
    <Content>
      <TitleBlock>
        <TitleContent>
          <Title>{campaign.name}</Title>
          <Text>
            {`You have been invited to join a ${currentSchool?.schoolName} 1:1 device programme`}
          </Text>
        </TitleContent>
        {/*<InviteLogo />*/}
        {campaign.logo && (
          <img
            style={{ width: '174px', height: '174px', objectFit: 'cover' }}
            src={campaign.logo}
            alt=""
          />
        )}
      </TitleBlock>
      <Container>
        <DescriptionBlock>
          {/*<SubTitle>Campaign Description</SubTitle>*/}
          <SmallText
            dangerouslySetInnerHTML={{ __html: campaign.description }}
          />
        </DescriptionBlock>
        <CampaignDetailsBlock>
          <SubTitle>Key Scheme Information</SubTitle>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Details>
                {campaign.deadlineDate && (
                  <DetailsContainer>
                    <DetailsKeyTitle>
                      Scheme Registration Deadline
                    </DetailsKeyTitle>
                    <DetailsValueTitle>
                      {formatDateDDMMMYYYY(new Date(campaign?.deadlineDate))}
                    </DetailsValueTitle>
                  </DetailsContainer>
                )}
                <DetailsContainer>
                  <DetailsKeyTitle>First Collection Date</DetailsKeyTitle>
                  <DetailsValueTitle>
                    {formatDateDDMMMYYYY(new Date(campaign?.startDate))}
                  </DetailsValueTitle>
                </DetailsContainer>

                {/*<DetailsContainer>*/}
                {/*  <DetailsKeyTitle>First Collection Date</DetailsKeyTitle>*/}
                {/*  <DetailsValueTitle>31/07/2022</DetailsValueTitle>*/}
                {/*</DetailsContainer>*/}
              </Details>
            </Col>
          </Row>

          {/*<Row style={{ marginTop: 30 }} gutter={[36, 12]}>*/}
          {/*  {campaign.collections?.map((collection) => (*/}
          {/*    <Col span={11}>*/}
          {/*      <PaymentType*/}
          {/*        key={collection._id}*/}
          {/*        schemeName={collection.collectionSchemeName}*/}
          {/*        initialCollectionAmount={collection.initialDonationAmount}*/}
          {/*        collectionFrequency={collection.collectionFrequency}*/}
          {/*        recurringCollectionAmount={*/}
          {/*          collection.recurringDonationAmounts*/}
          {/*        }*/}
          {/*        numberOfCollections={collection.numberOfCollections}*/}
          {/*      />*/}
          {/*    </Col>*/}
          {/*  ))}*/}
          {/*</Row>*/}
        </CampaignDetailsBlock>
        <SubTitle style={{ marginTop: 80 }}>
          Please complete the following details to join the scheme
        </SubTitle>
        <StudentsForm
          students={students}
          collections={campaign.collections!}
          onChangeStudents={setStudents}
          onDecButtonClickHandler={onDecButtonClickHandler}
          onIncButtonClickHandler={onIncButtonClickHandler}
          isIdRequired={
            typeof campaign.isStudentsRefRequired === 'boolean'
              ? campaign.isStudentsRefRequired
              : false
          }
          initialDonationAmount={initialDonationAmount}
          recurringDonationAmount={recurringDonationAmount}
        />
        {/* <DeviceTradeinBlock onTradeinClickHandler={onTradeinClickHandler} /> */}

        {campaign.giftAid === true && (
          <BoostYourDonationBlock
            schoolCharityName={currentSchool?.charityName || ''}
          />
        )}

        <JoinCampaignPrivacyPolicy
          isCheckedPrivacyPolicy={isCheckedPrivacyPolicy}
          setIsCheckedPrivacyPolicy={setIsCheckedPrivacyPolicy}
        />
        <SchoolContribution
          isCheckedContribution={isCheckedContribution}
          setIsCheckedContribution={setIsCheckedContribution}
          urlka={campaign.contributionURL}
        />
        {campaign?.question && (
          <QuestionForm
            choices={campaign?.choices}
            question={campaign?.question}
            choice={choice}
            setChoice={setChoice}
          />
        )}

        <NextButton
          style={{ marginTop: 150, marginBottom: 40 }}
          onClick={onNextClickHandler}
        >
          Next
        </NextButton>
      </Container>
    </Content>
  );
}

const Content = styled.main`
  width: 100%;
  border-top: 1px solid #e0e0e0;
`;

const Container = styled.div`
  width: 100%;
  max-width: 870px;
  margin: 0px auto;
  padding: 80px 0;
`;

const TitleBlock = styled.div`
  display: flex;
  background-color: #f5f8fb;
  justify-content: center;
  padding: 40px 0;
`;

const TitleContent = styled.div`
  width: 870px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 30px;
  color: #102f54;
`;

const SubTitle = styled.h2`
  font-weight: 600;
  font-size: 20px;
  color: #102f54;
`;

const Text = styled.p`
  font-size: 20px;
  color: #6a7f94;
`;

const SmallText = styled(Text)`
  color: #102f54;
  font-weight: 400;
  font-size: 14px;
`;

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 70px;
`;

const CampaignDetailsBlock = styled.div`
  /* display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  max-width: 385px; */
`;

const NextButton = styled(ButtonDark)`
  padding: 13px 100px;
  float: right;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 28px;
  background-color: rgba(245, 248, 251, 0.7);
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailsKeyTitle = styled.span`
  font-weight: 400;
  font-size: 14px;

  color: #102f54;
`;

const DetailsValueTitle = styled.span`
  font-weight: 600;
  font-size: 14px;

  color: #102f54;
`;

const DetailsMainTitle = styled.span`
  font-weight: 600;
  font-size: 16px;

  color: #6b7a87;
`;

import {
  CampaignCreateType,
  CollectionForm,
} from '../../project-types/campaign/types';
import { ApiCreateSchool } from '../../project-types/schools/api-types';
import {
  SchoolRegisterType,
  SchoolUpdateType,
} from '../../project-types/schools/types';
import { UserRegisterType } from '../../project-types/users/types';

export interface SignUpSchoolFields
  extends UserRegisterType,
    SchoolRegisterType {}

export interface SignUpSchoolForm extends UserRegisterType, SchoolRegisterType {
  agree: boolean;
  confirmPassword: string;
}

export interface PaymnetInformationCorrectionForm {
  bankDebitName: string,
  bankAccount: string,
  sortCode: string,
  studentReferences: Array<string>,
}

export interface ChangePaymentPlanForm {
  options: PAYMENT_PLAN_OPTIONS;
  campaignName: string;
  paymentPlan: PAYMENT_PLAN;
  callibration: CALLIBRATION_OPTIONS;
  startDate?: string;
  endDate?: string;
  collectionFrequency: COLLECTION_FREQUENCY;
  recurringAmount: string;
  initialAmount: INITIAL_COLLECTION_AMOUNT;
  collectionPeriod: COLLECTION_PERIOD;
  numOfCollections: string;
}

export type SchoolFormFieldType = ApiCreateSchool['data']['schoolProfile'];
export type SchoolFormUpdateType = Omit<
  Required<SchoolUpdateType>,
  'email' | 'password' | 'URN' | 'address2' | 'address3' | 'logo'
>;
export type SchoolLoginFields = UserRegisterType;

export type CampaignFormFields = Omit<CampaignCreateType, 'isFreezed'>;
export type CollectionFormFileds = Omit<CollectionForm, 'isFreezed'>;

export type DonorTableActions =
  | 'Resume collections'
  | 'Remove donor'
  | 'Freeze collections';

export type DonorStatuses = 'Frozen' | 'Active';

export interface DonorTableRow {
  id: string;
  key: string;
  fullName: string;
  email: string;
  successfulCollections: number;
  failedCollections: number;
  plannedCollections: number;
  giftAidBool: string;
  totalCollected: string;
  notes: string;
  studentReferences: string;
}

export interface SchoolCampaignTableRow {
  key: string;
  id: number;
  school: string;
  campaignName: string;
  studentReferences: string;
  status: string;
  activePeriod: string;
  paymentPlan: string;
  totalCollected: string;
  totalCollections: number;
  totalFailedCollections: number;
  subscriptionID: number
}

export interface CollectionActionTableRow {
  key: string;
  id: string;
  contributorName: string;
  status: string;
  studentId: string;
  exceptionAmount: string;
  giftAidAmount: string;
  exceptionType?: string;
  exceptionDate?: string;
  exceptionStatus?: string;
}

export interface Campaign {
  school: string;
  campaignName: string;
  studentReferences: string[];
  status: string;
  activePeriodStart: string;
  activePeriodEnd: string;
  paymentPlan: string;
  totalCollected: number;
  totalPlanned: number;
}

export interface CollectionAction {
  postedDate: string;
  contributorName: string;
  status: string;
  studentId: string[];
  amount: number;
  giftAidAmount: number;
}

export interface Collection {
  date: string;
  school: string;
  campaignName: string;
  status: string;
  amount: number;
}

export interface SchoolCollectionTableRow {
  id: string;
  key: string;
  date: string;
  status: string;
  collectionAmount: string;
  giftAidAmount: string;
}

export interface DonationsTableRow {
  key: string;
  contributorName: string;
  contributorID: string;
  collectionDate: string;
  collectionAmount: string;
  giftAidAmount: string;
  status: string;
}

export interface CampaignsTableRow {
  key: string;
  id: string;
  campaignName: string;
  startDate: string;
  targetEndDate: string;
  registeredDonors: number;
  totalDonationsCollected: string;
  unhandledExceptions: number;
}

export interface ManageTableModel {
  id: string;
  key: string;
  firstCollectionDate: string;
  contributorName: string;
  contributorID: string;
  studentReferenceArray: Array<string>;
  giftAidRegistered: string;
  subscriptionName: string;
  informationError: boolean;
}

export interface DeviceTradeinTableRow {
  key: string;
  dateSubmitted: string;
  type: string;
  nameOrReference: string;
  quantityRegistred: number;
  expectedValue: string;
}

export interface RegistredDevicesTradeinTableRow {
  key: string;
  date: string;
  donorName: string;
  giftAidOptIn: string;
  quantity: number;
  totalValuation: string;
}

export type DonationsTableConfig = Array<{
  title: string;
  dataIndex: keyof DonationsTableRow;
  key: keyof DonationsTableRow;
}>;

export type DonorTableConfig = Array<{
  title: string;
  dataIndex: keyof DonorTableRow;
  key: keyof DonorTableRow;
  render?: any;
}>;

export type UpdateSchoolFormSteps =
  | 'schoolDetails'
  | 'personalDetails'
  | 'billingDetails';

export type UpdateSchoolBillingDetails = Pick<
  SchoolFormUpdateType,
  | 'bankAccountName'
  | 'branchSortCode1'
  | 'branchSortCode2'
  | 'branchSortCode3'
  | 'accountNumber'
>;

export enum COLLECTION_STATUSES {
  'Successful' = 'Successful',
  'Failed' = 'Failed',
  'Unprocessed' = 'Unprocessed',
}

export enum CAMPAIGN_STATUSES {
  'Active' = 'Active',
  'Archived' = 'Archived',
}

export enum ACTION_REQUIRED_STATUSES {
  'Failed Payment' = 'retry',
  'Information Error' = 'info',
  'Mandate Cancelled' = 'mandate',
}

export enum PAYMENT_PLAN_OPTIONS {
  WITHIN = 'within',
  ANOTHER = 'another',
}

export enum PAYMENT_PLAN {
  ANNULY = 'annualy',
  MONTHLY = 'monthly',
}

export enum CALLIBRATION_OPTIONS {
  NO_CALLIBRATION = 'no_callibration',
  ACCORDIN_TO_SUM = 'according_to_sum',
  ACCORDING_TO_SCHEDULE = 'accotding_to_schedule',
  CUSTOM = 'custom',
}

export enum COLLECTION_FREQUENCY {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUAL = 'annual',
  ONE_OFF = 'one_off',
}

export enum INITIAL_COLLECTION_AMOUNT {
  SAME = 'same',
  DIFFERENT = 'different',
}

export enum COLLECTION_PERIOD {
  CONTINIOUS = 'continious',
  FIXED = 'fixed',
}

export interface UpdateCampaignCollectionRulesType
  extends Pick<CampaignCreateType, 'collectionAttempts'> {}

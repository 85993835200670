import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import AdminLayout from '../layout/AdminLayout';
import NewSchoolForm from './NewSchoolForm';
import { adminCreateSchool } from '../../../feature/schoolsState';
import ROUTER_CONFIG from '../../../router/router-config';
import { ApiAdminCreateSchool } from '../../../project-types/admin-schools/api-types';

export default function NewSchoolAccountPage() {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const onSuccessCreateSchool = () => push(ROUTER_CONFIG.ADMIN_SCHOOLS.getPath());
  const onFormSubmitHandler = (formData: ApiAdminCreateSchool['data']) => dispatch(adminCreateSchool(formData, onSuccessCreateSchool));
  const onCancelClickHandler = () => console.log(4);

  return (
    <AdminLayout>
      <NewSchoolForm onCancelClickHandler={onCancelClickHandler} onFormSubmitHandler={onFormSubmitHandler} />
    </AdminLayout>
  );
}

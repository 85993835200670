import styled from 'styled-components/macro';
import { FieldWrap, FieldName, FieldInputText } from '../LayoutComponents';

interface TodayDateProps {
  todayDate: string;
}

export function TodayDate({ todayDate }: TodayDateProps) {
  return (
    <FieldWrap>
      <FieldName>Today’s Date</FieldName>
      <FieldDate>
        <FieldInputText>
          {todayDate}
        </FieldInputText>
      </FieldDate>
    </FieldWrap>
  );
}

const FieldDate = styled.div`
  background: #EEEEEE;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 9px 11px 5px 11px;
  width: fit-content;
`;

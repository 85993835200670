import axios from 'axios';

export const BASE_URL =
  process.env.REACT_APP_BACKEND || 'http://localhost:5000/api/';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'node-token': 'KggUoHGhkDp2QS7ZKf3M',
  },
});

export const setBearerToken = (token: string, tokenSetupDate?: string) => {
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  localStorage.setItem('token', token);
  if (tokenSetupDate) {
    localStorage.setItem('tokenSetupDate', tokenSetupDate);
  }
};

export const removeToken = () => {
  instance.defaults.headers.Authorization = null;
  localStorage.removeItem('token');
  localStorage.removeItem('tokenSetupDate');
  window.location.reload();
};

export const getToken = () => localStorage.getItem('token');

export const request = (params: any, allowedStatuses: number[] = [200]) =>
  instance(params).catch((e) => {
    const status = e?.response?.status;

    if (allowedStatuses.indexOf(status) === -1) {
      if (e?.response?.status === 401) {
        removeToken();
        window.location.href = `${process.env.PUBLIC_URL}/`;
      } else {
        throw e?.response;
      }
    } else {
      return e;
    }
    return null;
  });

function restoreToken() {
  const token = localStorage.getItem('token');
  if (token) {
    setBearerToken(token);
  }
}

export interface RequestParamsType {}

restoreToken();

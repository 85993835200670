import styled from 'styled-components/macro';
import {ButtonDark, ButtonLightGray} from '../../../../../ui/Button';
import {useState} from "react";
import WaitForApprove from "../../../../../ui/elements/WaitForApprove";

interface ButtonsBlockProps {
    onAcceptHandler: () => void;
    onCancelHandler: () => void;
}

export function ButtonsBlock({onAcceptHandler, onCancelHandler}: ButtonsBlockProps) {
    const [count, setCount] = useState(false)

    const handleClick = () => {
        onAcceptHandler()
        setCount(true)
    }

    return (
        <>
            <Buttons>
                <ButtonStyled disabled={count ? true : false} onClick={handleClick}>Accept</ButtonStyled>

                <ButtonStyledOutlined onClick={onCancelHandler}>
                    Cancel
                </ButtonStyledOutlined>
            </Buttons>
            {count ? <WaitForApprove/> : null}
        </>
    );
}

const ButtonStyled = styled(ButtonDark)`
  padding: 11px 0;
  width: 286px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 226px;
`;

const ButtonStyledOutlined = styled(ButtonLightGray)`
  padding: 11px 0;
  width: 286px;
  margin-left: 12px;
`;

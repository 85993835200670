import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Input from '../../../../ui/forms/Input';

import SearchIcon from '../../../../img/icons/icon_search.svg';
import { CharitiesTableRow } from '../../types';

interface CharitiesTableProps {
  tableData: Array<CharitiesTableRow>;
  onRowClickHandler: (charityId: string) => void;
}

export default function CharitiesTable({ tableData, onRowClickHandler }: CharitiesTableProps) {
  const [filteredTableSource, setFilteredTableSource] = useState(tableData);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setFilteredTableSource(tableData.filter((row) => row.charityName.toLowerCase().includes(searchValue.toLowerCase())));
  }, [searchValue, tableData]);

  const tableConfig: ColumnsType<CharitiesTableRow> = [
    {
      title: 'Charity Name',
      dataIndex: 'charityName',
      key: 'charityName',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Reg No.',
      dataIndex: 'regNumber',
      key: 'regNumber',
    },
    {
      title: 'Name Of Regulator',
      dataIndex: 'nameOfRegulator',
      key: 'nameOfRegulator',
    },
    {
      title: 'Authorised Official',
      dataIndex: 'authorisedOfficial',
      key: 'authorisedOfficial',
    },
  ];

  return (
    <Container>
      <StyledInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} iconSrc={SearchIcon} />
      <Table
        onRow={(rowData) => ({
          onClick: () => onRowClickHandler(rowData.key),
          style: { cursor: 'pointer' },
        })}
        columns={tableConfig}
        dataSource={filteredTableSource}
      />
    </Container>
  );
}

const Container = styled.div``;

const StyledInput = styled(Input)<{ iconSrc: string }>`
  width: 100%;
  max-width: 420px;
  padding: 15px 0 15px 48px;
  margin-top: 30px;
  background-image: ${(props) => `url(${props.iconSrc})`};
  background-repeat: no-repeat;
  background-position: 17px center;

  margin-bottom: 15px;
`;

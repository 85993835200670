import styled from 'styled-components/macro';
import Input from '../../../ui/forms/Input';
import { FormConfig } from '../../../utils/form';

interface PostcodeInputProps extends FormConfig {
  value: string;
  name: string;
  onChange: (e: any) => void;
  props: any;
}

export default function PostcodeInput({
  value, name, onChange, ...props
}: PostcodeInputProps) {
  return (
    <InputStyled value={value} name={name} onChange={onChange} {...props} />
  );
}

const InputStyled = styled(Input)`
  width: 100%;
`;

import styled from 'styled-components/macro';
import { ButtonLight } from '../../../ui/Button';

interface LoginLinkProps {
  onLoginClick: () => void;
}

export default function LoginLink({ onLoginClick }: LoginLinkProps) {
  return (
    <Container>
      <StyledLogin onClick={onLoginClick}>Login</StyledLogin>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 4px;
  font-size: 18px;
  line-height: 22px;
`;

const StyledLogin = styled(ButtonLight)`
  padding: 9px 24px;
  cursor: pointer;
`;

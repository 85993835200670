import { useState } from 'react';
import styled from 'styled-components/macro';
import { ButtonDark } from '../../../ui/Button';
import Checkbox from '../../../ui/forms/Checkbox';
import FindYourAddress from '../../../ui/forms/FindYourAddress';
import Input from '../../../ui/forms/Input';
import InputWrap from '../../../ui/forms/InputWrap';
import { FormConfig, renderFormConfig } from '../../../utils/form';
import PostcodeInput from '../../school/create-school/PostcodeInput';

interface BillingDetailsFormProps {
  formikInstance: any;
}

export default function BillingDetailsForm({ formikInstance }: BillingDetailsFormProps) {
  const [isEdit, setIsEdit] = useState(false);

  const formConfig: FormConfig = [
    {
      title: 'Name:*',
      name: 'billingName',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Name',
      },
    },
    {
      title: 'Postcode:*',
      name: 'postcode',
      component: StyledPostcodeInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Postcode of contact address',
      },
    },
  ];

  const formConfig2: FormConfig = [
    {
      title: 'Address line 1:*',
      name: 'address1',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'First line of contact address',
      },
    },
    {
      title: 'Address line 2:',
      name: 'address2',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Optional',
      },
    },
    {
      title: 'Address line 3:',
      name: 'address3',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Optional',
      },
    },
    {
      title: 'Town:',
      name: 'town',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Required',
      },
    },
    {
      title: 'County:',
      name: 'county',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Required',
      },
    },
    {
      title: 'Country:',
      name: 'country',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Required',
      },
    },
    {
      title: 'Telephone number:',
      name: 'phoneNumber',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Required',
      },
    },
  ];

  const formConfig3: FormConfig = [
    {
      title: 'Name(s) of account holder(s):*',
      name: 'bankAccountName',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Name of the bank account',
      },
    },
  ];

  const formConfig4: FormConfig = [
    {
      title: 'Bank/building society account number:*',
      name: 'accountNumber',
      component: StyledInput,
      props: {
        disabled: !isEdit,
        placeholder: 'Account number',
      },
    },
  ];

  return (
    <Container onSubmit={formikInstance.handleSubmit}>
      <StyledButton onClick={() => setIsEdit(!isEdit)}>Edit Details</StyledButton>
      <Title>Your details</Title>
      {renderFormConfig(formConfig, formikInstance)}
      <FindYourAddress isDisabled={!isEdit} postCode={formikInstance.values.postcode} formikInstance={formikInstance} />
      {renderFormConfig(formConfig2, formikInstance)}
      <Title>Bank details</Title>
      {renderFormConfig(formConfig3, formikInstance)}
      <InputWrap
        title="Branch sort code"
        hasError={!!formikInstance.errors.branchSortCode1 || !!formikInstance.errors.branchSortCode2 || !!formikInstance.errors.branchSortCode3}
      >
        <BranchInputWrap>
          <BrachInput disabled={!isEdit} placeholder="XX" name="branchSortCode1" value={formikInstance.values.branchSortCode1} onChange={formikInstance.handleChange} />
          <Dot />
          <BrachInput disabled={!isEdit} placeholder="XX" name="branchSortCode2" value={formikInstance.values.branchSortCode2} onChange={formikInstance.handleChange} />
          <Dot />
          <BrachInput disabled={!isEdit} placeholder="XX" name="branchSortCode3" value={formikInstance.values.branchSortCode3} onChange={formikInstance.handleChange} />
        </BranchInputWrap>
      </InputWrap>
      {renderFormConfig(formConfig4, formikInstance)}
      <ConfirmContainer>
        <span style={{ fontWeight: 'bold', marginBottom: '6px' }}>By clicking on the «Confirm» button below:</span>
        <StyledUl>
          <li>You confirm that, if the above bank account is a personal account, the account is in your name</li>
          <li>You confirm that you are the only signatory required to authorise the Direct Debit</li>
        </StyledUl>
        <ErrorDiv hasError={!!formikInstance.errors?.agree}>
          {renderFormConfig([{
            title: 'I understand', name: 'agree', component: StyledCheckbox, props: { disabled: !isEdit }, containerStyle: { flexDirection: 'row-reverse', justifyContent: 'flex-end', margin: '0' },
          }], formikInstance)}
        </ErrorDiv>
      </ConfirmContainer>
      {renderFormConfig([
        {
          title: 'Confirm password',
          name: 'validatePassword',
          component: StyledInput,
          props: {
            disabled: !isEdit,
            type: 'password',
          },
        },
      ], formikInstance)}
      <SubmitButton>Confirm</SubmitButton>
    </Container>
  );
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 30px;
  padding-bottom: 100px;
  max-width: 720px;
`;

const Title = styled.h3`
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin: 38px 0 24px;
  color: #102F54;
`;

const StyledButton = styled(ButtonDark)`
  padding: 14px 50px;
  align-self: flex-start;
`;

const SubmitButton = styled(StyledButton)`
  align-self: flex-end;
  margin-top: 50px;
  padding: 14px 100px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const StyledPostcodeInput = styled(PostcodeInput)`
  width: 100%;
  max-width: 420px;

  &:disabled {
    background: #f2f2f2;
  }
`;

const BranchInputWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BrachInput = styled(Input).attrs({ maxLength: 2 })`
  width: 60px;
  font-size: 14px;
  padding: 20px;
  &:disabled {
    background: #f2f2f2;
  }
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 5px;
  box-sizing: border-box;
`;

const ConfirmContainer = styled.div`
  border-radius: 4px;
  padding: 18px;
  background-color: #E0E0E0;
  font-size: 14px;
  box-sizing: border-box;
  margin: 20px 0;
  max-width: 720px;
`;

const StyledUl = styled.ul`
  padding: 0 15px;
  margin: 0;
  margin-bottom: 10px;
  line-height: 17px;
`;

const ErrorDiv = styled.div<{ hasError?: boolean }>`
  box-shadow: ${(props) => (props.hasError ? '0 0 1px 1px red' : 'none')};
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  padding: 4px 4px 0 4px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 15px;
`;

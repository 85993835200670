import { DatePicker, Dropdown, Menu, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { RadioGroup, RadioItem } from '../../../../../ui/forms/Radio';
import { SecondHeader } from '../../../../../ui/Headers';

import { CAMPAIGN_STATUSES } from '../../../types';

const { RangePicker } = DatePicker;

const CampaignStatuses = ['All', ...Object.keys(CAMPAIGN_STATUSES)];

interface CampaignsActionsProps {
  onChangeFilter: (filter: keyof typeof CAMPAIGN_STATUSES | null) => void;
  onChangeTimePeriod: (period: [string, string]) => void;
  onChangeSubmitPlan: () => void;
  onChangeCancelFurtherCollection: () => void;
  onInformationCorrectionHandler: () => void;
}

export default function CampaignsActions({
  onChangeFilter,
  onChangeTimePeriod,
  onChangeSubmitPlan,
  onChangeCancelFurtherCollection,
  onInformationCorrectionHandler,
}: CampaignsActionsProps) {
  const [typeofCampaign, setTypeOfCampaign] =
    useState<typeof CampaignStatuses[number]>('All');

  const onFilterClickHandler = (status: string) => {
    if (status === 'All') {
      onChangeFilter(null);
    } else {
      onChangeFilter(status as keyof typeof CAMPAIGN_STATUSES);
    }
  };

  const dropDownContent = (
    <Menu>
      <Menu.Item onClick={onInformationCorrectionHandler}>
        Parent Information Correction
      </Menu.Item>
      <Menu.Item onClick={onChangeSubmitPlan}>Change Payment Plan</Menu.Item>
      <Menu.Item onClick={onChangeCancelFurtherCollection}>
        Cancel further collections
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <StyledSecondHeader>Collection Schemes</StyledSecondHeader>
      <Filter>
        <div>
          Status:
          <FilterGroup
            value={typeofCampaign}
            onChange={(e) => setTypeOfCampaign(e.target.value)}
          >
            {CampaignStatuses.map((status) => (
              <FilterItem
                key={status}
                onClick={() => onFilterClickHandler(status)}
                value={status}
              >
                {status}
              </FilterItem>
            ))}
          </FilterGroup>
        </div>
        {/*<RangePicker onChange={(_e, period) => onChangeTimePeriod(period)} />*/}
        <Dropdown overlay={dropDownContent}>
          <StyledLink
            className="ant-dropdown-link"
            onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.preventDefault()
            }
          >
            Actions <DownOutlined />
          </StyledLink>
        </Dropdown>
      </Filter>
    </div>
  );
}

const Filter = styled.div`
  margin-top: 10px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterGroup = styled(RadioGroup)`
  margin-left: 14px;
`;

const FilterItem = styled(RadioItem)`
  margin-left: 18px;
`;

const StyledLink: FC<any> = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2682de;
  cursor: pointer;
`;

const StyledSecondHeader = styled(SecondHeader)`
  margin: 40px 0 15px 0;
`;

import { KeyboardEvent } from 'react';
import styled from 'styled-components/macro';
import Input from '../../../ui/forms/Input';
import { MainHeader } from '../../../ui/Headers';

interface SettingsProps {
  onSaveCollectionFeeHandler: () => void;
  collectionFeeValue: string;
  setCollectionFeeValue: (str: string) => void;
  giftAidFeeValue: string;
  setGiftAidFee: (str: string) => void;
  onSaveGiftAidFeeHandler: () => void;
}

const invalidChars = ['-', '+', 'e'];

export default function Settings({
  onSaveCollectionFeeHandler, collectionFeeValue, setCollectionFeeValue, giftAidFeeValue, setGiftAidFee, onSaveGiftAidFeeHandler,
}: SettingsProps) {
  const inputOnChangeHandler = (value: string, setValue: (str: string) => void) => {
    setValue(value);
    if (+value > 100) {
      setValue('100');
    }
    if (value.length > 5) {
      setValue(value.slice(0, 5));
    }
    if (+value < 10 && value.length > 4) {
      setValue(value.slice(0, 4));
    }
  };
  const blockInvalidChars = (e: KeyboardEvent<HTMLInputElement>) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Wrap>
      <StyledMainHeader>Settings</StyledMainHeader>
      <SettingsBlock>
        <SettingTitle>Default Collection Fee</SettingTitle>
        <SettingsContent>
          <StyledInput
            placeholder="XX%"
            type="number"
            min={0.01}
            max={100}
            step={0.01}
            value={collectionFeeValue}
            onKeyDown={blockInvalidChars}
            onChange={(e) => inputOnChangeHandler(e.target.value, setCollectionFeeValue)}
          />
          <Save onClick={onSaveCollectionFeeHandler}>Save</Save>
        </SettingsContent>
      </SettingsBlock>
      <SettingsBlock>
        <SettingTitle>Default Gift Aid Fee</SettingTitle>
        <SettingsContent>
          <StyledInput type="number" min={0.01} max={100} step={0.01} placeholder="XX%" value={giftAidFeeValue} onKeyDown={blockInvalidChars} onChange={(e) => inputOnChangeHandler(e.target.value, setGiftAidFee)} />
          <Save onClick={onSaveGiftAidFeeHandler}>Save</Save>
        </SettingsContent>
      </SettingsBlock>
    </Wrap>
  );
}

const Wrap = styled.main`
  box-sizing: border-box;
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const StyledInput = styled(Input)`
  width: 195px;
  padding: 15px 0 15px 18px;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  margin-bottom: 52px;
`;

const SettingsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`;

const SettingsContent = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`;

const SettingTitle = styled.span`
  font-size: 16px;
`;

const Save = styled.span`
  font-size: 14px;
  color: #C4C4C4;
  font-weight: 750;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #000;
  }
`;

import { MouseEvent } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ButtonLight } from '../../../../ui/Button';
import ROUTER_CONFIG from '../../../../router/router-config';
import { SchoolAdminPublicType } from '../../../../project-types/admin-schools/type';

interface AdminSidebarProps {
  schoolsList: Array<SchoolAdminPublicType> | null;
  onSchoolClick: (schoolId: string) => void;
}

export default function AdminSidebar({ schoolsList, onSchoolClick }: AdminSidebarProps) {
  const { push } = useHistory();
  const onNewSchoolAccountClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    push(ROUTER_CONFIG.ADMIN_NEW_SCHOOL_ACCOUNT.getPath());
  };
  const { schoolId } = useParams<{ schoolId?: string }>();

  return (
    <Wrap>
      <MenuSection>
        <MenuPoint>
          <Link to={ROUTER_CONFIG.ADMIN_ALL_COLLECTIONS.getPath()}>
            All Collections
          </Link>
        </MenuPoint>
        <MenuPoint>
          <MenuPointInner>
            <MenuLinkWrap>
              <Link to={ROUTER_CONFIG.ADMIN_SCHOOLS.getPath()}>Schools</Link>
              <SchoolCounter>{schoolsList ? schoolsList.length : ''}</SchoolCounter>
            </MenuLinkWrap>
            {schoolsList && (
            <SchoolsList>
              {schoolsList.map((school) => <SchoolsListItem onClick={() => onSchoolClick(school._id)} selected={school._id === schoolId} key={school.schoolName}>{school.schoolName}</SchoolsListItem>)}
            </SchoolsList>
            )}
            <ButtonNewSchool onClick={onNewSchoolAccountClickHandler}>
              + New school account
            </ButtonNewSchool>
          </MenuPointInner>
        </MenuPoint>
        <MenuPoint>
          <Link to={ROUTER_CONFIG.ADMIN_DONORS.getPath()}>
            Donors
          </Link>
        </MenuPoint>
        <MenuPoint>
          <Link to={ROUTER_CONFIG.ADMIN_CHARITIES_OVERVIEW.getPath()}>
            Charities
          </Link>
        </MenuPoint>
        <MenuPoint>
          <Link to={ROUTER_CONFIG.ADMIN_PAYOUTS.getPath()}>
            Payouts
          </Link>
        </MenuPoint>
      </MenuSection>
      <MenuSection>
        <MenuPoint>
          <Link to={ROUTER_CONFIG.ADMIN_SETTINGS.getPath()}>
            Settings
          </Link>
        </MenuPoint>
        <MenuPoint>
          <Link to={ROUTER_CONFIG.ADMIN_DOCUMENTS.getPath()}>
            Documentation
          </Link>
        </MenuPoint>
      </MenuSection>
    </Wrap>
  );
}

const Wrap = styled.main`
  margin-left: -55px;
  margin-bottom: -36px;
`;

const MenuPoint = styled.div`
  font-weight: 450;
  font-size: 16px;
  margin-bottom: 36px;
`;

const MenuPointInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuSection = styled.div`
  padding-left: 55px;

  & + & {
    padding-top: 36px;
    border-top: 1px solid #E0E0E0;
  }
`;

const SchoolsList = styled.ul`
  list-style-type: none;
  margin: 17px 0 13px 27px;
`;

const SchoolsListItem = styled.li<{ selected?: boolean }>`
  font-weight: ${(props) => (props.selected ? '750' : 'normal')};
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 14px;
  :last-of-type {
    margin-bottom: 0px;
  }
`;

const MenuLinkWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const SchoolCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: 7px;
  border-radius: 50%;
  background-color: #C4C4C4;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
`;

const ButtonNewSchool = styled(ButtonLight)`
  width: 208px;
  height: 35px;
  flex-shrink: 0;
  font-weight: 600;
  font-size: 14px;
`;

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AdminLayout from '../layout/AdminLayout';
import SchoolsView from './SchoolsView';
import { selectSchoolsList, verifySchool, adminDeleteSchool } from '../../../feature/schoolsState';
import ROUTER_CONFIG from '../../../router/router-config';

export default function SchoolsPage() {
  const { push } = useHistory();
  const schoolsList = useSelector(selectSchoolsList);
  const dispatch = useDispatch();

  const onDeleteSchoolClickHandler = (schoolId: string) => dispatch(adminDeleteSchool(schoolId));
  const onCreateSchoolAccountClickHandler = () => push(ROUTER_CONFIG.ADMIN_NEW_SCHOOL_ACCOUNT.getPath());
  const onDeleteSchoolAccountClickHandler = (schoolId: string) => dispatch(adminDeleteSchool(schoolId));
  const onSchoolNameClickHandler = (schoolId: string) => push(ROUTER_CONFIG.ADMIN_SCHOOL_PAGE.getPath({ schoolId }));
  const onVerifySchoolClickHandler = (schoolId: string) => dispatch(verifySchool(schoolId));

  return (
    <AdminLayout>
      {schoolsList && <SchoolsView schoolsList={schoolsList} onDeleteSchoolClickHandler={onDeleteSchoolClickHandler} onVerifySchoolClickHandler={onVerifySchoolClickHandler} onDeleteSchoolAccountClickHandler={onDeleteSchoolAccountClickHandler} onCreateSchoolAccountClickHandler={onCreateSchoolAccountClickHandler} onSchoolNameClickHandler={onSchoolNameClickHandler} />}
    </AdminLayout>
  );
}

import { Radio } from "antd";
import styled from "styled-components/macro";

export const RadioGroup = styled(Radio.Group)`
  & .ant-radio-inner {
    box-sizing: border-box;
  }
`;


export const RadioItem = styled(Radio)``;

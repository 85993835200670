import styled from 'styled-components/macro';
import {
  Select, Dropdown, Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { SecondHeader } from '../../../../ui/Headers';
import { CampaignStatuses } from '../../types';

const { Option } = Select;

interface CampaignsViewProps {
  statuses: Array<string>;
  onChangeFilter: (status: CampaignStatuses) => void;
  onFreezeClickHandler: () => void;
  onUnFreezeClickHandler: () => void;
  onDeleteClickHandler: () => void;
  onAddNewCampaignClickHandler: () => void;
}

export default function CampaignsView({
  statuses, onChangeFilter, onDeleteClickHandler, onFreezeClickHandler, onUnFreezeClickHandler, onAddNewCampaignClickHandler,
}: CampaignsViewProps) {
  const dropDownContent = (
    <Menu>
      <Menu.Item onClick={onFreezeClickHandler}>
        Freeze Campaign(s)
      </Menu.Item>
      <Menu.Item onClick={onUnFreezeClickHandler}>
        Unfreeze Campaign(s)
      </Menu.Item>
      <Menu.Item onClick={onDeleteClickHandler}>
        Delete Campaign(s)
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <StyledSecondHeader>Campaign Settings</StyledSecondHeader>
      <Controls>
        <SelectStyled placeholder="Active" onChange={(value) => onChangeFilter(value as CampaignStatuses)}>
          {statuses.map((status) => <Option key={status} value={status}>{status}</Option>)}
        </SelectStyled>
        <ControlsBlock>
          <StyledSpan onClick={onAddNewCampaignClickHandler}>+ Add new campaign</StyledSpan>
          <Dropdown overlay={dropDownContent}>
            <StyledLink className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Actions
              {' '}
              <DownOutlined />
            </StyledLink>
          </Dropdown>
        </ControlsBlock>
      </Controls>
    </div>
  );
}

const StyledSecondHeader = styled(SecondHeader)`
  font-size: 18px;
  line-height: 20px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 15px;
`;

const ControlsBlock = styled.div`
  display: flex;
  gap: 36px;
`;

const StyledSpan = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 750;
  cursor: pointer;
  margin: 0;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`;

const SelectStyled = styled(Select)`
  display: flex;
  width: 200px;
  /* flex-grow: 2; */
  &:before {
    content: "";
    /* width: 100%; */
  }
`;

const StyledLink = styled.a`
  font-size: 16px;
  line-height: 18px;
  font-weight: 750;
  padding: 0;
  margin: 0;
`;

import styled from 'styled-components/macro';
import { useState, useEffect } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SecondHeader } from '../../../ui/Headers';
import Input from '../../../ui/forms/Input';
import { VerificationTableRow } from '../types';
import SearchIcon from '../../../img/icons/icon_search.svg';

interface VerificationTableProps {
  tableData: Array<VerificationTableRow>;
  onVerifySchoolClickHandler: (schoolId: string) => void;
  onDeleteSchoolClickHandler: (schoolId: string) => void;
}

export default function VerificationsTable({ tableData, onVerifySchoolClickHandler, onDeleteSchoolClickHandler }: VerificationTableProps) {
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredTableData(tableData.filter((row) => row.schoolName.toLowerCase().includes(searchValue.toLowerCase())));
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue, tableData]);

  const tableConfig: ColumnsType<VerificationTableRow> = [
    {
      title: 'School Name',
      key: 'schoolName',
      dataIndex: 'schoolName',
    },
    {
      title: 'Date of registration',
      key: 'dateOfRegistration',
      dataIndex: 'dateOfRegistration',
    },
    {
      title: '',
      key: 'verify',
      dataIndex: 'verify',
      render: (value, record) => <VerifySpan onClick={() => onVerifySchoolClickHandler(record.schoolId)}>{value}</VerifySpan>,
    },
    {
      title: '',
      key: 'delete',
      dataIndex: 'delete',
      render: (value, record) => <DeleteSpan onClick={() => onDeleteSchoolClickHandler(record.schoolId)}>{value}</DeleteSpan>,
    },
  ];

  return (
    <div>
      <StyledSecondHeader>
        Waiting for verification (
        {tableData.length}
        )
      </StyledSecondHeader>
      <StyledInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search" iconSource={SearchIcon} />
      <TableContainer>
        <Table<VerificationTableRow> pagination={false} dataSource={filteredTableData} columns={tableConfig} />
      </TableContainer>
    </div>
  );
}

const StyledSecondHeader = styled(SecondHeader)`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 32px;
`;

const TableContainer = styled.div`
  box-sizing: border-box;
  height: 260px;
  overflow-y: scroll;
`;

const StyledInput = styled(Input)<{ iconSource: string }>`
  padding: 15px 15px 15px 48px;
  width: 420px;
  margin-bottom: 15px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 17px center;
`;

const VerifySpan = styled.span`
  font-weight: 750;
  cursor: pointer;
`;

const DeleteSpan = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

import styled from 'styled-components/macro';
import { Dispatch, SetStateAction, MouseEvent } from 'react';
import Input from '../../../../ui/forms/Input';
import Checkbox from '../../../../ui/forms/Checkbox';
import { ButtonDark, ButtonLightGray } from '../../../../ui/Button';
import { FormConfig, renderFormConfig } from '../../../../utils/form';

import PersonIcon from '../../../../img/icons/person-icon.svg';

interface DonorPersonalDetailsFormProps {
  formikInstance: any;
  onButtonClickHandler: Dispatch<SetStateAction<number>>;
  onBackDetailsClick: () => void;
  onLoginClickHandler: () => void;
}

export default function DonorPersonalDetailsForm({
  formikInstance, onButtonClickHandler, onBackDetailsClick, onLoginClickHandler,
}: DonorPersonalDetailsFormProps) {
  const ValidateForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const errors = await formikInstance.validateForm();
    if (Object.values(errors).length === 0) {
      onButtonClickHandler(2);
    }
  };

  return (
    <>
      <TitleBlock>
        <SubTitle>Create Your Parent Account</SubTitle>
        <CustomLink>
          <Text>Already have an account?</Text>
          <LinkText onClick={onLoginClickHandler}>Login</LinkText>
        </CustomLink>
      </TitleBlock>
      <FormWrapper>
        <FormIcon alt="" src={PersonIcon} />
        <FormContent>
          {renderFormConfig(formConfig, formikInstance)}
        </FormContent>
      </FormWrapper>
      <ButtonsContainer>
        <StyledGrayButton onClick={(e) => {
          e.preventDefault();
          onBackDetailsClick();
        }}
        >
          Back

        </StyledGrayButton>
        <StyledDarkButton onClick={ValidateForm}>
          Next

        </StyledDarkButton>
      </ButtonsContainer>
    </>
  );
}

const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const SubTitle = styled.h2`
  font-weight: 750;
  font-size: 20px;
  line-height: 24px;
  color: #102F54;
`;

const CustomLink = styled.div`
  display: flex;
  gap: 4px;
`;

const FormWrapper = styled.div`
  padding: 28px;
  background-color: rgba(245, 248, 251, 0.7);
  display: flex;
  gap: 24px;
`;

const FormContent = styled.div`
  flex: 1;
`;

const FormIcon = styled.img`
  width: 22px;
  height: 22px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #102F54;
`;

const LinkText = styled(Text)`
  color: #2682DE;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 420px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 8px;
  margin-right: 15px;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 100px;
`;

const StyledDarkButton = styled(ButtonDark)`
  padding: 18px 95px 14px;
`;

const StyledGrayButton = styled(ButtonLightGray)`
  padding: 18px 65px 14px;
`;

const formConfig: FormConfig = [
  {
    title: 'First Name',
    name: 'firstName',
    isRequired: true,
    component: StyledInput,
  },
  {
    title: 'Last Name',
    name: 'lastName',
    isRequired: true,
    component: StyledInput,
  },
  {
    title: 'Register as a corporation',
    name: 'isCorporation',
    component: StyledCheckbox,
    containerStyle: { flexDirection: 'row-reverse', justifyContent: 'flex-end' },
  },
  {
    title: 'Email',
    name: 'email',
    isRequired: true,
    component: StyledInput,
  },
  {
    title: 'Password',
    name: 'password',
    isRequired: true,
    component: StyledInput,
    props: {
      type: 'password',
    },
  },
  {
    title: 'Confirm Password',
    name: 'confirmPassword',
    isRequired: true,
    component: StyledInput,
    props: {
      type: 'password',
    },
  },
];

import styled from "styled-components/macro"; // Импортим именно /macro (для читаемых имен классов)
import DonorLogo from "../../../../img/DonorLogo.png";
import ContentImg from "../../../../img/ContentImg.png";


// Комопонент - функция
export default function Finish () {
  let value = 1;
  return (
    <>
      <Content>
        <Container>
          <Logo src={DonorLogo} />
          <Img src={ContentImg} />
        </Container>
      </Content>
    </>
  );
}

const Content = styled.main`
  width: 100%;
  border-top: 1px solid #E0E0E0;
  padding-top: 123px;
  padding-bottom: 200px;
`;


const Container = styled.div`
  width: 100%;
  max-width: 1242px;
  margin: 0px auto;
`;


const Logo = styled.img`
  display: block;
  margin: 0px auto;
`;

const Img = styled.img`
  display: block;
  margin: 0px auto;
`;



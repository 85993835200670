import { DatePicker } from 'antd';
import styled from 'styled-components/macro';
import { APPROVAL_STATUSES } from '../../types';
import { RadioGroup, RadioItem } from '../../../../ui/forms/Radio';

const { RangePicker } = DatePicker;

interface CollectionReportProps {
  onChangeFilter: (filter: keyof typeof APPROVAL_STATUSES) => void;
  onChangeTimePeriod: (period: [string, string]) => void;
  currentFilter: keyof typeof APPROVAL_STATUSES;
}

export default function CollectionReport({ onChangeFilter, onChangeTimePeriod, currentFilter }: CollectionReportProps) {
  return (
    <>
      <Filter>
        <div>
          Collection status:
          <FilterGroup value={currentFilter} onChange={(e) => onChangeFilter(e.target.value)}>
            {Object.keys(APPROVAL_STATUSES).map((status) => (
              <FilterItem key={status} value={status}>{status}</FilterItem>
            ))}
          </FilterGroup>
        </div>
        <RangePicker onChange={(_e, period) => onChangeTimePeriod(period)} picker="month" />
      </Filter>
    </>
  );
}

const Filter = styled.div`
  margin-top: 41px;
  margin-top: 31px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterGroup = styled(RadioGroup)`
  margin-left: 14px;
`;

const FilterItem = styled(RadioItem)`
  margin-left: 18px;
`;

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AdminLayout from '../layout/AdminLayout';
import AllCollections from './AllCollections';
import {
  getAdminCollections, selectAdminCollections,
} from '../../../feature/adminState';
import { AdminPaymentsFiltersAndOrderType } from '../../../project-types/admin-payment/types';
import { formatDateString } from '../../../utils/formats';
import { COLLECTION_STATUSES } from '../../school/types';

export default function AllCollectionsPage() {
  const [monthNum, setMonthNum] = useState(new Date().getMonth() + 1);
  const [donorSearchValue, setDonorSearchValue] = useState('');
  const [schoolSearchValue, setSchoolSearchValue] = useState('');
  const [campaignSearchValue, setCampaignSearchValue] = useState('');
  const [dateSearchValue, setDateSearchValue] = useState('');
  const [statusSearchValue, setStatusSearchValue] = useState<Array<string> | null>(null);

  const dispatch = useDispatch();
  const adminCollections = useSelector(selectAdminCollections);

  useEffect(() => {
    const collectionStart = formatDateString(`${new Date().getFullYear()}-${monthNum}`);
    const collectionEnd = formatDateString(`${new Date().getFullYear()}-${monthNum}`, true);
    // const statuses = statusSearchValue ? statusSearchValue.map((status) => COLLECTION_STATUSES[status as keyof typeof COLLECTION_STATUSES]) : undefined;

    // TODO add ordering
    const filters: AdminPaymentsFiltersAndOrderType = {
      collectionStart,
      collectionEnd,
      // status: statuses,
      // postedDate: dateSearchValue ? new Date(dateSearchValue).toISOString() : undefined,
      // campaign: campaignSearchValue || undefined,
      // school: schoolSearchValue || undefined,
      // donor: donorSearchValue || undefined,
    };

    dispatch(getAdminCollections(filters));
  }, [dispatch, monthNum]);

  return (
    <AdminLayout>
      <AllCollections
        donorSearch={donorSearchValue}
        onChangeDonorSearch={setDonorSearchValue}
        schoolSearch={schoolSearchValue}
        onChangeSchoolSearch={setSchoolSearchValue}
        campaignSearch={campaignSearchValue}
        onChangeCampaignSearch={setCampaignSearchValue}
        dateSearch={dateSearchValue}
        onChangeDateSearch={setDateSearchValue}
        onChangeStatusSearch={setStatusSearchValue}
        onMonthChange={setMonthNum}
        currentMonth={monthNum}
        adminCollections={adminCollections.payments}
        adminCollectionsStatistics={adminCollections.stats}
      />
    </AdminLayout>
  );
}

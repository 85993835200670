import {
  FieldWrap, FieldName, FieldInput, FieldInputText,
} from '../LayoutComponents';

interface NameOfAccountHolderProps {
  firstName: string;
  lastName: string;
}

export function NameOfAccountHolder({ firstName, lastName }: NameOfAccountHolderProps) {
  return (
    <FieldWrap>
      <FieldName>Name of Account Holder</FieldName>
      <FieldInput>
        <FieldInputText>
          <span style={{ display: 'block' }}>
            {firstName}
            {' '}
            {lastName}
          </span>
        </FieldInputText>
      </FieldInput>
    </FieldWrap>
  );
}

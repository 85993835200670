import styled from 'styled-components/macro';
import Input from '../../../../ui/forms/Input';
import { FormConfig, renderFormConfig } from '../../../../utils/form';

const StyledInput = styled(Input)`
  width: 100%;
  max-width: 630px;
`;

const formConfig1: FormConfig = [
  {
    title: 'Charity Name',
    name: 'charityName',
    component: StyledInput,
  },
  {
    title: 'ID',
    name: 'id',
    component: StyledInput,
  },
  {
    title: 'Reg No.',
    name: 'regNumber',
    component: StyledInput,
  },
  {
    title: 'Name Of Regulator',
    name: 'nameOfRegulator',
    component: StyledInput,
  },
];

const formConfig2: FormConfig = [
  {
    title: 'Title',
    name: 'title',
    component: StyledInput,
  },
  {
    title: 'First Name',
    name: 'firstName',
    component: StyledInput,
  },
  {
    title: 'Last Name',
    name: 'lastName',
    component: StyledInput,
  },
  {
    title: 'Email',
    name: 'email',
    component: StyledInput,
  },
  {
    title: 'Phone Number',
    name: 'phoneNumber',
    component: StyledInput,
  },
  {
    title: 'Postcode',
    name: 'postcode',
    component: StyledInput,
  },
];

interface CreateCharityFormProps {
  formikInstance: any;
}

export default function CharityForm({ formikInstance }: CreateCharityFormProps) {
  return (
    <>
      {renderFormConfig(formConfig1, formikInstance)}
      <SubTitle>Authorised Official</SubTitle>
      {renderFormConfig(formConfig2, formikInstance)}
    </>
  );
}

const SubTitle = styled.h3`
  font-size: 16px;
  line-height: 22px;
  margin: 80px 0 24px;
  font-weight: bold;
`;

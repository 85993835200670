import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CharityTableRow } from '../../types';

interface CharityTableProps {
  tableData: Array<CharityTableRow>;
}

const tableConfig: ColumnsType<CharityTableRow> = [
  {
    title: 'Donation Date',
    dataIndex: 'donationDate',
    key: 'donationDate',
  },
  {
    title: 'Contributor Name',
    dataIndex: 'donorName',
    key: 'donorName',
  },
  {
    title: 'School',
    dataIndex: 'schoolName',
    key: 'schoolName',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Gift Aid',
    dataIndex: 'giftAid',
    key: 'giftAid',
  },
  {
    title: 'Approval Request Sent',
    dataIndex: 'approvalRequestSent',
    key: 'approvalRequestSent',
  },
  {
    title: 'Approval Default Date',
    dataIndex: 'approvalDefaultDate',
    key: 'approvalDefaultDate',
  },
];

export default function CharityTable({ tableData }: CharityTableProps) {
  return (
    <Table<CharityTableRow> dataSource={tableData} columns={tableConfig} />
  );
}

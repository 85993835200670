import styled from 'styled-components/macro';
import { ButtonDark } from '../../../ui/Button';

import FirstStepIcon from '../../../img/icons/1_step.svg';
import SecondStepIcon from '../../../img/icons/2_step.svg';
import ThirdStepIcon from '../../../img/icons/3_step.svg';

interface RegisterDeviceTradeinViewProps {
  onRegisterDeviceClickHandler: () => void;
}

export default function RegisterDeviceTradeinView({ onRegisterDeviceClickHandler }: RegisterDeviceTradeinViewProps) {
  return (
    <Container>
      <FirstBlock>
        <BlueText>A request from Mona Vale School</BlueText>
        <Title>Do you have any unwanted devices?</Title>
        <Text>Help contribute to the cost of your school’s 1:1 device programme by sustainably recycling your unwanted devices with devices for education trade-in.</Text>
        <StyledButton onClick={onRegisterDeviceClickHandler}>Register Device for Trade-in</StyledButton>
      </FirstBlock>
      <HowItWorksBlock>
        <GreenTitle>how it works</GreenTitle>
        <StepItems>
          <StepItem>
            <IconContainer alt="" src={FirstStepIcon} />
            <BoldBlueText>Generate your instant trade-in valuation</BoldBlueText>
          </StepItem>
          <StepItem>
            <IconContainer alt="" src={SecondStepIcon} />
            <BoldBlueText>Drop off the devices at your school</BoldBlueText>
          </StepItem>
          <StepItem>
            <IconContainer alt="" src={ThirdStepIcon} />
            <BoldBlueText>Credit generated for your School’s 1-1 laptop program</BoldBlueText>
          </StepItem>
        </StepItems>
      </HowItWorksBlock>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 65px;
  padding-top: 80px;
  padding-bottom: 50px;
`;

const Title = styled.h2`
  font-size: 30px;
  margin: 0;
  font-weight: bold;
  color: #102F54;
`;

const GreenTitle = styled(Title)`
  font-size: 21px;
  color: #10C08A;
  text-transform: uppercase;
`;

const Text = styled.p`
  font-size: 20px;
  margin: 0;
  line-height: 20px;
  color: #909DA8;
`;

const BlueText = styled(Text)`
  color: #2682DE;
`;

const BoldBlueText = styled(BlueText)`
  font-weight: bold;
  font-size: 16px;
`;

const StyledButton = styled(ButtonDark)`
  padding: 12px 50px;
  margin-top: 40px;
  align-self: flex-start;
`;

const FirstBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 490px;
`;

const HowItWorksBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  justify-content: center;
  /* align-items: center; */
  filter: drop-shadow(0px 2px 10px rgba(138, 162, 178, 0.272));
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 180px 36px;
`;

const StepItems = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17px;
  max-width: 200px;
`;

const IconContainer = styled.img`
  width: 100px;
  height: 100px;
`;

import styled, { CSSProperties } from 'styled-components/macro';
import { ReactElement } from 'react';

import IconDirection from '../../../img/icons/icon_direction_blue.svg';
import DirectionArrowIcon from '../../../img/icons/direction-arrow-icon.svg';

type DateConfigKey = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

const StyleddDate = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
  width: 160px;
`;

const getDateComp = (start: string, end: string) => (
  <StyleddDate>
    <span style={{ fontWeight: 'bold' }}>{start}</span>
    <img alt="" src={DirectionArrowIcon} />
    <span style={{ fontWeight: 'bold' }}>{end}</span>
  </StyleddDate>
);

const DateConfig: { [key in DateConfigKey]: ReactElement | string } = {
  1: getDateComp('Jan 1', 'Feb 1'),
  2: getDateComp('Feb 1', 'Mar 1'),
  3: getDateComp('Mar 1', 'Apr 1'),
  4: getDateComp('Apr 1', 'May 1'),
  5: getDateComp('May 1', 'Jun 1'),
  6: getDateComp('Jun 1', 'Jul 1'),
  7: getDateComp('Jul 1', 'Aug 1'),
  8: getDateComp('Aug 1', 'Sep 1'),
  9: getDateComp('Sep 1', 'Oct 1'),
  10: getDateComp('Oct 1', 'Nov 1'),
  11: getDateComp('Nov 1', 'Dec 1'),
  12: getDateComp('Dec 1', 'Jan 1'),
};

interface CollectionPeriodComponentProps {
  monthNum: number;
  onIncDateRange: () => void;
  onDecrDateRange: () => void;
  styles?: CSSProperties;
}

export default function CollectionPeriodComponent({
  monthNum, onIncDateRange, onDecrDateRange, styles = {},
}: CollectionPeriodComponentProps) {
  return (
    <Container style={styles}>
      <StyledMainHeader>Collection period:</StyledMainHeader>
      <PickDataContainer>
        <ArrowButtonLeft onClick={onDecrDateRange} arrowIcon={IconDirection} />
        {DateConfig[`${monthNum}` as DateConfigKey]}
        <ArrowButtonRight onClick={onIncDateRange} arrowIcon={IconDirection} />
      </PickDataContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
`;

const StyledMainHeader = styled.h3`
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  font-weight: bold;
`;

const PickDataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ArrowButtonLeft = styled.button<{ arrowIcon: string; }>`
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  width: 21px;
  height: 21px;
  border-radius: 4px;
  background-image: ${(props) => `url(${props.arrowIcon})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
`;

const ArrowButtonRight = styled(ArrowButtonLeft)`
  transform: rotate(180deg);
`;

const StyledDate = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 115px;
  text-align: center;
  white-space: nowrap;
`;

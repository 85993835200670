import * as yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { FreezeDonorsType } from '../campaign/types';
import { BankInfoType } from './types';

export const billingSchemaFieldsRequiered = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup.string().required(),
  postcode: yup.string().required(),
  address1: yup.string().required(),
  address2: yup.string().optional(),
  address3: yup.string().optional(),
  town: yup.string().required(),
  country: yup.string().required(),
});

// let result: Partial<Record<keyof typeof billingSchemaFields, RequiredStringSchema<string>>> = {};
// const a = Object.keys(billingSchemaFields).reduce((acc, key) => {
//   const schema = billingSchemaFields[key as keyof typeof billingSchemaFields];
//   acc[key as keyof typeof billingSchemaFields] = schema.required();
//   return acc;
// }, result);

export const billingSchemaFieldsOptional = yup.object({
  firstName: yup.string().optional(),
  lastName: yup.string().optional(),
  billingName: yup.string().optional(),
  phoneNumber: yup.string().optional(),
  postcode: yup.string().optional(),
  address1: yup.string().optional(),
  address2: yup.string().optional(),
  address3: yup.string().optional(),
  town: yup.string().optional(),
  country: yup.string().optional(),
  county: yup.string().optional(),
  bankUnhookException: yup.boolean().optional(),
  sortCode: yup.string().optional(),
});

export const bankInfoSchema: yup.SchemaOf<Partial<BankInfoType>> = yup.object({
  bankAccountName: yup.string().optional(),
  branchSortCode1: yup.string().optional(),
  branchSortCode2: yup.string().optional(),
  branchSortCode3: yup.string().optional(),
  sortCode: yup.string().optional(),
  accountNumber: yup.string().optional(),
});

export const freezeRequestSchema: yup.SchemaOf<FreezeDonorsType> = yup.object({
  donors: yup.array().of(yup.mixed()),
  toFreeze: yup.boolean().required(),
});

import DonorAccountLayout from '../../layout/DonorAccountLayout';
import RegisterDeviceTradeinForm from './RegisterDeviceTradeinForm';

export default function RegisterDeviceTradeinFormPage() {
  const onFormSubmit = (values: any) => console.log(values);

  return (
    <DonorAccountLayout>
      <RegisterDeviceTradeinForm onFormSubmit={onFormSubmit} />
    </DonorAccountLayout>
  );
}

import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { getUserProfile } from '../../../feature/userState';
import { MainHeader } from '../../../ui/Headers';
import { SchoolFormUpdateType } from '../types';
import UpdateSchoolForm from './UpdateSchoolForm';

interface SchoolSettingsProps {
  onUpdate: (school: SchoolFormUpdateType) => void;
  onChangePasswordClickHandler: () => void;
}

export default function SchoolSettings({ onUpdate, onChangePasswordClickHandler }: SchoolSettingsProps) {
  const profile = useSelector(getUserProfile);

  if (!(profile && profile.user && profile.schoolProfile)) {
    return (<></>);
  }
  const { user: { email, ...userRows }, schoolProfile } = profile;

  const formFields: SchoolFormUpdateType = {
    ...userRows,
    ...schoolProfile,
  };

  return (
    <Wrap>
      <MainHeader>Account Details</MainHeader>
      <div>For any queries contact our helpdesk on 020 8064 0380</div>
      <UpdateSchoolForm profile={formFields} onSignUp={onUpdate} externalErrors={{}} onChangePasswordClickHandler={onChangePasswordClickHandler} />
    </Wrap>
  );
}

const Wrap = styled.main`
  width: 100%;
  margin-bottom: 100px;
  position: relative;
`;

import styled, {keyframes} from 'styled-components/macro';


export default function LoadingAuth() {


    return (
            <div style={{marginTop: '10px', marginBottom: '-25px'}}>
                <AnimText>A</AnimText>
                <AnimText>u</AnimText>
                <AnimText>t</AnimText>
                <AnimText>h</AnimText>
                <AnimText>e</AnimText>
                <AnimText>n</AnimText>
                <AnimText>t</AnimText>
                <AnimText>i</AnimText>
                <AnimText>c</AnimText>
                <AnimText>a</AnimText>
                <AnimText>t</AnimText>
                <AnimText>i</AnimText>
                <AnimText>n</AnimText>
                <AnimText>g</AnimText>
                <AnimText>.</AnimText>
                <AnimText>.</AnimText>
                <AnimText>.</AnimText>
                <AnimText>t</AnimText>
                <AnimText>h</AnimText>
                <AnimText>i</AnimText>
                <AnimText>s</AnimText>
                <AnimText>&nbsp;</AnimText>
                <AnimText>m</AnimText>
                <AnimText>a</AnimText>
                <AnimText>y</AnimText>
                <AnimText>&nbsp;</AnimText>
                <AnimText>t</AnimText>
                <AnimText>a</AnimText>
                <AnimText>k</AnimText>
                <AnimText>e</AnimText>
                <AnimText>&nbsp;</AnimText>
                <AnimText>s</AnimText>
                <AnimText>e</AnimText>
                <AnimText>v</AnimText>
                <AnimText>e</AnimText>
                <AnimText>r</AnimText>
                <AnimText>a</AnimText>
                <AnimText>l</AnimText>
                <AnimText>&nbsp;</AnimText>
                <AnimText>s</AnimText>
                <AnimText>e</AnimText>
                <AnimText>c</AnimText>
                <AnimText>o</AnimText>
                <AnimText>n</AnimText>
                <AnimText>d</AnimText>
                <AnimText>s</AnimText>
            </div>

    );
}



const flip = keyframes`
 0%,
      80% {
        transform: rotateY(360deg);
      }
`

const AnimText = styled.span`
  position: relative;
      display: inline-block;
      color: rgb(0, 0, 0);
      animation: ${flip} 3s infinite;
      animation-delay: calc(0.2s);
`;


import styled from 'styled-components/macro';
import { ButtonDark } from '../../../ui/Button';

interface ChangePasswordComponentProps {
  onChangePasswordClickHandler: () => void;
}

export default function ChangePasswordComponent({ onChangePasswordClickHandler }: ChangePasswordComponentProps) {
  return (
    <Container>
      <StyledSpan>Password</StyledSpan>
      <StyledButtonDark onClick={onChangePasswordClickHandler}>Change Password</StyledButtonDark>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const StyledSpan = styled.span`
  font-size: 14px;
  margin-bottom: 13px;
  font-weight: bold;
`;

const StyledButtonDark = styled(ButtonDark)`
  /* background-color: #fff; */
  padding: 13px 50px;
  align-self: flex-start;
  /* border: 1px solid #D6D6D6; */
`;

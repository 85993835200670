import { useState } from 'react';
import styled from 'styled-components/macro';
import { MainHeader } from '../../../ui/Headers';
import { ButtonDark } from '../../../ui/Button';
import EditDetailsForm from './EditDetailsForm';
import { DonorFields, UpdateDonorFields } from '../../../project-types/donor/types';

interface BillingInformationProps {
  profile: DonorFields;
  onFormSubmit: (formData: UpdateDonorFields) => void;
  email: string;
}

export default function BillingInformation({ profile, email, onFormSubmit }: BillingInformationProps) {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <Wrap>
      <StyledMainHeader>Billing information</StyledMainHeader>
      <StyledSpan>
        For any queries contact our helpdesk on 0808 196 4774
      </StyledSpan>
      <ButtonContainer>
        <StyledDarkButton onClick={() => setIsEdit(!isEdit)}>Edit Details</StyledDarkButton>
      </ButtonContainer>
      <EditDetailsForm onFormSubmit={onFormSubmit} email={email} profile={profile} isEdit={isEdit} />
    </Wrap>
  );
}

const Wrap = styled.main`
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 710px;
`;

const StyledMainHeader = styled(MainHeader)``;

const StyledSpan = styled.span``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledDarkButton = styled(ButtonDark)`
  width: 175px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 20px;
`;

import styled from 'styled-components';
import { MainHeader } from '../../../../ui/Headers';
import BackIconImg from '../../../../img/icons/back.svg';
import FundraisingReports from './data/FundraisingReports';
import FundraisingTable from './data/FundraisingTable';
import { TableRow } from './FundraisingOverviewPage';

interface FundraisingOverviewProps {
  onBack: () => void;
  tableRows: Array<TableRow>;
  onChangeTimePeriod: (period: [string, string]) => void;
}

export default function FundraisingOverview({ tableRows, onBack, onChangeTimePeriod }: FundraisingOverviewProps) {
  return (
    <Wrap>
      <GoBack onClick={onBack}>
        <Info>
          <BackIcon />
          Go back to Dashboard
        </Info>
      </GoBack>
      <MainHeader>Fundraising Overview</MainHeader>
      <div>View donations statistics for all Campaigns</div>
      <FundraisingReports onChangeTimePeriod={onChangeTimePeriod} />
      <FundraisingTable tableRows={tableRows} />
    </Wrap>
  );
}

const Wrap = styled.main`
  margin-left: 6px;
`;

const Info = styled.div`
  font-size: 16px;
  line-height: 26px;
  display: flex;
`;

const BackIcon = styled.img.attrs({
  src: BackIconImg,
})`
  margin-right: 11px;
`;

const GoBack = styled.div`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 54px;
  cursor: pointer;
`;

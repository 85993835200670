import styled from 'styled-components/macro';
import { useState, useEffect } from 'react';
import {
  DatePicker, Table, Input,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AllCollectionsTableRow } from '../types';
import { COLLECTION_STATUSES } from '../../school/types';
import IconSearch from '../../../img/icons/icon_search.svg';

interface CollectionsTableProps {
  tableRows: Array<AllCollectionsTableRow>;
  donorSearch: string;
  onChangeDonorSearch: (donor: string) => void;
  schoolSearch: string;
  onChangeSchoolSearch: (school: string) => void;
  campaignSearch: string;
  onChangeCampaignSearch: (campaign: string) => void;
  dateSearch: string;
  onChangeDateSearch: (date: string) => void;
  onChangeStatusSearch: (status: Array<any> | null) => void;
}

export default function CollectionsTable({
  tableRows, donorSearch, onChangeDonorSearch, schoolSearch, onChangeSchoolSearch, campaignSearch, onChangeCampaignSearch, dateSearch, onChangeDateSearch, onChangeStatusSearch,
}: CollectionsTableProps) {
  const [filteredTableData, setFilteredTableData] = useState(tableRows);

  useEffect(() => {
    setFilteredTableData(tableRows.filter((row) => row.donor.toLowerCase().includes(donorSearch.toLowerCase()) && row.school.toLowerCase().includes(schoolSearch.toLowerCase()) && row.campaignId.toLowerCase().includes(campaignSearch.toLowerCase()) && row.date.includes(dateSearch)));
  }, [donorSearch, schoolSearch, campaignSearch, dateSearch, tableRows]);

  const TableDateColumnCell = (
    <TableColumnCell>
      Date
      <DatePicker onChange={(value, dateString) => onChangeDateSearch(dateString)} format="DD MMM YYYY" />
    </TableColumnCell>
  );
  const TableDonorColumnCell = (
    <TableColumnCell>
      Donor
      <Input value={donorSearch} onChange={(e) => onChangeDonorSearch(e.target.value)} prefix={<InputPrefix iconSource={IconSearch} />} />
    </TableColumnCell>
  );
  const TableSchoolColumnCell = (
    <TableColumnCell>
      School
      <Input value={schoolSearch} onChange={(e) => onChangeSchoolSearch(e.target.value)} prefix={<InputPrefix iconSource={IconSearch} />} />
    </TableColumnCell>
  );
  const TableCampaignIdColumnCell = (
    <TableColumnCell>
      Campaign ID
      <Input value={campaignSearch} onChange={(e) => onChangeCampaignSearch(e.target.value)} prefix={<InputPrefix iconSource={IconSearch} />} />
    </TableColumnCell>
  );
  const StatusFilterOptions = Object.keys(COLLECTION_STATUSES).map((status) => ({ text: status, value: status }));

  const tableConfig: ColumnsType<AllCollectionsTableRow> = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: StatusFilterOptions,
      // todo remove it
      onFilter: (value, record) => record.status.indexOf(COLLECTION_STATUSES[value as keyof typeof COLLECTION_STATUSES]) === 0,
      render: (text) => text[0] + text.slice(1).toLowerCase(),
    },
    {
      title: TableDateColumnCell,
      dataIndex: 'date',
      key: 'date',
      render: (text) => <DateSpan>{text}</DateSpan>,
    },
    {
      title: TableDonorColumnCell,
      dataIndex: 'donor',
      key: 'donor',
    },
    {
      title: TableSchoolColumnCell,
      dataIndex: 'school',
      key: 'school',
    },
    {
      title: TableCampaignIdColumnCell,
      dataIndex: 'campaignId',
      key: 'campaignId',
    },
    {
      title: 'Total amount',
      dataIndex: 'ammount',
      key: 'ammount',
    },
    {
      title: 'Gift Aid (y/n)',
      dataIndex: 'giftAid',
      key: 'giftAid',
    },
    {
      title: 'Gift Aid Amount',
      dataIndex: 'giftAidAmount',
      key: 'giftAidAmount',
    },
  ];

  return (
    <Table<AllCollectionsTableRow> onChange={(_p, filters) => onChangeStatusSearch(filters.status)} columns={tableConfig} dataSource={filteredTableData} />
  );
}

const TableColumnCell = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const DateSpan = styled.span`
  white-space: nowrap;
`;

const InputPrefix = styled.div<{ iconSource: string }>`
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: center center;
`;

import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ManageTableModel } from '../../../school/types';
import OverviewManageTable from './OverviewManageTable';
import {
  confirmPreAuth,
  loadCampaignScheme,
  rejectPreAuth,
  selectManageDonors,
} from '../../../../feature/campaignState';
import { formatDateDDMMYY } from '../../../../ui/DateFormatMethods';

interface DeviceTradeinProps {
  campaign: any;
  campaignId: string;
  onShowContributorInfoHandler: (contributorId: string) => void;
  onInformatioCorrectionHandler: () => void;
  onChangePaymentPlan: () => void;
  setPreAuthSelectedRows: (selectedRows: Array<string>) => void;
  preAuthSelectedRows: Array<string>;
  setPreAuthSelectedContributorId: (contributorId: string) => void;
}

export default function ManageDonorsTable({
  campaign,
  campaignId,
  onShowContributorInfoHandler,
  onInformatioCorrectionHandler,
  onChangePaymentPlan,
  setPreAuthSelectedRows,
  preAuthSelectedRows,
  setPreAuthSelectedContributorId,
}: DeviceTradeinProps) {
  const dispatch = useDispatch();

  const tableData: Array<ManageTableModel> = campaign.collectionPreAuthorisations.map(
    (table: ManageTableModel) => ({
      id: table.id,
      key: table.id,
      contributorName: table.contributorName,
      contributorID: table.contributorID,
      studentReferenceArray: table.studentReferenceArray,
      subscriptionName: table.subscriptionName,
      firstCollectionDate: formatDateDDMMYY(new Date(table.firstCollectionDate)),
      giftAidRegistered: table.giftAidRegistered ? 'Yes' : 'No',
      informationError: table.informationError,
    }),
  );

  const onUpdateClickHandler = () => {
    if (preAuthSelectedRows.length === 0) return;
    preAuthSelectedRows.map((row) => dispatch(confirmPreAuth(row)));
    setTimeout(() => {
      dispatch(loadCampaignScheme(campaignId));
    }, 1000);

    setPreAuthSelectedRows([]);
  };

  const onDeleteClickHandler = () => {
    if (preAuthSelectedRows.length === 0) return;
    preAuthSelectedRows.map((row) => dispatch(rejectPreAuth(row)));
    setTimeout(() => {
      dispatch(loadCampaignScheme(campaignId));
    }, 1000);
    setPreAuthSelectedRows([]);
  };

  const [namePre, setNamePre] = useState('');

  const getDropDownContent = () => {
    if (preAuthSelectedRows.length === 1) {
      return (
        <Menu>
          <Menu.Item onClick={onChangePaymentPlan}>Change Payment Plan</Menu.Item>
          <Menu.Item onClick={onInformatioCorrectionHandler}>Information Correction</Menu.Item>
          <Menu.Item onClick={onUpdateClickHandler}>Confirm</Menu.Item>
          <Menu.Item onClick={onDeleteClickHandler}>Remove</Menu.Item>
        </Menu>
      );
    }
    return <></>;

    // return (
    //   <Menu>
    //     <Menu.Item onClick={onUpdateClickHandler}>Confirm</Menu.Item>
    //     <Menu.Item onClick={onDeleteClickHandler}>Remove</Menu.Item>
    //   </Menu>
    // );
  };

  const filtered = tableData.filter((d) => d.contributorName.toLowerCase().includes(namePre.toLowerCase()));

  return (
    <>
      <Controls>
        <SecondHeader>Collection Pre-authorisation</SecondHeader>
        <Dropdown overlay={getDropDownContent()}>
          <StyledLink
            className="ant-dropdown-link"
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
          >
            Actions
            {' '}
            <DownOutlined />
          </StyledLink>
        </Dropdown>
      </Controls>
      <InputStyled
        placeholder="Find by name:"
        value={namePre}
        onChange={(e) => setNamePre(e.target.value)}
      />
      <StyledOverviewTable
        tableData={namePre ? filtered : tableData}
        selectedRows={preAuthSelectedRows}
        onShowContributorInfoHandler={onShowContributorInfoHandler}
        setSelectedRows={setPreAuthSelectedRows}
        setPreAuthSelectedContributorId={setPreAuthSelectedContributorId}
      />
    </>
  );
}

const StyledOverviewTable = styled(OverviewManageTable)`
  margin-top: 100px;
  border-top: 2px solid #2682de;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-bottom: 15px;
  margin-top: 50px;
`;

const StyledLink: FC<any> = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2682de;
  cursor: pointer;
`;

export const SecondHeader = styled.h2`
  font-weight: 750;
  font-size: 18px;
  line-height: 26px;
`;

const InputStyled = styled.input`
  width: 255px;
  height: 34px;
  //margin-right: 20px;
  margin-bottom: 15px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  padding: 0 25px 0 25px;
`;

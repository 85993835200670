import styled from "styled-components/macro";


export default function Checkbox(props: any) {
  return (<InputStyled type="checkbox" {...props} />);
}

const InputStyled = styled.input`
  width: 22px;
  height: 22px;
`;

import styled from 'styled-components/macro';

export function BankAddress() {
  return (
    <Address>
      243 High Street
      Boston Spa
      West Yorkshire
      LS23 6AL
    </Address>
  );
}

const Address = styled.div`
  font-size: 14px;
  line-height: 18px;
  max-width: 97px;
  margin-top: 39px;
  margin-bottom: 66px;
`;

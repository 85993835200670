import styled from "styled-components/macro";
import { ButtonDarkBlack } from "../../../../ui/Button";


interface PendingProps {
  onCreateCampaign: () => void;
}
export default function Pending({ onCreateCampaign }: PendingProps) {
  return (<Wrap>
    <MainHeader>Your school&rsquo;s account has been successfully verified</MainHeader>
    <ButtonNewCampaign onClick={onCreateCampaign}>
      + Create your first campaign
    </ButtonNewCampaign>
  </Wrap>);
}

const Wrap = styled.main`
  padding: 162px 0 0 78px;
`;

const MainHeader = styled.p`
  font-weight: 400;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 41px;
  text-transform: none;
`;

const ButtonNewCampaign = styled(ButtonDarkBlack)``;
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { selectAdminCurrentDonor, loadAdminCurrentDonor, adminFreezeDonor } from '../../../feature/campaignState';
import AdminLayout from '../layout/AdminLayout';
import DonorView from './DonorView';
import { selectAdminDonorCollections, getAdminDonorCollections } from '../../../feature/adminState';
import { PaymentFilterDateAndStatus } from '../../../project-types/payment/types';
import { formatDateString } from '../../../utils/formats';

export default function DonorPage() {
  const [monthNum, setMonthNum] = useState(new Date().getMonth() + 1);

  const currentDonor = useSelector(selectAdminCurrentDonor);
  const donorCollections = useSelector(selectAdminDonorCollections);
  const dispatch = useDispatch();
  const { donorId } = useParams<{ donorId: string }>();

  useEffect(() => {
    dispatch(loadAdminCurrentDonor(donorId));
  }, [dispatch, donorId]);

  useEffect(() => {
    const collectionStart = formatDateString(`${new Date().getFullYear()}-${monthNum}`);
    const collectionEnd = formatDateString(`${new Date().getFullYear()}-${monthNum}`, true);

    const params: Omit<PaymentFilterDateAndStatus, 'status'> = { collectionEnd, collectionStart };

    dispatch(getAdminDonorCollections(donorId, params));
  }, [dispatch, donorId, monthNum]);

  const onFreezeCollectionsClickHandler = () => dispatch(adminFreezeDonor([donorId], true));
  const onResumeCollectionsClickHandler = () => dispatch(adminFreezeDonor([donorId], false));

  return (
    <AdminLayout>
      {currentDonor && <DonorView currentDonor={currentDonor} onFreezeCollectionsClickHandler={onFreezeCollectionsClickHandler} onResumeCollectionsClickHandler={onResumeCollectionsClickHandler} currentMonth={monthNum} onMonthChange={setMonthNum} donorCollections={donorCollections} />}
    </AdminLayout>
  );
}

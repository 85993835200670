import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import DonorLayout from './DonorLayout';
import LoginLink from '../../layout/Menu/LoginLink';
import { getUserProfile, loadProfile } from '../../../feature/userState';
import ROUTER_CONFIG from '../../../router/router-config';
import DonorAccountMenu from './menu/DonorAccountMenu';

interface DonorSignupLayoutProps {
  children: ReactNode;
}

export default function DonorSignupLayout({
  children,
}: DonorSignupLayoutProps) {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getUserProfile);
  const location = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    dispatch(loadProfile());
  }, [dispatch]);

  const onLoginClickHandler = () =>
    push(ROUTER_CONFIG.DONOR_LOGIN.getPath(), { from: location });

  const Menu =
    currentProfile && currentProfile.donorProfile ? (
      <DonorAccountMenu
        accountName={
          currentProfile?.donorProfile?.firstName &&
          currentProfile?.donorProfile?.lastName
            ? `${currentProfile?.donorProfile?.firstName} ${currentProfile?.donorProfile?.lastName}`
            : 'Account'
        }
      />
    ) : (
      <LoginLink onLoginClick={onLoginClickHandler} />
    );

  return (
    <DonorLayout menu={Menu} headerLoginLinkTo={ROUTER_CONFIG.DONOR_LOGIN.path}>
      {children}
    </DonorLayout>
  );
}

import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { FormConfig, renderFormConfig } from '../../../utils/form';
import Input from '../../../ui/forms/Input';
import { ButtonDark } from '../../../ui/Button';
import { UpdateDonorFields } from '../../../project-types/donor/types';
import { ChangeDonorEmailSchema } from '../donor-schemas';

interface ChangeEmailFormProps {
  onFormSubmit: (formData: UpdateDonorFields) => void;
  onCancelClickHandler: () => void;
  onForgotPasswordClickHandler: () => void;
}

export default function ChangeEmailForm({
  onFormSubmit,
  onCancelClickHandler,
  onForgotPasswordClickHandler,
}: ChangeEmailFormProps) {
  const formik = useFormik({
    initialValues: {
      email: '',
      validatePassword: '',
    },
    validationSchema: ChangeDonorEmailSchema,
    onSubmit: (values) => onFormSubmit({ parent: values }),
  });

  return (
    <Container onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig, formik)}
      <StyledSpan onClick={onForgotPasswordClickHandler}>
        Forgot your password?
      </StyledSpan>
      <ButtonsBlock>
        <LightButton
          onClick={(e) => {
            e.preventDefault();
            onCancelClickHandler();
          }}
        >
          Cancel
        </LightButton>
        <StyledDarkButton type="submit">Save</StyledDarkButton>
      </ButtonsBlock>
    </Container>
  );
}

const Container = styled.form`
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledSpan = styled.span`
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
`;

const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
`;

const LightButton = styled(ButtonDark)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 55px;
  background: #fff;
  border: 1px solid #969696;
  color: #969696;
`;

const StyledDarkButton = styled(ButtonDark)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 55px;
`;

const formConfig: FormConfig = [
  {
    title: 'Email',
    name: 'email',
    props: {
      autocomplete: 'off',
    },
    component: StyledInput,
  },
  {
    title: 'Enter the password for confirmation',
    name: 'validatePassword',
    props: {
      type: 'password',
    },
    component: StyledInput,
  },
];

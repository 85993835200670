import styled from 'styled-components/macro';
import { ButtonDark, ButtonDarkBlack, ButtonLightGray } from '../../../ui/Button';
import CheckmarkImg from '../../../img/checkmark.svg';
// import useKeyboardAction from '../../hooks/keyboardAction';

interface ComponentProps {
  onNext: () => void
  onClose: () => void
  onGoToFundraising: () => void
  onGoToSettings: () => void
  campaignName: string
}

export default function InviteModal({
  onNext,
  onClose,
  onGoToFundraising,
  onGoToSettings,
  campaignName,
}: ComponentProps) {
  // useKeyboardAction({
  //   enterKeyPressed: onAddGroupName,
  //   escKeyPressed: onClose,
  // });

  return (
    <Wrapper>
      <img src={CheckmarkImg} />
      <Title>Congratulations!</Title>
      <SubTitle>
        {`Your campaign "${campaignName}" has been saved.`}
      </SubTitle>
      <Block>
        <BlockText>
          Go to your Fundraising Page to see campaign progress
        </BlockText>
        <ButtonStyled onClick={onGoToFundraising}>Go to Fundraising Page</ButtonStyled>
      </Block>
      <Block>
        <BlockText>
          Add account information in order to receive funds
        </BlockText>
        <ButtonStyled onClick={onGoToSettings}>Add account information</ButtonStyled>
      </Block>
      <BottomLink onClick={onNext}>Go back to dashboard</BottomLink>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 42px;
  max-width: 958px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
`;

const Title = styled.h1`
  margin-bottom: 28px;
  margin-top: 29px;
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-bottom: 56px;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
`;

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 33px;
  max-width: 662px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const BlockText = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  max-width: 234px;
  margin-bottom: 0;
`;

const ButtonStyled = styled(ButtonDark)`
  padding: 11px 0;
  width: 286px;
`;

const BottomLink = styled.a`
  font-weight: 750;
  font-size: 18px;
  line-height: 22px;
  margin-top: 64px;
  color: #BDBDBD!important;
`;

/* eslint-disable no-unused-vars */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import campaignState from '../feature/campaignState';
import schoolState from '../feature/schoolsState';
import adminState from '../feature/adminState';
import { userProfile } from '../feature/userState';
// import counterReducer from '../features/counter/counterSlice';

// import user from '../features/user/userState';

export const store = configureStore({
  reducer: {
    userProfile: userProfile.reducer,
    campaignState,
    schoolState,
    adminState,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

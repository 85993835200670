import { useParams, useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminCurrentSchool, selectAdminCurrentSchool } from '../../../feature/schoolsState';
import {
  selectAdminCampaignsList, loadAdminCampaignsList, adminFreezeCampaigns, adminDeleteCampaigns,
} from '../../../feature/campaignState';
import { getAdminSchoolCollections, selectAdminSchoolCollections } from '../../../feature/adminState';
import AdminLayout from '../layout/AdminLayout';
import SchoolView from './SchoolView';
import { AdminPaymentSchoolFiltersOrderType } from '../../../project-types/admin-payment/types';
import { formatDateString } from '../../../utils/formats';
import ROUTER_CONFIG from '../../../router/router-config';
import { COLLECTION_STATUSES } from '../../school/types';

export default function SchoolPage() {
  const [monthNum, setMonthNum] = useState(new Date().getMonth() + 1);
  const [donorSearch, setDonorSearch] = useState('');
  const [dateSearch, setDateSearch] = useState('');
  const [campaignSearch, setCampaignSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState<Array<string> | null>(null);

  const { schoolId } = useParams<{ schoolId: string }>();
  const { push } = useHistory();
  const school = useSelector(selectAdminCurrentSchool);
  const adminSchoolCollections = useSelector(selectAdminSchoolCollections);
  const campaignsList = useSelector(selectAdminCampaignsList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminCurrentSchool(schoolId));
    dispatch(loadAdminCampaignsList(schoolId));
  }, [dispatch, schoolId]);

  useEffect(() => {
    const collectionStart = formatDateString(`${new Date().getFullYear()}-${monthNum}`);
    const collectionEnd = formatDateString(`${new Date().getFullYear()}-${monthNum}`, true);
    // const statuses = statusSearch ? statusSearch.map((status) => COLLECTION_STATUSES[status as keyof typeof COLLECTION_STATUSES]) : undefined;

    const filters: AdminPaymentSchoolFiltersOrderType = {
      collectionEnd,
      collectionStart,
      // status: statuses,
      // donor: donorSearch || undefined,
      // campaign: campaignSearch || undefined,
      // postedDate: new Date(dateSearch).toISOString(),
    };

    dispatch(getAdminSchoolCollections(schoolId, filters));
  }, [dispatch, schoolId, monthNum, statusSearch]);

  const onFreeze = (selectedCampaignsIds: Array<string>) => dispatch(adminFreezeCampaigns(schoolId, selectedCampaignsIds, true));
  const onUnFreeze = (selectedCampaignsIds: Array<string>) => dispatch(adminFreezeCampaigns(schoolId, selectedCampaignsIds, false));
  const onDelete = (selectedCampaignsIds: Array<string>) => dispatch(adminDeleteCampaigns(schoolId, selectedCampaignsIds));

  const onAddNewCampaignClickHandler = () => push(ROUTER_CONFIG.ADMIN_CREATE_CAMPAIGN.getPath({ schoolId }));

  return (
    <AdminLayout>
      {school && <SchoolView schoolCollections={adminSchoolCollections.payments} schoolCollectionsStatistics={adminSchoolCollections.stats} campaignsList={campaignsList} currentSchool={school} onAddNewCampaignClickHandler={onAddNewCampaignClickHandler} onFreeze={onFreeze} onUnFreeze={onUnFreeze} onDelete={onDelete} currentMonth={monthNum} onMonthChange={setMonthNum} donorSearch={donorSearch} onChangeDonorSearch={setDonorSearch} campaignSearch={campaignSearch} onChangeCampaignSearch={setCampaignSearch} dateSearch={dateSearch} onChangeDateSearch={setDateSearch} onChangeStatusSearch={setStatusSearch} />}
    </AdminLayout>
  );
}

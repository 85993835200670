import styled from 'styled-components/macro';
import { Table, DatePicker, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CampaignsTableRow } from '../../types';

const { RangePicker } = DatePicker;
const {Search} = Input

interface CampaignsTableProps {
  tableData: Array<CampaignsTableRow>;
  onChangeTimePeriod: (period: [string, string]) => void;
  onCampaignClickHandler: (campaignId: string) => void;
  onChangeId: (id: string) => void;
  onChangeName: (name: string) => void
}

export default function CampaignsTable({ tableData, onChangeTimePeriod, onCampaignClickHandler, onChangeId, onChangeName }: CampaignsTableProps) {
  const tableConfig: ColumnsType<CampaignsTableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Scheme Name',
      dataIndex: 'campaignName',
      key: 'campaignName',
      render: (value, record) => <CampaignLink onClick={() => onCampaignClickHandler(record.key)}>{value}</CampaignLink>,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'Target Registration Deadline',
      dataIndex: 'targetEndDate',
      key: 'targetEndDate',
    },
    {
      title: 'Registered contributors',
      dataIndex: 'registeredDonors',
      key: 'registredDonors',
    },
    {
      title: 'Total Collections Collected',
      dataIndex: 'totalDonationsCollected',
      key: 'totalDonationsCollected',
    },
    {
      title: 'Unhandled Exceptions',
      dataIndex: 'unhandledExceptions',
      key: 'unhandledExceptions',
    },
  ];

  return (
    <TableContainer>
      <FilterWrapper>
        <StyledRangePicker onChange={(_, period) => onChangeTimePeriod(period)} picker="month" />
        <StyledInput placeholder="ID" onChange={(e) => onChangeId(e.target.value)}/>
        <StyledInput placeholder="Scheme Name" onChange={(e) => onChangeName(e.target.value)} />
      </FilterWrapper>
      <Table<CampaignsTableRow> dataSource={tableData} columns={tableConfig} style={{ borderTop: '2px solid #2682DE' }} />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledRangePicker = styled(RangePicker)`
  align-self: flex-start;
`;

const StyledInput = styled(Input)`
  width: 130px;
`;

const CampaignLink = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

import styled from 'styled-components/macro';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DonorLayout from './DonorLayout';
import { getUserProfile, loadProfile } from '../../../feature/userState';
import DonorAccountMenu from './menu/DonorAccountMenu';
import ROUTER_CONFIG from '../../../router/router-config';

interface DonorAccountLayoutProps {
  children: ReactNode;
}

export default function DonorSignupLayout({
  children,
}: DonorAccountLayoutProps) {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getUserProfile);

  useEffect(() => {
    dispatch(loadProfile());
  }, [dispatch]);

  return (
    <DonorLayout
      menu={
        <DonorAccountMenu
          accountName={
            currentProfile?.donorProfile?.firstName &&
            currentProfile?.donorProfile?.lastName
              ? `${currentProfile?.donorProfile?.firstName} ${currentProfile?.donorProfile?.lastName}`
              : 'Account'
          }
        />
      }
      headerLoginLinkTo={ROUTER_CONFIG.DONOR_LOGIN.path}
    >
      <Content>{children}</Content>
    </DonorLayout>
  );
}

const Content = styled.div`
  padding: 15px 150px;
`;

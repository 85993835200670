import styled from 'styled-components/macro';

import MapFlagIcon from '../../../../img/icons/mapFlag.svg';
import CalendarIcon from '../../../../img/icons/calendar.svg';

export default function SummaryInfoBlock() {
  return (
    <Container>
      <Title>Summary</Title>
      <Rows>
        <Divider />
        <RowItem>
          <RowTitleBlock>
            <RowIcon alt="" src={CalendarIcon} />
            <RowText>Device Registration Deadline</RowText>
          </RowTitleBlock>
          <RowValue>01/07/22</RowValue>
        </RowItem>
        <Divider />
        <RowItem>
          <RowTitleBlock>
            <RowIcon alt="" src={CalendarIcon} />
            <RowText>Device Drop-off Period</RowText>
          </RowTitleBlock>
          <RowValue>01/07/22 - 07/07/22</RowValue>
        </RowItem>
        <Divider />
        <RowItem>
          <RowTitleBlock>
            <RowIcon alt="" src={CalendarIcon} />
            <RowText>Device Collection Date</RowText>
          </RowTitleBlock>
          <RowValue>08/07/22</RowValue>
        </RowItem>
        <Divider />
        <RowItem>
          <RowTitleBlock>
            <RowIcon alt="" src={MapFlagIcon} />
            <RowText>Drop-off Address</RowText>
          </RowTitleBlock>
          <RowValue>
            Mona Vale School 32 Vale Ave
            South Vale
            CR9 7HY
          </RowValue>
        </RowItem>
      </Rows>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 20px;
  border: 1px solid #D5DCE5;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid #D5DCE5;
`;

const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const RowTitleBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RowText = styled.span`
  font-size: 14px;
  color: #102f54;
  white-space: nowrap;
`;

const RowIcon = styled.img``;

const RowValue = styled(RowText)`
  color: #2682DE;
  white-space: pre-wrap;
  text-align: right;
`;

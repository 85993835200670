import styled from 'styled-components/macro';
import { ButtonDark } from '../../../../ui/Button';
import CharitiesTable from './CharitiesTable';

interface CharitiesOverviewProps {
  onRowClickHandler: (charityId: string) => void;
  onNewCharityClickHandler: () => void;
}

export default function CharitiesOverview({ onRowClickHandler, onNewCharityClickHandler }: CharitiesOverviewProps) {
  return (
    <Wrapper>
      <Title>Charities</Title>
      <StyledButton onClick={onNewCharityClickHandler}>+ New Charity account</StyledButton>
      <SubTitle>All Charities</SubTitle>
      <CharitiesTable tableData={[]} onRowClickHandler={onRowClickHandler} />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  box-sizing: border-box;
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const Title = styled.h2`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: #102f54;
`;

const SubTitle = styled.h3`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #102f54;
  margin-top: 65px;
`;

const StyledButton = styled(ButtonDark)`
  padding: 20px 120px;
  margin-top: 36px;
`;

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RegistredDevicesTradeinTableRow } from '../../types';

interface RegistredDevicesTableProps {
  tableData: Array<RegistredDevicesTradeinTableRow>;
  onSelectRows: (deviceIds: Array<string>) => void;
}

const tableConfig: ColumnsType<RegistredDevicesTradeinTableRow> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Donor',
    dataIndex: 'donorName',
    key: 'donorName',
  },
  {
    title: 'Gift Aid Opt-in',
    dataIndex: 'giftAidOptIn',
    key: 'giftAidOptIn',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Total Valuation',
    dataIndex: 'totalValuation',
    key: 'totalValuation',
  },
];

export default function RegistredDevicesTable({ tableData, onSelectRows }: RegistredDevicesTableProps) {
  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        onChange: (ids) => onSelectRows(ids as Array<string>),
      }}
      columns={tableConfig}
      dataSource={tableData}
    />
  );
}

/* eslint-disable camelcase */
import { message } from 'antd';
import { useState, MouseEvent } from 'react';
import styled from 'styled-components/macro';
import { ButtonDarkBlack } from '../Button';

const API_URL = 'https://api.getAddress.io';
const API_KEY = 'VSMcxg4LYUuiJAQa-HfeQQ33301';

export interface FindAddressApiResponseAddressItem {
  formatted_address: Array<string>;
  thoroughfare: string;
  building_name: string;
  sub_building_name: string;
  building_number: string;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  locality: string;
  town_or_city: string;
  county: string;
  district: string;
  country: string;
}

export interface FindAddressApiResponse {
  postcode: string;
  latitude: string;
  longitude: string;
  addresses: Array<FindAddressApiResponseAddressItem>;
}

interface FindYourAddressProps {
  postCode: string;
  formikInstance: any;
  isDisabled?: boolean;
}

export default function FindYourAddress({ postCode, formikInstance, isDisabled = false }: FindYourAddressProps) {
  const [apiResponse, setApiResponse] = useState<FindAddressApiResponse | undefined>();
  const [dropdownContent, setDropdownContent] = useState<Array<string> | null>(null);

  const onClickHandler = async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    try {
      const data = await fetch(`${API_URL}/find/${postCode}?expand=true&api-key=${API_KEY}`);
      const res: FindAddressApiResponse = await data.json();
      setApiResponse(res);
      const dropdownArr = res.addresses.map((address, index) => `${index + 1}. ${address.formatted_address.join(',').replace(/,+/g, ', ')}`);
      setDropdownContent(dropdownArr);
      // console.log(dropdownArr);
    } catch (error) {
      console.log(error);
      message.error('Invalid postcode');
    }
  };

  const onOptionClickHandler = (str: string) => {
    if (!apiResponse) return;

    const lineNumber = str.split('.')[0];
    const address = apiResponse.addresses[+lineNumber - 1];

    formikInstance.setFieldValue('address1', address.line_1);
    formikInstance.setFieldValue('address2', address.line_2);
    formikInstance.setFieldValue('address3', address.line_3);
    formikInstance.setFieldValue('country', address.country);
    formikInstance.setFieldValue('town', address.town_or_city);
  };

  return (
    <Container>
      <ButtonStyled disabled={isDisabled} onClick={(e) => onClickHandler(e)}>Find your Address</ButtonStyled>
      {/* {dropdownContent && ( */}
      <DropdownContainer isReady={!!dropdownContent}>
        {dropdownContent && dropdownContent.map((str) => <StyledListItem onClick={() => onOptionClickHandler(str)} key={str}>{str}</StyledListItem>)}
      </DropdownContainer>
      {/* )} */}
    </Container>
  );
}

const DropdownContainer = styled.div<{ isReady: boolean }>`
  position: absolute;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #C4C4C4;
  padding: 15px;
  display: flex;
  opacity: ${(props) => (!props.isReady ? '0' : '1')};
  height: ${(props) => (!props.isReady ? '0' : 'auto')};
  width: ${(props) => (!props.isReady ? '0' : 'auto')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: none;
`;

const Container = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
  &:hover ${DropdownContainer} {
    display: flex;
  }
`;

const ButtonStyled = styled(ButtonDarkBlack)`
  margin-top: 21px;
  color: #FFFFFF;
  /* margin-bottom: 53px; */
  margin: 10px 0;
  &:disabled {
    cursor: initial;
  }
`;

const StyledListItem = styled.span`
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { request, setBearerToken } from '../api';
import {
  ApiAdminDeleteDonors,
  ApiAdminFreezeDonors,
  ApiGetAdminDonorsList,
  ApiGetDonorItem,
} from '../project-types/admin-donors/api-types';
import { DonorAdminType } from '../project-types/admin-donors/type';
import {
  ApiAdminDeleteCampaigns,
  ApiAdminFreezeCampaigns,
  ApiGetAdminCampaignsListBySchool,
} from '../project-types/admin-campaigns/api-types';
import { CampaignInviteAcceptType } from '../project-types/campaign-join/types';
import {
  ApiCampaignList,
  ApiCreateCampaign,
  ApiGetCampaignByInvite,
  ApiGetOneCampaign,
  ApiSendInvites,
  ApiFreezeDonors,
  ApiCampaignRemoveDonors,
  ApiCampaignUpdate,
  ApiGetExportFile,
  CalibrateDataApi,
  PaymentCustomDataApi,
  ExceptionsPostDataApi,
} from '../project-types/campaign/api-types';
import {
  ApiGetCampaignDonations,
  ApiGetManageFields,
  ApiManageDonorUpdate,
} from '../project-types/campaign-payment/api-types';
import {
  BalanceTransferType,
  CalibrateSummaryType,
  CampaignContributorsType,
  CampaignEditType,
  CampaignPublicType,
  CampaignType,
  FreezeDonorsType,
  FullCampaignType,
  FullContributorType,
  FullCurrentPlanType,
  PayOptType,
  SchemeEditType,
} from '../project-types/campaign/types';
import { FreezeAdminCampaignsType } from '../project-types/admin-campaigns/types';
import { createAxiosConfig } from '../project-types/common/axios';
import { HTTP_METHODS } from '../project-types/common/http-types';
import {
  ApiCreateDonor,
  ApiDeleteDonor,
  ApiJoinCampaign,
  ApiDeleteCampignForDonor,
} from '../project-types/donor/api-types';
import { CampaignForDonor } from '../project-types/donor/types';
import {
  DonorPaymentStatsType,
  DonorCampaignSumRow,
  DonorSchemesType,
  DashboardDataType,
  DonorCollectionsState,
} from '../project-types/donor-payment/types';
import {
  PaymentPublicType,
  DonorPaymentFields,
  PaymentWithStudentRefsType,
} from '../project-types/payment/types';
import {
  ApiGetDonationsMade,
  ApiGetDonorCampaignsSum,
} from '../project-types/donor-payment/api-types';
import { AppThunk, RootState } from '../store';
import { setCurrentSchool } from './schoolsState';
import { CampaignPaymentStatsType } from '../project-types/campaign-payment/types';
import { PaymnetInformationCorrectionForm } from '../components/school/types';

type СampaignsListState = Array<CampaignPublicType>;
type DonorsListState = Array<DonorPaymentFields>;
type AdminDonorsListState = Array<DonorAdminType>;
type DonorCampaignsListState = Array<DonorCampaignSumRow>;
type DonorSchemesListState = Array<DonorSchemesType>;
type DashboardDataState = DashboardDataType;

type AdminCampaignsListState = Array<CampaignPublicType>;
type CurrentCampaignState = CampaignPublicType | null;
type PayOptState = PayOptType | null;
type CampaignInviteJoinState = CampaignInviteAcceptType;
type AdminCurrentDonorState = DonorAdminType | null;
type CurrentCampaignCollectionsState = {
  donationsList: Array<PaymentWithStudentRefsType>;
  stats: CampaignPaymentStatsType | null;
};
type CurrentTestCollectionsState = { donorsList: Array<any> };

const initialCampaignListState: СampaignsListState = [];
const initialCurrentCampaign: CurrentCampaignState = null;
const initialPayOpt: PayOptState = null;
const initialDonorListState: DonorsListState = [];
const initialAdminDonorsListState: AdminDonorsListState = [];
const initialAdminCampaignsListState: AdminCampaignsListState = [];
const initialDonorCampaignsListState: DonorCampaignsListState = [];
const initialDonorSchemesListState: DonorSchemesListState = [];
const initialDashboardDataState: DashboardDataState = {
  activeOrPendingSubscriptions: 0,
  totalCollectionsNumber: 0,
  totalCollectionsSum: '',
  totalStudentsNumber: 0,
};
const initialAdminCurrentDonorState: AdminCurrentDonorState = null;
const initialDonorCollectionsState: Array<DonorCollectionsState> = [];
const initialCurrentCampaignCollectionsState: CurrentCampaignCollectionsState =
  {
    donationsList: [],
    stats: null,
  };
const initialCurrentTestCollectionsState: CurrentTestCollectionsState = {
  donorsList: [],
};

const initialCampaignInviteJoinState: CampaignInviteJoinState = {
  donationAmount: 0,
  students: [],
};

const initialSchemeState: FullCampaignType = {
  scheme: {
    id: '',
    inviteCode: '',
    schemeName: '',
    startDate: '',
    endDate: '',
    registeredContributors: 1,
    totalCollections: '',
    numberCollections: 1,
    collectionType: '',
    campaignDescription: '',
    collectionRules: 0,
    collectionNotificationEmailArray: [''],
    schoolContributionAgreementID: '',
  },
  contributors: [
    {
      id: '',
      name: '',
      email: '',
      giftAidBool: true,
      studentReferenceArray: [''],
      successfulCollections: 1,
      failedCollections: 1,
      plannedCollections: 1,
      totalCollected: '',
      notes: '',
    },
  ],
  collections: [
    {
      id: '',
      contributorName: '',
      contributorID: '',
      collectionDate: '',
      collectionAmount: '',
      giftAidAmount: '',
      status: '',
    },
  ],
  collectionPreAuthorisations: [
    {
      id: '',
      contributorName: '',
      contributorID: '',
      firstCollectionDate: '',
      studentReferenceArray: [''],
      giftAidRegistered: true,
      subscriptionName: '',
      informationError: false,
    },
  ],
  exceptions: [
    {
      id: '',
      contributorName: '',
      contributorID: '',
      exceptionDate: '',
      exceptionAmount: '',
      exceptionGiftAidAmount: '',
      exceptionStatus: '',
      exceptionType: '',
      studentReferenceArray: [''],
    },
  ],
};

const initialContributorState: FullContributorType = {
  parent: {
    id: '',
    name: '',
    email: '',
    activeSchemes: 1,
    totalCollectionsSum: '',
    totalCollectionsNumber: 1,
  },
  collections: [
    {
      id: '',
      collectionDate: '',
      collectionAmount: '',
      giftAidAmount: '',
      status: '',
    },
  ],
  schemes: [
    {
      id: '',
      name: '',
      schoolName: '',
      studentReferenceArray: [''],
      status: '',
      subscriptionName: '',
      subscriptionID: 1,
      totalCollected: '',
      totalCollections: 1,
      totalFailedCollections: 1,
      startDate: '',
      endDate: '',
    },
  ],
};

const initialCurrentPlanState: FullCurrentPlanType = {
  currentSchemeChoicesArray: [
    {
      id: '',
      text: '',
      isActive: true,
    },
  ],
  otherSchemeChoicesArray: [
    {
      schemeID: '',
      schemeName: '',
      schemeChoicesArray: [
        {
          id: '',
          text: '',
        },
      ],
    },
  ],
};

const initialBalanceState: BalanceTransferType = {
  totalCollected: '',
  totalRemittance: '',
  totalGiftAid: '',
  BTS: [
    {
      period: '',
      name: '',
      link: '',
      sum: '',
    },
  ],
};

export const campaignScheme = createSlice({
  name: 'campaignScheme',
  initialState: initialSchemeState,
  reducers: {
    setCampaignScheme: (state, action) => (state = action.payload),
  },
});

export const contributorScheme = createSlice({
  name: 'contributorScheme',
  initialState: initialContributorState,
  reducers: {
    setContributorScheme: (state, action) => (state = action.payload),
  },
});

export const currentPlanSlice = createSlice({
  name: 'currentPlanSlice',
  initialState: initialCurrentPlanState,
  reducers: {
    setCurrentPlan: (state, action) => (state = action.payload),
  },
});

export const campaignList = createSlice({
  name: 'list',
  initialState: initialCampaignListState,
  reducers: {
    setList: (state, action: PayloadAction<Array<CampaignPublicType>>) => [
      ...(action.payload || []),
    ],
  },
});

export const donorList = createSlice({
  name: 'donorList',
  initialState: initialDonorListState,
  reducers: {
    setDonorList: (state, action: PayloadAction<Array<DonorPaymentFields>>) => [
      ...(action.payload || []),
    ],
  },
});

export const balanceTransferSlice = createSlice({
  name: 'balanceTransferSlice',
  initialState: initialBalanceState,
  reducers: {
    setBalanceTransfer: (state, action) => (state = action.payload),
  },
});

export const manageList = createSlice({
  name: 'manageList',
  initialState: initialCurrentTestCollectionsState,
  reducers: {
    setManageList: (state, action) => action.payload,
  },
});

export const adminDonorsList = createSlice({
  name: 'adminDonorsList',
  initialState: initialAdminDonorsListState,
  reducers: {
    setAdminDonorsList: (
      state,
      action: PayloadAction<Array<DonorAdminType>>
    ) => [...action.payload],
  },
});

export const donorCampaignsList = createSlice({
  name: 'donorCampaignsList',
  initialState: initialDonorCampaignsListState,
  reducers: {
    setDonorCampaignsList: (
      state,
      action: PayloadAction<Array<DonorCampaignSumRow>>
    ) => [...action.payload],
  },
});

export const donorSchemesList = createSlice({
  name: 'donorSchemesList',
  initialState: initialDonorSchemesListState,
  reducers: {
    setDonorSchemesList: (
      state,
      action: PayloadAction<Array<DonorSchemesType>>
    ) => [...action.payload],
  },
});

export const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState: initialDashboardDataState,
  reducers: {
    setDashboardData: (state, action: PayloadAction<DashboardDataType>) =>
      action.payload,
  },
});

export const donorCollections = createSlice({
  name: 'donorCollections',
  initialState: initialDonorCollectionsState,
  reducers: {
    setDonorCollections: (
      state,
      action: PayloadAction<Array<DonorCollectionsState>>
    ) => [...action.payload],
  },
});

export const adminCampaignsList = createSlice({
  name: 'adminCampaignsList',
  initialState: initialAdminCampaignsListState,
  reducers: {
    setAdminCampaignsList: (
      state,
      action: PayloadAction<Array<CampaignPublicType>>
    ) => [...action.payload],
  },
});

export const currentCampaign = createSlice({
  name: 'campaign',
  initialState: initialCurrentCampaign as CurrentCampaignState,
  reducers: {
    setCampaign: (state, action: PayloadAction<CampaignPublicType>) => {
      return action.payload;
    },

    setActivePaymentOption: (state, action: PayloadAction<any>) => {
      state!.activePaymentOption = action.payload;
    },
  },
});

export const payOpt = createSlice({
  name: 'payOpt',
  initialState: initialPayOpt as PayOptState,
  reducers: {
    setPayOpt: (state, action: PayloadAction<PayOptType>) => action.payload,

    // setActivePaymentOption: (state, action: any) => {
    //     state!.activePaymentOption = action.payload;
    // },
  },
});

export const currentCampaignCollections = createSlice({
  name: 'campaignCollections',
  initialState:
    initialCurrentCampaignCollectionsState as CurrentCampaignCollectionsState,
  reducers: {
    setCampaignCollections: (
      state,
      action: PayloadAction<ApiGetCampaignDonations['successResponse']>
    ) => action.payload,
  },
});

export const adminCurrentDonor = createSlice({
  name: 'adminCurrentDonor',
  initialState: initialAdminCurrentDonorState as AdminCurrentDonorState,
  reducers: {
    setAdminCurrentDonor: (state, action: PayloadAction<DonorAdminType>) =>
      action.payload,
  },
});

export const campaignInviteJoin = createSlice({
  name: 'campaign-invite-join',
  initialState: initialCampaignInviteJoinState,
  reducers: {
    setCampaignInviteJoin: (
      state,
      action: PayloadAction<CampaignInviteJoinState>
    ) => action.payload,
  },
});

export const { setCampaignScheme } = campaignScheme.actions;
export const { setContributorScheme } = contributorScheme.actions;
export const { setCurrentPlan } = currentPlanSlice.actions;
export const { setList } = campaignList.actions;
export const { setDonorList } = donorList.actions;
export const { setManageList } = manageList.actions;
export const { setAdminDonorsList } = adminDonorsList.actions;
export const { setDonorCampaignsList } = donorCampaignsList.actions;
export const { setDonorSchemesList } = donorSchemesList.actions;
export const { setDashboardData } = dashboardDataSlice.actions;
export const { setDonorCollections } = donorCollections.actions;
export const { setAdminCampaignsList } = adminCampaignsList.actions;
export const { setCampaign, setActivePaymentOption } = currentCampaign.actions;
export const { setPayOpt } = payOpt.actions;
export const { setCampaignCollections } = currentCampaignCollections.actions;
export const { setAdminCurrentDonor } = adminCurrentDonor.actions;
export const { setCampaignInviteJoin } = campaignInviteJoin.actions;

export const { setBalanceTransfer } = balanceTransferSlice.actions;

const campaignState = combineReducers({
  campaignScheme: campaignScheme.reducer,
  contributorScheme: contributorScheme.reducer,
  currentPlanSlice: currentPlanSlice.reducer,
  list: campaignList.reducer,
  currentCampaign: currentCampaign.reducer,
  payOpt: payOpt.reducer,
  currentCampaignCollections: currentCampaignCollections.reducer,
  adminCurrentDonor: adminCurrentDonor.reducer,
  campaignInviteJoin: campaignInviteJoin.reducer,
  donorList: donorList.reducer,
  manageList: manageList.reducer,
  adminDonorsList: adminDonorsList.reducer,
  donorCampaignsList: donorCampaignsList.reducer,
  donorCollections: donorCollections.reducer,
  adminCampaignsList: adminCampaignsList.reducer,
  balanceTransferSlice: balanceTransferSlice.reducer,
  donorSchemesList: donorSchemesList.reducer,
  dashboardData: dashboardDataSlice.reducer,
});

export default campaignState;

export const updateScheme =
  (campaignId: string, campaign: SchemeEditType): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig({
        url: ['/oto-schemes/', campaignId],
        method: HTTP_METHODS.PATCH,
        data: {
          campaign,
        },
      })
    )
      .then((res: { data: ApiCampaignUpdate['successResponse'] }) => {
        dispatch(setCampaign(res.data.campaign));
        message.success('Campaign successfully updated');
      })
      .catch(() => message.error('Update error'));
  };
export const loadCampaignScheme =
  (campaignId: string): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig({
        url: ['/oto-schemes/', campaignId],
        method: HTTP_METHODS.GET,
      })
    ).then((response) => {
      dispatch(setCampaignScheme(response.data));
    });
  };

export const loadBalanceTransfer = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig({
      url: ['/oto-balance/'],
      method: HTTP_METHODS.GET,
    })
  ).then((response) => {
    dispatch(setBalanceTransfer(response.data));
  });
};

export const loadContributorScheme =
  (contributorId: string): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig({
        url: ['/oto-parents/', contributorId],
        method: HTTP_METHODS.GET,
      })
    ).then((response) => {
      dispatch(setContributorScheme(response.data));
    });
  };

export const loadCurrentPlan =
  (contributorId: string, campaignId: string): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig({
        url: ['/oto-parents/', contributorId, '/current-plan/', campaignId],
        method: HTTP_METHODS.GET,
      })
    ).then((response) => {
      dispatch(setCurrentPlan(response.data));
    });
  };

export const loadCurrentPlanPreAuth =
  (contributorId: string, campaignId: string): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig({
        url: [
          '/oto-parents/',
          contributorId,
          '/current-plan/',
          campaignId,
          '/pre',
        ],
        method: HTTP_METHODS.GET,
      })
    ).then((response) => {
      dispatch(setCurrentPlan(response.data));
    });
  };

export const postLoadCalibrate = (
  contributorId: string,
  campaignId: string,
  paymentPlanId: string,
  data: CalibrateDataApi
) =>
  request(
    createAxiosConfig({
      url: [
        '/oto-parents/',
        contributorId,
        '/calibrate/',
        campaignId,
        '/',
        paymentPlanId,
      ],
      data: data,
      method: HTTP_METHODS.POST,
    })
  )
    .then((response) => response.data)
    .catch(() =>
      message.error('The requested change cannot currently be made')
    );

export const postLoadCalibratePreAuth = (
  contributorId: string,
  campaignId: string,
  paymentPlanId: string,
  data: CalibrateDataApi
) =>
  request(
    createAxiosConfig({
      url: [
        '/oto-parents/',
        contributorId,
        '/calibrate/',
        campaignId,
        '/',
        paymentPlanId,
        '/pre',
      ],
      data: data,
      method: HTTP_METHODS.POST,
    })
  )
    .then((response) => response.data)
    .catch(() =>
      message.error('The requested change cannot currently be made')
    );

export const postLoadPayment = (
  contributorId: string,
  campaignId: string,
  data: PaymentCustomDataApi
) =>
  request(
    createAxiosConfig({
      url: ['/oto-parents/', contributorId, '/customise/', campaignId],
      data: data,
      method: HTTP_METHODS.POST,
    })
  ).then((response) => response.data);

export const postLoadPaymentPreAuth = (
  contributorId: string,
  campaignId: string,
  data: PaymentCustomDataApi
) =>
  request(
    createAxiosConfig({
      url: ['/oto-parents/', contributorId, '/customise/', campaignId, '/pre'],
      data: data,
      method: HTTP_METHODS.POST,
    })
  ).then((response) => response.data);

export const postLoadCancelCollection = (
  contributorId: string,
  campaignId: string,
  method: string
) =>
  request(
    createAxiosConfig({
      url: [
        '/oto-parents/',
        contributorId,
        '/customise/',
        campaignId,
        '/exit/',
        method,
      ],
      method: HTTP_METHODS.POST,
    })
  ).then((response) => response.data);

export const postExceptions = (
  exceptionId: string,
  data: ExceptionsPostDataApi
) =>
  request(
    createAxiosConfig({
      url: ['/oto-exceptions/', exceptionId],
      data: data,
      method: HTTP_METHODS.POST,
    })
  ).then((response) => response.data);

export const updateInformationCorrection =
  (
    parentID: string,
    subscriptionID: string,
    data: PaymnetInformationCorrectionForm
  ): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig({
        url: ['/oto-parents/', parentID, '/infopatch/', subscriptionID],
        method: HTTP_METHODS.POST,
        data,
      })
    )
      .then((res) => {
        message.success('Campaign successfully updated');
      })
      .catch(() => message.error('Update error'));
  };

export const loadCampaignList = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiCampaignList>({
      url: ['/campaigns'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiCampaignList['successResponse'] }) => {
    dispatch(setList(response.data.campaignList));
  });
};

export const loadDonorCampaignsList = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetDonorCampaignsSum>({
      url: ['/payment', '/', 'donor', '/', 'campaigns'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetDonorCampaignsSum['successResponse'] }) => {
    dispatch(setDonorCampaignsList(response.data.campaignsList));
  });
};

export const loadDonorSchemesList = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig({
      url: ['/oto-parent-dash', '/', 'list'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: any }) => {
    dispatch(setDonorSchemesList(response.data.subscriptions));
  });
};

export const loadDashboardData = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig({
      url: ['/oto-parent-dash', '/', 'dash'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: any }) => {
    dispatch(setDashboardData(response.data.dashboardData));
  });
};

export const loadAdminDonorsList = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetAdminDonorsList>({
      url: ['/admin/donor'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetAdminDonorsList['successResponse'] }) =>
    dispatch(setAdminDonorsList(response.data.donorsList))
  );
};
export const loadAdminCampaignsList =
  (schoolId: string): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiGetAdminCampaignsListBySchool>({
        url: ['/admin/schools/', schoolId, '/campaigns'],
        method: HTTP_METHODS.GET,
      })
    ).then(
      (response: {
        data: ApiGetAdminCampaignsListBySchool['successResponse'];
      }) => dispatch(setAdminCampaignsList(response.data.campaignsList))
    );
  };

export const loadDonorCollections = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig({
      url: ['/oto-parent-dash', '/', 'collections'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: any }) =>
    dispatch(setDonorCollections(response.data.collections))
  );
};

export const freezeDonors =
  (
    campaignId: string,
    donors: FreezeDonorsType['donors'],
    toFreeze: FreezeDonorsType['toFreeze']
  ): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiFreezeDonors>({
        url: ['/campaigns', '/', campaignId, '/freeze'],
        method: HTTP_METHODS.PATCH,
        data: {
          donors,
          toFreeze,
        },
      })
    ).then((response: { data: ApiFreezeDonors['successResponse'] }) =>
      dispatch(setCampaign(response.data.campaign))
    );
  };

export const adminFreezeDonors =
  (donorIds: Array<DonorAdminType['_id']>, toFreeze: boolean): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiAdminFreezeDonors>({
        url: ['/admin/donor', '/freeze'],
        method: HTTP_METHODS.PATCH,
        data: { donorIds, toFreeze },
      })
    )
      .then(() => dispatch(loadAdminDonorsList()))
      .catch(() => message.error('Freezing error'));
  };

export const adminFreezeDonor =
  (donorIds: Array<DonorAdminType['_id']>, toFreeze: boolean): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiAdminFreezeDonors>({
        url: ['/admin/donor', '/freeze'],
        method: HTTP_METHODS.PATCH,
        data: { donorIds, toFreeze },
      })
    )
      .then(() => dispatch(loadAdminCurrentDonor(donorIds[0])))
      .catch(() => message.error('Freezing error'));
  };

export const adminFreezeCampaigns =
  (
    schoolId: string,
    campignIds: FreezeAdminCampaignsType['campignIds'],
    toFreeze: FreezeAdminCampaignsType['toFreeze']
  ): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiAdminFreezeCampaigns>({
        url: ['/admin/campaigns', '/freeze'],
        method: HTTP_METHODS.PATCH,
        data: {
          campignIds,
          toFreeze,
        },
      })
    )
      .then(() => dispatch(loadAdminCampaignsList(schoolId)))
      .catch(() => message.error('Freezing error'));
  };

export const adminDeleteDonors =
  (donorIds: Array<DonorAdminType['_id']>): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiAdminDeleteDonors>({
        url: ['/admin/donor'],
        method: HTTP_METHODS.DELETE,
        data: { donors: donorIds },
      })
    )
      .then(() => dispatch(loadAdminDonorsList()))
      .catch(() => message.error('Deletion error'));
  };

export const deleteManageDonors =
  (
    campaignId: string,
    authorised: string,
    donors: ApiCampaignRemoveDonors['data']['donors']
  ): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiDeleteDonor>({
        url: ['/campaign/', campaignId, '/donors'] as any,
        method: HTTP_METHODS.DELETE,
        data: {
          donors,
        },
      })
    ).catch(() => message.error('Deletion error'));
  };

export const adminDeleteCampaigns =
  (schoolId: string, campignIds: Array<CampaignPublicType['_id']>): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiAdminDeleteCampaigns>({
        url: ['/admin/campaigns'],
        method: HTTP_METHODS.DELETE,
        data: { campaignIds: { campignIds } },
      })
    )
      .then(() => dispatch(loadAdminCampaignsList(schoolId)))
      .catch(() => message.error('Deletion error'));
  };

export const deleteCampaignForDonor =
  (campaignId: CampaignPublicType['_id']): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiDeleteCampignForDonor>({
        url: ['/donors', '/campaigns/', campaignId],
        method: HTTP_METHODS.DELETE,
      })
    ).then(() => dispatch(loadDonorCampaignsList()));
  };

export const deleteDonors =
  (
    campaignId: string,
    donors: ApiCampaignRemoveDonors['data']['donors']
  ): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiDeleteDonor>({
        url: ['/campaign/', campaignId, '/donors'] as any,
        method: HTTP_METHODS.DELETE,
        data: {
          donors,
        },
      })
    );
  };

export const updateManageDonor =
  (campaignId: string, donorId: string, authorized: boolean): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiManageDonorUpdate>({
        url: ['/payment', '/', 'donor'],
        method: HTTP_METHODS.PATCH,
        data: {
          donor: donorId,
          campaign: campaignId,
          authorized: authorized,
        },
      })
    )
      .then((res) => {
        console.log(res);
        message.success('Campaign successfully updated');
      })
      .catch(() => message.error('Update error'));
  };

export const confirmPreAuth =
  (donorId: string): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig({
        url: ['/oto-schemes/approve-subscription/', donorId],
        method: HTTP_METHODS.POST,
      })
    )
      .then((res) => {
        console.log(res);
        message.success('Campaign successfully updated');
      })
      .catch(() => message.error('Update error'));
  };

export const rejectPreAuth =
  (donorId: string): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig({
        url: ['/oto-schemes/reject-subscription/', donorId],
        method: HTTP_METHODS.POST,
      })
    )
      .then((res) => {
        console.log(res);
        message.success('Campaign successfully updated');
      })
      .catch(() => message.error('Update error'));
  };

export const loadCampaign =
  (campaignId: CampaignPublicType['_id']): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiGetOneCampaign>({
        url: ['/campaigns', '/', campaignId],
        method: HTTP_METHODS.GET,
      })
    )
      .then((response: { data: ApiGetOneCampaign['successResponse'] }) =>
        dispatch(setCampaign(response.data.campaign))
      )
      .catch(() => message.error('Failed to load campaign'));
  };

export const loadPayment = (paymentId: string) => {
  request(
    createAxiosConfig({
      url: ['/oto-parent-dash/payment_download/', paymentId],
      method: HTTP_METHODS.GET,
      responseType: 'blob',
    })
  ).then((response) => {
    const blob = new Blob([response.data], { type: 'application/pdf' });
    window.open(URL.createObjectURL(blob));
  });
};

export const loadExport = (campaignId: CampaignPublicType['_id']) => {
  request(
    createAxiosConfig({
      url: ['/oto-schemes/export/', campaignId],
      method: HTTP_METHODS.GET,
      responseType: 'blob',
    })
  ).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
  });
};

export const loadExportProblematic = (campaignId: CampaignPublicType['_id']) => {
  request(
    createAxiosConfig({
      url: ['/oto-schemes/export-problematic/', campaignId],
      method: HTTP_METHODS.GET,
      responseType: 'blob',
    })
  ).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export_problematic.csv');
    document.body.appendChild(link);
    link.click();
  });
};

export const loadExportSummary = (campaignId: CampaignPublicType['_id']) => {
  request(
    createAxiosConfig({
      url: ['/oto-schemes/export-summary/', campaignId],
      method: HTTP_METHODS.GET,
      responseType: 'blob',
    })
  ).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export_summary.csv');
    document.body.appendChild(link);
    link.click();
  });
};

export const loadCampaignCollections =
  (
    campaignId: CampaignPublicType['_id'],
    collectionStart: string,
    collectionEnd: string,
    status: string
  ): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiGetCampaignDonations>({
        url: ['/payment', '/', 'campaign', '/', campaignId],
        method: HTTP_METHODS.GET,
        params: {
          collectionStart,
          collectionEnd,
          status,
        },
      })
    ).then((response: { data: ApiGetCampaignDonations['successResponse'] }) =>
      dispatch(setCampaignCollections(response.data))
    );
  };

export const loadAdminCurrentDonor =
  (donorId: string): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiGetDonorItem>({
        url: ['/admin/donor', '/', donorId],
        method: HTTP_METHODS.GET,
      })
    ).then((response: { data: ApiGetDonorItem['successResponse'] }) =>
      dispatch(setAdminCurrentDonor(response.data.donorProfile))
    );
  };

export const loadCampaignByInvite =
  (
    campaignId: CampaignPublicType['_id'],
    inviteCode: CampaignPublicType['inviteCode']
  ): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiGetCampaignByInvite>({
        url: ['/invite/', campaignId, '/', inviteCode],
        method: HTTP_METHODS.GET,
      })
    )
      .then((response: { data: ApiGetCampaignByInvite['successResponse'] }) => {
        dispatch(setCampaign(response.data.campaign));
        dispatch(setCurrentSchool(response.data.schoolProfile));
      })
      .catch(() => {
        message.error('invite error');
      });
  };

export const createCampaign = (campaign: ApiCreateCampaign['data']) =>
  request(
    createAxiosConfig({
      url: ['/oto-schemes'],
      data: campaign,
      method: HTTP_METHODS.POST,
    })
  ).then(
    (response: { data: ApiCreateCampaign['successResponse'] }) => response.data
  );

export const updateCampaign =
  (campaignId: string, campaign: CampaignEditType): AppThunk =>
  (dispatch) => {
    request(
      createAxiosConfig<ApiCampaignUpdate>({
        url: ['/campaigns', '/', campaignId],
        method: HTTP_METHODS.PATCH,
        data: campaign,
      })
    )
      .then((res: { data: ApiCampaignUpdate['successResponse'] }) => {
        dispatch(setCampaign(res.data.campaign));
        message.success('Campaign successfully updated');
      })
      .catch(() => message.error('Update error'));
  };

export const createIvite = (
  campaignId: CampaignPublicType['_id'],
  invite: ApiSendInvites['data']
) =>
  request(
    createAxiosConfig<ApiSendInvites>({
      url: ['/campaigns', '/', campaignId, '/invite'],
      data: invite,
      method: HTTP_METHODS.POST,
    })
  );

export const createDonor = (data: ApiCreateDonor['data']) =>
  request(
    createAxiosConfig<ApiCreateDonor>({
      url: ['/donors'],
      data,
      method: HTTP_METHODS.POST,
    })
  )
    .then((response: { data: ApiCreateDonor['successResponse'] }) => {
      setBearerToken(response.data.token, new Date().toISOString());
      return response.data;
    })
    .catch((err) => {
      if (
        err.data.user &&
        err.data.user[0] === 'User with this email already exists'
      ) {
        message.error('User with this email already exists');
      }
      if (
        err.data.email &&
        err.data.email[0] === 'email must be a valid email'
      ) {
        message.error('Email must be a valid email');
      }
      // debugger;
      if (err.data.errors.payment && err.data.errors.payment[0]) {
        message.error(err.data.errors.payment[0]);
      }
    });

export const JoinDonorCampaign = (
  campaignId: CampaignPublicType['_id'],
  inviteCode: CampaignPublicType['inviteCode'],
  donationAmount: CampaignInviteAcceptType['donationAmount'],
  students: CampaignInviteAcceptType['students'],
  choice: CampaignInviteAcceptType['choice'],
  studentNumber: CampaignInviteAcceptType['studentNumber'],
  campaign: any
) =>
  request(
    createAxiosConfig<ApiJoinCampaign>({
      url: ['/donors', '/join/', campaignId],
      method: HTTP_METHODS.POST,
      data: {
        inviteCode,
        donationAmount,
        students,
        choice,
        studentNumber,
        paymentOption: campaign.activePaymentOption,
      },
    })
  )
    .then(
      (response: { data: ApiJoinCampaign['successResponse'] }) => response.data
    )
    .catch((err) => {
      if (err.data.errors && err.data.errors.campaign) {
        message.error(err.data.errors.campaign[0]);
      }
    });

export const selectCampaignList = (state: RootState) =>
  state.campaignState.list;
export const selectCurrentCampaign = (state: RootState) =>
  state.campaignState.currentCampaign;
export const selectPayOpt = (state: RootState) => state.campaignState.payOpt;
export const selectCurrentCampaignCollections = (state: RootState) =>
  state.campaignState.currentCampaignCollections;
export const selectAdminCurrentDonor = (state: RootState) =>
  state.campaignState.adminCurrentDonor;
export const selectCampaignInvite = (state: RootState) =>
  state.campaignState.campaignInviteJoin;
export const selectCampaignDonors = (state: RootState) =>
  state.campaignState.donorList;
export const selectManageDonors = (state: RootState) =>
  state.campaignState.manageList;
export const selectDonorCampaignsList = (state: RootState) =>
  state.campaignState.donorCampaignsList;

export const selectDonorSchemesList = (state: RootState) =>
  state.campaignState.donorSchemesList;
export const selectDonorCollections = (state: RootState) =>
  state.campaignState.donorCollections;
export const selectDashboardData = (state: RootState) =>
  state.campaignState.dashboardData;
export const selectAdminDonorsList = (state: RootState) =>
  state.campaignState.adminDonorsList;
export const selectAdminCampaignsList = (state: RootState) =>
  state.campaignState.adminCampaignsList;

export const selectCampaignScheme = (state: RootState) =>
  state.campaignState.campaignScheme;

export const selectContributorScheme = (state: RootState) =>
  state.campaignState.contributorScheme;

export const selectCurrentPlan = (state: RootState) =>
  state.campaignState.currentPlanSlice;

export const selectBalanceTransfer = (state: RootState) =>
  state.campaignState.balanceTransferSlice;

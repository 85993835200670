import styled from 'styled-components/macro';
import BackButton from '../../../../ui/elements/BackButton';
import { APPROVAL_STATUSES } from '../../types';
import CharityInfo from './CharityInfo';
import CharityTable from './CharityTable';
import CharityTableControls from './CharityTableControls';

interface CharityViewProps {
  onChangeTimePeriod: (timePeriod: [string, string]) => void;
  onChangeFilter: (filter: keyof typeof APPROVAL_STATUSES) => void;
  currentFilter: keyof typeof APPROVAL_STATUSES;
  onBackClickHandler: () => void;
}

export default function CharityView({ onChangeTimePeriod, onChangeFilter, currentFilter, onBackClickHandler }: CharityViewProps) {
  return (
    <Wrapper>
      <TitleWrapper>
        <TitleBlock>
          <BackButton onClickHandler={onBackClickHandler} />
          <Title>Charity 1</Title>
        </TitleBlock>
        <CharityInfo />
      </TitleWrapper>
      <SubTitle>Approvals Report</SubTitle>
      <CharityTableControls onChangeFilter={onChangeFilter} onChangeTimePeriod={onChangeTimePeriod} currentFilter={currentFilter} />
      <CharityTable tableData={[]} />
    </Wrapper>
  );
}

const Wrapper = styled.form`
  box-sizing: border-box;
  padding: 102px 54px 100px;
  flex-grow: 1;
  max-width: 1020px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleBlock = styled.div`
  display: flex;
  gap: 17px;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 34px;
  color: #102f54;
  font-weight: bold;
`;

const SubTitle = styled.h3`
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 26px;
  color: #102f54;
  margin-top: 100px;
`;

import React from 'react';
import styled from 'styled-components/macro';
import {
  CampaignPublicType,
  CampaignType,
  FullCampaignType,
} from '../../../../project-types/campaign/types';
import { CampaignPaymentStatsType } from '../../../../project-types/campaign-payment/types';
import {
  formatDateDDMMMYYYY,
  formatDateDDMMYY,
  getEndDate,
} from '../../../../ui/DateFormatMethods';
import { showPriceDigits } from '../../../../utils/formats';

import CalendarIcon from '../../../../img/icons/calendar.svg';
import EuroIcon from '../../../../img/icons/euroIcon.svg';
import DocumentCheckIcon from '../../../../img/icons/documentCheck.svg';
import accountImage from '../../../../img/icons/accountImage.svg';

interface SummaryTableProps {
  className?: string;
  onEditDescriptionClickHandler: () => void;
  onEditCollectionRulesClickHandler: () => void;
  onEditCollectionNotificationsClickHandler: () => void;
  campaign: FullCampaignType;
  campaignStats: CampaignPaymentStatsType | null;
}

const SummaryTable: React.FC<SummaryTableProps> = ({
  className,
  onEditDescriptionClickHandler,
  onEditCollectionRulesClickHandler,
  onEditCollectionNotificationsClickHandler,
  campaign,
  campaignStats,
}) => {
  // const initialDonationAmount = campaign.initialDonation ? campaign.initialDonation : campaign.donationAmounts && campaign.donationAmounts[0] ? campaign.donationAmounts[0] : 0;

  return (
    <Container className={className}>
      <TableTitle>Summary</TableTitle>
      <Table>
        <Column>
          <RowItem>
            <RowTitle>
              <TitleIcon alt="" src={CalendarIcon} />
              <TitleText>Start Date</TitleText>
            </RowTitle>
            <ValueText>
              {formatDateDDMMYY(new Date(campaign.scheme.startDate))}
            </ValueText>
          </RowItem>
          {/*<RowItem>*/}
          {/*  <RowTitle>*/}
          {/*    <TitleIcon alt="" src={CalendarIcon} />*/}
          {/*    <TitleText>End Date</TitleText>*/}
          {/*  </RowTitle>*/}
          {/*  <ValueText>{getEndDate(campaign)}</ValueText>*/}
          {/*</RowItem>*/}
          <RowItem>
            <RowTitle>
              <TitleIcon alt="" src={EuroIcon} />
              <TitleText>Number of Collections</TitleText>
            </RowTitle>
            <ValueText>{campaign.collections.length || 0}</ValueText>
          </RowItem>
        </Column>

        <Column>
          <RowItem>
            <RowTitle>
              <TitleIcon alt="" src={DocumentCheckIcon} />
              <TitleText>Collection Notifications</TitleText>
            </RowTitle>
            <EditLink onClick={onEditCollectionNotificationsClickHandler}>
              View/Edit
            </EditLink>
          </RowItem>
          {/*<RowTitle>*/}
          {/*  <TitleIcon alt="" src={CalendarIcon} />*/}
          {/*  <TitleText>Recurring Collection Amount(s)</TitleText>*/}
          {/*</RowTitle>*/}
          {/*<ValueText>{`£ ${showPriceDigits(campaign?.collections && campaign?.collections[0] ? campaign?.collections[0].recurringDonationAmounts : 0)}`}</ValueText>*/}
          {/*<ValueText>{`£ ${showPriceDigits(campaign.donationAmounts && campaign.donationAmounts[0] ? campaign.donationAmounts[0] : 0)}`}</ValueText>*/}
          {/*</RowItem>*/}
          {/*<RowItem>*/}
          {/*<RowTitle>*/}
          {/*  <TitleIcon alt="" src={CalendarIcon} />*/}
          {/*  <TitleText>Initial Donation Amount</TitleText>*/}
          {/*</RowTitle>*/}
          {/*<ValueText>{`£ ${showPriceDigits(initialDonationAmount)}`}</ValueText>*/}
          {/*</RowItem>*/}
          <RowItem>
            <RowTitle>
              <TitleIcon alt="" src={accountImage} />
              <TitleText>Scheme Pre-Authorisation</TitleText>
            </RowTitle>
            <ValueText>{campaign.collectionPreAuthorisations.length}</ValueText>
            {/*<ValueText>{campaign.isAuthorizationRequired === true ? 'Active' : 'Not Active'}</ValueText>*/}
          </RowItem>
        </Column>

        <Column>
          <RowItem>
            <RowTitle>
              <TitleIcon alt="" src={DocumentCheckIcon} />
              <TitleText>Scheme Description</TitleText>
            </RowTitle>
            <EditLink onClick={onEditDescriptionClickHandler}>
              View/Edit
            </EditLink>
          </RowItem>
          <RowItem>
            <RowTitle>
              <TitleIcon alt="" src={DocumentCheckIcon} />
              <TitleText>Collection Rules</TitleText>
            </RowTitle>
            <EditLink onClick={onEditCollectionRulesClickHandler}>
              View/Edit
            </EditLink>
          </RowItem>
          {/*<RowItem>*/}
          {/*  <RowTitle>*/}
          {/*    <TitleIcon alt="" src={DocumentCheckIcon} />*/}
          {/*    <TitleText>Collection Notifications</TitleText>*/}
          {/*  </RowTitle>*/}
          {/*  <EditLink onClick={onEditCollectionNotificationsClickHandler}>View/Edit</EditLink>*/}
          {/*</RowItem>*/}
        </Column>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px 20px 40px;
  background-color: #f7fafc;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
`;

const TableTitle = styled.h3`
  font-size: 14px;
  color: #102f54;
  text-transform: uppercase;
  font-weight: bold;
`;

const Table = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 312px;
`;

const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  border-top: 1px solid #d5dce5;

  &:last-child {
    border-bottom: 1px solid #d5dce5;
  }
`;

const RowTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TitleIcon = styled.img`
  max-width: 15px;
  max-height: 15px;
`;

const TitleText = styled.span`
  font-size: 14px;
  color: #102f54;
`;

const ValueText = styled(TitleText)`
  font-weight: bold;
`;

const EditLink = styled(TitleText)`
  color: #2682de;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default SummaryTable;

import {
  FieldWrap, FieldName, FieldCodeWrap, FieldCode,
} from '../LayoutComponents';

export function ServiceUserNumber() {
  return (
    <FieldWrap>
      <FieldName>Service User Number</FieldName>
      <FieldCodeWrap>
        <FieldCode>1</FieldCode>
        <FieldCode>8</FieldCode>
        <FieldCode>0</FieldCode>
        <FieldCode>0</FieldCode>
        <FieldCode>0</FieldCode>
        <FieldCode>6</FieldCode>
      </FieldCodeWrap>
    </FieldWrap>
  );
}

import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../router/router-config';
import AdminLayout from '../layout/AdminLayout';
import PayoutsView from './PayoutsView';

export default function PayoutsPage() {
  const { push } = useHistory();
  const onMakePayoutClickHandler = () => push(ROUTER_CONFIG.ADMIN_MAKE_PAYOUT.getPath());

  return (
    <AdminLayout>
      <PayoutsView onMakePayoutClickHandler={onMakePayoutClickHandler} />
    </AdminLayout>
  );
}

import * as yup from 'yup';

import { signUpSchema } from '../../project-types/users/schemas';
import { phoneRegExp } from '../../utils/phoneRegExp';

import {
  PersonalDetailsForm,
  DebitDetailsForm,
  EditAccountSettingsForm,
  ChangeDonorPasswordForm,
  ChangeDonorEmailForm,
  ChangeDebitDetailsForm,
} from './types';
import { ResetPasswordFields } from '../../project-types/donor/types';
// no types exists for UkModulusChecking. need to use require instead of import
const UkModulusChecking = require('uk-modulus-checking');

const postcodeRegExp =
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/;

export const EditAccountSettingsSchema: yup.SchemaOf<EditAccountSettingsForm> =
  yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  });

export const ChangeDonorPasswordSchema: yup.SchemaOf<ResetPasswordFields> =
  yup.object({
    password: yup.string().max(256).min(5).defined(),
    'new-password': yup.string().max(256).min(5).defined(),
    'password-confirm': yup
      .string()
      .required()
      .oneOf([yup.ref('new-password'), null], 'Password must match'),
  });

export const ChangeDonorEmailSchema: yup.SchemaOf<ChangeDonorEmailForm> =
  yup.object({
    validatePassword: yup.string().max(256).min(5).defined(),
    email: yup
      .string()
      .email('email must be a valid email')
      .defined('email must be a valid email'),
  });

export const DebitDetailsSchema: yup.SchemaOf<DebitDetailsForm> = yup.object({
  title: yup.string().optional(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  billingName: yup.string().optional(),
  postcode: yup
    .string()
    .matches(postcodeRegExp, 'Please use valid UK postcode')
    .required(),
  address1: yup.string().optional(),
  address2: yup.string().optional(),
  address3: yup.string().optional(),
  town: yup.string().required(),
  county: yup.string().required(),
  country: yup.string().required(),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Please only use digits and "+"')
    .required(),
  email: yup
    .string()
    .email('email must be a valid email')
    .defined('email must be a valid email'),
  bankAccountName: yup.string().max(18).required(),
  branchSortCode1: yup.string().required(),
  branchSortCode2: yup.string().required(),
  branchSortCode3: yup.string().required(),
  accountNumber: yup
    .string()
    .required()
    .test(
      'is-valid-uk-account',
      'Please provide valid UK account',
      (value, context) =>
        new UkModulusChecking({
          accountNumber: value,
          sortCode: `${context.parent.branchSortCode1}${context.parent.branchSortCode2}${context.parent.branchSortCode3}`,
        }).isValid()
    ),
  bankUnhookException: yup.boolean().optional(),
  agree: yup.boolean().required().oneOf([true], 'You cannot proceed unless this box is checked'),
});

export const DebitDetailsWithoutDebitSchema: yup.SchemaOf<DebitDetailsForm> =
  yup.object({
    title: yup.string().optional(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    billingName: yup.string().required(),
    postcode: yup.string().required(),
    address1: yup.string().optional(),
    address2: yup.string().optional(),
    address3: yup.string().optional(),
    town: yup.string().optional(),
    county: yup.string().optional(),
    country: yup.string().optional(),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, 'Please only use digits and "+"')
      .required(),
    email: yup
      .string()
      .email('email must be a valid email')
      .defined('email must be a valid email'),
    bankAccountName: yup.string().optional(),
    branchSortCode1: yup.string().optional(),
    branchSortCode2: yup.string().optional(),
    branchSortCode3: yup.string().optional(),
    accountNumber: yup.string().optional(),
    bankUnhookException: yup.boolean().optional(),
    agree: yup.boolean().required().oneOf([true], 'You cannot proceed unless this box is checked'),
  });

export const ChangeDebitDetailsSchema: yup.SchemaOf<ChangeDebitDetailsForm> =
  yup
    .object({
      validatePassword: yup.string().max(256).min(5).defined(),
    })
    .concat(DebitDetailsSchema);

export const DonorDetailsSchema: yup.SchemaOf<PersonalDetailsForm> = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    isCorporation: yup.boolean().required().oneOf([true, false]),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Password must match'),
  })
  .concat(signUpSchema);

export const DonorCreateSchemas = [DonorDetailsSchema, DebitDetailsSchema];

import { CampaignPublicType } from '../project-types/campaign/types';

export const formatDateDDMMMYYYY = (date: Date) =>
  date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

export const formatDateDDMMYY = (date: Date) =>
  date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

export const getEndDate = (campaign: CampaignPublicType) => {
  const { collectionPeriod, startDate } = campaign;

  const date = new Date(startDate);

  if (!campaign.numberOfCollections) {
    return '∞';
  }

  switch (collectionPeriod) {
    case 'monthly':
      return formatDateDDMMMYYYY(
        new Date(
          date.setMonth(date.getMonth() + 1 * campaign.numberOfCollections),
        ),
      );
    case 'quarterly':
      return formatDateDDMMMYYYY(
        new Date(
          date.setMonth(date.getMonth() + 3 * campaign.numberOfCollections),
        ),
      );
    case 'annualy':
      return formatDateDDMMMYYYY(
        new Date(
          date.setFullYear(
            date.getFullYear() + 1 * campaign.numberOfCollections,
          ),
        ),
      );
    case 'oneoff':
      return '-';
    default:
      return '';
  }
};

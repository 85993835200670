import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Input from '../../../../ui/forms/Input';

import crossImg from '../../../../img/icons/cross.svg';

interface EmailRecipientsProps {
  name: string,
  // onChange: (values: Array<string>) => void;
  setFieldValue: (field: string, value: Array<string>) => void;
}

export default function EmailRecipients({ name, setFieldValue }: EmailRecipientsProps) {
  const [values, setValues] = useState(['']);

  useEffect(() => {
    setFieldValue(name, values);
  }, [values, setFieldValue, name]);

  const onChangeHandler = (key: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValues = [
      ...values.slice(0, key),
      e.target.value,
      ...values.slice(key + 1),
    ];

    setValues(newValues);
  };

  const onAddNewClickHandler = () => setValues((oldValues) => [...oldValues, '']);

  const onRemove = (key: number) => {
    const newValues = ([
      ...values.slice(0, key),
      ...values.slice(key + 1),
    ]);

    setValues(newValues);
  };

  return (
    <Container>
      {values.map((v, i) => (
        <InputBlock>
          <InputStyled placeholder="Email" value={v} onChange={onChangeHandler(i)} />
          {i !== 0 && <CrossStyled onClick={() => onRemove(i)} alt="" src={crossImg} />}
        </InputBlock>
      ))}
      <AddRecipientButton onClick={onAddNewClickHandler}>+ Add new recipient</AddRecipientButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputStyled = styled(Input)`
  width: 100%;
  max-width: 421px;
`;

const AddRecipientButton = styled.span`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const InputBlock = styled.div`
  display: flex;
  gap: 18px;
`;

const CrossStyled = styled.img`
  cursor: pointer;
`;

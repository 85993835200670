import styled from 'styled-components/macro';
import { SchoolFundraisingCampaignInfoType } from '../../../../project-types/payment/types';
import { ButtonDark } from '../../../../ui/Button';
import { formatDateDDMMMYYYY, formatDateDDMMYY } from '../../../../ui/DateFormatMethods';
import { serializeStatisticsPrice } from '../../../../utils/formats';
import { CampaignsTableRow } from '../../types';
import CampaignsTable from './CampaignsTable';
import { PendingMessage, VerifiedMessage } from './VerificationMessages';

interface CampaignsListProps {
  campaignsList: Array<SchoolFundraisingCampaignInfoType>;
  isProfileActive: boolean;
  onCreateCampaignClickHandler: () => void;
  onChangeTimePeriod: (period: [string, string]) => void;
  onCampaignClickHandler: (campaignId: string) => void;
  onChangeId: (id: string) => void;
  onChangeName: (name: string) => void
}

export default function CampaignsList({
  campaignsList,
  isProfileActive,
  onCreateCampaignClickHandler,
  onChangeTimePeriod,
  onCampaignClickHandler,
  onChangeId,
  onChangeName
}: CampaignsListProps) {
  const tableData: Array<CampaignsTableRow> = campaignsList.map((campaign) => ({
    key: campaign.id,
    id: campaign.id,
    campaignName: campaign.schemeName,
    startDate: formatDateDDMMYY(new Date(campaign.startDate)),
    targetEndDate:
      campaign.endDate === '∞'
        ? '∞'
        : formatDateDDMMYY(new Date(campaign.endDate)),
    registeredDonors: campaign.registeredContributors,
    totalDonationsCollected: campaign.totalCollections,
    unhandledExceptions: campaign.numberExceptionsWaiting,
  }));

  const pageContent = () => {
    if (!isProfileActive) {
      return <PendingMessage />;
    }

    // if (isProfileActive && campaignsList.length === 0) {
    //   return <VerifiedMessage />;
    // }

    return (
      <CampaignsTable
        tableData={tableData}
        onChangeTimePeriod={onChangeTimePeriod}
        onCampaignClickHandler={onCampaignClickHandler}
        onChangeName={onChangeName}
        onChangeId={onChangeId}
      />
    );
  };

  return (
    <Container>
      <Header>
        <StyledTitle>Collection Schemes</StyledTitle>
        <StyledButton
          onClick={onCreateCampaignClickHandler}
          disabled={!isProfileActive}
          isDisabled={!isProfileActive}
        >
          + New Scheme
        </StyledButton>
      </Header>
      {pageContent()}
    </Container>
  );
}

const Container = styled.div`
  padding-top: 44px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.h1`
  font-size: 30px;
  color: #102f54;
`;

const StyledButton = styled(ButtonDark)<{ isDisabled: boolean }>`
  padding: 14px 50px;

  color: ${({ isDisabled }) => isDisabled && 'white'};
  background-color: ${({ isDisabled }) => isDisabled && '#BDBDBD'};
  border: ${({ isDisabled }) => isDisabled && 'none'};
`;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { loadCampaignList, selectCampaignList } from '../../../../feature/campaignState';
import ROUTER_CONFIG from '../../../../router/router-config';
import SchoolLayout from '../../layout/SchoolLayoutWithTabs';
import PendingVerified from './PendingVerified';

export default function PendingPage() {
  const { push } = useHistory();

  // const { dispatch } = useDispatch();

  const campaigns = useSelector(selectCampaignList);

  // useEffect(() => {
  //     dispatch(loadCampaignList());
  // }, [dispatch]);

  const onCreateCampaign = () => {
    push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_CREATE.getPath());
  };

  useEffect(() => {
    if (campaigns.length > 0) {
      push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_CREATE.getPath());
    }
  }, [campaigns]);

  return (
    <SchoolLayout>
      <PendingVerified onCreateCampaign={onCreateCampaign} />
    </SchoolLayout>
  );
}

import { useState } from 'react';
import DatePicker from 'antd/lib/date-picker';
import styled from 'styled-components/macro';

import { RadioGroup, RadioItem } from '../../../../../ui/forms/Radio';
import { SecondHeader } from '../../../../../ui/Headers';

import { ACTION_REQUIRED_STATUSES } from '../../../types';

const { RangePicker } = DatePicker;
const ActionStatuses = [{title: 'ALL', value: 'ALL'}, {title: 'Failed Payment', value: 'retry'}, {title: 'Information Error', value: 'info'}, {title: 'Mandate Cancelled', value: 'mandate'}];

interface IActionRequiredActionsProps {
  onChangeFilter: (
    filter: string | null,
  ) => void;
  onChangeTimePeriod: (period: [string, string]) => void;
}

export default function ActionsRequiredActions({
  onChangeFilter,
  onChangeTimePeriod,
}: IActionRequiredActionsProps) {
  const [typeofCampaign, setTypeOfCampaign] = useState('ALL');

  const onFilterClickHandler = (status: string) => {
    if (status === 'All') {
      onChangeFilter(null);
    } else {
      onChangeFilter(status);
    }
  };

  return (
    <div>
      <StyledSecondHeader>Action Required</StyledSecondHeader>
      <Filter>
        <StatusWrapper>
          Status:
          <FilterGroup
            value={typeofCampaign}
            onChange={(e) => setTypeOfCampaign(e.target.value)}
          >
            {ActionStatuses.map((status) => (
              <FilterItem
                key={status.title}
                onClick={() => onFilterClickHandler(status.value)}
                value={status.value}
              >
                {status.title}
              </FilterItem>
            ))}
          </FilterGroup>
        </StatusWrapper>
        <RangePicker onChange={(_e, period) => onChangeTimePeriod(period)} />
      </Filter>
    </div>
  );
}

const StatusWrapper = styled.div`
  display: flex;
`;

const Filter = styled.div`
  margin-top: 10px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterGroup = styled(RadioGroup)`
  margin-left: 14px;
`;

const FilterItem = styled(RadioItem)`
  margin-left: 18px;
`;

const StyledSecondHeader = styled(SecondHeader)`
  margin: 40px 0 15px 0;
`;

import {FC} from 'react';
import styled from 'styled-components';
import {Checkbox} from 'antd';

const NewPrivacy: FC = () => {
    return (
        <Container>
            <TextField />
        </Container>
    );
};

const Container = styled.div`
  width: 100%;
  //height: 100%;
  overflow: hidden;
  //background-color: #1ea7fd;
`;

const TextField = styled.iframe.attrs({src: '/contribagtsat.html'})`
  width: 100%;
  height: 98vh;
  overflow-y: hidden;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: #102f54;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const NumberInSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: #e8f4ff;
  color: #2682de;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;



const Subtitle = styled.span`
  font-weight: 400;
  font-size: 14px;

  color: #909da8;
`;

const CheckboxText = styled.span`
  font-size: 14px;
  font-weight: 600;

  color: #102f54;
`;

export default NewPrivacy;

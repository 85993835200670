import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import DropDown, { DropDownItem } from '../../../ui/DropDown';
import AccountPlaceholderImage from '../../../img/icons/accountImage.svg';
import ROUTER_CONFIG from '../../../router/router-config';
import { removeToken } from '../../../api';
import { useSelector } from 'react-redux';
import { getIsUserDonor, getIsUserSchool, getUserProfile } from '../../../feature/userState';

interface AccountMenuProps {
  accountName: string;
  onDonationCampaignsClick: () => void;
  onDeviceTradeinClick: () => void;
  onAccountSettingsClick: () => void;
  onAffordabilityCalculatorClick: () => void;
}

export default function AccountMenu({
  accountName,
  onDonationCampaignsClick,
  onDeviceTradeinClick,
  onAccountSettingsClick,
  onAffordabilityCalculatorClick,
}: AccountMenuProps) {
  const { push } = useHistory();
  const profile = useSelector(getUserProfile);
  const isDonor = useSelector(getIsUserDonor);
  const isSchool = useSelector(getIsUserSchool);

  const onLogout = () => {
    push(ROUTER_CONFIG.DONOR_LOGIN.getPath());
    removeToken();
  };

  return (
    <Container>
      {profile?.schoolProfile?.logo ? (
        <img
          style={{ width: '25px', maxHeight: '25px', borderRadius: '50%' }}
          alt=""
          src={profile.schoolProfile.logo}
        />
      ) : (
        <img alt="" src={AccountPlaceholderImage} />
      )}
      {accountName && (
        <DropDown title={accountName}>
          <StyledText>your scheme support</StyledText>
          <DropDownItem
            title="Collection Schemes"
            onClick={onDonationCampaignsClick}
          />
          {/* <DropDownItem title="Device Trade-in" onClick={onDeviceTradeinClick} /> */}
          {isSchool && (
            <DropDownItem
              title="1:1 Affordability Calculator"
              onClick={onAffordabilityCalculatorClick}
            />
          )}
          <StyledText>settings</StyledText>
          {isDonor && (
            <DropDownItem
              title="Account Settings"
              onClick={onAccountSettingsClick}
            />
          )}
          <DropDownItem title="Sign Out" onClick={onLogout} />
        </DropDown>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const StyledText = styled.span`
  font-size: 10px;
  color: #909da8;
  text-transform: uppercase;
  padding: 10px;
`;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  loadSchoolFundraisingData,
  selectSchoolFundraisingData,
} from '../../../../feature/schoolsState';
import { getUserProfile, getIsUserAuth } from '../../../../feature/userState';
import ROUTER_CONFIG from '../../../../router/router-config';
import { formatDateString } from '../../../../utils/formats';
import SchoolLayoutWithTabs from '../../layout/SchoolLayoutWithTabs';
import CampaignsList from './CampaignsList';

export default function CampaignsListPage() {
  const campaignsList = useSelector(selectSchoolFundraisingData);
  const profile = useSelector(getUserProfile);
  const isAuth = useSelector(getIsUserAuth);
  const [selectedPeriod, setSelectedPeriod] = useState<[string, string]>([
    '',
    '',
  ]);
  const [selectedID, setSelectedID] = useState<string>('');
  const [selectedName, setSelectedName] = useState<string>('');
  const [filteredCampaignsList, setFilteredCampaignsList] = useState(
    campaignsList
  );

  const dispatch = useDispatch();
  const { push } = useHistory();

  // const campaigns = useSelector(selectCampaignList);

  // useEffect(() => {
  //   if (isAuth && !campaigns.length) {
  //     dispatch(loadCampaignList());
  //   }
  // }, [isAuth, dispatch, campaigns]);

  useEffect(() => {
    if (campaignsList) {
      setFilteredCampaignsList(campaignsList);
    }
  }, [campaignsList]);

  useEffect(() => {
    dispatch(loadSchoolFundraisingData());
  }, []);

  useEffect(() => {
    if (isAuth) {
      const startDate = selectedPeriod[0]
        ? formatDateString(selectedPeriod[0])
        : '';
      const endDate = selectedPeriod[1]
        ? formatDateString(selectedPeriod[1], true)
        : '';

      const start = new Date(startDate);
      const end = new Date(endDate);

      const updatedList = [...campaignsList];

      if (!startDate || !endDate || !selectedID || !selectedName) {
        setFilteredCampaignsList(campaignsList);
      }
      if (
        startDate !== '' &&
        endDate !== '' &&
        selectedID === '' &&
        selectedName === ''
      ) {
        setFilteredCampaignsList(
          updatedList.filter((scheme) => {
            const schemeStart = new Date(scheme.startDate);
            const schemeEnd = new Date(scheme.endDate);

            return schemeStart > start && schemeEnd < end;
          })
        );
      }
      if (startDate !== '' && endDate !== '' && selectedName !== '') {
        setFilteredCampaignsList(
          updatedList.filter((scheme) => {
            const schemeStart = new Date(scheme.startDate);
            const schemeEnd = new Date(scheme.endDate);

            return (
              schemeStart > start &&
              schemeEnd < end &&
              scheme.schemeName.includes(selectedName)
            );
          })
        );
      }
      if (startDate !== '' && endDate !== '' && selectedID !== '') {
        setFilteredCampaignsList(
          updatedList.filter((scheme) => {
            const schemeStart = new Date(scheme.startDate);
            const schemeEnd = new Date(scheme.endDate);

            return (
              schemeStart > start && schemeEnd < end && scheme.id === selectedID
            );
          })
        );
      }
      if (
        startDate !== '' &&
        endDate !== '' &&
        selectedID !== '' &&
        selectedName !== ''
      ) {
        setFilteredCampaignsList(
          updatedList.filter((scheme) => {
            const schemeStart = new Date(scheme.startDate);
            const schemeEnd = new Date(scheme.endDate);

            return (
              schemeStart > start &&
              schemeEnd < end &&
              scheme.id === selectedID &&
              scheme.schemeName.includes(selectedName)
            );
          })
        );
      }
      if (startDate === '' && endDate === '' && selectedName !== '') {
        setFilteredCampaignsList(updatedList.filter((scheme) => scheme.schemeName.includes(selectedName)));
      }
      if (
        startDate === '' &&
        endDate === '' &&
        selectedName === '' &&
        selectedID !== ''
      ) {
        setFilteredCampaignsList(updatedList.filter((scheme) => scheme.id === selectedID));
      }
      if (
        startDate === '' &&
        endDate === '' &&
        selectedName !== '' &&
        selectedID !== ''
      ) {
        setFilteredCampaignsList(updatedList.filter((scheme) => scheme.id === selectedID && scheme.schemeName.includes(selectedName)));
      }
    }
  }, [isAuth, dispatch, selectedPeriod, selectedID, selectedName]);

  const onCreateCampaignClickHandler = () => {
    push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_CREATE.getPath());
  };

  const onCampaignClickHandler = (campaignId: string) => {
    push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_INFO.getPath({ campaignId }));
  };

  return (
    <SchoolLayoutWithTabs>
      <CampaignsList
        campaignsList={filteredCampaignsList}
        isProfileActive={profile?.user.active || false}
        onCreateCampaignClickHandler={onCreateCampaignClickHandler}
        onChangeTimePeriod={setSelectedPeriod}
        onCampaignClickHandler={onCampaignClickHandler}
        onChangeId={setSelectedID}
        onChangeName={setSelectedName}
      />
    </SchoolLayoutWithTabs>
  );
}

import styled from 'styled-components/macro';
import { Input, message } from 'antd';
import { useRef } from 'react';
import { ButtonDark, ButtonDarkBlack, ButtonLightGray } from '../../../ui/Button';
import CloseImg from '../../../img/close.svg';
import { CreateInvitesListType } from '../../../project-types/campaign/types';
// import useKeyboardAction from '../../hooks/keyboardAction';

interface ComponentProps {
  onNext: () => void
  onClose: () => void
  onPreview: () => void

  contacts: CreateInvitesListType['members']
  setContacts: (contacts: CreateInvitesListType['members']) => void
  message: CreateInvitesListType['message']
  setMessage: (message: CreateInvitesListType['message']) => void
}

export default function InviteSentModal({
  onNext,
  onClose,
  onPreview,
  contacts,
  setContacts,
  setMessage,
  message,
}: ComponentProps) {
  // const [contacts, setContacts] = useState<CreateInvitesListType['members']>([]);

  // useKeyboardAction({
  //   enterKeyPressed: onAddGroupName,
  //   escKeyPressed: onClose,
  // });

  const onAddNew = () => {
    setContacts([...contacts, {
      email: '',
      fullName: ''
    }])
  }

  const onChange = (field: 'email' | 'fullName', key: number) => (e: any) => {
    const { value } = e.target;
    const newValues: CreateInvitesListType['members'] = [
      ...contacts.slice(0, key),
      {
        ...contacts[key],
        [field]: value
      },
      ...contacts.slice(key + 1),
    ];

    setContacts(newValues);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  return (
    <Wrapper onSubmit={onSubmit}>
      <ModalTop>
        <Close>
          <img src={CloseImg} onClick={onClose} />
        </Close>
        <Title>Send invites by email</Title>
      </ModalTop>
      <ModalMiddle>
        <ModalText>
          {/* <Link href="#">Import your email contacts</Link> or enter them below: */}
        </ModalText>

        {contacts.map((c, k) => (
          <InputWrap key={`${k}`}>
            <InputStyled onChange={onChange('email', k)} placeholder="Email" type="email" value={c.email} />
            <InputStyled onChange={onChange('fullName', k)} placeholder="Full Name (recommended)" value={c.fullName} />
          </InputWrap>
        ))}

        <ModalText>
          <Link onClick={onAddNew} href="#">+ Add another contact</Link>
          {' '}
          {/* or <Link href="#">add many at once</Link> */}
        </ModalText>
        <TextareaWrap>
          <Textarea maxLength={255} onChange={(e) => setMessage(e.target.value)} placeholder="Add a personal message (optional)">{message}</Textarea>
          <Symbols>
            {message?.length}
            /225
          </Symbols>
        </TextareaWrap>
      </ModalMiddle>
      <ModalBottom>
        <ButtonStyledOutlined onClick={onPreview}>
          Preview
        </ButtonStyledOutlined>
        <ButtonStyled type="submit">
          Send
        </ButtonStyled>
      </ModalBottom>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  max-width: 647px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
`;

const ModalTop = styled.div`
  padding-top: 88px;
  width: 100%;
  padding-bottom: 21px;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
`;

const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const ModalMiddle = styled.div`
  padding: 33px 27px;
  width: 100%;
  box-sizing: border-box;
`;

const ModalBottom = styled.div`
  padding: 15px 27px;
  padding-bottom: 110px;
  box-sizing: border-box;
  display: grid;
  gap: 21px;
  grid-template-columns: 1fr 1fr;
`;

const Title = styled.h1`
  font-weight: 450;
  font-size: 28px;
  line-height: 26px;
  text-align: center;
`;

const ModalText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: black;
`;

const Link = styled.a`
  text-decoration: underline!important;
  color: black!important;
`;

const TextareaWrap = styled.div`
  position: relative;
`;

const Symbols = styled.div`
  position: absolute;
  bottom: 9px;
  right: 17px;
  font-size: 15px;
  color: #BDBDBD;
  line-height: 22px;
`;

const Textarea = styled.textarea`
  padding: 14px 17px;
  width: 100%;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
  height: 167px;
  outline: none;
`;

const ButtonStyled = styled(ButtonDark)`
  padding: 11px 0;
  width: 286px;
`;

const ButtonStyledOutlined = styled(ButtonLightGray)`
  padding: 11px 0;
  width: 286px;
`;

const InputWrap = styled.div`
  margin-bottom:  17px;
`;

const InputStyled = styled(Input)`
  width: 50%;
  height: 49px;
  &:nth-last-of-type(1) {
    margin-left: 16px;
    width: calc(50% - 16px);
  }
`;

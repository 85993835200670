import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { request, setBearerToken } from '../api';
import {
  ApiVerifySchools,
  ApiGetAdminSchoolList,
  ApiGetAdminSchool,
  ApiAdminDeleteSchool,
  ApiAdminCreateSchool,
} from '../project-types/admin-schools/api-types';
import { SchoolAdminPublicType } from '../project-types/admin-schools/type';
import { ApiGetFundraisingOverview } from '../project-types/campaign-payment/api-types';
import { createAxiosConfig } from '../project-types/common/axios';
import { HTTP_METHODS } from '../project-types/common/http-types';
import { SchoolFundraisingCampaignInfoType as CampaignFundraisingSchools } from '../project-types/payment/types';
import {
  ApiChangeSchool,
  ApiCreateSchool,
} from '../project-types/schools/api-types';
import {
  SchoolPublicTypeLite,
  SchoolPublicType,
} from '../project-types/schools/types';
import { ApiCreateUser } from '../project-types/users/api-types';
import { AppThunk, RootState } from '../store';
import { loadProfile } from './userState';

type CurrentSchoolType = SchoolPublicTypeLite | null;
type SchoolsListType = Array<SchoolAdminPublicType> | null;

const initialCurrentSchoolType: CurrentSchoolType = null;
type AdminCurrentSchoolType = SchoolPublicType | null;
type SchoolFundraisingDataType = Array<CampaignFundraisingSchools>;

const initialSchoolsListState: SchoolsListType = null;
const initialAdminCurrentSchoolState: AdminCurrentSchoolType = null;
const initialSchoolFundraisingDataState: SchoolFundraisingDataType = [];

export const currentSchoolType = createSlice({
  name: 'list',
  initialState: initialCurrentSchoolType as CurrentSchoolType,
  reducers: {
    setCurrentSchool: (state, action: PayloadAction<SchoolPublicTypeLite>) =>
      action.payload,
  },
});

export const schoolsList = createSlice({
  name: 'schoolsList',
  initialState: initialSchoolsListState as SchoolsListType,
  reducers: {
    setSchoolsList: (
      state,
      action: PayloadAction<Array<SchoolAdminPublicType>>
    ) => action.payload,
  },
});

export const adminCurrentSchool = createSlice({
  name: 'adminCurrentSchool',
  initialState: initialAdminCurrentSchoolState as AdminCurrentSchoolType,
  reducers: {
    setAdminCurrentSchool: (state, action: PayloadAction<SchoolPublicType>) =>
      action.payload,
  },
});

export const schoolFundraisingData = createSlice({
  name: 'schoolFundraisingData',
  initialState: initialSchoolFundraisingDataState,
  reducers: {
    setSchoolFundraisingData: (
      state,
      action: PayloadAction<Array<CampaignFundraisingSchools>>
    ) => [...action.payload],
  },
});

const schoolState = combineReducers({
  currentSchool: currentSchoolType.reducer,
  currentAdminSchool: adminCurrentSchool.reducer,
  schoolsList: schoolsList.reducer,
  schoolFundraisingData: schoolFundraisingData.reducer,
});

export const { setCurrentSchool } = currentSchoolType.actions;
export const { setSchoolsList } = schoolsList.actions;
export const { setAdminCurrentSchool } = adminCurrentSchool.actions;
export const { setSchoolFundraisingData } = schoolFundraisingData.actions;

export default schoolState;

export const getAdminCurrentSchool = (
  schoolId: SchoolPublicType['_id']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetAdminSchool>({
      url: ['/admin/schools', '/', schoolId],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetAdminSchool['successResponse'] }) =>
    dispatch(setAdminCurrentSchool(response.data.schoolProfile))
  );
};

export const signUpSchool = (assignment: ApiCreateSchool['data']) =>
  request(
    createAxiosConfig<ApiCreateSchool>({
      url: ['/schools'],
      data: assignment,
      method: HTTP_METHODS.POST,
    })
  );

export const signInSchool = (
  fields: ApiCreateUser['data'],
  setLoading: any
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig({
      url: ['/login'],
      data: fields,
      method: HTTP_METHODS.POST,
    }),
    [200, 401]
  ).then(({ headers, status }) => {
    if (status === 200) {
      setBearerToken(headers.authorization, new Date().toISOString());
      // dispatch(setCurrentProfile(data.user));
      dispatch(loadProfile());
    } else {
      message.error('Auth error');
      setLoading(false);
    }
  });
};

export const getSchoolsList = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetAdminSchoolList>({
      url: ['/admin/schools'],
      method: HTTP_METHODS.GET,
    })
  ).then((res: { data: ApiGetAdminSchoolList['successResponse'] }) =>
    dispatch(setSchoolsList(res.data.schoolsList))
  );
};

export const verifySchool = (schoolId: string): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiVerifySchools>({
      url: ['/admin/schools', '/', 'verify'],
      data: {
        schoolIds: [schoolId],
      },
      method: HTTP_METHODS.PATCH,
    })
  )
    .then(() => dispatch(getSchoolsList()))
    .catch(() => {
      message.error('Verifying error');
    });
};

export const adminDeleteSchool = (schoolId: string): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminDeleteSchool>({
      url: ['/admin/schools', '/', schoolId],
      method: HTTP_METHODS.DELETE,
    })
  )
    .then(() => dispatch(getSchoolsList()))
    .catch(() => message.error('Deletion error'));
};

export const updateSchool = (school: ApiChangeSchool['data']) =>
  request(
    createAxiosConfig<ApiChangeSchool>({
      url: ['/schools'],
      data: school,
      method: HTTP_METHODS.PATCH,
    })
  );

export const loadSchoolFundraisingData = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetFundraisingOverview>({
      url: ['/oto-schemes'],
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetFundraisingOverview['successResponse'] }) => {
    dispatch(setSchoolFundraisingData(response.data.schemeArray));
  });
};

// export const adminCreateSchool = (formData: ApiAdminCreateSchool['data'], callbackFn: () => void) => request(createAxiosConfig<ApiAdminCreateSchool>({
//   url: ['/admin/schools'],
//   method: HTTP_METHODS.POST,
//   data: formData,
// })).then(() => callbackFn()).catch(() => message.error('Creating school account error'));

export const adminCreateSchool = (
  formData: ApiAdminCreateSchool['data'],
  callbackFn: () => void
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminCreateSchool>({
      url: ['/admin/schools'],
      method: HTTP_METHODS.POST,
      data: formData,
    })
  )
    .then(() => {
      dispatch(getSchoolsList());
      callbackFn();
    })
    .catch((err) => {
      if (
        err.data.user &&
        err.data.user[0] === 'User with this email already exists'
      ) {
        message.error('User with this email already exists');
      }
      if (
        err.data.email &&
        err.data.email[0] === 'email must be a valid email'
      ) {
        message.error('Email must be a valid email');
      }
    });
};

export const getCurrentSchool = (state: RootState) =>
  state.schoolState.currentSchool;
export const selectSchoolsList = (state: RootState) =>
  state.schoolState.schoolsList;
export const selectAdminCurrentSchool = (state: RootState) =>
  state.schoolState.currentAdminSchool;
export const selectSchoolFundraisingData = (state: RootState) =>
  state.schoolState.schoolFundraisingData;

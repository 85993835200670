import styled from 'styled-components/macro';
import { useHistory, useLocation } from 'react-router-dom';
import CreateSchoolForm, { CreateSchoolFormTypes } from './CreateSchoolForm';
import ROUTER_CONFIG from '../../../router/router-config';

interface CreateSchoolTypes extends CreateSchoolFormTypes {
  // onSignUp: (schoolFields: SignUpSchoolFields) => void
}

export default function CreateSchool({ onSignUp, externalErrors }: CreateSchoolTypes) {
  const location = useLocation();
  const { push } = useHistory();

  const onLoginClickHandler = () => {
    push(ROUTER_CONFIG.LOGIN_SCHOOL_PAGE.getPath(), { from: location });
  };

  return (
    <CreateSchooFormWrap>
      <Content>
        <HeaderBlock>
          <MainHeader>
            Create your School Account
          </MainHeader>
          <LoginBlock>
            <StyledText>Already have an account?</StyledText>
            <LoginLink onClick={onLoginClickHandler}>Login</LoginLink>
          </LoginBlock>
        </HeaderBlock>
        <CreateSchoolForm onSignUp={onSignUp} externalErrors={externalErrors} />
      </Content>
    </CreateSchooFormWrap>
  );
}

const CreateSchooFormWrap = styled.div`
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 100px;
`;

const LoginBlock = styled.div`
  display: flex;
  gap: 3px;
`;

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const StyledText = styled.span`
  font-size: 14px;
  color: #102F54;
`;

const LoginLink = styled(StyledText)`
  color: #2682DE;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const MainHeader = styled.h1`
  font-weight: 600;
  font-size: 20px;
  margin-top: 0;
  color: #102F54
`;

const Content = styled.div`
  width: 100%;
  max-width: 870px;
  display: flex;
  padding: 30px 70px;
  flex-direction: column;
`;

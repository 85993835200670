import CampaignInfo from '../components/school/campaign/campaign-folder/CampaignInfoPage';
import CreateCampaignPage from '../components/school/campaign/create-campaign/CreateCampaignPage';
import CreateSchoolPage from '../components/school/create-school/CreateSchoolPage';
import PendingPage from '../components/school/pending/pending-verification/PendingPage';
import PendingPageVerified from '../components/school/pending/pending-verified/PendingPageVerified';
import LoginSchoolPage from '../components/school/school-login/LoginSchoolPage';
import DonorPaymentPreviewPage from '../components/donor/payment/preview/DonorPaymentPreviewPage';
import DonorInviteAcceptPage from '../components/donor/invite/accept/DonorInviteAcceptPage';
import WelcopmePage from '../components/welcome/WelcomePage';
import InvitePage from '../components/school/invite-donors/InvitePage';
import FundraisingOverviewPage from '../components/school/campaign/fundraising-overview/FundraisingOverviewPage';
import PayoutPage from '../components/school/campaign/payouts/PayoutPage';
import SchoolSettings from '../components/school/school-settings/SchoolSettingsPage';
import FinishPage from '../components/donor/sign-up/finish/FinishPage';
import CreateDonorPage from '../components/donor/invite/create-donor/CreateDonorPage';
import DonorLoginPage from '../components/donor/login/DonorLoginPage';
import ThanksPage from '../components/donor/sign-up/thanks/ThanksPage';
import DonorPagesPage from '../components/donor/layout/DirectPay/DirectPayPage';
import DonorDashboard from '../components/donor/dashboard/DonorDashboardPage';
import DonorSettingsPage from '../components/donor/donor-settings/DonorSettingsPage';
import DonorBillingInformationPage from '../components/donor/billing-information/BillingInformationPage';
import DonorChangeEmailPage from '../components/donor/change-email/ChangeEmailPage';
import DonorChangePasswordPage from '../components/donor/change-password/ChangePasswordPage';
import DonorCompletePage from '../components/donor/complete/DonorCompletePage';
import PrivacyPage from '../components/donor/sign-up/privacy/PrivacyPage';
import AgreementPage from '../components/donor/sign-up/agreement/AgreementPage';
import AdminDocuments from '../components/admin/documents/DocumentsPage';
import AdminNewSchoolAccountPage from '../components/admin/new-school-account/NewSchoolAccountPage';
import AdminDonorsPage from '../components/admin/donors/DonorsPage';
import AdminSchoolsPage from '../components/admin/schools/SchoolsPage';
import AdminSchoolPage from '../components/admin/school-page/SchoolPage';
import AdminAllCollections from '../components/admin/all-collections/AllCollectionsPage';
import AdminSettingsPage from '../components/admin/settings/SettingsPage';
import AdminDonorPage from '../components/admin/donor/DonorPage';
import AdminPayoutsPage from '../components/admin/payouts/PayoutsPage';
import AdminMakePayoutPage from '../components/admin/make-payout/MakePayoutPage';
import AdminLoginPage from '../components/admin/login/AdminLoginPage';
import ForgotPasswordPage from '../components/forgot-password/ForgotPasswordPage';
import ForgotPasswordCompletePage from '../components/forgot-password/ForgotPasswordCompletePage';
import AdminCreateCampaignPage from '../components/admin/create-campaign/AdminCreateCampaignPage';
import CampaignsListPage from '../components/school/campaign/campaigns-list/CampaignsListPage';
import SchemeSupportSettingsPage from '../components/school/scheme-support-settings/SchemeSupportSettingsPage';
import DeviceTradeinPage from '../components/school/device-tradein/overview-page/DeviceTradeinPage';
import DeviceTradeinDetailsPage from '../components/school/device-tradein/details-page/DeviceTradeinDetailsPage';
import RegisterDeviceTradeinPage from '../components/donor/register-device-tradein/RegisterDeviceTradeinPage';
import RegisterDeviceTradeinFormPage from '../components/donor/register-device-tradein/form-page/RegisterDeviceTradeinFormPage';
import CharitiesOverviewPage from '../components/admin/charities-folder/charities-overview/CharitiesOverviewPage';
import CreateCharityPage from '../components/admin/charities-folder/create-charity/CreateCharityPage';
import CharityPage from '../components/admin/charities-folder/charity/CharityPage';
import DonorDeviceTradeinPage from '../components/donor/device-tradein/overview-page/DonorDeviceTradeinPage';
import DonorDeviceTradeinDetailsPage from '../components/donor/device-tradein/details-page/DonorDeviceTradeinDetailsPage';
import NewPrivacy from '../components/donor/invite/accept/Components/NewPrivacy';
import ContributorInfo from '../components/school/campaign/campaign-contributor/ContributorInfoPage';

// : Record<string, RouteProps>

const ROUTER_CONFIG = {
  WELCOME_PAGE: {
    path: '/welcome',
    getPath: () => '/welcome',
    exact: true,
    children: <WelcopmePage />,
  },
  FORGOT_PASSWORD_PAGE: {
    path: '/forgot-password',
    getPath: () => '/forgot-password',
    exact: true,
    children: <ForgotPasswordPage />,
  },
  FORGOT_PASSWORD_COMPLETE_PAGE: {
    path: '/forgot-password/complete',
    getPath: () => '/forgot-password/complete',
    exact: true,
    children: <ForgotPasswordCompletePage />,
  },
  CREATE_SCHOOL_PAGE: {
    path: '/school/sign-up',
    getPath: () => '/school/sign-up',
    exact: true,
    children: <CreateSchoolPage />,
  },
  LOGIN_SCHOOL_PAGE: {
    path: '/school/login',
    getPath: () => '/school/login',
    exact: true,
    children: <LoginSchoolPage />,
  },
  SCHOOL_CAMPAIGNS_LIST: {
    path: '/school/campaigns',
    getPath: () => '/school/campaigns',
    exact: true,
    children: <CampaignsListPage />,
  },
  SCHOOL_CAMPAIGN_INFO: {
    path: '/school/campaign/info/:campaignId',
    getPath: ({ campaignId }: { campaignId: string }) =>
      `/school/campaign/info/${campaignId}`,
    exact: true,
    children: <CampaignInfo />,
  },
  SCHOOL_CAMPAIGN_CONTRIBUTOR_INFO: {
    path: '/school/campaign/info/:campaignId/contributor/:contributorId',
    getPath: ({
      campaignId,
      contributorId,
    }: {
      campaignId: string;
      contributorId: string;
    }) => `/school/campaign/info/${campaignId}/contributor/${contributorId}`,
    exact: true,
    children: <ContributorInfo />,
  },
  SCHOOL_CAMPAIGN_CREATE: {
    path: '/school/campaign/create',
    getPath: () => '/school/campaign/create',
    exact: true,
    children: <CreateCampaignPage />,
  },
  SCHOOL_SIGNUP_PENDING: {
    path: '/school/sign-up/pending/pending-verification',
    getPath: () => '/school/sign-up/pending/pending-verification',
    exact: true,
    children: <PendingPage />,
  },
  SCHOOL_SIGNUP_PENDING_VERIFIED: {
    path: '/school/sign-up/pending/pending-verified',
    getPath: () => '/school/sign-up/pending/pending-verified',
    exact: true,
    children: <PendingPageVerified />,
  },
  DONOR_PAYMENT_PREVIEW: {
    path: '/donor/payment/preview',
    getPath: () => '/donor/payment/preview',
    exact: true,
    children: <DonorPaymentPreviewPage />,
  },
  SCHOOL_INVITE: {
    path: '/school/capaign/:campaignId/invite-donors',
    getPath: ({ campaignId }: { campaignId: string }) =>
      `/school/capaign/${campaignId}/invite-donors`,
    exact: true,
    children: <InvitePage />,
  },
  SCHOOL_CAMPAIGN_FUNRAISING: {
    path: '/school/capaign/fundraising',
    getPath: () => '/school/capaign/fundraising',
    exact: true,
    children: <FundraisingOverviewPage />,
  },
  SCHOOL_CAMPAIGN_PAYOUTS: {
    path: '/school/campaign/payouts',
    getPath: () => '/school/campaign/payouts',
    exact: true,
    children: <PayoutPage />,
  },
  SCHOOL_SETTINGS: {
    path: '/school/settings',
    getPath: (ref = '') => `/school/settings${ref}`,
    exact: true,
    children: <SchoolSettings />,
  },
  SCHOOL_SCHEME_SUPPORT_SETTINGS: {
    path: '/school/scheme-settings',
    getPath: () => '/school/scheme-settings',
    exact: true,
    children: <SchemeSupportSettingsPage />,
  },
  SCHOOL_DEVICE_TRADEIN: {
    path: '/school/device-tradein',
    getPath: () => '/school/device-tradein',
    exact: true,
    children: <DeviceTradeinPage />,
  },
  SCHOOL_DEVICE_TRADEIN_DETAILS: {
    path: '/school/device-tradein/:campaignId',
    getPath: ({ campaignId }: { campaignId: string }) =>
      `/school/device-tradein/${campaignId}`,
    exact: true,
    children: <DeviceTradeinDetailsPage />,
  },
  DONOR_CREATE_FORM: {
    path: '/donor/create/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/create/${campaignId}/${inviteCode}`,
    exact: true,
    children: <CreateDonorPage />,
  },
  DONOR_SIGNUP_FINISH: {
    path: '/donor/sign-up/finish',
    getPath: () => '/donor/sign-up/finish',
    exact: true,
    children: <FinishPage />,
  },
  DONOR_SIGNUP_THANKS: {
    path: '/donor/sign-up/thanks',
    getPath: () => '/donor/sign-up/thanks',
    exact: true,
    children: <ThanksPage />,
  },
  DONOR_SIGNUP_DIRECTPAY: {
    path: '/donor/sign-up/directpay/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/sign-up/directpay/${campaignId}/${inviteCode}`,
    exact: true,
    children: <DonorPagesPage />,
  },
  DONOR_LOGIN: {
    path: '/',
    getPath: () => '/',
    exact: true,
    children: <DonorLoginPage />,
  },
  DONOR_INVITE_ACCEPT: {
    path: '/donor/invite/accept/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/invite/accept/${campaignId}/${inviteCode}`,
    exact: true,
    children: <DonorInviteAcceptPage />,
  },
  DONOR_SIGNUP_PRIVACY: {
    path: '/donor/privacy/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/privacy/${campaignId}/${inviteCode}`,
    exact: true,
    children: <PrivacyPage />,
  },
  DONOR_SIGNUP_AGREEMENT: {
    path: '/donor/agreement/:campaignId/:inviteCode',
    getPath: ({
      campaignId,
      inviteCode,
    }: {
      campaignId: string;
      inviteCode: string;
    }) => `/donor/agreement/${campaignId}/${inviteCode}`,
    exact: true,
    children: <AgreementPage />,
  },
  DONOR_COMPLETE: {
    path: '/donor/complete',
    getPath: () => '/donor/complete',
    exact: true,
    children: <DonorCompletePage />,
  },
  DONOR_DASHBOARD: {
    path: '/donor/dashboard',
    getPath: () => '/donor/dashboard',
    exact: true,
    children: <DonorDashboard />,
  },
  DONOR_SETTINGS: {
    path: '/donor/settings',
    getPath: () => '/donor/settings',
    exact: true,
    children: <DonorSettingsPage />,
  },
  DONOR_BILLING_INFORMATION: {
    path: '/donor/billing',
    getPath: () => '/donor/billing',
    exact: true,
    children: <DonorBillingInformationPage />,
  },
  DONOR_CHANGE_EMAIL: {
    path: '/donor/:donorId/change-email',
    getPath: ({ donorId }: { donorId: string }) =>
      `/donor/${donorId}/change-email`,
    exact: true,
    children: <DonorChangeEmailPage />,
  },
  DONOR_CHANGE_PASSWORD: {
    path: '/donor/:donorId/change-password',
    getPath: ({ donorId }: { donorId: string }) =>
      `/donor/${donorId}/change-password`,
    exact: true,
    children: <DonorChangePasswordPage />,
  },
  DONOR_DEVICE_TRADEIN: {
    path: '/donor/device-tradein',
    getPath: () => '/donor/device-tradein',
    exact: true,
    children: <DonorDeviceTradeinPage />,
  },
  DONOR_DEVICE_TRADEIN_DETAILS: {
    path: '/donor/device-tradein/:id',
    getPath: ({ id }: { id: string }) => `/donor/device-tradein/${id}`,
    exact: true,
    children: <DonorDeviceTradeinDetailsPage />,
  },
  REGISTER_DEVICE_TRADEIN_PAGE: {
    path: '/register-device-tradein',
    getPath: () => '/register-device-tradein',
    exact: true,
    children: <RegisterDeviceTradeinPage />,
  },
  REGISTER_DEVICE_TRADEIN_FORM: {
    path: '/register-device-tradein-form',
    getPath: () => '/register-device-tradein-form',
    exact: true,
    children: <RegisterDeviceTradeinFormPage />,
  },
  ADMIN_LOGIN: {
    path: '/admin/login',
    getPath: () => '/admin/login',
    exact: true,
    children: <AdminLoginPage />,
  },
  ADMIN_DOCUMENTS: {
    path: '/admin/documents',
    getPath: () => '/admin/documents',
    exact: true,
    children: <AdminDocuments />,
  },
  ADMIN_NEW_SCHOOL_ACCOUNT: {
    path: '/admin/new-school-account',
    getPath: () => '/admin/new-school-account',
    exact: true,
    children: <AdminNewSchoolAccountPage />,
  },
  ADMIN_DONORS: {
    path: '/admin/donors',
    getPath: () => '/admin/donors',
    exact: true,
    children: <AdminDonorsPage />,
  },
  ADMIN_SCHOOLS: {
    path: '/admin/schools',
    getPath: () => '/admin/schools',
    exact: true,
    children: <AdminSchoolsPage />,
  },
  ADMIN_SCHOOL_PAGE: {
    path: '/admin/school/:schoolId',
    getPath: ({ schoolId }: { schoolId: string }) =>
      `/admin/school/${schoolId}`,
    exact: true,
    children: <AdminSchoolPage />,
  },
  ADMIN_ALL_COLLECTIONS: {
    path: '/admin/all-collections',
    getPath: () => '/admin/all-collections',
    exact: true,
    children: <AdminAllCollections />,
  },
  ADMIN_SETTINGS: {
    path: '/admin/settings',
    getPath: () => '/admin/settings',
    exact: true,
    children: <AdminSettingsPage />,
  },
  ADMIN_DONOR: {
    path: '/admin/donor/:donorId',
    getPath: ({ donorId }: { donorId: string }) => `/admin/donor/${donorId}`,
    exact: true,
    children: <AdminDonorPage />,
  },
  ADMIN_PAYOUTS: {
    path: '/admin/payouts',
    getPath: () => '/admin/payouts',
    exact: true,
    children: <AdminPayoutsPage />,
  },
  ADMIN_MAKE_PAYOUT: {
    path: '/admin/make-payout',
    getPath: () => '/admin/make-payout',
    exact: true,
    children: <AdminMakePayoutPage />,
  },
  ADMIN_CREATE_CAMPAIGN: {
    path: '/admin/create-campaign/:schoolId',
    getPath: ({ schoolId }: { schoolId: string }) =>
      `/admin/create-campaign/${schoolId}`,
    exact: true,
    children: <AdminCreateCampaignPage />,
  },
  ADMIN_CHARITIES_OVERVIEW: {
    path: '/admin/charities',
    getPath: () => '/admin/charities',
    exact: true,
    children: <CharitiesOverviewPage />,
  },
  ADMIN_CREATE_CHARITY: {
    path: '/admin/create-charity',
    getPath: () => '/admin/create-charity',
    exact: true,
    children: <CreateCharityPage />,
  },
  ADMIN_CHARITY_VIEW: {
    path: '/admin/charity/:charityId',
    getPath: ({ charityId }: { charityId: string }) =>
      `/admin/charity/${charityId}`,
    exact: true,
    children: <CharityPage />,
  },
  NEW_PRIVACY: {
    path: '/new/privacy/test',
    getPath: () => '/',
    exact: true,
    children: <NewPrivacy />,
  },
  // DONOR_LOGIN: {
  //   path: '/',
  //   getPath: () => '/',
  //   exact: true,
  //   children: <DonorLoginPage />,
  // },
};

export default ROUTER_CONFIG;

import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { updateSchool } from '../../../feature/schoolsState';
import { loadProfile } from '../../../feature/userState';
import ROUTER_CONFIG from '../../../router/router-config';
import SchoolLayout from '../layout/SchoolLayoutWithTabs';
import { SchoolFormUpdateType } from '../types';
import SchoolSettings from './SchoolSettings';

export default function SchoolSettingsPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const onUpdate = (schoolFields: SchoolFormUpdateType) => {
    updateSchool(schoolFields)
      .then(() => {
        message.info('School is updated');
        setTimeout(() => {
            dispatch(loadProfile());
        }, 1000)

        // history.push(ROUTER_CONFIG.SCHOOL_SIGNUP_PENDING.getPath());
      })
      .catch((e) => {
        if (e?.response?.data) {
          // debugger;
          Object.keys(e.response.data)
            .forEach((row) => e.response.data[row]
              .forEach((err: string) => message.error(err)));
          // Object.keys(e?.response?.data)
          // setExternalErrors(e.response.data);
        }
      });
  };

  const onChangePasswordClickHandler = () => history.push(ROUTER_CONFIG.FORGOT_PASSWORD_PAGE.getPath(), { loginPage: 'school' });

  return (
    <SchoolLayout>
      <SchoolSettings onUpdate={onUpdate} onChangePasswordClickHandler={onChangePasswordClickHandler} />
    </SchoolLayout>
  );
}

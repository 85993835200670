import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../router/router-config';
import DonorAccountLayout from '../layout/DonorAccountLayout';
import RegisterDeviceTradeinView from './RegisterDeviceTradeinView';

export default function RegisterDeviceTradeinPage() {
  const { push } = useHistory();

  const onRegisterDeviceClickHandler = () => push(ROUTER_CONFIG.REGISTER_DEVICE_TRADEIN_FORM.getPath());

  return (
    <DonorAccountLayout>
      <RegisterDeviceTradeinView onRegisterDeviceClickHandler={onRegisterDeviceClickHandler} />
    </DonorAccountLayout>
  );
}

import DataTable from '../../../../ui/elements/Table';
import { DonorCampaignsTableRow, DonorCampaignsTableConfig } from '../../types';
import { DonorSchemesType } from '../../../../project-types/donor-payment/types';

interface DonorsTableProps {
  tableRows: Array<any>;
  selectedCampaigns: Array<string>;
  onCampaignSelectHandler: (campaignIds: Array<string>) => void;
}

export default function DonorsTable({
  tableRows,
  selectedCampaigns,
  onCampaignSelectHandler,
}: DonorsTableProps) {
  const tableConfig = [
    {
      title: 'ID',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
    },
    {
      title: 'Scheme Name',
      dataIndex: 'schemeName',
      key: 'schemeName',
    },
    {
      title: 'Scheme Choice',
      dataIndex: 'schemeChoice',
      key: 'schemeChoice',
    },
    {
      title: 'Number of Students',
      dataIndex: 'numberOfStudents',
      key: 'numberOfStudents',
    },
    {
      title: 'Student Reference(s)',
      dataIndex: 'studentReferences',
      key: 'studentReferences',
    },
    // {
    //   title: 'Active Period',
    //   dataIndex: 'activePeriod',
    //   key: 'activePeriod',
    // },
    {
      title: 'Collection sum',
      dataIndex: 'collectionSum',
      key: 'collectionSum',
    },
  ];

  return (
    <DataTable
      dataSource={tableRows}
      columns={tableConfig}
      // rowSelection={{
      //   type: 'checkbox',
      //   selectedRowKeys: selectedCampaigns,
      //   onChange: (ids) => onCampaignSelectHandler(ids as Array<string>),
      // }}
    />
  );
}

import styled, {keyframes} from 'styled-components/macro';


export default function WaitForApprove() {


    return (
        <div style={{marginTop: '10px', marginBottom: '-25px'}}>
            <AnimText>P</AnimText>
            <AnimText>l</AnimText>
            <AnimText>e</AnimText>
            <AnimText>a</AnimText>
            <AnimText>s</AnimText>
            <AnimText>e</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>w</AnimText>
            <AnimText>a</AnimText>
            <AnimText>i</AnimText>
            <AnimText>t</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>a</AnimText>
            <AnimText>n</AnimText>
            <AnimText>d</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>d</AnimText>
            <AnimText>o</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>n</AnimText>
            <AnimText>o</AnimText>
            <AnimText>t</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>r</AnimText>
            <AnimText>e</AnimText>
            <AnimText>l</AnimText>
            <AnimText>o</AnimText>
            <AnimText>a</AnimText>
            <AnimText>d</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>t</AnimText>
            <AnimText>h</AnimText>
            <AnimText>i</AnimText>
            <AnimText>s</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>p</AnimText>
            <AnimText>a</AnimText>
            <AnimText>g</AnimText>
            <AnimText>e</AnimText>
            <AnimText>.</AnimText>
            <AnimText>.</AnimText>
            <AnimText>.</AnimText>
            <AnimText>t</AnimText>
            <AnimText>h</AnimText>
            <AnimText>i</AnimText>
            <AnimText>s</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>m</AnimText>
            <AnimText>a</AnimText>
            <AnimText>y</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>t</AnimText>
            <AnimText>a</AnimText>
            <AnimText>k</AnimText>
            <AnimText>e</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>s</AnimText>
            <AnimText>e</AnimText>
            <AnimText>v</AnimText>
            <AnimText>e</AnimText>
            <AnimText>r</AnimText>
            <AnimText>a</AnimText>
            <AnimText>l</AnimText>
            <AnimText>&nbsp;</AnimText>
            <AnimText>s</AnimText>
            <AnimText>e</AnimText>
            <AnimText>c</AnimText>
            <AnimText>o</AnimText>
            <AnimText>n</AnimText>
            <AnimText>d</AnimText>
            <AnimText>s</AnimText>
        </div>

    );
}



const flip = keyframes`
 0%,
      80% {
        transform: rotateY(360deg);
      }
`

const AnimText = styled.span`
  position: relative;
      display: inline-block;
      color: rgb(0, 0, 0);
      animation: ${flip} 3s infinite;
      animation-delay: calc(0.2s);
`;


import {
  FieldWrap, FieldName, FieldCodeWrap, FieldCode,
} from '../LayoutComponents';

export function Referenca() {
  return (
    <FieldWrap>
      <FieldName>Referenca</FieldName>
      <FieldCodeWrap>
        <FieldCode>
          M
        </FieldCode>
        <FieldCode>
          Y
        </FieldCode>
        <FieldCode>
          W
        </FieldCode>
        <FieldCode>
          J
        </FieldCode>
        <FieldCode>
          J
        </FieldCode>
        <FieldCode>
          V
        </FieldCode>
        <FieldCode>
          D
        </FieldCode>
        <FieldCode>
          T
        </FieldCode>
        <FieldCode>
          9
        </FieldCode>
        <FieldCode>
          9
        </FieldCode>
      </FieldCodeWrap>
    </FieldWrap>
  );
}

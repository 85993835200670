import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../router/router-config';
import AdminLayout from '../layout/AdminLayout';
import MakePayout from './MakePayout';

export default function MakePayoutPage() {
  const { push } = useHistory();

  const onSelectClickHandler = (searchValue: string) => console.log(searchValue);
  const onPaidClickHandler = () => console.log('paid');
  const onGoBackClickHandler = () => push(ROUTER_CONFIG.ADMIN_PAYOUTS.getPath());
  const onSubmitClickHandler = () => console.log('submit');

  return (
    <AdminLayout>
      <MakePayout onSubmitClickHandler={onSubmitClickHandler} onGoBackClickHandler={onGoBackClickHandler} onPaidClickHandler={onPaidClickHandler} onSelectClickHandler={onSelectClickHandler} />
    </AdminLayout>
  );
}

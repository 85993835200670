import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../../router/router-config';
import AdminLayout from '../../layout/AdminLayout';
import CharitiesOverview from './CharitiesOverview';

export default function CharitiesOverviewPage() {
  const { push } = useHistory();

  const onRowClickHandler = (charityId: string) => console.log(charityId);
  const onNewCharityClickHandler = () => push(ROUTER_CONFIG.ADMIN_CREATE_CHARITY.getPath());

  return (
    <AdminLayout>
      <CharitiesOverview onNewCharityClickHandler={onNewCharityClickHandler} onRowClickHandler={onRowClickHandler} />
    </AdminLayout>
  );
}

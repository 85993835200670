import styled from 'styled-components/macro'; // Импортим именно /macro (для читаемых имен классов)
import DonorLogo from '../../../../img/DonorLogo.png';
import SilverWing from '../../../../img/silverwing.png';
import DirectDebit from '../../../../img/directdebit.png';
import { ButtonDark } from '../../../../ui/Button';
import { DonorPublicFields } from '../../../../project-types/donor/types';
import {
  BankAddress,
  ContactDetails,
  NameOfAccountHolder,
  BankAccountNumber,
  BranchSortCode,
  ServiceUserNumber,
  Referenca,
  BankInstruction,
  TodayDate,
  StatementName,
  GuaranteeBlock,
  ButtonsBlock,
} from './blockComponents';
import Logo from '../../../layout/Logo';

interface DirectPayProps {
  donorFields: DonorPublicFields;
  onSubmitHandler: () => void;
  onEditDetailsHandler: () => void;
  onCancelHandler: () => void;
}

// Комопонент - функция
export default function DirectPay({
  donorFields,
  onSubmitHandler,
  onEditDetailsHandler,
  onCancelHandler,
}: DirectPayProps) {
  const {
    firstName,
    lastName,
    address1,
    address2,
    address3,
    accountNumber,
    branchSortCode1,
    branchSortCode2,
    branchSortCode3,
    town,
    country,
    bankUnhookException,
    postcode,
    phoneNumber,
  } = donorFields;

  const requiredData = firstName && lastName && address1;

  const todayFormatDate = ((date: Date) =>
    date.toLocaleDateString('uk').split('.').join('/'))(new Date());

  return (
    <Wrap>
      {requiredData && (
        <Container>
          <LogoStyled />
          <ButtonsBlock
            onAcceptHandler={onSubmitHandler}
            onCancelHandler={onCancelHandler}
          />
          {bankUnhookException === false ? (
            <>
              <Content>
                <Left>
                  <img src={SilverWing} alt="" />
                  <BankAddress />
                  <ContactDetails
                    firstName={firstName as string}
                    lastName={lastName as string}
                    address1={address1}
                    address2={address2}
                    address3={address3}
                    town={town}
                    country={country}
                    postCode={postcode}
                    phoneNumber={phoneNumber}
                  />
                  <NameOfAccountHolder
                    firstName={firstName as string}
                    lastName={lastName as string}
                  />
                  <BankAccountNumber accountNumber={accountNumber as string} />
                  <BranchSortCode
                    branchSortCode1={branchSortCode1 as string}
                    branchSortCode2={branchSortCode2 as string}
                    branchSortCode3={branchSortCode3 as string}
                  />
                </Left>
                <Right>
                  <TitleWrap>
                    <Title>
                      Instruction to your bank or building society to pay by
                      Direct Debit
                    </Title>
                    <img src={DirectDebit} alt="" />
                  </TitleWrap>
                  <ServiceUserNumber />
                  <Referenca />
                  <BankInstruction />
                  <TodayDate todayDate={todayFormatDate} />
                  <StatementName />
                </Right>
              </Content>
              <BottomText>
                Banks and building society may not accept Direct Debit
                Instructions for some types of account
              </BottomText>
              <GuaranteeBlock imageSrc={DirectDebit} />
              <CardBottomText>
                Please do not send this form to your bank
              </CardBottomText>
              <ButtonsBlock
                onAcceptHandler={onSubmitHandler}
                onCancelHandler={onCancelHandler}
              />
            </>
          ) : (
            <Content>
              <BankUnhookText>
                You confirm having received authorisation to make a manual
                payment. Please contact{' '}
                <a href="mailto:support@silverwing.co">support@silverwing.co</a>{' '}
                for further details, or your school's scheme administrator
              </BankUnhookText>
            </Content>
          )}
        </Container>
      )}
      <ButtonStyledBottom onClick={onEditDetailsHandler}>
        Edit Details
      </ButtonStyledBottom>
    </Wrap>
  );
}

const Wrap = styled.main`
  padding: 102px 0 32px 0;
`;

const Container = styled.div`
  max-width: 968px;
  margin: 0px auto;
`;

const LogoStyled = styled(Logo)`
  margin: 0px auto;
  display: block;
  margin-bottom: 94px;
`;

const ButtonStyledBottom = styled(ButtonDark)`
  padding: 11px 43px;
  margin-left: 50px;
  margin-top: 95px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
`;

const Left = styled.div`
  flex: 1;
  width: 50%;
  margin-right: 89px;
`;

const Right = styled.div`
  flex: 1;
  width: 50%;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Title = styled.h2`
  font-size: 34px;
  line-height: 36px;
  max-width: 269px;
  margin-right: 54px;
  margin-bottom: 57px;
`;

const BottomText = styled.p`
  font-size: 14px;
  line-height: 14px;
  margin: 25px 0;
`;

const CardBottomText = styled.p`
  float: right;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
`;

const BankUnhookText = styled.span`
  border-radius: 4px;
  padding: 18px;
  background-color: #e0e0e0;
  font-size: 18px;

  a {
    color: blue;
  }
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { message } from 'antd';
import { AppThunk, RootState } from '../store';
import { request } from '../api';
import { HTTP_METHODS } from '../project-types/common/http-types';
import { createAxiosConfig } from '../project-types/common/axios';
import { ApiGetProfile } from '../project-types/users/api-types';
import { ApiUpdateDonor } from '../project-types/donor/api-types';
import { ResetPasswordFields } from '../project-types/donor/types';

// const a: ProfileState = { user: id };

type ProfileState = {
  user?: ApiGetProfile['successResponse'];
};

const initialProfileState: ProfileState = {
  user: undefined,
};

export const userProfile = createSlice({
  name: 'UserProfile',
  initialState: initialProfileState,
  reducers: {
    setCurrentProfile: (
      state,
      action: PayloadAction<ProfileState['user']>
    ) => ({ user: action.payload }),
  },
});

export const { setCurrentProfile } = userProfile.actions;

export const loadProfile = (): AppThunk => (dispatch) => {
  request(
    createAxiosConfig({
      url: ['/api/restricted/who-am-i'],
      method: HTTP_METHODS.POST,
    }),
    [200, 401, 403]
  ).then(({ data, status }) => {
    if (status === 200) {
      dispatch(setCurrentProfile(data));
    } else if (status === 401) {
      message.error('Auth error');
    }
  });
};

export const changeDonorProfile =
  (formData: ApiUpdateDonor['data'], callbackFn?: () => void): AppThunk =>
  (dispatch) =>
    request(
      createAxiosConfig<ApiUpdateDonor>({
        url: ['/oto-parent-dash'],
        data: formData,
        method: HTTP_METHODS.PATCH,
      })
    )
      .then(() => {
        dispatch(loadProfile());
        message.success('Profile successfully changed');

        if (callbackFn) {
          callbackFn();
        }
      })
      .catch((err) => {
        if (
          err.data.errors &&
          err.data.errors.donor &&
          err.data.errors.donor[0]
        ) {
          message.error(err.data.errors.donor[0]);
        } else {
          message.error('Changing profile error');
        }
      });

export const restorePassword = (login: string, onSuccessCb: () => void) =>
  request(
    createAxiosConfig({
      url: ['/reset-password-request'],
      method: HTTP_METHODS.POST,
      data: {
        login,
      },
    })
  )
    .then((res: { data: { success: string } }) => {
      message.success(res.data.success);
      onSuccessCb();
    })
    .catch((err) => {
      if (err.data.error) {
        message.error(err.data.error);
      }
    });

export const changePassword =
  (formData: ResetPasswordFields, callbackFn?: () => void): AppThunk =>
  (dispatch) =>
    request(
      createAxiosConfig({
        url: ['/change-password'],
        data: formData,
        method: HTTP_METHODS.POST,
      })
    )
      .then(() => {
        message.success('Password successfully changed');

        if (callbackFn) {
          callbackFn();
        }
      })
      .catch((err) => {
        if (
          err.data.errors 
          // &&
          // err.data.errors.donor &&
          // err.data.errors.donor[0]
        ) {
          // message.error(err.data.errors.donor[0]);
        } else {
          message.error('Changing password error');
        }
      });
export default userProfile.reducer;

export const getUserProfile = (state: RootState) => state.userProfile?.user;
export const getIsUserDonor = (state: RootState) =>
  state.userProfile?.user?.user?.userType === 'donor';
export const getIsUserSchool = (state: RootState) =>
  state.userProfile?.user?.user?.userType === 'school';
export const getIsUserAuth = (state: RootState) => !!state.userProfile?.user;

import DataTable from '../../../../../ui/elements/Table';
import { TableColumns, TableRow } from '../FundraisingOverviewPage';

interface FundraisingTableProps {
  tableRows: Array<TableRow>;
}

const tableConfig: TableColumns = [
  {
    title: 'Campaign Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
  },
];

export default function FundraisingTable({ tableRows }: FundraisingTableProps) {
  return (
    <DataTable
      dataSource={tableRows}
      columns={tableConfig}
    />
  );
}

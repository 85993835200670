import {
  DonorPublicFields,
  DonorPublicFieldsWithEmail,
} from '../../../../../project-types/donor/types';
import DataTable from '../../../../../ui/elements/Table';
import { CollectionActionTableRow, DonorTableConfig, DonorTableRow } from '../../../types';
import styled from "styled-components/macro";
import { ColumnsType } from "antd/lib/table";


interface DonorsTableProps {
  tableSource: Array<DonorTableRow>;
  onSelectDonor: (donorIds: Array<DonorPublicFieldsWithEmail['_id']>) => void;
  selectedDonorsKeys: Array<DonorPublicFieldsWithEmail['_id']>;
  onShowContributorInfoHandler: (contributorId: string) => void;
}

export default function DonorsTable({
  tableSource,
  onSelectDonor,
  selectedDonorsKeys,
  onShowContributorInfoHandler
}: DonorsTableProps) {
  const tableConfig: ColumnsType<any> = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (value, record) => (
        <ContributorNameLink
          onClick={() => onShowContributorInfoHandler(record.key)}
        >
          {value}
        </ContributorNameLink>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Gift Aid Registered',
      dataIndex: 'giftAidBool',
      key: 'giftAidBool',
    },
    {
      title: 'Student Reference(s)',
      dataIndex: 'studentReferences',
      key: 'studentReferences',
    },
    // {
    //   title: 'Donation Amount',
    //   dataIndex: 'amount',
    //   key: 'amount',
    // },
    {
      title: 'Successful Collections',
      dataIndex: 'successfulCollections',
      key: 'successfulCollections',
      render: (text) => (<GreenColumn>{text}</GreenColumn>),
    },
    {
      title: 'Failed Collections',
      dataIndex: 'failedCollections',
      key: 'failedCollections',
      render: (text) => (<RedColumn>{text}</RedColumn>),
    },
    {
      title: 'Planned Collections',
      dataIndex: 'plannedCollections',
      key: 'plannedCollections',
    },
    {
      title: 'Total Collections',
      dataIndex: 'totalCollected',
      key: 'totalCollected',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    // {
    //   title: 'Gift Aid Amount',
    //   dataIndex: 'giftAidAmount',
    //   key: 'giftAidAmount',
    // },
  ];


  return (
    <DataTable
      dataSource={tableSource}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE' }}
    />
  );
}

const RedColumn = styled.p`
  margin: 0;
  color: red;
`;

const GreenColumn = styled.p`
  margin: 0;
  color: green;
`;

const ContributorNameLink = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

import { message } from 'antd';
import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { request, setBearerToken } from '../api';
import { createAxiosConfig } from '../project-types/common/axios';
import { HTTP_METHODS } from '../project-types/common/http-types';
import { ApiCreateUser } from '../project-types/users/api-types';
import { AppThunk, RootState } from '../store';
import { loadProfile } from './userState';
import {
  AdminPaymentSchoolFiltersOrderType,
  AdminPaymentSchoolItem,
  AdminPaymentSchoolStatsType,
  AdminPaymentItem,
  AdminPaymentStatsType,
  AdminPaymentsFiltersAndOrderType,
  AdminDonorPaymentItem,
  AdminDonorCollectionsItem,
} from '../project-types/admin-payment/types';
import {
  ApiAdminGetDonorsCollections,
  ApiGetAdminPayments,
  ApiGetAdminDonorPayments,
  ApiGetAdminSchoolPayments,
} from '../project-types/admin-payment/api-types';
import { CampaignAdminCreateType } from '../project-types/admin-campaigns/types';
import { ApiAdminCreateCampiagn } from '../project-types/admin-campaigns/api-types';
import { loadAdminCampaignsList } from './campaignState';

export const signInAdmin = (fields: ApiCreateUser['data']): AppThunk => (
  dispatch
) => {
  request(
    createAxiosConfig({
      url: ['/login'],
      data: fields,
      method: HTTP_METHODS.POST,
    }),
    [200, 401]
  ).then(({ data, status }) => {
    if (status === 200) {
      setBearerToken(data.token, new Date().toISOString());
      // dispatch(setCurrentProfile(data.user));
      dispatch(loadProfile());
    } else {
      message.error('Auth error');
    }
  });
};

type AdminSchoolCollectionsState = {
  payments: Array<AdminPaymentSchoolItem>;
  stats: AdminPaymentSchoolStatsType | null;
};
type AdminCollectionsState = {
  payments: Array<AdminPaymentItem>;
  stats: AdminPaymentStatsType | null;
};
type AdminDonorsListState = Array<AdminDonorCollectionsItem>;
type AdminDonorCollectionsState = Array<AdminDonorPaymentItem>;

const initialAdminSchoolCollectionsState: AdminSchoolCollectionsState = {
  payments: [],
  stats: null,
};
const initialAdminCollectionsState: AdminCollectionsState = {
  payments: [],
  stats: null,
};
const initialAdminDonorsListState: AdminDonorsListState = [];
const initialAdminDonorCollectionsState: AdminDonorCollectionsState = [];

export const adminSchoolCollections = createSlice({
  name: 'adminSchoolCollections',
  initialState: initialAdminSchoolCollectionsState,
  reducers: {
    setAdminSchoolCollections: (
      state,
      action: PayloadAction<ApiGetAdminSchoolPayments['successResponse']>
    ) => action.payload,
  },
});

export const adminCollections = createSlice({
  name: 'adminCollections',
  initialState: initialAdminCollectionsState,
  reducers: {
    setAdminCollections: (
      state,
      action: PayloadAction<ApiGetAdminPayments['successResponse']>
    ) => action.payload,
  },
});

export const adminDonorsList = createSlice({
  name: 'adminDonorsList',
  initialState: initialAdminDonorsListState,
  reducers: {
    setAdminDonorsList: (
      state,
      action: PayloadAction<Array<AdminDonorCollectionsItem>>
    ) => [...(action.payload || [])],
  },
});

export const adminDonorCollections = createSlice({
  name: 'adminDonorCollections',
  initialState: initialAdminDonorCollectionsState,
  reducers: {
    setAdminDonorCollections: (
      state,
      action: PayloadAction<Array<AdminDonorPaymentItem>>
    ) => [...(action.payload || [])],
  },
});

const adminState = combineReducers({
  schoolCollections: adminSchoolCollections.reducer,
  collections: adminCollections.reducer,
  adminDonorsList: adminDonorsList.reducer,
  donorCollections: adminDonorCollections.reducer,
});

export default adminState;

export const { setAdminDonorCollections } = adminDonorCollections.actions;
export const { setAdminCollections } = adminCollections.actions;
export const { setAdminSchoolCollections } = adminSchoolCollections.actions;
export const { setAdminDonorsList } = adminDonorsList.actions;

export const getAdminSchoolCollections = (
  schoolId: string,
  filters: AdminPaymentSchoolFiltersOrderType
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetAdminSchoolPayments>({
      url: ['/admin', '/', 'payment', '/', 'school', '/', schoolId],
      method: HTTP_METHODS.GET,
      params: filters,
    })
  ).then((res: { data: ApiGetAdminSchoolPayments['successResponse'] }) =>
    dispatch(setAdminSchoolCollections(res.data))
  );
};

export const getAdminCollections = (
  filters: AdminPaymentsFiltersAndOrderType
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetAdminPayments>({
      url: ['/admin', '/', 'payment', '/', 'list-stats'],
      params: filters,
      method: HTTP_METHODS.GET,
    })
  ).then((response: { data: ApiGetAdminPayments['successResponse'] }) =>
    dispatch(setAdminCollections(response.data))
  );
};

export const getAdminDonorsList = (
  params: ApiAdminGetDonorsCollections['params']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminGetDonorsCollections>({
      url: ['/admin', '/', 'payment', '/', 'donors'],
      method: HTTP_METHODS.GET,
      params,
    })
  ).then((res: { data: ApiAdminGetDonorsCollections['successResponse'] }) =>
    dispatch(setAdminDonorsList(res.data.donors))
  );
};

export const getAdminDonorCollections = (
  donorId: string,
  params: ApiGetAdminDonorPayments['params']
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiGetAdminDonorPayments>({
      url: ['/admin', '/', 'payment', '/', 'donor', '/', donorId],
      method: HTTP_METHODS.GET,
      params,
    })
  ).then((res: { data: ApiGetAdminDonorPayments['successResponse'] }) =>
    dispatch(setAdminDonorCollections(res.data.payments))
  );
};

export const adminCreateCampaign = (
  createFields: CampaignAdminCreateType,
  onSuccessCb: () => void
): AppThunk => (dispatch) => {
  request(
    createAxiosConfig<ApiAdminCreateCampiagn>({
      url: ['/admin/campaigns'],
      method: HTTP_METHODS.POST,
      data: createFields,
    })
  ).then(() => {
    dispatch(loadAdminCampaignsList(createFields.school));
    message.success('Campaign successfully created');
    onSuccessCb();
  });
};

export const selectAdminCollections = (state: RootState) =>
  state.adminState.collections;
export const selectAdminDonorsList = (state: RootState) =>
  state.adminState.adminDonorsList;
export const selectAdminSchoolCollections = (state: RootState) =>
  state.adminState.schoolCollections;
export const selectAdminDonorCollections = (state: RootState) =>
  state.adminState.donorCollections;

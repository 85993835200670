import styled from 'styled-components/macro';
import BackButton from '../../../../ui/elements/BackButton';
import { RegistredDevicesTradeinTableRow } from '../../types';
import RegistredDevicesTable from './RegistredDevicesTable';
import StatusBlock from './StatusBlock';
import SummaryInfoBlock from './SummaryInfoBlock';

interface DeviceTradeinDetailsProps {
  tableData: Array<RegistredDevicesTradeinTableRow>;
  onSelectRows: (deviceIds: Array<string>) => void
  onBackClickHandler: () => void;
}

export default function DeviceTradeinDetails({ tableData, onSelectRows, onBackClickHandler }: DeviceTradeinDetailsProps) {
  return (
    <Container>
      <LeftColumn>
        <TitleBlock>
          <StyledBackButton onClickHandler={onBackClickHandler} />
          <Title>Year 7 1:1 Initiative</Title>
        </TitleBlock>
        <StatusBlock />
        <SummaryInfoBlock />
      </LeftColumn>
      <RightColumn>
        <SubTitle>Registered Devices</SubTitle>
        <RegistredDevicesTable tableData={tableData} onSelectRows={onSelectRows} />
      </RightColumn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 120px;
  margin-bottom: 70px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  gap: 20px;
`;

const TitleBlock = styled.div`
  display: flex;
  gap: 30px;
`;

const StyledBackButton = styled(BackButton)`
  padding-top: 10px;
`;

const Title = styled.h1`
  font-size: 30px;
  color: #102f54;
  font-weight: bold;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
`;

import styled from 'styled-components/macro';
import { ButtonDark, ButtonDarkBlack, ButtonLightGray } from '../../../ui/Button';
import CloseImg from '../../../img/close.svg';
// import useKeyboardAction from '../../hooks/keyboardAction';

interface ComponentProps {
  onNext: () => void
  onClose: () => void,
  schoolName: string,
  campaignName: string,
  fullName: string
}

export default function InviteTemplateModal({
  onNext,
  onClose,
  schoolName,
  campaignName,
  fullName,
}: ComponentProps) {
  // useKeyboardAction({
  //   enterKeyPressed: onAddGroupName,
  //   escKeyPressed: onClose,
  // });

  return (
    <Wrapper>
      <ModalTop>
        <Close>
          <img src={CloseImg} onClick={onClose} />
        </Close>
        <Title>Send invites by email</Title>
      </ModalTop>
      <ModalMiddle>
        <ModalCard>
          <ModalCardText>
            {schoolName}
            {' '}
            <b>would like you to make a donation for</b>
            {' '}
            {campaignName}
            .
          </ModalCardText>
          <div>
            <b>
              About this fundraiser:
            </b>
          </div>
          <span>School Donation Scheme Title</span>
          <MakeDonationButton>Make Donation</MakeDonationButton>
          <ModalCardBottomText>
            This email was sent on behalf of
            {' '}
            {fullName}
            .
          </ModalCardBottomText>
        </ModalCard>
      </ModalMiddle>
      <ModalBottom>
        <ButtonStyledOutlined onClick={onClose}>
          Back
        </ButtonStyledOutlined>
        <ButtonStyledGrey>
          Download HTML
        </ButtonStyledGrey>
      </ModalBottom>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 647px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
`;

const ModalTop = styled.div`
  padding-top: 88px;
  width: 100%;
  padding-bottom: 21px;
  border-bottom: 1px solid #E0E0E0;
  position relative;
`;

const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const ModalMiddle = styled.div`
  padding: 32px 51px;
  border-bottom: 1px solid #E0E0E0;
`;

const ModalBottom = styled.div`
  padding: 32px 25px;
  display: grid;
  gap: 21px;
  grid-template-columns: 1fr 1fr;
`;

const ModalCard = styled.div`
  background: #F8F8F8;
  padding: 82px 60px 56px 60px;
  max-width: 544px;
`;

const ModalCardText = styled.p`
  font-style: italic;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: black;
  padding-bottom: 41px;
  border-bottom: 1px solid #BDBDBD;
`;

const Title = styled.h1`
  font-weight: 450;
  font-size: 28px;
  line-height: 26px;
  text-align: center;
`;

const ButtonStyledOutlined = styled(ButtonLightGray)`
  padding: 11px 0;
  width: 286px;
`;

const ButtonStyledGrey = styled(ButtonDark)`
  padding: 11px 0;
  width: 286px;
  background-color: #BDBDBD;
  display: none;
`;

const MakeDonationButton = styled(ButtonStyledGrey)`
  margin-top: 28px;
`;

const ModalCardBottomText = styled.span`
  border-top: 1px solid #BDBDBD;
  padding-top: 15px;
  display: block;
  margin-top: 89px;
  font-size: 16px;
  line-height: 20px;
`;

import { ReactNode, CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from "antd";

interface InputWrapProps {
  title: string
  children: ReactNode
  hasError?: boolean
  isRequired?: boolean
  style?: CSSProperties
  labelStyle?: CSSProperties,
  tooltip?: boolean,
  tooltipText?: string
}

export default function InputWrap({
  title, children, hasError, isRequired, style = {}, labelStyle = {}, tooltip = false, tooltipText = ''
}: InputWrapProps) {
  return (
    <InputWrapBlock style={style} hasError={hasError}>
      <LabelWrapper>
        <Label isRequired={isRequired} style={labelStyle}>
          {title}
        </Label>
        {tooltip && tooltipText !== '' && <Tooltip title={tooltipText} placement="right">
          <TooltipInfo>?</TooltipInfo>
        </Tooltip>}
      </LabelWrapper>
      {children}
    </InputWrapBlock>
  );
}

const InputWrapBlock = styled.div<{ hasError?: boolean }>`
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 24px;
  & input, textarea {
    border-color: ${(props) => (props.hasError ? 'red' : 'default')}
  }
`;

const Label = styled.label<{ isRequired?: boolean }>`
  font-size: 16px;
  color: #102F54;

  &::after {
    ${({ isRequired }) => isRequired && `
    content: '*';
    padding-left: 2px;
    color: red;`
}
  }
`;

const LabelWrapper = styled.div`
  display: flex;
`;

const TooltipInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #5a5a5a;
  color: white;
  font-size: 14px;
  cursor: pointer;
`;

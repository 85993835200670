import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Agreement from './Agreement';
import { getUserProfile } from '../../../../feature/userState';
import { useCampaignFromInvite } from '../../hooks';
import ROUTER_CONFIG from '../../../../router/router-config';
import DonorLayout from '../../layout/DonorSignupLayout';

export default function AgreementPage() {
  const { push } = useHistory();
  const {
    campaign, inviteCode, currentSchool, campaignId,
  } = useCampaignFromInvite();
  const profile = useSelector(getUserProfile);

  const onNext = () => {

    if (campaign?._id) {
      if (profile && profile.donorProfile) {
        push(ROUTER_CONFIG.DONOR_SIGNUP_DIRECTPAY.getPath({ campaignId: campaign?._id, inviteCode }), { donorProfile: profile.donorProfile });
      } else {
        push(ROUTER_CONFIG.DONOR_CREATE_FORM.getPath({ campaignId: campaign?._id, inviteCode }));
      }
    }
  };

  const onBack = () => {
    if (campaign?._id) {
      push(ROUTER_CONFIG.DONOR_SIGNUP_PRIVACY.getPath({ campaignId: campaign?._id, inviteCode }));
    }
  };

  return (
    <DonorLayout>
      <Agreement currentSchool={currentSchool} onBack={onBack} campaign={campaign} onNext={onNext} />
    </DonorLayout>
  );
}

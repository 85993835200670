import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import DonorDashboard from './DonorDashboard';
import DonorLayout from '../layout/DonorLayoutWithTabs';
import {
  deleteCampaignForDonor,
  loadDonorCollections,
  selectDonorCollections,
  selectDonorSchemesList,
  loadDonorSchemesList,
  selectDashboardData,
  loadDashboardData,
} from '../../../feature/campaignState';

export default function DonorDashboardPage() {
  const [currentPeriod, setCurrentPeriod] = useState(new Date().getMonth() + 1);
  const [selectedCollectionPeriod, setSelectedCollectionPeriod] = useState<[string, string]>([
    '',
    '',
  ]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<Array<string>>([]);

  const onIncDateRange = () => {
    if (currentPeriod < 12) {
      setCurrentPeriod(currentPeriod + 1);
    } else {
      setCurrentPeriod(1);
    }
  };
  const onDecrDateRange = () => {
    if (currentPeriod > 1) {
      setCurrentPeriod(currentPeriod - 1);
    } else {
      setCurrentPeriod(12);
    }
  };
  const donorSchemesList = useSelector(selectDonorSchemesList);
  const donorCollections = useSelector(selectDonorCollections);
  const dashboardData = useSelector(selectDashboardData);
  const dispatch = useDispatch();

  const [filteredCollectionData, setFilteredCollectionData] = useState(donorCollections || []);

  useEffect(() => {
    if (donorSchemesList.length === 0) {
      dispatch(loadDonorSchemesList());
    }
    dispatch(loadDashboardData());
    dispatch(loadDonorCollections());
  }, [dispatch]);

  // useEffect(() => {
  //   const startDate = new Date(
  //     new Date().getFullYear(),
  //     currentPeriod - 1,
  //     1
  //   ).toISOString();
  //   const endDate = new Date(
  //     new Date().getFullYear(),
  //     currentPeriod,
  //     1,
  //     23,
  //     59,
  //     0
  //   ).toISOString();
  //
  //   dispatch(loadDonorCollections(startDate, endDate));
  // }, [dispatch, currentPeriod]);

  useEffect(() => {
    const tmpArray = [...donorCollections];
    if (selectedCollectionPeriod[0] !== '' && selectedCollectionPeriod[1] !== '') {
      const date1 = new Date(selectedCollectionPeriod[0]).getTime();
      const date2 = new Date(selectedCollectionPeriod[1]).getTime();

      const filteredByDateCampaigns = donorCollections.filter((collection) => {
        const collectionDate = new Date(collection.collectionDate).getTime();

        if (date1 <= collectionDate && collectionDate <= date2) {
          return true;
        }

        return false;
      });

      setFilteredCollectionData(filteredByDateCampaigns.sort((a, b) => a.id - b.id));
    } else {
      setFilteredCollectionData(tmpArray.sort((a, b) => a.id - b.id));
    }
  }, [selectedCollectionPeriod, donorCollections]);

  const onCancelDonationsClickHandler = () => {
    // TODO fix it
    selectedCampaigns.forEach((campaignId) => dispatch(deleteCampaignForDonor(campaignId)));
  };

  return (
    <DonorLayout>
      <DonorDashboard
        onCancelDonationsClickHandler={onCancelDonationsClickHandler}
        donorSchemesList={donorSchemesList}
        donorCollections={filteredCollectionData}
        dashboardData={dashboardData}
        currentPeriodMonth={currentPeriod}
        onIncDateRange={onIncDateRange}
        onDecrDateRange={onDecrDateRange}
        selectedCampaigns={selectedCampaigns}
        onCampaignSelectHandler={setSelectedCampaigns}
        onChangeTimePeriod={setSelectedCollectionPeriod}
      />
    </DonorLayout>
  );
}

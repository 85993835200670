import styled from 'styled-components/macro';
import EditIcon from '../../../img/icons/edit_icon_blue.svg';

interface UpdateEmailComponentProps {
  email: string;
  onEmailEditClickHandler: () => void;
}

export default function UpdateEmailComponent({ email, onEmailEditClickHandler }: UpdateEmailComponentProps) {
  return (
    <Container>
      <StyledSpan>Email</StyledSpan>
      <Content>
        <StyledInput>{email}</StyledInput>
        {/* <StyledButton onClick={onEmailEditClickHandler}>
          <img alt="edit icon" src={EditIcon} />
        </StyledButton> */}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: column;
`;

const Content = styled.div`
  display: flex;
  gap: 13px;
`;

const StyledInput = styled.div`
  background: #EEEEEE;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  height: 42px;
  padding: 0 10px;
  width: 100%;
  max-width: 420px;
  font-size: 14px;
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 4px;
  cursor: pointer;
`;

const StyledSpan = styled.p`
  font-size: 14px;
  margin-bottom: 13px;
  font-weight: bold;
`;

import { useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import styled from 'styled-components/macro';

import { CollectionActionTableRow } from '../../../types';
import { ButtonDark } from '../../../../../ui/Button';
import { formatDateDDMMYY } from "../../../../../ui/DateFormatMethods";

interface IActionRequiredTableProps {
  tableData: Array<CollectionActionTableRow>;
  onViewCollectionHandler: (paymentRecord: CollectionActionTableRow) => void;
}

export default function ActionRequiredTable({
  tableData,
  onViewCollectionHandler,
}: IActionRequiredTableProps) {
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);

  const tableConfig: ColumnsType<CollectionActionTableRow> = [
    {
      title: 'Exception ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Contributor Name',
      dataIndex: 'contributorName',
      key: 'contributorName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Collection Date',
      dataIndex: 'exceptionDate',
      key: 'exceptionDate',
      render: (text) => formatDateDDMMYY(new Date(text))
    },
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'Amount',
      dataIndex: 'exceptionAmount',
      key: 'exceptionAmount',
    },
    {
      title: 'Gift Aid Amount',
      dataIndex: 'giftAidAmount',
      key: 'giftAidAmount',
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      align: 'center',
      render: (_value, record: CollectionActionTableRow) => (
        <StyleButtonDark onClick={() => onViewCollectionHandler(record)}>
          View
        </StyleButtonDark>
      ),
    },
  ];

  return (
    <Table<CollectionActionTableRow>
      // rowSelection={{
      //   type: 'checkbox',
      //   onChange: (ids) => setSelectedRows(ids as Array<string>),
      //   selectedRowKeys: selectedRows,
      // }}
      dataSource={tableData}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE' }}
    />
  );
}

const StyleButtonDark = styled(ButtonDark)`
  font-size: 14px;
  font-weight: 700;
  width: 78px;
  height: 36px;
  color: #ffffff;
`;

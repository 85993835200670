import * as yup from 'yup';
import { schoolCreateSchema } from '../../project-types/schools/schemas';
import { signUpSchema } from '../../project-types/users/schemas';
import { phoneRegExp } from '../../utils/phoneRegExp';
import { NewCharityAccountType, NewSchoolAccountFormType } from './types';

export const newSchoolAccountSchema: yup.SchemaOf<NewSchoolAccountFormType> =
  yup
    .object({
      confirmPassword: yup
        .string()
        .required()
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    .concat(signUpSchema)
    .concat(schoolCreateSchema);

export const newCharitySchema: yup.SchemaOf<NewCharityAccountType> = yup.object(
  {
    charityName: yup.string().required(),
    id: yup.string().required(),
    regNumber: yup.string().required(),
    nameOfRegulator: yup.string().required(),
    title: yup.string().optional(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup
      .string()
      .email('email must be a valid email')
      .defined('email must be a valid email'),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required(),
    postcode: yup.string().required(),
  }
);

import styled from 'styled-components/macro';
import { useState, useEffect } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SecondHeader } from '../../../ui/Headers';
import Input from '../../../ui/forms/Input';
import SearchIcon from '../../../img/icons/icon_search.svg';
import { VerifiedSchoolTableRow } from '../types';

interface VerifiedSchoolTableProps {
  tableData: Array<VerifiedSchoolTableRow>;
  onDeleteSchoolAccountClickHandler: (schoolId: string) => void;
  onSchoolNameClickHandler: (schoolId: string) => void;
}

export default function VerifiedSchoolTable({ tableData, onDeleteSchoolAccountClickHandler, onSchoolNameClickHandler }: VerifiedSchoolTableProps) {
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [searchValue, setSearchValue] = useState('');

  const tableConfig: ColumnsType<VerifiedSchoolTableRow> = [
    {
      title: 'School Name',
      dataIndex: 'schoolName',
      key: 'schoolName',
      render: (value, record) => <LinkSpan onClick={() => onSchoolNameClickHandler(record.schoolId)}>{value}</LinkSpan>,
    },
    {
      title: 'Date of registration',
      dataIndex: 'dateOfRegistration',
      key: 'dateOfRegistration',
    },
    {
      title: 'Account Status',
      dataIndex: 'accountStatus',
      key: 'accountStatus',
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (value, record) => <DeleteSpan onClick={() => onDeleteSchoolAccountClickHandler(record.schoolId)}>{value}</DeleteSpan>,
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredTableData(tableData.filter((row) => row.schoolName.toLowerCase().includes(searchValue.toLowerCase())));
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue, tableData]);

  return (
    <Wrap>
      <StyledSecondHeader>Verified school accounts</StyledSecondHeader>
      <StyledInput placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} iconSource={SearchIcon} />
      <TableContainer>
        <Table<VerifiedSchoolTableRow> pagination={false} columns={tableConfig} dataSource={filteredTableData} />
      </TableContainer>
    </Wrap>
  );
}

const Wrap = styled.div`
  margin-top: 52px;
`;

const StyledSecondHeader = styled(SecondHeader)`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 32px;
`;

const StyledInput = styled(Input)<{ iconSource: string }>`
  padding: 15px 15px 15px 48px;
  width: 420px;
  margin-bottom: 15px;
  background-image: ${(props) => `url(${props.iconSource})`};
  background-repeat: no-repeat;
  background-position: 17px center;
`;

const TableContainer = styled.div`
  box-sizing: border-box;
  height: 260px;
  overflow-y: scroll;
`;

const DeleteSpan = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const LinkSpan = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

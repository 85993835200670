import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  adminFreezeDonors, adminDeleteDonors,
} from '../../../feature/campaignState';
import { selectAdminDonorsList, getAdminDonorsList } from '../../../feature/adminState';
import AdminLayout from '../layout/AdminLayout';
import { DonorsTableRow } from '../types';
import DonorsTable from './DonorsTable';
import ROUTER_CONFIG from '../../../router/router-config';
import { DonorSearchType } from '../../../project-types/admin-payment/types';

export default function DonorsPage() {
  const [selectedDonors, setSelectedDonors] = useState<Array<string>>([]);
  const dispatch = useDispatch();
  const donorsList = useSelector(selectAdminDonorsList);
  const { push } = useHistory();

  useEffect(() => {
    const filters: Partial<DonorSearchType> = {};
    dispatch(getAdminDonorsList(filters));
  }, [dispatch]);

  const onFreezeClickHandler = () => {
    if (selectedDonors.length === 0) return;

    dispatch(adminFreezeDonors(selectedDonors, true));
    setSelectedDonors([]);
  };
  const onResumeClickHandler = () => {
    if (selectedDonors.length === 0) return;

    dispatch(adminFreezeDonors(selectedDonors, false));
    setSelectedDonors([]);
  };
  const onDeleteClickHandler = () => {
    if (selectedDonors.length === 0) return;
    dispatch(adminDeleteDonors(selectedDonors));
    setSelectedDonors([]);
  };
  const onDonorClickHandler = (donorId: string) => push(ROUTER_CONFIG.ADMIN_DONOR.getPath({ donorId }));

  const tableData: Array<DonorsTableRow> = donorsList.map((donor) => ({
    key: donor._id,
    name: donor.donorName,
    campaignId: donor.campaignName,
    school: donor.schoolName,
    activeCollections: `£ ${donor.activeCollections.join(' ')}`,
    activeTradeIns: 'XXXX',
  }));

  return (
    <AdminLayout>
      <DonorsTable selectedDonors={selectedDonors} onSelectDonor={setSelectedDonors} onDeleteClickHandler={onDeleteClickHandler} onResumeClickHandler={onResumeClickHandler} onFreezeClickHandler={onFreezeClickHandler} tableData={tableData} onDonorClickHandler={onDonorClickHandler} />
    </AdminLayout>
  );
}

import styled from "styled-components/macro";
import InfoIcon from "../../../../img/icons/info.svg";

export default function Pending () {
  return (<Wrap>
    <MainHeader>
      <img src={InfoIcon} width="20px" height="20px" />
      <TextHeader>Your school is pending verification</TextHeader>
    </MainHeader>
    <MainText>Device for Education&rsquo;s admins will be&nbsp;approving shortly.</MainText>
  </Wrap>);
}

const Wrap = styled.main`
  padding: 162px 0 0 78px;
`;

const MainHeader = styled.p`
  margin-top: 0;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
`;

const TextHeader = styled.span`
  font-weight: 400;
  font-size: 22px;
  text-transform: none;
  margin-left: 15px;
`;

const MainText = styled.p`
  font-size: 18px;
`;
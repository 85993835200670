import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { ButtonDark, ButtonLight } from '../../../../ui/Button';
import { newCharitySchema } from '../../admin-schemas';
import { NewCharityAccountType } from '../../types';
import CharityForm from './CharityForm';

interface CreateCharityViewProps {
  onFormSubmit: (values: NewCharityAccountType) => void;
  onCancelClickHandler: () => void;
}

export default function CreateCharityView({ onFormSubmit, onCancelClickHandler }: CreateCharityViewProps) {
  const formik = useFormik({
    initialValues: {
      charityName: '',
      id: '',
      regNumber: '',
      nameOfRegulator: '',
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      postcode: '',
    },
    validationSchema: newCharitySchema,
    onSubmit: (values) => onFormSubmit(values),
  });

  return (
    <Wrapper>
      <Title>New Charity account</Title>
      <CharityForm formikInstance={formik} />
      <ButtonBlock>
        <CancelButton onClick={(e) => {
          e.preventDefault();
          onCancelClickHandler();
        }}
        >
          Cancel
        </CancelButton>
        <SubmitButton type="submit">Create Account</SubmitButton>
      </ButtonBlock>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  box-sizing: border-box;
  padding: 102px 54px 100px;
  flex-grow: 1;
  max-width: 1020px;
`;

const Title = styled.h2`
  font-size: 24px;
  line-height: 26px;
  color: #102f54;
  font-weight: bold;
  margin-bottom: 50px;
`;

const ButtonBlock = styled.div`
  max-width: 630px;
  display: flex;
  gap: 25px;
  margin-top: 110px;
`;

const SubmitButton = styled(ButtonDark)`
  padding: 12px;
  width: 100%;
`;

const CancelButton = styled(ButtonLight)`
  padding: 12px;
  width: 100%;
`;

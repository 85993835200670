import styled from 'styled-components/macro';
import CreateCampaignForm from '../../school/campaign/create-campaign/CreateCampaignForm';
import { CampaignFormFields } from '../../school/types';

interface AdminCreateCampaignProps {
  onSubmit: (campaignFields: CampaignFormFields) => void,
}

export default function AdminCreateCampaign({ onSubmit }: AdminCreateCampaignProps) {
  return (
    <Wrap>
      <Header>New Campaign</Header>
      Launch a new Donation Scheme
      <CreateCampaignForm onSubmit={onSubmit} />
    </Wrap>
  );
}

const Wrap = styled.div`
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
  box-sizing: border-box;
`;

const Header = styled.h1`
  font-weight: 750;
  font-size: 34px;
  margin-top: 0;
  margin-bottom: 0;
`;

import { FC, CSSProperties } from 'react';
import styled from 'styled-components/macro';

import InputWrap from '../ui/forms/InputWrap';

export type FormRow = {
  id?: number;
  title: string;
  name: string;
  component: FC<any>;
  containerStyle?: CSSProperties;
  isRequired?: boolean;
  props?: Record<string, any>;
  labelStyle?: CSSProperties;
  tooltip?: boolean;
  tooltipText?: string;
  message?: string | Function;
};

export type FormConfig = Array<FormRow>;

export function renderFormConfig(
  formConfig: FormConfig,
  formik: any,
  onRemove?: (index: number) => void,
  onAdd?: () => void,
  arrLength?: number,
) {
  return formConfig.map((RowConfig, index) => {
    const rowConfigProps = { ...RowConfig.props };

    if (onRemove) {
      rowConfigProps.onRemove = () => onRemove(index);
    }
    if (onAdd) {
      rowConfigProps.onAdd = () => onAdd();
    }
    if (arrLength) {
      rowConfigProps.arrLength = arrLength;
    }

    const errorText = formik.errors[RowConfig.name];
    const hasError = !!errorText;
    const humanizedErrorText = errorText?.startsWith(RowConfig.name)
      ? `${RowConfig.title} ${errorText?.replace(RowConfig.name, '')}`
      : errorText;

    return (
      <InputWrap
        style={RowConfig.containerStyle || {}}
        key={RowConfig.name}
        isRequired={RowConfig.isRequired}
        title={RowConfig.title}
        hasError={hasError}
        labelStyle={RowConfig.labelStyle || {}}
        tooltip={RowConfig.tooltip}
        tooltipText={RowConfig.tooltipText}
      >
        <RowConfig.component
          value={formik.values[RowConfig.name]}
          key={RowConfig.name}
          name={RowConfig.name}
          id={RowConfig.id}
          onChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
          {...rowConfigProps}
        />
        {hasError && <ErrorDiv hasError={hasError}>{humanizedErrorText}</ErrorDiv>}
        {rowConfigProps.message && (
          <Message>
            {typeof rowConfigProps.message === 'string'
              ? rowConfigProps.message
              : rowConfigProps.message(formik.values[RowConfig.name])}
          </Message>
        )}
      </InputWrap>
    );
  });
}

const ErrorDiv = styled.div<{ hasError?: boolean }>`
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  padding: 4px 4px 0 4px;
  color: red;
`;

const Message = styled.label`
  font-size: 14px;
  color: #102f54;
`;

import { useHistory } from 'react-router';
import ROUTER_CONFIG from '../../../../router/router-config';
import { showPriceDigits } from '../../../../utils/formats';
import DonorLayoutWithTabs from '../../layout/DonorLayoutWithTabs';
import { DeviceTradeinTableRow } from '../../../school/types';
import DeviceTradein from '../../../school/device-tradein/overview-page/DeviceTradein';

export default function DeviceTradeinPage() {
  const { push } = useHistory();

  const tableData: Array<DeviceTradeinTableRow> = [
    {
      key: '123',
      dateSubmitted: '24 Nov 2021',
      type: 'Community',
      nameOrReference: 'Year 7 1:1 Initiative',
      quantityRegistred: 20,
      expectedValue: `£ ${showPriceDigits(800)}`,
    },
    {
      key: '345',
      dateSubmitted: '24 Nov 2021',
      type: 'School',
      nameOrReference: 'Year 7 1:1 Initiative',
      quantityRegistred: 40,
      expectedValue: `£ ${showPriceDigits(900)}`,
    },
  ];

  const onRowClickHandler = (id: string) => push(ROUTER_CONFIG.DONOR_DEVICE_TRADEIN_DETAILS.getPath({ id }));
  const onNewSchemeClickHandler = () => console.log('new schema');
  const onGenerateNewValuationClickHandler = () => console.log('new valuation');

  return (
    <DonorLayoutWithTabs>
      <DeviceTradein onNewSchemeClickHandler={onNewSchemeClickHandler} onGenerateNewValuationClickHandler={onGenerateNewValuationClickHandler} tableData={tableData} onRowClickHandler={onRowClickHandler} />
    </DonorLayoutWithTabs>
  );
}

import { ReactNode } from "react";
import styled from "styled-components/macro";
import Logo from "./Logo";

interface LogoLayoutProps {
  children: ReactNode
}

export default function LogoLayout({ children }: LogoLayoutProps) {
  return (
    <LayoutWrap>
      <Header>
        <Logo />
      </Header>
      {children}
    </LayoutWrap>
  );
}

const LayoutWrap = styled.div`
  padding: 0 92px 0 55px;
  max-width: 1500px;
`;

const Header = styled.header`
  padding-top: 43px;
  margin-bottom: 77px;
`;

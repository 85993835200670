import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { Tabs } from 'antd';
import SchoolLayout from './SchoolLayout';
import ROUTER_CONFIG from '../../../router/router-config';
import AccountMenu from '../../layout/Menu/AccountMenu';
import {
  getIsUserAuth,
  getUserProfile,
  loadProfile,
} from '../../../feature/userState';
import {
  loadBalanceTransfer,
  selectBalanceTransfer,
} from '../../../feature/campaignState';

interface SchoolLayoutWithTabsProps {
  children: ReactNode;
}

const { TabPane } = Tabs;

export default function SchoolLayoutWithTabs({
  children,
}: SchoolLayoutWithTabsProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const profile = useSelector(getUserProfile);
  const isAuth = useSelector(getIsUserAuth);
  const balanceTransferData = useSelector(selectBalanceTransfer);

  useEffect(() => {
    if (!isAuth) {
      dispatch(loadProfile());
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    dispatch(loadBalanceTransfer());
  }, [dispatch]);

  const checkBalanceTransfer =
    Object.keys(balanceTransferData).length !== 0 &&
    balanceTransferData.totalCollected !== '' &&
    balanceTransferData.totalGiftAid !== '' &&
    balanceTransferData.totalRemittance !== '';

  const onTabChange = (activeKey: string) => {
    switch (activeKey) {
      case ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.path:
        history.push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());
        break;
      case ROUTER_CONFIG.SCHOOL_CAMPAIGN_PAYOUTS.path:
        history.push(ROUTER_CONFIG.SCHOOL_CAMPAIGN_PAYOUTS.getPath());
        break;
      case ROUTER_CONFIG.SCHOOL_SCHEME_SUPPORT_SETTINGS.path:
        history.push(ROUTER_CONFIG.SCHOOL_SCHEME_SUPPORT_SETTINGS.getPath());
        break;
      case ROUTER_CONFIG.SCHOOL_DEVICE_TRADEIN.path:
        history.push(ROUTER_CONFIG.SCHOOL_DEVICE_TRADEIN.getPath());
        break;
      default:
        break;
    }
  };

  const onDonationCampaignsClick = () =>
    history.push(ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.getPath());
  const onAffordabilityCalculatorClick = () =>
    window.open('https://afford-edge.silverwing.co', '_blank', 'noopener,noreferrer');
  const onDeviceTradeinClick = () =>
    history.push(ROUTER_CONFIG.SCHOOL_DEVICE_TRADEIN.getPath());
  const onAccountSettingsClick = () =>
    history.push(ROUTER_CONFIG.SCHOOL_SETTINGS.getPath());

  return (
    <SchoolLayout
      menu={
        <AccountMenu
          onDonationCampaignsClick={onDonationCampaignsClick}
          onDeviceTradeinClick={onDeviceTradeinClick}
          onAccountSettingsClick={onAccountSettingsClick}
          onAffordabilityCalculatorClick={onAffordabilityCalculatorClick}
          accountName={profile?.schoolProfile?.schoolName || 'Account'}
        />
      }
      headerLoginLinkTo={ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.path}
    >
      <TabsContainer>
        <StyledTabs
          onChange={onTabChange}
          activeKey={history.location.pathname}
        >
          <TabPane
            tab="Collection Schemes"
            key={ROUTER_CONFIG.SCHOOL_CAMPAIGNS_LIST.path}
          />
          {/* <TabPane tab="Device Trade-in" key={ROUTER_CONFIG.SCHOOL_DEVICE_TRADEIN.path} /> */}
          {checkBalanceTransfer && (
            <TabPane
              tab="Balance Transfer Statements"
              key={ROUTER_CONFIG.SCHOOL_CAMPAIGN_PAYOUTS.path}
            />
          )}
          {/* <TabPane tab="Scheme Support Settings" key={ROUTER_CONFIG.SCHOOL_SCHEME_SUPPORT_SETTINGS.path} /> */}
        </StyledTabs>
      </TabsContainer>
      <Content>{children}</Content>
    </SchoolLayout>
  );
}

const Content = styled.div`
  padding: 15px 150px;
`;

const TabsContainer = styled.div`
  .ant-tabs-nav-wrap {
    background: #f5f8fb;

    .ant-tabs-nav-list {
      padding-left: 150px;
    }
  }
`;

const StyledTabs = styled(Tabs)``;

import styled from 'styled-components/macro';
import { MainHeader } from '../../../ui/Headers';
import { ButtonLight } from '../../../ui/Button';
import VerificationsTable from './VerificationsTable';
import VerifiedSchoolTable from './VerifiedSchoolTable';
import { VerificationTableRow, VerifiedSchoolTableRow, AccountStatuses } from '../types';
import { SchoolAdminPublicType } from '../../../project-types/admin-schools/type';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';

interface SchoolsViewProps {
  onDeleteSchoolClickHandler: (schoolId: string) => void;
  onVerifySchoolClickHandler: (schoolId: string) => void;
  onDeleteSchoolAccountClickHandler: (schoolId: string) => void;
  onCreateSchoolAccountClickHandler: () => void;
  onSchoolNameClickHandler: (schoolId: string) => void;
  schoolsList: Array<SchoolAdminPublicType>;
}

export default function SchoolsView({
  onDeleteSchoolClickHandler, onVerifySchoolClickHandler, onDeleteSchoolAccountClickHandler, schoolsList, onCreateSchoolAccountClickHandler, onSchoolNameClickHandler,
}: SchoolsViewProps) {
  const entryData = schoolsList.reduce((acc: { verificationList: Array<VerificationTableRow>, verifiedList: Array<VerifiedSchoolTableRow> }, school) => {
    if (!school.active) {
      const schoolRow: VerificationTableRow = {
        schoolName: school.schoolName,
        schoolId: school._id,
        dateOfRegistration: school?.regDate ? formatDateDDMMMYYYY(new Date(school.regDate)) : '',
        verify: 'Verify',
        delete: 'Delete',
      };
      acc.verificationList.push(schoolRow);
    } else {
      const schoolRow: VerifiedSchoolTableRow = {
        schoolName: school.schoolName,
        schoolId: school._id,
        dateOfRegistration: school?.regDate ? formatDateDDMMMYYYY(new Date(school.regDate)) : '',
        accountStatus: AccountStatuses.NEEDS_VERIFICATION,
        delete: 'Delete school account',
      };
      acc.verifiedList.push(schoolRow);
    }

    return acc;
  }, { verificationList: [], verifiedList: [] });

  return (
    <Wrapper>
      <StyledMainHeader>Schools</StyledMainHeader>
      <StyledButtonLight onClick={onCreateSchoolAccountClickHandler}>+ New School account</StyledButtonLight>
      <VerificationsTable onDeleteSchoolClickHandler={onDeleteSchoolClickHandler} onVerifySchoolClickHandler={onVerifySchoolClickHandler} tableData={entryData.verificationList} />
      <VerifiedSchoolTable onDeleteSchoolAccountClickHandler={onDeleteSchoolAccountClickHandler} onSchoolNameClickHandler={onSchoolNameClickHandler} tableData={entryData.verifiedList} />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  padding: 102px 54px 48px;
  flex-grow: 1;
  max-width: 1020px;
`;

const StyledMainHeader = styled(MainHeader)`
  font-size: 24px;
  line-height: 26px;
`;

const StyledButtonLight = styled(ButtonLight)`
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 120px;
  margin-top: 36px;
  margin-bottom: 66px;
`;

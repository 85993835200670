import { DatePicker, Dropdown, Menu, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { RadioGroup, RadioItem } from '../../../../../ui/forms/Radio';
import { SecondHeader } from '../../../../../ui/Headers';

import { COLLECTION_STATUSES } from '../../../types';

const { RangePicker } = DatePicker;

const CollectionStatuses = ['All', ...Object.keys(COLLECTION_STATUSES)];

interface CollectionsActionsProps {
  onChangeFilter: (filter: keyof typeof COLLECTION_STATUSES | null) => void;
  onChangeTimePeriod: (period: [string, string]) => void;
}

export default function CollectionsActions({
  onChangeFilter,
  onChangeTimePeriod,
}: CollectionsActionsProps) {
  const [typeofCollection, setTypeOfCollection] = useState<
    typeof CollectionStatuses[number]
  >('All');

  const onFilterClickHandler = (status: string) => {
    if (status === 'All') {
      onChangeFilter(null);
    } else {
      onChangeFilter(status as keyof typeof COLLECTION_STATUSES);
    }
  };

  const dropDownContent = (
    <Menu>
      <Menu.Item onClick={() => {}}>Change Payment Plan</Menu.Item>
      <Menu.Item onClick={() => {}}>Cancel further collections</Menu.Item>
    </Menu>
  );

  return (
    <div>
      <StyledSecondHeader>Collections</StyledSecondHeader>
      <Filter>
        <div>
          Status:
          <FilterGroup
            value={typeofCollection}
            onChange={(e) => setTypeOfCollection(e.target.value)}
          >
            {CollectionStatuses.map((status) => (
              <FilterItem
                key={status}
                onClick={() => onFilterClickHandler(status)}
                value={status}
              >
                {status}
              </FilterItem>
            ))}
          </FilterGroup>
        </div>
        <RangePicker onChange={(_e, period) => onChangeTimePeriod(period)} />
        {/*<Dropdown overlay={dropDownContent}>*/}
        {/*  <StyledLink*/}
        {/*    className="ant-dropdown-link"*/}
        {/*    onClick={(e: React.ChangeEvent<HTMLInputElement>) =>*/}
        {/*      e.preventDefault()*/}
        {/*    }*/}
        {/*  >*/}
        {/*    Actions <DownOutlined />*/}
        {/*  </StyledLink>*/}
        {/*</Dropdown>*/}
      </Filter>
    </div>
  );
}

const Filter = styled.div`
  margin-top: 10px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterGroup = styled(RadioGroup)`
  margin-left: 14px;
`;

const FilterItem = styled(RadioItem)`
  margin-left: 18px;
`;

const StyledLink: FC<any> = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2682de;
  cursor: pointer;
`;

const StyledSecondHeader = styled(SecondHeader)`
  margin: 40px 0 15px 0;
`;
